(function() {
    //'use strict';
    angular
        .module('mobilityexchangeApp')
        .factory('IssueTracker', IssueTracker);

    IssueTracker.$inject = ['$resource', 'DateUtils'];

    function IssueTracker ($resource, DateUtils) {
        var resourceUrl =  'api/issue-tracker/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                url: 'api/issue-tracker/:id?'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data, headers, status) {

                    if(status !== 200)
                        return status;

                    if (data || !_.isEmpty(data)) {
                        data = angular.fromJson(data);

                        data.issueStatusDate = DateUtils.convertDateTimeFromServer(data.issueStatusDate);
                        data.transactionDate = DateUtils.convertDateTimeFromServer(data.transactionDate);
                        data.invoiceDate = DateUtils.convertDateTimeFromServer(data.invoiceDate);
                        data.submissionDate = DateUtils.convertDateTimeFromServer(data.submissionDate);
                        data.iamClassificationDate = DateUtils.convertDateTimeFromServer(data.iamClassificationDate);
                        data.dateAcknowledgedTarget = DateUtils.convertDateTimeFromServer(data.dateAcknowledgedTarget);
                        data.dateAcknowledgedActual = DateUtils.convertDateTimeFromServer(data.dateAcknowledgedActual);
                        data.dateResolvedTarget = DateUtils.convertDateTimeFromServer(data.dateResolvedTarget);
                        data.dateResolvedActual = DateUtils.convertDateTimeFromServer(data.dateResolvedActual);

                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'getServiceProvider': {
                method: 'GET',
                url:'api/issue-tracker/against/:id',
                transformResponse: function (data) {
                    ////console.log('GOT DATA, TRANSFORMING!!');
                    if (data) {
                        data = angular.fromJson(data);
                        if (data.businessRegExpiry) {
                        	data.businessRegExpiry = DateUtils.convertLocalDateFromServer(data.businessRegExpiry);
                        }

                        if (data.createddate) {
                            data.createdDateForDisplay = data.createddate;
                        }

                        if (data.modifieddate) {
                            data.modifiedDateForDisplay = data.modifieddate;
                        }

                        if (data.subscriptionControl) {
                        	data.subscriptionControl.startDate = DateUtils.convertLocalDateFromServer(data.subscriptionControl.startDate);
                        }

                        if (data.subscriptionControl) {
                            data.subscriptionControl.endDate = DateUtils.convertLocalDateFromServer(data.subscriptionControl.endDate);
                        }

                        // convert quality expiration date
                        $.each(data.quality,
                            function (index, quality) {
                                quality.expirydate = DateUtils.convertLocalDateFromServer(quality.expirydate);
                                quality.reviewdate = DateUtils.convertLocalDateFromServer(quality.reviewdate);
                            }
                        );

                        $.each(data.compliance,
                            function (index, compliance) {
                                compliance.expirydate = DateUtils.convertLocalDateFromServer(compliance.expirydate);
                                compliance.reviewdate = DateUtils.convertLocalDateFromServer(compliance.reviewdate);
                            }
                        );

                        $.each(data.memberAssociations,
                            function (index, memberAssociations) {
                                memberAssociations.valExpiryDate = DateUtils.convertLocalDateFromServer(memberAssociations.valExpiryDate);
                                memberAssociations.valReviewDate = DateUtils.convertLocalDateFromServer(memberAssociations.valReviewDate);

                                memberAssociations.statusDate = DateUtils.convertLocalDateFromServer(memberAssociations.statusDate);
                                memberAssociations.originalJoinDate = DateUtils.convertLocalDateFromServer(memberAssociations.originalJoinDate);
                                memberAssociations.currentJoinDate = DateUtils.convertLocalDateFromServer(memberAssociations.currentJoinDate);
                                memberAssociations.expiryDate = DateUtils.convertLocalDateFromServer(memberAssociations.expiryDate);
                                memberAssociations.terminationDate = DateUtils.convertLocalDateFromServer(memberAssociations.terminationDate);
                            }
                        );

                        $.each(data.serviceproviderContacts,
                            function (index, serviceproviderContacts) {
                                serviceproviderContacts.dob = DateUtils.convertLocalDateFromServer(serviceproviderContacts.dob);
                                $.each(serviceproviderContacts.contactQualifications,
                                    function (index, contactQualifications) {
                                        contactQualifications.certDate = DateUtils.convertLocalDateFromServer(contactQualifications.certDate);
                                        contactQualifications.expDate= DateUtils.convertLocalDateFromServer(contactQualifications.expDate);
                                    }
                                )
                            }
                        );
                    }

                    return data;
                }
            },
            'getServiceProviderMemberStatus': {
                method: 'GET',
                url:'api/service-providers/:id/member-status'
            },
            'getAll': {
                method: 'GET',
                url: 'api/issue-tracker/sp/:spId',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'getClaimant': {
                method: 'GET',
                url: 'api/issue-tracker-claimant/',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            }
            ,
            'getClaimantCandidates': {
                method: 'GET',
                url: 'api/issue-tracker/claimant/candidate',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            }
            ,
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        data.issueStatusDate = DateUtils.convertDateTimeFromServer(data.issueStatusDate);
                        data.transactionDate = DateUtils.convertDateTimeFromServer(data.transactionDate);
                        data.invoiceDate = DateUtils.convertDateTimeFromServer(data.invoiceDate);
                        data.submissionDate = DateUtils.convertDateTimeFromServer(data.submissionDate);
                        data.iamClassificationDate = DateUtils.convertDateTimeFromServer(data.iamClassificationDate);
                        data.dateAcknowledgedTarget = DateUtils.convertDateTimeFromServer(data.dateAcknowledgedTarget);
                        data.dateAcknowledgedActual = DateUtils.convertDateTimeFromServer(data.dateAcknowledgedActual);
                        data.dateResolvedTarget = DateUtils.convertDateTimeFromServer(data.dateResolvedTarget);
                        data.dateResolvedActual = DateUtils.convertDateTimeFromServer(data.dateResolvedActual);

                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        data.issueStatusDate = DateUtils.convertDateTimeFromServer(data.issueStatusDate);
                        data.transactionDate = DateUtils.convertDateTimeFromServer(data.transactionDate);
                        data.invoiceDate = DateUtils.convertDateTimeFromServer(data.invoiceDate);
                        data.submissionDate = DateUtils.convertDateTimeFromServer(data.submissionDate);
                        data.iamClassificationDate = DateUtils.convertDateTimeFromServer(data.iamClassificationDate);
                        data.dateAcknowledgedTarget = DateUtils.convertDateTimeFromServer(data.dateAcknowledgedTarget);
                        data.dateAcknowledgedActual = DateUtils.convertDateTimeFromServer(data.dateAcknowledgedActual);
                        data.dateResolvedTarget = DateUtils.convertDateTimeFromServer(data.dateResolvedTarget);
                        data.dateResolvedActual = DateUtils.convertDateTimeFromServer(data.dateResolvedActual);

                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'addComment': {
                method: 'POST',
                // url: 'api/issue-tracker/:id/comment',
                url: 'api/issue-comments',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'getComments': {
                method: 'GET',
                url:'api/issue-tracker/:id/comments',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }

                    return data;
                }
            }
        });
    }
})();
