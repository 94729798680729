(function() {
	// 'use strict';

	angular.module('mobilityexchangeApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'claim-header',
						{
							parent : 'entity',
							url : '/claim-header?page&sort&search&claimId&filedBy&bIamId&aIamId&filedAgainist&filedAgainstMxStatus&fileDate&modifiedDate&totalClaim&status&navtype',
							data : {
								authorities : [ 'RPP_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ],
								pageTitle : 'mobilityexchangeApp.claimHeader.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/claim-header/claim-headers.html',
									controller : 'ClaimHeaderController',
									controllerAs : 'vm'
								}
							},
							params : {
								page : {
									value : '1',
									squash : true
								},
								sort : {
									value : 'id,desc',
									squash : true
								},
								search : null,
								claimId : null,
								filedBy : null,
								bIamId : null,
								aIamId : null,
								filedAgainist : null,
								filedAgainstMxStatus : null,
								fileDate : null,
								modifiedDate : null,
								totalClaim : null,
								status : null,
								navtype : null
							},
							resolve : {
								pagingParams : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												page : PaginationUtil
														.parsePage($stateParams.page),
												sort : $stateParams.sort,
												predicate : PaginationUtil
														.parsePredicate($stateParams.sort),
												ascending : PaginationUtil
														.parseAscending($stateParams.sort),
												search : $stateParams.search
											};
										} ],
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('claimHeader');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})
				.state(
						'claim-lookup',
						{
							parent : 'entity',
							url : '/claim-header?page&sort&search&navtype',
							data : {
								authorities : [ 'RPP_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ],
								pageTitle : 'mobilityexchangeApp.claimHeader.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/claim-header/claim-headers.html',
									controller : 'ClaimHeaderController',
									controllerAs : 'vm'
								}
							},
							params : {
								page : {
									value : '1',
									squash : true
								},
								sort : {
									value : 'id,desc',
									squash : true
								},
								search : null,
								claimId : null,
								filedBy : null,
								bIamId : null,
								aIamId : null,
								filedAgainist : null,
								filedAgainstMxStatus : null,
								fileDate : null,
								modifiedDate : null,
								totalClaim : null,
								status : null,
							},
							resolve : {
								pagingParams : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												page : PaginationUtil
														.parsePage($stateParams.page),
												sort : $stateParams.sort,
												predicate : PaginationUtil
														.parsePredicate($stateParams.sort),
												ascending : PaginationUtil
														.parseAscending($stateParams.sort),
												search : $stateParams.search
											};
										} ],
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('claimHeader');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})
				.state(
						'claim-header-detail',
						{
							parent : 'claim-header',
							url : '/claim-header/{id}',
							data : {
								authorities : [ 'RPP_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ],
								pageTitle : 'mobilityexchangeApp.claimHeader.detail.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/claim-header/claim-header-detail.html',
									controller : 'ClaimHeaderDetailController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('claimHeader');
											return $translate.refresh();
										} ],
								entity : [ '$stateParams', 'ClaimHeader',
										function($stateParams, claimHeader) {
											return ClaimHeader.get({
												id : $stateParams.id
											}).$promise;
										} ],
								previousState : [
										"$state",
										function($state) {
											var currentStateData = {
												name : $state.current.name
														|| 'claim-header',
												params : $state.params,
												url : $state.href(
														$state.current.name,
														$state.params)
											};
											return currentStateData;
										} ]
							}
						})
				.state(
						'claim-header-detail.edit',
						{
							parent : 'claim-header-detail',
							url : '/detail/edit',
							data : {
								authorities : [ 'RPP_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ]
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/claim-header/claim-header-dialog.html',
									controller : 'ClaimHeaderDialogController',
									controllerAs : 'vm',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('claimHeader');
											return $translate.refresh();
										} ],
								entity : [ 'ClaimHeader',
										function(ClaimHeader) {
											return ClaimHeader.get({
												id : $stateParams.id
											}).$promise;
										} ],
								previousState : [
										"$state",
										function($state) {
											var currentStateData = {
												name : $state.current.name,
												params : $state.params,
												url : $state.href(
														$state.current.name,
														$state.params)
											};
											return currentStateData;
										} ]
							}
						})
				.state(
						'claim-header.new',
						{
							parent : 'claim-header',
							url : '/new/{sp}',
							data : {
								authorities : [ 'RPP_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ]
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/claim-header/claim-header-dialog.html',
									controller : 'ClaimHeaderDialogController',
									controllerAs : 'vm',
								}
							},
							resolve : {
								entity : function() {
									return {
										comments : null,
										createdDate : null,
										createdBy : null,
										modifiedDate : null,
										modifiedBy : null,
										id : null
									};
								}
							},
							previousState : [
									"$state",
									function($state) {
										var currentStateData = {
											isCopy : 'false',
											params : $state.params,
											url : $state.href(
													$state.current.name,
													$state.params)
										};
										return currentStateData;
									} ]
						})
				.state(
						'claim-header.file',
						{
							parent : 'claim-header',
							url : '/file',
							data : {
								authorities : [ 'ROLE_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ]
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/claim-header/step1.html',
									controller : 'ClaimHeaderFileController',
									controllerAs : 'vm',
								}
							},
							resolve : {
								entity : [
										'$stateParams',
										'ClaimHeader',
										function($stateParams, ClaimHeader) {
											return ClaimHeader.getClaimant().$promise;
										} ]
							},
							previousState : [
									"$state",
									function($state) {
										var currentStateData = {
											isCopy : 'false',
											params : $state.params,
											url : $state.href(
													$state.current.name,
													$state.params)
										};
										return currentStateData;
									} ]
						})
				.state(
						'claim-header.edit',
						{
							parent : 'claim-header',
							url : '/{id}',
							data : {
								authorities : [ 'RPP_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ],
								pageTitle : 'mobilityexchangeApp.claimHeader.detail.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/claim-header/claim-header-dialog.html',
									controller : 'ClaimHeaderDialogController',
									controllerAs : 'vm',
								}
							},
							resolve : {
								entity : [ '$stateParams', 'ClaimHeader',
										function($stateParams, ClaimHeader) {
											return ClaimHeader.get({
												id : $stateParams.id
											}).$promise;
										} ],
								previousState : [
										"$state",
										function($state) {
											var currentStateData = {
												name : $state.current.name
														|| 'claim-header',
												params : $state.params,
												url : $state.href(
														$state.current.name,
														$state.params)
											};
											return currentStateData;
										} ]
							}
						})
				.state(
						'claim-header.delete',
						{
							parent : 'claim-header',
							url : '/{id}/delete',
							data : {
								authorities : [ 'RPP_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ]
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/claim-header/claim-header-delete-dialog.html',
													controller : 'claimHeaderDeleteController',
													controllerAs : 'vm',
													size : 'md',
													resolve : {
														entity : [
																'ClaimHeader',
																function(
																		ClaimHeader) {
																	return ClaimHeader
																			.get({
																				id : $stateParams.id
																			}).$promise;
																} ]
													}
												}).result.then(function() {
											$state.go('claim-header', null, {
												reload : 'claim-header'
											});
										}, function() {
											$state.go('^');
										});
									} ]
						});
	}

})();
