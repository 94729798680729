(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('report-schedule.edit', {
            parent: 'sql-extract',
            url: '/sql-extract/{id}/schedule',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sql-extract/report-schedule.html',
                    controller: 'SQLExtractDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ReportSchedule', function(ReportSchedule) {
                            return ReportSchedule.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('sql-extract', null, { reload: 'sql-extract' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        ;
    }

})();
