(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('ElasticsearchReindexController', ElasticsearchReindexController);

    ElasticsearchReindexController.$inject = [];

    function ElasticsearchReindexController() {
    }
})();
