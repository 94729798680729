(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('MemberContactsProfileImageCropController', MemberContactsProfileImageCropController);

    MemberContactsProfileImageCropController.$inject = ['$uibModalInstance', 'entity', '$http', '$scope', 'Upload', '$timeout'];

    function MemberContactsProfileImageCropController($uibModalInstance, entity, $http, $scope, Upload, $timeout) {
        var vm = this;

        vm.memberContacts = entity;
        vm.clear = clear;
        vm.providerContactFile = null;
        function clear() {
        	$uibModalInstance.close(vm.providerContactFile);
        }

        $scope.upload = function (dataUrl) {
        	$scope.resultMsg = "uploading picture...";
            Upload.upload({
                url: '/api/contactfileupload?spid='+vm.memberContacts.serviceProviderId +'&cpid='+ vm.memberContacts.id+'&docdesc=profile&type=picture&typeid=1',
                data: {
                    file: Upload.dataUrltoBlob(dataUrl)
                },
            }).then(function (response) {
                $timeout(function () {
                    $scope.result = response.data;
                    vm.providerContactFile = response.data;
                    $scope.resultMsg = "Upload Successful.";
                    $uibModalInstance.close(vm.providerContactFile);
                });
            }, function (response) {
                if (response.status > 0) {
                	 $scope.result = null;
                	$scope.errorMsg = response.status 
                    + ': ' + response.data;
                	}
            });
        }
    }
})();
