(function() {
	// 'use strict';

	angular.module('mobilityexchangeApp')
	.controller('RegisterCompanyController', RegisterCompanyController)
			.controller('ClaimBusinessController', ClaimBusinessController);

	RegisterCompanyController.$inject = [ '$rootScope', '$scope',
			'previousState', '$http', '$translate', '$timeout',
			'ServiceProviders', 'ProviderContacts', '$location', 'Lookups',
			'DTOptionsBuilder', 'DTColumnDefBuilder', 'MemberProfile',
			'LoginService' ];
	ClaimBusinessController.$inject = [ '$rootScope', '$scope',
			'previousState', '$http', '$translate', '$timeout',
			'ServiceProviders', 'ProviderContacts', '$location', 'Lookups',
			'entity' ];

	function RegisterCompanyController($rootScope, $scope, previousState,
			$http, $translate, $timeout, ServiceProviders, ProviderContacts,
			$location, Lookups, DTOptionsBuilder, DTColumnDefBuilder,
			MemberProfile, LoginService) {
		$scope.controllerName = "RegisterCompanyController";
		$rootScope.showSearchBox = true;
		$rootScope.mainLogoLink = "#";
		var vm = this;
		vm.save = save;
		vm.savecontact = savecontact;
		vm.saveProfile = saveProfile;
		vm.currentYear = new Date().getFullYear();

		vm.countryCodes = Lookups.getByLookupType({
			id : 50
		});

		vm.functionalRoles = Lookups.getByLookupType({
			id : 12
		});
		var _searchTerm;
		$scope.spid = previousState.spid;
		$scope.getServices = function(svc_val) {

			if (svc_val.length < 3)
				return;

			_searchTerm = svc_val;

			return $http.get('api/_search/services/serviceprovidername', {
				params : {
					query : '' + svc_val + '',
					sensor : false
				}
			}).then(function(response) {
				return response.data.map(function(searchresults) {
					return searchresults;
				});
			});
		};

		$scope.invalidCaptcha = false;
		vm.phonePrefix = '';

		$scope.setPhonePrefix = function(countryCode) {
			for (var i = 0; i < vm.countryCodes.length; i++) {
				if (vm.countryCodes[i].lookupvalue === countryCode) {
					vm.phonePrefix = '+' + vm.countryCodes[i].flex3;
				}
			}
		};

		$scope.Captcha = function() {
			var alpha = new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I',
					'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U',
					'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g',
					'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's',
					't', 'u', 'v', 'w', 'x', 'y', 'z');
			var i;
			var code = "";
			for (i = 0; i < 6; i++) {
				code = code + alpha[Math.floor(Math.random() * alpha.length)]
						+ " ";
			}
			$scope.mainCaptcha = code;
		};
		function ValidCaptcha() {
			if (vm.c == undefined)
				return false;
			var string1 = removeSpaces($scope.mainCaptcha);
			var string2 = removeSpaces(vm.c);
			if (string1 == string2) {
				$scope.invalidCaptcha = false;
				return true;
			} else {
				$scope.invalidCaptcha = true;
				return false;
			}
		}

		function ValidCaptchaContact() {
			if (vm.c == undefined)
				return false;
			var string1 = removeSpaces($scope.mainCaptcha);
			var string2 = removeSpaces(vm.c);
			if (string1 == string2) {
				$scope.invalidCaptcha = false;
				return true;
			} else {
				$scope.invalidCaptcha = true;
				return false;
			}
		}

		function removeSpaces(string) {
			return string.split(' ').join('');
		}

		$scope.getMatchingCompany = function() {
			if (vm.serviceProviders.serviceproviderAddresses[0].country === undefined) {
				return;
			}
			if (vm.serviceProviders.companylegalname.length < 3)
				return;

			_searchTerm = svc_val;

			return $http.get('api/_search/services/serviceprovidername', {
				params : {
					query : '' + svc_val + '',
					sensor : false
				}
			}).then(function(response) {
				return response.data.map(function(searchresults) {
					return searchresults;
				});
			});
		};

		vm.confirmRegistration = function() {
			confirmSave();
		}

		function confirmSave() {

			if (!ValidCaptcha()) {
				$scope.submitted = true;
				return;
			}

			if (vm.registerform.$invalid) {
				$scope.submitted = true;
			} else {
                $scope.saving = true;
				vm.serviceProviders.serviceproviderContacts = Object
						.values(vm.serviceProviders.serviceproviderContacts);
				vm.serviceProviders.serviceproviderAddresses = Object
						.values(vm.serviceProviders.serviceproviderAddresses);
				vm.serviceProviders.serviceproviderContacts[0].officephone = vm.phonePrefix
						+ ' '
						+ vm.serviceProviders.serviceproviderContacts[0].officephone;
				vm.serviceProviders.createdby = 'reg_'
						+ vm.serviceProviders.serviceproviderContacts[0].lastname;
				vm.serviceProviders.serviceproviderContacts[0].publicDisplay = false;
				ServiceProviders.confirmSave(vm.serviceProviders, onSaveConfirmSuccess, onSaveError);
			}
		}

		function save() {

			if (!ValidCaptcha()) {
				$scope.submitted = true;
				return;
			}

			if (vm.registerform.$invalid) {
				$scope.submitted = true;
			} else {
				$scope.saving = true;
				vm.serviceProviders.serviceproviderContacts = Object
						.values(vm.serviceProviders.serviceproviderContacts);
				vm.serviceProviders.serviceproviderAddresses = Object
						.values(vm.serviceProviders.serviceproviderAddresses);
				vm.serviceProviders.serviceproviderContacts[0].officephone = vm.phonePrefix
						+ ' '
						+ vm.serviceProviders.serviceproviderContacts[0].officephone;
				vm.serviceProviders.createdby = 'reg_'
						+ vm.serviceProviders.serviceproviderContacts[0].lastname;
				vm.serviceProviders.serviceproviderContacts[0].publicDisplay = false;
				vm.response = ServiceProviders.save(vm.serviceProviders, onSaveSuccess, onSaveError);
			}
		}

		function savecontact() {
			if (vm.registerform.$invalid) {
				$scope.submitted = true;
			} else {
				vm.serviceProviders.serviceproviderContacts[0].serviceprovider.id = previousState.spid;
				vm.serviceProviders.serviceproviderContacts[0].publicDisplay = false;
				ProviderContacts.save(vm.serviceProviders.serviceproviderContacts, onSaveSuccess, onSaveError);
			}
		}

		function onSaveConfirmSuccess(result, headers) {
			$scope.$emit('mobilityexchangeApp:serviceProvidersUpdate', result);
			setSavingFlags(false);
			$location.path('registersuccess');
		}

		function onSaveError() {
			setSavingFlags(false);
		}

		function onSaveSuccess(result, headers) {

			$scope.$emit('mobilityexchangeApp:serviceProvidersUpdate', result);
			setSavingFlags(false);
			var existingProviderContactFlag = headers()['app_existing_provider_contact'];
			var existingServiceProvidersFlag = headers()['app_existing_service_providers'];
			if (existingProviderContactFlag) {
				$scope.existingProviderContactMsg = "Contact is associated with registered company";
			} else if (existingServiceProvidersFlag) {
				$scope.existingServiceProvidersMsg = "Company name is associated with registered company";
			} else {
				$location.path('registersuccess');
			}
		}

        function setSavingFlags(saving) {
			vm.isSaving = saving;
			$scope.saving = saving;
        }

		$scope.setSPId = function(spResult) {
			$scope.spIdSelected = spResult.id;
		};

		$scope.countrySelected = false;
		$scope.changeCountry = function() {
			$scope.countrySelected = true;
		};

		$scope.findcompany = function() {
			alert('findcompany=' + vm.serviceProviders.companylegalname
					+ ',country='
					+ vm.serviceProviders.serviceproviderAddresses[0].country);
		};

		vm.getServiceProviders = function(search) {
			vm.serviceProviderSelected = null;
			$http
					.get(
							'api/_search/services/serviceprovidername/?query='
									+ search).then(function(response) {
						vm.serviceProvidersSearchResult = response.data;
					});
		};

		vm.getProviderContacts = function(email) {
			if (email !== '') {
				$http.get('api/member-contacts/email/?email=' + email).then(
						function(response) {
							vm.providerContactsSearchResult = response.data;
							if (!vm.providerContactsSearchResult.providerConactId) {
								vm.gotoStep(vm.currentStep + 1);
							} else if (vm.providerContactsSearchResult.authorities) {
								for (var i = 0; i < vm.providerContactsSearchResult.authorities.length; i++) {
									if (vm.providerContactsSearchResult.authorities[i]) {
										var roleName = vm.providerContactsSearchResult.authorities[i].name;
										if (roleName === 'ROLE_USER') {
											vm.providerContactsSearchResultHasUserRole = 'YES';
										}
										if (roleName === 'ROLE_SP_ADMIN') {
											vm.providerContactsSearchResultHasAdminRole = 'YES';
										}
									}
								}
							}
						});
			}
		};

		vm.sendRegistrationSupportEmail = function(email, subject) {
			if (email !== '') {
				$http.get('api/member-contacts/sendemail/?email=' + email + '&subject='+subject).then(
						function(response) {

							if(response.status == 200) {
								vm.registrationSupportMessage = 'Your request is being processed.  Our support team will review your request and be in touch with you shortly.';
							}
						});
			}
		};

		vm.processStepO = function(choice, email) {
			switch(choice){
			case '1':
				LoginService.open();
				break;
			case '2':
				$location.path('reset/request');
				break;
			case '3':
				vm.sendRegistrationSupportEmail(email, 'User Access request');
				break;
			case '4':
				vm.sendRegistrationSupportEmail(email, 'Admin Access request');
				break;
			}

		}

		$scope.hasSignInCredentials = [];
		$scope.hasAdminRight = [];
		vm.dtOptions = DTOptionsBuilder.newOptions()
				.withDOM('C<"clear">lfrtip').withOption('lengthMenu',
						[ 50, 100, 150, 200 ]);

		vm.dtColumnDefs = [ DTColumnDefBuilder.newColumnDef(3).notSortable() ];

		vm.dtOptions1 = DTOptionsBuilder.newOptions().withDOM(
				'C<"clear">lfrtip');
		vm.dtColumnDefs1 = [ DTColumnDefBuilder.newColumnDef(2).notSortable(),
				DTColumnDefBuilder.newColumnDef(3).notSortable() ];
		vm.getServiceProvider = function(spid) {
			vm.idSelectedSp = spid;
			$http
					.get('api/service-providers/register/' + spid)
					.then(
							function(response) {
								vm.serviceProviderSelected = response.data;

								if (vm.serviceProviderSelected.serviceproviderContacts) {
									for (var i = 0; i < vm.serviceProviderSelected.serviceproviderContacts.length; i++) {
										getProviderContactRoles(
												vm.serviceProviderSelected.serviceproviderContacts[i],
												vm.serviceProviderSelected.serviceproviderContacts[i].id);
									}
								}
								vm.gotoStep(vm.currentStep + 1);
							});

		}

		function getProviderContactRoles(providerContact, id) {
			$http
					.get('/api/provider-contact-roles/' + id)
					.then(
							function(response) {
								$scope.providerContactRoles = response.data.authorities;
								$scope.hasSignInCredentials[id] = 'NO';
								$scope.hasAdminRight[id] = 'NO';
								if ($scope.providerContactRoles)
									for (var i = 0; i < $scope.providerContactRoles.length; i++) {
										if ($scope.providerContactRoles[i]) {
											var roleName = $scope.providerContactRoles[i].name;
											if (roleName === 'ROLE_USER') {
												$scope.hasSignInCredentials[id] = 'YES';
											}
											if (roleName === 'ROLE_SP_ADMIN') {
												$scope.hasAdminRight[id] = 'YES';
											}
										}
									}
							});
		}
		;

		// wizard
		var vm = this;

		// Model
		vm.currentStep = 0;
		vm.steps = [ {
			step : 0,
			name : "First step",
			template : "app/account/registercompany/step0.html"
		}, {
			step : 1,
			name : "Second step",
			template : "app/account/registercompany/step1.html"
		}, {
			step : 2,
			name : "Third step",
			template : "app/account/registercompany/step2.html"
		}, {
			step : 3,
			name : "Fourth step",
			template : "app/account/registercompany/step3.html"
		}, {
			step : 4,
			name : "fifth step",
			template : "app/account/registercompany/step4.html"
		}, ];
		vm.user = {};

		// Functions
		vm.gotoStep = function(newStep) {
			vm.currentStep = newStep;
		}

		vm.getStepTemplate = function() {
			for (var i = 0; i < vm.steps.length; i++) {
				if (vm.currentStep == vm.steps[i].step) {
					return vm.steps[i].template;
				}
			}
		}

		function saveProfile() {

			if (!ValidCaptchaContact()) {
				$scope.submitted = true;
				return;
			}
			if (vm.editForm.$invalid) {
				$scope.submitted = true;
			} else {
				$scope.saving = true;
				vm.memberContacts.id = -1;
				vm.memberContacts.serviceProviderId = vm.serviceProviderSelected.id;
				MemberProfile.save(vm.memberContacts, onSaveSuccess, onSaveMemberError);
			}
		}

		function onSaveMemberError() {
			$scope.saving = false;
			$scope.showSpinner = false;
		}

	}

	function ClaimBusinessController($rootScope, $scope, previousState, $http,
			$translate, $timeout, ServiceProviders, ProviderContacts,
			$location, Lookups, entity) {
		var vm = this;
		vm.savecontact = savecontact;
		vm.functionalRoles = Lookups.getByLookupType({
			id : 12
		});
		$scope.spid = previousState.spid;
		vm.serviceProviders = entity;
		vm.currentYear = new Date().getFullYear();
		$scope.selected_model = vm.functionalRoles;

		function setPhonePrefixes() {
			for (var i = 0; i < vm.serviceProviders.serviceproviderAddresses.length; i++) {
				if (vm.serviceProviders.serviceproviderAddresses[i].addressType.id == '1530') {
					var phonePrefix = vm.serviceProviders.serviceproviderAddresses[i].phonePrefix;
					vm.officephonePrefix = '+' + phonePrefix;
					vm.cellPrefix = '+' + phonePrefix;
				}
			}
		}

		setPhonePrefixes();

		function savecontact() {

			if (!ValidCaptcha()) {
				$scope.submitted = true;
				return;
			}
			if (vm.registerform.$invalid) {
				$scope.submitted = true;
			} else {
				vm.providerContact.serviceproviders = vm.serviceProviders;
				vm.providerContact.officephone = vm.officephonePrefix + ' '
						+ vm.providerContact.officephone;
				vm.providerContact.cellphone = vm.cellPrefix + ' '
						+ vm.providerContact.cellphone;
				ProviderContacts.save(vm.providerContact, onSaveSuccess, onSaveError);
			}
		}

		function onSaveSuccess(result) {
			$scope.$emit('mobilityexchangeApp:providerContactSave', result);
			vm.isSaving = false;
			$location.path('claimsuccess/' + $scope.spid);
		}

		function onSaveError() {
			vm.isSaving = false;
		}

		$scope.mxEmail = function(emailid) {
			$scope.sendMail(emailid, "Query from IAM Mobility Exchange", "");
		};

		$scope.invalidCaptcha = false;

		$scope.Captcha = function() {
			var alpha = new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I',
					'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U',
					'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g',
					'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's',
					't', 'u', 'v', 'w', 'x', 'y', 'z');
			var i;
			var code = "";
			for (i = 0; i < 6; i++) {
				code = code + alpha[Math.floor(Math.random() * alpha.length)]
						+ " ";
			}
			$scope.mainCaptcha = code;
		};
		function ValidCaptcha() {
			if ($scope.c == undefined)
				return false;
			var string1 = removeSpaces($scope.mainCaptcha);
			var string2 = removeSpaces($scope.c);
			if (string1 == string2) {
				$scope.invalidCaptcha = false;
				return true;
			} else {
				$scope.invalidCaptcha = true;
				return false;
			}
		}

		function removeSpaces(string) {
			return string.split(' ').join('');
		}
	}

})();
