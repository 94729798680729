(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('DisclaimerDialogController', DisclaimerDialogController);

    DisclaimerDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Disclaimer', 'DisclaimerTracking'];

    function DisclaimerDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Disclaimer, DisclaimerTracking) {
        var vm = this;

        vm.disclaimer = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        vm.accept = accept;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.disclaimerTracking = {};

        function accept () {
            DisclaimerTracking.save(vm.disclaimerTracking, onSaveSuccess, onSaveError);
        }

        function decline () {

        }


        function save () {
            vm.isSaving = true;
            if (vm.disclaimer.id !== null) {
                Disclaimer.update(vm.disclaimer, onSaveSuccess, onSaveError);
            } else {
                Disclaimer.save(vm.disclaimer, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('mobilityexchangeApp:disclaimerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.effective_date = false;
        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.modifiedDate = false;
        vm.datePickerOpenStatus.modifiedBy = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
