(function() {
    //'use strict';
    angular
        .module('mobilityexchangeApp')
        .factory('AuditLog', AuditLog);

    AuditLog.$inject = ['$resource', 'DateUtils'];

    function AuditLog ($resource, DateUtils) {
        var resourceUrl =  'api/audit-logs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertDateTimeFromServer(data.createddate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.createddate = DateUtils.convertLocalDateToServer(copy.createddate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.createddate = DateUtils.convertLocalDateToServer(copy.createddate);
                    return angular.toJson(copy);
                }
            },
            'getSPData': {
                method: 'GET',
                url: 'api/sp-audit-logs/:spid',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                    }
                    return data;
                }

            },
            'getAdvData': {
                method: 'GET',
                url: 'api/adv-audit-logs/:advId',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                    }
                    return data;
                }
            },
            'getClaimHeaderLogs': {
                method: 'GET',
                url: 'api/audit-logs/claim-header/:claimHeaderId',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                    }
                    return data;
                }
            },
            'getIssueTrackerLogs': {
                method: 'GET',
                url: 'api/audit-logs/issue-tracker/:issueTrackerId',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                    }
                    return data;
                }
            },
            'revokeacceptChange':{
                method: 'PUT',
                url: 'api/revokeaccept-audit-logs',
                params: {id: '@id'},
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    //copy.createddate = DateUtils.convertLocalDateToServer(copy.createddate);
                    return angular.toJson(copy);
                }
            },
            'getPendingSPData':{
                method: 'GET',
                url: 'api/sp-audit-logs-pending/:spid',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertDateTimeFromServer(data.createddate);
                    }
                    return data;
                }
            },
            'getAllAuditData':{
                method: 'GET',
                url: 'api/sp-audit-logs-all/:spid',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertDateTimeFromServer(data.createddate);
                    }
                    return data;
                }
            }
        });
    }
})();
