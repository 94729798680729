(function() {
	// 'use strict';

	angular.module('mobilityexchangeApp').controller('ClaimHeaderController',
			ClaimHeaderController).controller('ClaimHeaderFileController',
			ClaimHeaderFileController);

	ClaimHeaderController.$inject = [ 'ClaimHeader', 'pagingParams', '$http',
			'$scope', 'Lookups', 'AlertService', 'Principal',
			'ClaimHeaderSearch', 'paginationConstants', '$stateParams',
			'ParseLinks', '$state' ];

	ClaimHeaderFileController.$inject = [ '$http', '$scope',
			'DTOptionsBuilder', 'DTColumnDefBuilder', 'ClaimHeader', 'entity', 'DateUtils' ];

	function ClaimHeaderController(ClaimHeader, pagingParams, $http, $scope,
			Lookups, AlertService, Principal, ClaimHeaderSearch,
			paginationConstants, $stateParams, ParseLinks, $state) {

		var vm = this;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.loadAll = loadAll;

		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.clear = clear;
		vm.transitionOnKeyUp = transitionOnKeyUp;
		vm.search = search;
		vm.searchQuery = pagingParams.search;
		vm.currentSearch = pagingParams.search;
		vm.claimId = $stateParams.claimId;
		vm.filedBy = $stateParams.filedBy;
		vm.bIamId = $stateParams.bIamId;
		vm.aIamId = $stateParams.aIamId;
		vm.filedAgainist = $stateParams.filedAgainist;
		vm.filedAgainstMxStatus = $stateParams.filedAgainstMxStatus;
		vm.fileDate = $stateParams.fileDate;
		vm.modifiedDate = $stateParams.modifiedDate;
		vm.totalClaim = $stateParams.totalClaim;
		vm.status = $stateParams.status;
		vm.claimStatus = Lookups.getByLookupType({
			id : 70
		});

        vm.fileNewClaim = fileNewClaim;

		$scope.ifRppAdmin = function() {
			return Principal.hasAnyAuthority([ 'RPP_ADMIN' ]);
		};

		loadAll();

		function loadAll() {
			if (pagingParams.search) {
				ClaimHeaderSearch.query({
					query : pagingParams.search,
					page : pagingParams.page - 1,
					size : vm.itemsPerPage,
					sort : sort(),
					claimId : vm.claimId ? vm.claimId : $stateParams.claimId,
					filedBy : vm.filedBy ? vm.filedBy : $stateParams.filedBy,
					bIamId : vm.bIamId ? vm.bIamId : $stateParams.bIamId,
					aIamId : vm.aIamId ? vm.aIamId : $stateParams.aIamId,
					filedAgainist : vm.filedAgainist ? vm.filedAgainist
							: $stateParams.filedAgainist,
					filedAgainstMxStatus : vm.filedAgainstMxStatus ? vm.filedAgainstMxStatus
							: $stateParams.filedAgainstMxStatus,
					fileDate : vm.fileDate ? vm.fileDate
							: $stateParams.fileDate,
					modifiedDate : vm.modifiedDate ? vm.modifiedDate
							: $stateParams.modifiedDate,
					totalClaim : vm.totalClaim ? vm.totalClaim
							: $stateParams.totalClaim,
					status : vm.status ? vm.status : $stateParams.status
				}, onSuccess, onError);
			} else {
				ClaimHeader.query({
					page : pagingParams.page - 1,
					size : vm.itemsPerPage,
					sort : sort()

				}, onSuccess, onError);
			}

			function sort() {

				var result = [ vm.predicate + ','
						+ (vm.reverse ? 'asc' : 'desc') ];

				if (vm.predicate !== 'id') {
					result.push('id');
				}
				return result;
			}

		}

		function onSuccess(data, headers) {
			vm.links = ParseLinks.parse(headers('link'));
			vm.totalItems = headers('X-Total-Count');
			vm.queryCount = vm.totalItems;
			vm.ClaimHeaders = data;
			vm.claims = vm.ClaimHeaders.claims;
			vm.delinquents = vm.ClaimHeaders.delinquent;
			vm.page = pagingParams.page;

		}

		function onError(error) {
			AlertService.error(error.data.message);
		}

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch,
				claimId : vm.claimId ? vm.claimId : $stateParams.claimId,
				filedBy : vm.filedBy ? vm.filedBy : $stateParams.filedBy,
				bIamId : vm.bIamId ? vm.bIamId : $stateParams.bIamId,
				aIamId : vm.aIamId ? vm.aIamId : $stateParams.aIamId,
				filedAgainist : vm.filedAgainist ? vm.filedAgainist
						: $stateParams.filedAgainist,
				filedAgainstMxStatus : vm.filedAgainstMxStatus ? vm.filedAgainstMxStatus
						: $stateParams.filedAgainstMxStatus,
				fileDate : vm.fileDate ? vm.fileDate : $stateParams.fileDate,
				modifiedDate : vm.modifiedDate ? vm.modifiedDate
						: $stateParams.modifiedDate,
				totalClaim : vm.totalClaim ? vm.totalClaim
						: $stateParams.totalClaim,
				status : vm.status ? vm.status : $stateParams.status
			});
		}

		function search(searchQuery) {
			searchQuery = '-';
			if (!searchQuery) {
				return vm.clear();
			}
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			vm.currentSearch = searchQuery;
			vm.transition();
		}

		function clear() {
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = true;
			vm.currentSearch = null;
			vm.transition();
		}

		$scope.goToOnKeyUp = function(e, searchQuery) {
			if (event.keyCode === 13) {
				vm.transitionOnKeyUp(searchQuery);
			}
		}

		function transitionOnKeyUp(searchQuery) {
			searchQuery = '-';
			if (!searchQuery) {
				return vm.clear();
			}
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			vm.currentSearch = searchQuery;
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch,
				claimId : vm.claimId,
				filedBy : vm.filedBy,
				bIamId : vm.bIamId,
				aIamId : vm.aIamId,
				filedAgainist : vm.filedAgainist,
				filedAgainstMxStatus : vm.filedAgainstMxStatus,
				fileDate : vm.fileDate,
				modifiedDate : vm.modifiedDate,
				totalClaim : vm.totalClaim,
				status : vm.status
			});
		}

		function fileNewClaim() {
            window.open('https://mx.mobilityex.com/rppredirect/', '_blank');
         }
	}

	function ClaimHeaderFileController($http, $scope, DTOptionsBuilder,
			DTColumnDefBuilder, ClaimHeader, entity, DateUtils) {
		var vm = this;

		vm.today = new Date();
		vm.dtOptions1 = DTOptionsBuilder.newOptions().withDOM(
				'C<"clear">lfrtip');
		vm.dtOptions2 = DTOptionsBuilder.newOptions().withDOM(
		'C<"clear">lfrtip').withDisplayLength(100).withLanguage({"sSearch":"Refine Results:"});
		vm.dtColumnDefs1 = [ DTColumnDefBuilder.newColumnDef(3).notSortable() ];

        $scope.terminationDates = [];
		$scope.isActiveRPP = [];
		$scope.ableTofileClaim = false;
		vm.claimantServiceProvider = entity;
		if (vm.claimantServiceProvider
				&& vm.claimantServiceProvider.memberAssociations) {
			for (var k = 0; k < vm.claimantServiceProvider.memberAssociations.length; k++) {
				if (vm.claimantServiceProvider.memberAssociations[k].memberAssociation.id === 800
						&& (vm.claimantServiceProvider.memberAssociations[k].memberType.id === 1660
								|| vm.claimantServiceProvider.memberAssociations[k].memberType.id === 1661
								|| vm.claimantServiceProvider.memberAssociations[k].memberType.id === 1662
								|| vm.claimantServiceProvider.memberAssociations[k].memberType.id === 1676
								|| vm.claimantServiceProvider.memberAssociations[k].memberType.id === 1678
								|| vm.claimantServiceProvider.memberAssociations[k].memberType.id === 1679
								|| vm.claimantServiceProvider.memberAssociations[k].memberType.id === 5555
								|| vm.claimantServiceProvider.memberAssociations[k].memberType.id === 5556
								|| vm.claimantServiceProvider.memberAssociations[k].memberType.id === 5557
								)
						&& vm.claimantServiceProvider.memberAssociations[k].memberStatus.id === 1201) {
					$scope.ableTofileClaim = true;
					break;
				}
			}
		}

		vm.getServiceProviders = function(search) {
			$http
					.get(
							'api/_search/services/serviceprovidername/?includeNonOperational=true&query='
									+ search)
					.then(
							function(response) {
								vm.serviceProvidersSearchResult = response.data;

								if (vm.serviceProvidersSearchResult) {
									for (var i = 0; i < vm.serviceProvidersSearchResult.length; i++) {
										isActiveRPP(vm.serviceProvidersSearchResult[i].id);
									}
								}
							});
		};

		function isActiveRPP(id) {
			$http.get('api/member-associations/rpp/' + id).then(
					function(response) {
						vm.rppMember = response.data;
						if (vm.rppMember) {
							$scope.isActiveRPP[id] = true;
							$scope.terminationDates[id] = DateUtils.convertLocalDateFromServer(vm.rppMember.terminationDate);
						}
					});
		}
	}
})();
