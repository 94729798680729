(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('BillingDiscountDetailController', BillingDiscountDetailController);

    BillingDiscountDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'BillingDiscount'];

    function BillingDiscountDetailController($scope, $rootScope, $stateParams, previousState, entity, BillingDiscount) {
        var vm = this;

        vm.billingDiscount = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:billingDiscountUpdate', function(event, result) {
            vm.billingDiscount = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
