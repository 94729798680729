(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('quotation-response', {
            parent: 'entity',
            url: '/quotation-response?page&sort&search&res_status&filter',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SP_PRICING'],
                pageTitle: 'mobilityexchangeApp.quotationResponse.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/quotation-response/quotation-responses.html',
                    controller: 'QuotationResponseController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('quotationResponse');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })


        .state('quotation-response-dtls-v', {
            parent: 'entity',
            url: '/quotation-response-dtls?resId&spId&newRes=1',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SP_PRICING'],
                pageTitle: 'mobilityexchangeApp.quotationResponse.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/quotation-response/quotation-responses.html',
                    controller: 'QuotationResponseController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('quotationResponse');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })


        .state('quotation-response-detail', {
            parent: 'quotation-response',
            url: '/quotation-response/{id}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SP_PRICING'],
                pageTitle: 'mobilityexchangeApp.quotationResponse.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/quotation-response/quotation-response-detail.html',
                    controller: 'QuotationResponseDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('quotationResponse');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'QuotationResponse', function($stateParams, QuotationResponse) {
                    return QuotationResponse.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'quotation-response',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('quotation-response-detail.edit', {
            parent: 'quotation-response-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SP_PRICING']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/quotation-response/quotation-response-dialog.html',
                    controller: 'QuotationResponseDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['QuotationResponse', function(QuotationResponse) {
                            return QuotationResponse.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('quotation-response.new', {
            parent: 'quotation-response',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SP_PRICING']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/quotation-response/quotation-response-dialog.html',
                    controller: 'QuotationResponseDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                requestId: null,
                                serviceproviderId: null,
                                providerContactId: null,
                                remarks: null,
                                createdBy: null,
                                createdOn: null,
                                modifiedBy: null,
                                modifiedOn: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('quotation-response', null, { reload: 'quotation-response' });
                }, function() {
                    $state.go('quotation-response');
                });
            }]
        })
        .state('quotation-response.edit', {
            parent: 'quotation-response',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SP_PRICING']
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('quotationResponse');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'QuotationResponse', function($stateParams, QuotationResponse) {
                    return QuotationResponse.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'quotation-response',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            },
            views: {
            'content@': {
                templateUrl: 'app/entities/quotation-response/quotation-response-dialog.html',
                controller: 'QuotationResponseDialogController',
                controllerAs: 'vm'
                }
            }
        })
        .state('quotation-response.delete', {
            parent: 'quotation-response',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SP_PRICING']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/quotation-response/quotation-response-delete-dialog.html',
                    controller: 'QuotationResponseDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['QuotationResponse', function(QuotationResponse) {
                            return QuotationResponse.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('quotation-response', null, { reload: 'quotation-response' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
