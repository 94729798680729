(function () {
	// 'use strict';
	angular.module('mobilityexchangeApp').controller(
		'IssueTrackerDialogController', IssueTrackerDialogController)
		// .directive('tmpl', testComp)
		.directive('fileUpload', function () {
			return {
				scope: true, // create a new scope
				link: function (scope, el, attrs) {
					el.bind('change', function (event) {
						var files = event.target.files;
						// iterate files since 'multiple' may be specified
						// on the element
						for (var i = 0; i < files.length; i++) {
							// emit event upward
							scope.$emit("fileSelected", {
								file: files[i]
							});
						}
					});
				}
			};
		})
		.directive('claimFileUpload', function () {
            return {
                scope: true, // create a new scope
                link: function (scope, el, attrs) {
                    el.bind('change', function (event) {
                        var files = event.target.files;
                        // iterate files since 'multiple' may be specified
                        // on the element
                        for (var i = 0; i < files.length; i++) {
                            // emit event upward
                            scope.$emit("claimFileSelected", {
                                file: files[i]
                            });
                        }
                    });
                }
            };
        });

    /*
	testComp.$inject = ['$compile'];

	function testComp($compile) {
		var directive = {};
		directive.restrict = 'A';
		directive.templateUrl = 'app/entities/claim-header/claim-docs.html';
		directive.transclude = true;
		directive.link = function (scope, element, attrs) {
		}
		return directive;
	}
	*/

	IssueTrackerDialogController.$inject = ['$timeout', '$scope',
		'$stateParams', 'entity', 'IssueTracker', 'Lookups', 'BillingPlan',
		'DateUtils', '$http', 'BillingDiscount', '$q', 'ServiceProviders',
		'Principal', 'DTOptionsBuilder', 'DTColumnDefBuilder', '$compile',
		'$uibModal', '$cookies', 'MemberProfile', 'AuditLog', '$location'];

	function IssueTrackerDialogController($timeout, $scope, $stateParams,
		entity, IssueTracker, Lookups, BillingPlan, DateUtils, $http,
		BillingDiscount, $q, ServiceProviders, Principal, DTOptionsBuilder,
		DTColumnDefBuilder, $compile, $uibModal, $cookies, MemberProfile, AuditLog, $location) {

		var vm = this;

		vm.claimHeader = entity;
		vm.issueDocs = vm.claimHeader.issueDocs;
        var sortIssueDocs = true;
        if (sortIssueDocs && vm.issueDocs) {
            vm.issueDocs.sort(function(a, b) { return DateUtils.fromServerLocalDateTimeToTimestamp(b.createddate) - DateUtils.fromServerLocalDateTimeToTimestamp(a.createddate); });
        }

        var filingServiceProvider = $location.search().filingServiceProvider;
        console.log('Filing service provider: ' + filingServiceProvider);

		vm.issueComments = vm.claimHeader.issueComments;
		// Sort issue comments by created date desc
		var sortIssueComments = true;
		if (sortIssueComments && vm.issueComments) {
            vm.issueComments.sort(function(a, b) { return DateUtils.fromServerLocalDateTimeToTimestamp(b.createddate) - DateUtils.fromServerLocalDateTimeToTimestamp(a.createddate); });
        }

        if (vm.currentServiceProvider == null) {
            // If filing service provider was specified load it. This is to handle scenarios where a service provider
            // administrator can file a claim for multiple service providers
            if (filingServiceProvider) {
                vm.currentServiceProvider = ServiceProviders.get({id: filingServiceProvider}).$promise.then(
                    function (result) {
                        console.log('Filing service provider: ' + result);
                        vm.currentServiceProvider = result;
                    }
                );
            } else {
                vm.currentServiceProvider = ServiceProviders.getServiceProvidersCurrentUserLenient().$promise.then(
                    function (result) {
                        console.log('Current user service provider: ' + result);
                        vm.currentServiceProvider = result;
                    }
                );
            }
        }

		vm.datePickerOpenStatus = {};
		vm.datePickerOpenStatus.submissionDate = false;
		vm.datePickerOpenStatus.staffReviewDate = false;
		vm.datePickerOpenStatus.firstInterventionDate = false;
		vm.datePickerOpenStatus.secondInterventionDate = false;
		vm.datePickerOpenStatus.thirdInterventionDate = false;
		vm.datePickerOpenStatus.adlPublicationDate = false;
		vm.datePickerOpenStatus.expulsionNoticeDate = false;
		vm.datePickerOpenStatus.explusionRequestDate = false;
		vm.datePickerOpenStatus.expulsionConfirmDate = false;
		vm.datePickerOpenStatus.informalInterventionDate = false;

		$scope.displayDateFormat = DateUtils.displayDateFormat;
		vm.openCalendar = openCalendar;

        if (vm.claimHeader.filedAgainst == null) {
            vm.claimHeader.filedAgainst = IssueTracker.getServiceProvider({
                id: $stateParams.sp
            }).$promise.then(function (result) {
                console.log('Filed against: ' + result);
                vm.claimHeader.filedAgainst = result;
                vm.claimHeader.filedAgainst.displayName = buildDisplayName(result);

               vm.claimHeader.memberStatus = IssueTracker.getServiceProviderMemberStatus({
                   id: result.id
               }).$promise.then(function (memberStatusResult) {
                   console.log('Member status: ' + memberStatusResult);
                   if (memberStatusResult && memberStatusResult.memberStatus) {
                    vm.claimHeader.memberStatus = memberStatusResult.memberStatus;
                   }
               });
            });
        } else {
            vm.claimHeader.filedAgainst.displayName = buildDisplayName(vm.claimHeader.filedAgainst);

            vm.claimHeader.memberStatus = IssueTracker.getServiceProviderMemberStatus({
                id: vm.claimHeader.filedAgainst.id
            }).$promise.then(function (result) {
                console.log('Member status: ' + result);
                if (result && result.memberStatus) {
                    vm.claimHeader.memberStatus = result.memberStatus;
                }
            });
        }

        if (vm.claimHeader.filer == null) {
            if (filingServiceProvider) {

                ServiceProviders.get({id: filingServiceProvider}).$promise.then(
                    function (result) {
                        console.log('Filer (filing): ' + result);
                        vm.claimHeader.filer = result;
                        vm.claimHeader.filer.displayName = buildDisplayName(result);
                    }
                );
            } else {
                IssueTracker.getClaimant().$promise.then(function (result) {
                    console.log('Filer: ' + result);
                    vm.claimHeader.filer = result;
                    vm.claimHeader.filer.displayName = buildDisplayName(result);
                });
            }
        } else {
            vm.claimHeader.filer.displayName = buildDisplayName(vm.claimHeader.filer);
        }

		vm.docTypes = Lookups.getByLookupType({
			id: 73
		});

		vm.issueType = Lookups.getByLookupType({
			id : 91
		});

		vm.issueStatus = Lookups.getByLookupType({
			id : 92
		});

		vm.currencies = Lookups.getByLookupType({
			id : 52
		});

        vm.docTypesByIssueType = function() {

            console.log('Issue type: ' + vm.claimHeader.issueType + ', lookup value: ' + vm.claimHeader.issueType.lookupvalue);
            if (vm.claimHeader.issueType.lookupvalue == 'Financial') {
                return vm.docTypes.filter(isFinancialDocType);
            } else {
                return vm.docTypes;
            }
        }

        function isFinancialDocType(docType) {
            console.log('Checking doc type lookup value: ' + (docType && docType.lookupvalue));
            return docType && docType.lookupvalue == ' Unpaid Invoice';
        }

        // Default issue status to pending (6019)
        if (vm.claimHeader.issueStatus == null) {
            vm.claimHeader.issueStatus = {};
            vm.claimHeader.issueStatus.id = 6019;
        }

		$scope.isIssueAdmin = function() {
			return Principal.hasAnyAuthority([ 'IIRS_ADMIN' ]);
		};

		vm.dtInstance = {};

		vm.dtOptions1 = DTOptionsBuilder.newOptions('className',
			'details-control').withDOM('C<"clear">lfrtip');
		vm.dtColumnDefs1 = [DTColumnDefBuilder.newColumnDef(4).notSortable()];
		vm.dtColumnDefs2 = [DTColumnDefBuilder.newColumnDef(0).notSortable()];
        // vm.reverse = true;

        vm.submitClaimToIam = {};
        vm.submitClaimToIam.initiate = initiateSubmitClaimToIam;
        vm.submitClaimToIam.errors = null;
        vm.submitClaimToIam.errorsHtml = null;

        vm.submitClaimToIam.modalInstance = null;

        vm.acknowledgeIssue = acknowledgeIssue;
        vm.resolveIssue = resolveIssue;

        vm.convertToRpp = {};
        vm.convertToRpp.initiate = initiateConvertToRpp;
        vm.convertToRpp.errors = null;
        vm.convertToRpp.errorsHtml = null;
        vm.convertToRpp.modalInstance = null;

        vm.displayTransactionDetails = vm.claimHeader.issueType && vm.claimHeader.issueType.lookupvalue && vm.claimHeader.issueType.lookupvalue != 'Ethics' && vm.claimHeader.issueType.lookupvalue != 'IAM Trademark Abuse';
        vm.setValuesForDocToBeEmailed = function(type, docId) {
			console.log(type + '    ' + docId);
			vm.typeOfDocToBeEmailed = type;
			vm.idOdDocToBeEmailed = docId;
			vm.emailsent = false;
		};

        vm.emailOnePopupUrl = "email_required_doc_templ.html";
        vm.emailOne = function(e) {
            e.stopPropagation();
            e.preventDefault();
            vm.emailsent = true;

            console.log("Sending emails ====>> " + vm.typeOfDocToBeEmailed);

            if (vm.typeOfDocToBeEmailed === 'RequiredDoc') {
                console.log('Add sending for required doc');

                $http({
                    method : 'POST',
                    url : '/api/claim-invoice-details/' + vm.idOdDocToBeEmailed + '/email-required-documents',
                    headers : {
                        'X-CSRF-TOKEN' : $cookies.get('CSRF-TOKEN'),
                        'cookie' : $cookies.cookiename
                    }
                }).then(function(response) {
                    console.log(response);
                    vm.showProgress = false;
                });
            }
        };

        vm.isCurrentServiceProviderFiledAgainst = function() {
            var match = vm.currentServiceProvider && vm.claimHeader && vm.claimHeader.filedAgainst && vm.currentServiceProvider.id == vm.claimHeader.filedAgainst.id;
            return match;
        };

        $scope.submitClaimToIamIamInstance = null;

		$scope.closeSubmitClaimToIamModal = function () {
			if (vm.submitClaimToIam.modalInstance) {
			    vm.submitClaimToIam.modalInstance.close("Ok");
			    $scope.submitClaimToIamIamInstance.close("Ok");
			}
			if ($scope.submitClaimToIamIamInstance) {
			    $scope.submitClaimToIamIamInstance.close("Ok");
			}
		}

        $scope.convertToRppInstance = null;

		$scope.closeConvertToRppModal = function () {
			if (vm.convertToRpp.modalInstance) {
			    vm.convertToRpp.modalInstance.close("Ok");
			    $scope.convertToRppInstance.close("Ok");
			}
			if ($scope.convertToRppInstance) {
			    $scope.convertToRppInstance.close("Ok");
			}
		}

        // If filer contact information is not set initialize it from the logged in contact
        if (!vm.claimHeader.filerContactFirstname && !vm.claimHeader.filerContactLastname) {
            getAccount();
        }

        function getAccount() {
            vm.memberProfile = MemberProfile.get().$promise.then(function (result) {
                console.log('Member profile result: ' + result);
                if (result != null) {
                    vm.claimHeader.filerContactFirstname = result.firstname;
                    vm.claimHeader.filerContactLastname = result.lastname;
                    vm.claimHeader.filerContactEmail = result.email;
                    vm.claimHeader.filerContactPhone = result.officephone;
                    vm.claimHeader.filerContactCellphone = result.cellphone;
                }
            });
        }

        function buildDisplayName(result) {
            var displayName = '';

            if (result && result.serviceproviderAddresses && result.serviceproviderAddresses.length > 0) {
                var address;
                var mainAddress = result.serviceproviderAddresses.filter(isMainAddress);
                if (mainAddress && mainAddress.length > 0) {
                    address = mainAddress[0];
                }

                displayName = result.companylegalname;
                if (address && address.city) {
                  displayName += (', ' + address.city);
                }

                if (address && address.state) {
                  displayName += (', ' + address.state);
                }

                if (address && address.country) {
                  displayName += (', ' + address.country);
                }
            }

            return displayName;
        }

        function isMainAddress(address) {
            return address && address.addressType && address.addressType.id == 1530 &&
            address.addressType.lookuptypes && address.addressType.lookuptypes.id == 16;
        }

		vm.save = save;

		function save() {
		    $scope.error = null;
		    console.log("Edit form: " + $scope.editForm);
		    // Validation is not enabled for the form because of client requirements. However, if dates or amounts are
		    // entered we want to ensure that they're specified in the expected format.
		    var validationMessages = validate($scope.editForm);
		    if (validationMessages.length > 0) {
		        vm.validationErrorMessages = validationMessages;
		        $scope.submitted = true;
		        return;
		    }

		    // If there's any text in the comment we want to consider it a new comment and save it
		    if (vm.claimHeader.newComment && vm.claimHeader.newComment.length > 0) {
                var issueComment = {};
                issueComment.description = vm.claimHeader.newComment;
                issueComment.issueTracker = vm.claimHeader.id;
                vm.claimHeader.issueComments.push(issueComment);
            }

            var updateEntity = vm.claimHeader.id != null;
            var createEntity = vm.claimHeader.id == null;

            vm.isSaving = true;
            // Clear validation error
            vm.validationErrorMessages = null;

            if (vm.claimHeader.id !== null) {
                IssueTracker.update(vm.claimHeader, onSaveSuccess, onSaveError);
            } else {
                IssueTracker.save(vm.claimHeader, onSaveSuccess, onSaveError);
            }
		}

        function validate(editForm) {
            var validationMessages = validateDates(editForm);
            if (validationMessages.length > 0) {
                return validationMessages;
            }

            return validateAmounts(editForm);
        }

        function validateDates(editForm) {
            var invalidMessages = [];
            var suggestion = 'Please use the calendar icon. The valid date format is YYYY-MMM-DD (e.g. 2023-Feb-01)';

            if (editForm.iamClassificationDate && editForm.iamClassificationDate.$invalid) {
                invalidMessages.push('IAM Classification date is invalid. ' + suggestion + '. ');
            }

            if (editForm.dateAcknowledgedActual && editForm.dateAcknowledgedActual.$invalid) {
                invalidMessages.push('Acknowledged (Actual) date is invalid. ' + suggestion + '. ');
            }

            if (editForm.dateAcknowledgedTarget && editForm.dateAcknowledgedTarget.$invalid) {
                invalidMessages.push('Acknowledged (Target) date is invalid. ' + suggestion + '. ');
            }

            if (editForm.dateResolvedActual && editForm.dateResolvedActual.$invalid) {
                invalidMessages.push('Resolution (Actual) date is invalid. ' + suggestion + '. ');
            }

            if (editForm.dateResolvedTarget && editForm.dateResolvedTarget.$invalid) {
                invalidMessages.push('Resolution (Target) date is invalid. ' + suggestion + '. ');
            }

            if (editForm.transactionDate && editForm.transactionDate.$invalid) {
                invalidMessages.push('Transaction Date is invalid. ' + suggestion + '. ');
            }

            if (editForm.invoiceDate && editForm.invoiceDate.$invalid) {
                invalidMessages.push('Invoice Date is invalid. ' + suggestion + '. ');
            }

            return invalidMessages;
        }

        function validateAmounts(editForm) {
            var invalidMessages = [];
            if (editForm.amountClaimed && editForm.amountClaimed.$invalid) {
                invalidMessages.push('Total Amount Due (if applicable) - Numbers and decimal only. Do not enter any commas.');
            }
            return invalidMessages;
        }

		function saveWithFormValidation() {
		    $scope.error = null;
		    console.log("Edit form: " + $scope.editForm);
		    if ($scope.editForm.$invalid) {
                $scope.submitted = true;
            } else {
                var updateEntity = vm.claimHeader.id != null;
                var createEntity = vm.claimHeader.id == null;

                vm.isSaving = true;
                // Clear validation error
                vm.validationErrorMessage = null;

                if (vm.claimHeader.id !== null) {
                    IssueTracker.update(vm.claimHeader, onSaveSuccess, onSaveError);
                } else {
                    IssueTracker.save(vm.claimHeader, onSaveSuccess, onSaveError);
                }
			}
		}

		function onSaveSuccess(result) {
		    // With blind assignment of result to vm.claimHeader any follow-up changes to vm.claimHeader.invoiceDetails
		    // are not sent to the server when consecutive saves are done. To avoid that update vm.claimHeader only
		    // in case of a claim creation. For updates data that is in the vm should already be up-to-date as that's
		    // what was sent in the update request.
		    // vm.claimHeader = result;
		    var createEntity = vm.claimHeader.id == null;
            if (createEntity) {
              vm.claimHeader = result;
            }

            // If there was a text left in the comment box refresh comments because that text was submitted and a
            // new comment was created
            if (vm.claimHeader.id && vm.claimHeader.newComment && vm.claimHeader.newComment.length > 0) {
                refreshComments(vm.claimHeader.id);
            }

			// Update dates if any have been set on the server-side as result of date or state processing
            if (!vm.claimHeader.dateAcknowledgedTarget && result.dateAcknowledgedTarget) {
                vm.claimHeader.dateAcknowledgedTarget = result.dateAcknowledgedTarget;
            }

            if (!vm.claimHeader.dateAcknowledgedActual && result.dateAcknowledgedActual) {
                vm.claimHeader.dateAcknowledgedActual = result.dateAcknowledgedActual;
            }

            if (!vm.claimHeader.dateResolvedTarget && result.dateResolvedTarget) {
                vm.claimHeader.dateResolvedTarget = result.dateResolvedTarget;
            }

            if (!vm.claimHeader.dateResolvedActual && result.dateResolvedActual) {
                vm.claimHeader.dateResolvedActual = result.dateResolvedActual;
            }

			$scope.$emit('mobilityexchangeApp:issueTrackerUpdate', result);
			vm.isSaving = false;
		}

        function refreshComments(issueTrackerId) {
            console.log("Refreshing comments issue id: " + issueTrackerId);
            vm.issueComments = IssueTracker.getComments({
                id: issueTrackerId
            });

            vm.claimHeader.issueComments = vm.issueComments;

            // Clear the comment
            vm.claimHeader.newComment = '';
        }

		function onSaveError() {
			vm.isSaving = false;
		}
		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}

		vm.uploadedDocLine = null;
        $scope.closeUploadClaimAlert = function() {
            console.log('Closing upload claim alert');
            $scope.showUploadClaimAlert = false;
        }

		function doUploadFile(id, file) {
			vm.isUploading = true;
			var url = "/api/issue-docs/upload?issueTrackerId=" + id
				+ "&description=" + vm.uploadedDocLine.description
				+ "&type=" + vm.uploadedDocLine.docType.id;

			var data = new FormData();
			data.append('uploadfile', file);

			var config = {
				transformRequest: angular.identity,
				transformResponse: angular.identity,
				headers: {
					'Content-Type': undefined
				}
			}

			$http
				.post(url, data, config)
				.then(
					function (response) {
						$scope.uploadResult = "File uploaded successfully.";
						vm.isUploading = false;
						data = angular.fromJson(response.data);

                        $http.get('/api/issue-tracker/' + id + '/docs').then(function (res) {
                            console.log('Refreshed issue tracker docs: ' + res);
                            var responseData = angular.fromJson(res.data);
                            console.log('Response data: ' + responseData);
                            // Update issue documents table
                            vm.issueDocs = responseData;
                            // Update issue to reference the update list of documents.
                            vm.claimHeader.issueDocs = vm.issueDocs;
                        });
					},
					function (response) {
						vm.isUploading = false;
						$scope.uploadResult = "Error uploading file, Files must be 3 megabyte or less. Only PDF and image files are permitted. Please contact support@mobilityex.com if you require assistance";
					});
		};

        function initiateSubmitClaimToIam($event, id) {
            console.log('Initiate submit issue to IAM');

            $event.preventDefault();
            $event.stopPropagation();

            // Submitting claim to IAM using HTTP POST doesn't work in production (it returns 403). That needs to be
            // investigated because HTTP POST works in QC. As a work-around, HTTP GET is used to submit claim request
            // to IAM until the authorization error in production is resolved.
            $http.get('api/issue-tracker/' + id + '/submit-to-iam', {
			}).then(function(result) {
                console.log('Result: ' + result);
                if (result && result.data) {
                    console.log('Result data: ' + result.data);
                    if (result.data.submissionDate != null) {
                        vm.claimHeader.submissionDate = DateUtils.convertDateTimeFromServer(result.data.submissionDate);
                        if (result.data.issueStatus) {
                            vm.claimHeader.issueStatus = result.data.issueStatus;
                        }
                        $scope.uploadResult = "Claim was successfully submitted.";
                        $scope.showUploadClaimAlert = true;
                        $scope.uploadSuccess = true;
                    } else {
                        vm.submitClaimToIam.errorsHtml = result.data.errorsHtml;
                        vm.submitClaimToIam.errors = result.data.errors;

                        $scope.submitClaimToIamIamInstance = $uibModal
                            .open({
                                templateUrl: "app/entities/issue-tracker/submit-issue-to-iam-errors.html",
                                scope: $scope
                            });
                    }
                }
			});
        }

        function acknowledgeIssue($event, id) {
            $event.preventDefault();
            $event.stopPropagation();


            // Acknowledging an issue using HTTP POST doesn't work in production (it returns 403). That needs to be
            // investigated because HTTP POST works in QC. As a work-around, HTTP GET is used to acknowledge an issue
            // until the authorization error in production is resolved.
            $http.get('api/issue-tracker/' + id + '/acknowledge', {
			}).then(function(result) {
                if (result && result.data) {
                    if (result.data.issueStatus && result.data.issueStatus.id == 6013) {
                        vm.claimHeader = result.data;
                        $scope.uploadResult = "Issue was successfully acknowledged.";
                        $scope.showUploadClaimAlert = true;
                        $scope.uploadSuccess = true;
                    } else {
                        $scope.uploadResult = "Failed to acknowledge the issue.";
                        $scope.showUploadClaimAlert = true;
                        $scope.uploadSuccess = false;
                    }
                }
			});
        }

        function resolveIssue($event, id) {
            $event.preventDefault();
            $event.stopPropagation();


            // Resolving an issue using HTTP POST doesn't work in production (it returns 403). That needs to be
            // investigated because HTTP POST works in QC. As a work-around, HTTP GET is used to resolve an issue
            // until the authorization error in production is resolved.
            $http.get('api/issue-tracker/' + id + '/resolve', {
			}).then(function(result) {
                if (result && result.data) {
                    if (result.data.issueStatus && result.data.issueStatus.id == 6014) {
                        vm.claimHeader = result.data;
                        $scope.uploadResult = "Issue was successfully resolved.";
                        $scope.showUploadClaimAlert = true;
                        $scope.uploadSuccess = true;
                    } else {
                        $scope.uploadResult = "Failed to resolve the issue.";
                        $scope.showUploadClaimAlert = true;
                        $scope.uploadSuccess = false;
                    }
                }
			});
        }

        function initiateConvertToRpp($event, id) {
            $event.preventDefault();
            $event.stopPropagation();

            // Converting issue to RPP using HTTP POST doesn't work in production (it returns 403). That needs to be
            // investigated because HTTP POST works in QC. As a work-around, HTTP GET is used to convert issue
            // to RPP until the authorization error in production is resolved.

            $http.get('api/issue-tracker/' + id + '/convert-to-rpp', {
			}).then(function(result) {
                if (result && result.data) {
                    if (result.data.claimId != null) {
                        // vm.claimHeader.submissionDate = DateUtils.convertDateTimeFromServer(result.data.submissionDate);
                        $scope.uploadResult = "Issue was successfully converted to RPP.";
                        vm.claimHeader.issueId = result.data.claimId;
                        vm.claimHeader.claimId = result.data.claimId;
                        console.log('Claim header: ' + vm.claimHeader);
                        $scope.showUploadClaimAlert = true;
                        $scope.uploadSuccess = true;
                    } else {
                        $scope.convertToRppInstance = $uibModal
                            .open({
                                templateUrl: "app/entities/issue-tracker/convert-issue-to-rpp-errors.html",
                                scope: $scope
                            });
                    }
                }
			});

        }

		// an array of files selected
		$scope.files = [];

		// listen for the file selected event
		$scope.$on("fileSelected", function (event, args) {
			$scope.$apply(function () {
				// add the file object to the scope's files collection
				$scope.files.push(args.file);

				$scope.fileToUpload = args.file;
				if ($scope.fileToUpload && !vm.isUploading) {
					doUploadFile(vm.claimHeader.id, $scope.fileToUpload);
				}
			});
		});

        $scope.$on("claimFileSelected", function (event, args) {
            console.log('Claim file selected');
			$scope.$apply(function () {
				// add the file object to the scope's files collection
				$scope.files.push(args.file);
				$scope.claimFileToUpload = args.file;
                if (vm.claimHeader.id) {
                    doUploadClaimFile(vm.claimHeader.id, $scope.claimFileToUpload);
                }
			});
		});

		$scope.modalInstance = null;
		$scope.openUploadDocs = function () {
			$scope.modalInstance = $uibModal
				.open({
					templateUrl: "app/entities/issue-tracker/issue-tracker-doc-upload.html",
					scope: $scope
				});
			// vm.selectedInvoice = invoice;
			$scope.modalInstance.result.then(function (response) {
				$scope.result = '${response} button hit';
			});
		};

        $scope.addComment = function () {
            console.log("Comment: " + vm.claimHeader.newComment);
            // If no comment was added don't do anything
            if (!vm.claimHeader.newComment || vm.claimHeader.newComment.length <= 0) {
                return;
            }

            var issueComment = {};
            issueComment.description = vm.claimHeader.newComment;
            issueComment.issueTracker = {};
            issueComment.issueTracker.id = vm.claimHeader.id;

            IssueTracker.addComment(issueComment).$promise.then(function() {
                refreshComments(issueComment.issueTracker.id);
            });
        }

        $scope.sSpModalInstance = null;
		vm.openSwitchSP = function (claimHeader) {
        	$scope.sSpModalInstance = $uibModal
        		.open({
        			    templateUrl: "app/entities/issue-tracker/issue-tracker-switch-sp-dialog.html",
        			    scope: $scope
        			    //controller: 'IssueTrackerController'
        			});
        };

		$scope.ok = function () {
			vm.uploadedDocLine = null;
			$scope.uploadResult = "";
			$scope.modalInstance.close("Ok");
		}

		$scope.cancel = function () {
			vm.uploadedDocLine = null;
			$scope.uploadResult = "";
			$scope.modalInstance.dismiss();
		}

        vm.serviceProvidersSearchResult = [{}];
        vm.terminationDates = [];
        vm.isActiveRPP = [];
        vm.rppMember = {};
        vm.rppMembers = [{}];
        vm.delinquents = [{}];

        $scope.closeSspModal = function(){
            $scope.sSpModalInstance.dismiss();
        }

		vm.getServiceProviders = function(search) {
            $http
                .get('api/_search/services/serviceprovidername/?query=' + search)
                .then(
                    function(response) {
                        vm.serviceProvidersSearchResult = response.data;

                        if (vm.serviceProvidersSearchResult) {
                            for (var i = 0; i < vm.serviceProvidersSearchResult.length; i++) {
                                vm.isActiveRPP(vm.serviceProvidersSearchResult[i].id);
                                vm.delinquents[vm.serviceProvidersSearchResult[i].id] = vm.serviceProvidersSearchResult[i];
                            }
                        }
                    });
        };

         vm.isActiveRPP = function(id) {
            $http.get('api/member-associations/rpp/' + id).then(
                function(response) {
                    vm.rppMember = response.data;
                    if (vm.rppMember) {
                        vm.rppMembers[id] = response.data;
                        vm.isActiveRPP[id] = true;
                        vm.terminationDates[id] = DateUtils.convertLocalDateFromServer(vm.rppMember.terminationDate);
                    }
                });
        }

        vm.switchAndSaveSP = function (newSpId, iamId){
            vm.claimHeader.delinquent = vm.delinquents[newSpId];
            vm.claimHeader.delinquentIAMID = iamId;
            vm.save();
            $scope.sSpModalInstance.close("Ok");
        }

        $scope.showAll = 'false';

        vm.getIssueTrackerLogs = function () {
            if (vm.claimHeader.id) {
                vm.issueTrackerLogs = AuditLog.getIssueTrackerLogs({
                    issueTrackerId: vm.claimHeader.id
                });
                $scope.showAll = 'true';
            }
        };

        vm.getIssueTrackerLogs();
	}
})();
