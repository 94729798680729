(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('MemberContactsGdprDeleteController', MemberContactsGdprDeleteController);

    MemberContactsGdprDeleteController.$inject = ['$uibModalInstance', 'entity', '$http'];

    function MemberContactsGdprDeleteController($uibModalInstance, entity, $http) {
        var vm = this;

        vm.memberContacts = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        //console.log("GDPR ID: " + entity.id);
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
        	$http.delete('/api/member-contacts/gdpr/remove/'+id, function (data) {

            }).success( function(response) {
            	$uibModalInstance.close(true);
            });
        }
    }
})();
