(function () {
	// 'use strict';
	angular.module('mobilityexchangeApp').factory('AdHeader', AdHeader);

	AdHeader.$inject = ['$resource', 'DateUtils'];

	function AdHeader($resource, DateUtils) {
		var resourceUrl = 'api/adv-controls/:id';

		return $resource(
			resourceUrl,
			{},
			{
				'query': {
					method: 'GET',
					isArray: true,
					url: 'api/adv-controls/:id?'
				},
				'get': {
					method: 'GET',
					transformResponse: function (data, headers, status) {

						if (status !== 200)
							return status;

						if (data || !_.isEmpty(data)) {
							data = angular.fromJson(data);
							data.pauseAdFrom = DateUtils.convertDateTimeFromServer(data.pauseAdFrom);
							data.pauseAdTo = DateUtils.convertDateTimeFromServer(data.pauseAdTo);
							data.submissionDate = DateUtils.convertDateTimeFromServer(data.submissionDate);
							data.activationDate = DateUtils.convertDateTimeFromServer(data.activationDate);
							data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
							data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
						}

						$.each(
							data.advLocationSetups,
							function (index, advLocationSetups) {
								advLocationSetups.startDate = DateUtils.convertLocalDateFromServer(advLocationSetups.startDate);
								advLocationSetups.endDate = DateUtils.convertLocalDateFromServer(advLocationSetups.endDate);

							});

						$.each(
							data.advCapabilitySetups,
							function (index, advCapabilitySetups) {
								advCapabilitySetups.startDate = DateUtils.convertLocalDateFromServer(advCapabilitySetups.startDate);
								advCapabilitySetups.endDate = DateUtils.convertLocalDateFromServer(advCapabilitySetups.endDate);

							});
						return data;
					}
				},
				'update': {
					method: 'PUT',
					transformRequest: function (data) {
						var copy = angular.copy(data);
						return angular.toJson(copy);
					},
					transformResponse: function (data) {
						if (data) {
							data = angular.fromJson(data);
							data.pauseAdFrom = DateUtils.convertDateTimeFromServer(data.pauseAdFrom);
							data.pauseAdTo = DateUtils.convertDateTimeFromServer(data.pauseAdTo);
							data.submissionDate = DateUtils.convertDateTimeFromServer(data.submissionDate);
							data.activationDate = DateUtils.convertDateTimeFromServer(data.activationDate);

							data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
							data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
						}

						$.each(
							data.advLocationSetups,
							function (index, advLocationSetups) {
								advLocationSetups.startDate = DateUtils.convertDateTimeFromServer(advLocationSetups.startDate);
								advLocationSetups.endDate = DateUtils.convertDateTimeFromServer(advLocationSetups.endDate);

							});
						$.each(
							data.advCapabilitySetups,
							function (index, advCapabilitySetups) {
								advCapabilitySetups.startDate = DateUtils.convertDateTimeFromServer(advCapabilitySetups.startDate);
								advCapabilitySetups.endDate = DateUtils.convertDateTimeFromServer(advCapabilitySetups.endDate);

							});

						return data;
					}
				},
				'copy': {
					method: 'GET',
					url: 'api/adv-controls/copy/:id',
					transformRequest: function (data) {
						return angular.toJson(data);
					},
					transformResponse: function (data) {
						if (data) {
							data = angular.fromJson(data);
							data.pauseAdFrom = DateUtils
								.convertDateTimeFromServer(data.pauseAdFrom);
							data.pauseAdTo = DateUtils
								.convertDateTimeFromServer(data.pauseAdTo);

							data.submissionDate = DateUtils
								.convertDateTimeFromServer(data.submissionDate);
							data.activationDate = DateUtils
								.convertDateTimeFromServer(data.activationDate);

							data.createdDate = DateUtils
								.convertDateTimeFromServer(data.createdDate);
							data.modifiedDate = DateUtils
								.convertDateTimeFromServer(data.modifiedDate);
						}

						$.each(
							data.advLocationSetups,
							function (index, advLocationSetups) {
								advLocationSetups.startDate = DateUtils.convertDateTimeFromServer(advLocationSetups.startDate);
								advLocationSetups.endDate = DateUtils.convertDateTimeFromServer(advLocationSetups.endDate);

							});
						$.each(
							data.advCapabilitySetups,
							function (index, advCapabilitySetups) {
								advCapabilitySetups.startDate = DateUtils.convertDateTimeFromServer(advCapabilitySetups.startDate);
								advCapabilitySetups.endDate = DateUtils.convertDateTimeFromServer(advCapabilitySetups.endDate);

							});

						return data;
					}
				},
				'getServiceProviders': {
					method: 'GET',
					url: 'api/adv-controls/serviceproviders',
					isArray: true,
					cache: true,
					transformResponse: function (data) {
						if (data) {
							data = angular.fromJson(data);
							data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
							data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
						}
						return data;
					}
				},
				'getServiceProvidersForBillSetup': {
					method: 'GET',
					url: 'api/adv-controls/bill-setup/serviceproviders',
					isArray: true,
					cache: true,
					transformResponse: function (data) {
						if (data) {
							data = angular.fromJson(data);
							data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
							data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
						}
						return data;
					}
				},
				'save': {
					method: 'POST',
					transformRequest: function (data) {
						var copy = angular.copy(data);
						return angular.toJson(copy);
					},
					transformResponse: function (data) {
						if (data) {
							data = angular.fromJson(data);

							data.pauseAdFrom = DateUtils.convertDateTimeFromServer(data.pauseAdFrom);
							data.pauseAdTo = DateUtils.convertDateTimeFromServer(data.pauseAdTo);

							data.submissionDate = DateUtils.convertDateTimeFromServer(data.submissionDate);
							data.activationDate = DateUtils.convertDateTimeFromServer(data.activationDate);

							data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
							data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
						}
						$.each(
							data.advLocationSetups,
							function (index, advLocationSetups) {
								advLocationSetups.startDate = DateUtils.convertDateTimeFromServer(advLocationSetups.startDate);
								advLocationSetups.endDate = DateUtils.convertDateTimeFromServer(advLocationSetups.endDate);

							});

						$.each(
							data.advCapabilitySetups,
							function (index, advCapabilitySetups) {
								advCapabilitySetups.startDate = DateUtils.convertDateTimeFromServer(advCapabilitySetups.startDate);
								advCapabilitySetups.endDate = DateUtils.convertDateTimeFromServer(advCapabilitySetups.endDate);

							});
						return data;
					}
				}
			});
	}
})();
