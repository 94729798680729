(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('Communication_extractDetailController', Communication_extractDetailController);

    Communication_extractDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Communication_extract'];

    function Communication_extractDetailController($scope, $rootScope, $stateParams, previousState, entity, Communication_extract) {
        var vm = this;

        vm.communication_extract = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:communication_extractUpdate', function(event, result) {
            vm.communication_extract = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
