(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('AuditLogDetailController', AuditLogDetailController);

    AuditLogDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AuditLog'];

    function AuditLogDetailController($scope, $rootScope, $stateParams, previousState, entity, AuditLog) {
        var vm = this;

        vm.auditLog = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:auditLogUpdate', function(event, result) {
            vm.auditLog = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
