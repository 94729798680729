(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('LogsService', LogsService);

    LogsService.$inject = ['$resource'];

    function LogsService($resource) {
        var service = $resource('management/jhipster/logs', {}, {
            'findAll': {method: 'GET', isArray: true},
            'changeLevel': {method: 'PUT'}
        });

        return service;
    }
})();
