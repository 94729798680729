(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('AuditLogController', AuditLogController);

    AuditLogController.$inject = ['$state', 'AuditLog', 'AuditLogSearch', 'ParseLinks', 'AlertService',
        'paginationConstants', 'pagingParams','$http','Principal','$scope','$window'];

    function AuditLogController($state, AuditLog, AuditLogSearch, ParseLinks, AlertService, paginationConstants, pagingParams, $http,
                                Principal, $scope, $window) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                AuditLogSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                AuditLog.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.auditLogs = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        $scope.ifUserAdminAnalyst = function () {
            return Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_ANALYST']);
        };

        vm.auditLogsActedOn = [];

        vm.changeMessage ;

        vm.acceptAllChanges = function(){

           // $http.put('/api/acceptallchanges');

            $window.location.reload();

           // vm.changeMessage = "Audit Changes applied, Please refresh screen";

        };

        vm.acceptAll = function(){
            if(vm.revokeAllVal)  vm.revokeAllVal = false;
            for(var i=0; i< vm.auditLogs.length; i++){
                vm.auditLogs[i].lastAction = 'ACC';
                vm.auditLogs[i].recDisplay = false;
                vm.auditLogsActedOn.push(vm.auditLogs[i]);
                AuditLog.revokeacceptChange(vm.auditLogs[i]);
            }
            vm.changeMessage = "Audit Changes applied, Please refresh screen";
        }

        vm.revokeAll = function(){
            if(vm.acceptAllVal)  vm.acceptAllVal = false;
            for(var i=0; i< vm.auditLogs.length; i++){
                vm.auditLogs[i].lastAction = 'REV';
                vm.auditLogsActedOn.push(vm.auditLogs[i]);
                AuditLog.revokeacceptChange(vm.auditLogs[i]);
            }
            vm.changeMessage = "Audit Changes applied, Please refresh screen";
        }

        vm.revokeacceptChange = function(auditLog, lastAction){

            auditLog.lastAction =lastAction;
            if(lastAction === 'ACC')
                auditLog.recDisplay = false;

            vm.auditLogsActedOn.push(auditLog);
            AuditLog.revokeacceptChange(auditLog);

            vm.changeMessage = "Audit Changes applied, Please refresh screen"
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
