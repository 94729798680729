(function () {
    'use strict';

    angular
        .module('mobilityexchangeApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ngImgCrop',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'uiGmapgoogle-maps',
            'rzModule',
            'ngMaterial',
            'angularFileUpload',
            'angularPayments',
            'google.places',
            'angularSpinner',
            'angulartics',
            'angulartics.google.analytics',
            'angulartics.google.tagmanager',
            'datatables',
            'ngSanitize',
            'ng-currency'
        ])
        .config(config)
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler'];

    config.$inject = ['$analyticsProvider'];


    function config($analyticsProvider){
       //$analyticsProvider.setAccount('UA-92672165-1');
       $analyticsProvider.settings.ga = {
           additionalAccountNames: undefined,
           // Select hits to send to all additional accounts
           additionalAccountHitTypes: {
               pageview: true,
               event: true,
               exception: false,
               ecommerce: false,
               userTiming: false,
               setUserProperties: false,
               userId: false
           },
           disableEventTracking: null,
           disablePageTracking: null,
           userId: null,
           enhancedEcommerce: false
       };

    }



    function run(stateHandler, translationHandler) {
        stateHandler.initialize();
        translationHandler.initialize();
    }

	angular
	.module('mobilityexchangeApp')
	.filter
	(
			'displayDateFormat',
			[
				"$filter",
				"DateUtils",
				function($filter, DateUtils)
				{
				    var filterFunction =
				    	function(input)
					    {
					        return (input == null ? "" : $filter('date')(input, DateUtils.displayDateFormat));

					    }
				    ;

				    return filterFunction;

				}
			]
	)
	;

})();
