(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('RelocationCapabilitiesDetailController', RelocationCapabilitiesDetailController);

    RelocationCapabilitiesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'RelocationCapabilities', 'ServiceProviders', 'Lookups'];

    function RelocationCapabilitiesDetailController($scope, $rootScope, $stateParams, previousState, entity, RelocationCapabilities, ServiceProviders, Lookups) {
        var vm = this;

        vm.relocationCapabilities = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:relocationCapabilitiesUpdate', function (event, result) {
            vm.relocationCapabilities = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
