(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('MemberContactsMoveController', MemberContactsMoveController);

    MemberContactsMoveController.$inject = ['$uibModalInstance', 'entity', 'MemberContacts', '$http'];

    function MemberContactsMoveController($uibModalInstance, entity, MemberContacts, $http) {
        var vm = this;

        vm.memberContacts = entity;
        vm.activeQual = 0;
        vm.activeControl = 0;
        vm.selectedSpId = null;
        vm.selectedSpName = null;
        vm.error = null;
        for (i = 0; i < vm.memberContacts.contactQualifications.length; i++) {
            if (vm.memberContacts.contactQualifications[i].expDate > Date.now()) {
                vm.activeQual++;
            }
        }

        for (i = 0; i < vm.memberContacts.contactControls.length; i++) {
            if (vm.memberContacts.contactControls[i].displayFlag
                || vm.memberContacts.contactControls[i].adminFlag) {
                vm.activeControl++;
            }
        }

        vm.clear = clear;
        vm.serviceProviders = null;
        vm.email = null;
        vm.title = null;
        vm.getServiceProviders = function (search) {
            $http
                .get('api/_search/services/serviceprovidername/?query=' + search)
                .then(
                    function (response) {
                        vm.serviceProviders = response.data;
                    });
        };

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.moveContact = function () {
            if (isValidMove()) {
                var data = {
                    providerContactId: vm.memberContacts.id,
                    spId: vm.selectedSpId,
                    email: vm.email,
                    title: vm.title,
                };

                $http.put('/api/member-contacts/move', JSON.stringify(data)).then(function (success) {
                    vm.error = null;
                    $uibModalInstance.close(true);
                }, function (error) {
                    vm.error = error.data.description;
                });
            }

        };

        function isValidMove() {
            vm.emailError = false;
            vm.titleError = false;
            if (!vm.email || vm.email.length === 0) {
                vm.emailError = true;
                return false;
            }
            if (!vm.title || vm.title.length === 0) {
                vm.titleError = true;
                return false;
            }

            return true;
        }

        vm.selectServiceProvider = function (sp) {
            vm.selectedSpId = sp.id;
            vm.selectedSpName = sp.companylegalname;
        }

        //        function confirmMove(id, spId) {
        //        	console.log("Member Id: " + id + " Provider Id: " + spId)
        //        	MemberContacts.delete({id: id, spId: spId},
        //                function () {
        //                    $uibModalInstance.close(true);
        //                });
        //        }
    }
})();
