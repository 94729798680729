(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('QuotationResponseController', QuotationResponseController);

    QuotationResponseController.$inject = ['QuotationResponse', 'QuotationResponseSearch',
        'Lookups', '$http','DateUtils','QuotationResponseDtl', 'QuotationRequest',
        'QuotationRequestDtl', '$timeout','$scope','FileUploader','$cookies', '$rootScope','$stateParams','Notifications', '$state','Principal',
        'ParseLinks',
        'paginationConstants', 'pagingParams'
    ];

    function QuotationResponseController(QuotationResponse, QuotationResponseSearch,
                                         Lookups, $http, DateUtils,
                                         QuotationResponseDtl, QuotationRequest, QuotationRequestDtl,
                                         $timeout, $scope,FileUploader,$cookies,
                                         $rootScope, $stateParams,Notifications,
                                         $state, Principal, ParseLinks,
                                         paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 10;//paginationConstants.itemsPerPage;
        vm.reset = reset;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        vm.quotationResponses = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.save = save;
        vm.quoteTotal = [];



        vm.quotationResponse = {};
        vm.quotationResponse.quotationResponseDtls = [{}];

        vm.modes = Lookups.getByLookupType({id: 60});
        vm.serviceTypes = Lookups.getByLookupType({id: 56});
        vm.containerTypes = Lookups.getByLookupType({id: 57});
        vm.reqStatus = Lookups.getByLookupType({id: 58});
        vm.resStatus = Lookups.getByLookupType({id: 59});

        vm.currency = Lookups.getActiveByLookupType({id: 52});
        vm.rateBasis = Lookups.getByLookupType({id: 61});

        vm.quoteOptionIdx = 0;
        vm.quoteOptionsSize = vm.quotationResponse.quotationResponseDtls.length;

        vm.bufferAmt = [];
        vm.rescurrency = '';
        vm.totalAmt = [];

        vm.newRequest = $stateParams.newRes;

        $scope.account = null;
        $scope.isAuthenticated = false;
        $scope.isAdminUser = false;

        vm.display = '';
        $timeout(function(){
            vm.display = 'display: block;';
        },2000);

        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
            for (var i = 0; i < $scope.account.authorities.length; i++) {
                if ($scope.account.authorities[i] === 'ROLE_ADMIN') {
                    $scope.isAdminUser = true;
                    break;
                }
            }

        });

        //$rootScope.count = 10;

        vm.getLineTotals = function(){

            var qReqDtls = vm.quotationResponse.quotationRequest.quotationRequestDtls;

            for(var i=0;i<qReqDtls.length; i++){

                vm.totalAmt[i] = 0;
                var qResDtls = qReqDtls[i].quotationResponseDtls;

                for(var j=0;j<qResDtls.length; j++){

                    if(_.isNaN(vm.totalAmt[i])  || _.isUndefined(vm.totalAmt[i])) {
                        vm.totalAmt[i] = 0.0;
                    }
                    vm.totalAmt[i] = vm.totalAmt[i] + parseFloat(qResDtls[j].responseItemAmt);
                }

                if( _.isNaN(vm.totalAmt[i]))
                    vm.totalAmt[i] = 0;
            }


        };





        vm.calcAmt = function(rateBasis, responseItemRate,responseItemQty, index){

            var amt = 0.00;
            if(responseItemRate && responseItemQty) {
                if (rateBasis === 68) {  // percent
                    amt =  ((responseItemRate) / 100) * (responseItemQty);
                }else
                if (rateBasis === 69) {  // percent
                    amt = ((responseItemRate) / 100) * (responseItemQty);
                }else{
                   amt = (responseItemRate) * (responseItemQty);
                }
            }else{
                amt =  0.00;
            }

            return (amt).toFixed(2);

        };

        vm.addTotal = function(amt, idx){
            vm.getLineTotals();
        };

        vm.getWtDisplay = function(wt, unit){
            if(unit === 'Kgs'){
                return (wt).toFixed(2) +" "+unit+"  / "+ (wt*2.2).toFixed(2) +" Lbs ";
            }
            else
            if(unit === 'Lbs'){
                return (wt/2.2).toFixed(2)+" Kgs  / "+ (wt).toFixed(2) +" Lbs ";
            }
        };


        vm.getVolDisplay = function(vol, unit){

            if(unit === 'Cft'){
                return (vol/35.3).toFixed(2) +" Cbm / "+ (vol).toFixed(2)+" "+unit+" ";
            }
            else
            if(unit === 'Cbm'){
                return  (vol).toFixed(2) +" Cbm / "+(vol*35.3).toFixed(2)+" Cft ";
            }
        };


        vm.setQty= function(rateBasis,quotationDtls, qResDtls) {

            var wtNum = parseFloat(quotationDtls.weight);
            var volNum = parseFloat(quotationDtls.volume);
            var qty = 0;

            if(rateBasis===65){  //Per Cbm
                if(quotationDtls.volumeUnit == 'Cbm')
                    qty =  parseFloat( (volNum).toFixed(2) );
                else
                if(quotationDtls.volumeUnit == 'Cft')
                    qty =  parseFloat((volNum/35.3).toFixed(2));
            }else
            if(rateBasis===66){  //Per Cft
                if(quotationDtls.volumeUnit == 'Cbm')
                    qty =  parseFloat((volNum*35.3).toFixed(2));
                else
                if(quotationDtls.volumeUnit == 'Cft')
                    qty =  parseFloat((volNum).toFixed(2));

            }else
            if(rateBasis===67){  //Per Kg
                if(quotationDtls.weightUnit == 'Kgs')
                    qty =  parseFloat((wtNum).toFixed(2));
                else
                if(quotationDtls.weightUnit == 'Lbs')
                    qty =  parseFloat((wtNum/2.2).toFixed(2));
            }
            else
            if(rateBasis===69){  //Per CWT
                if(quotationDtls.weightUnit == 'Kgs')
                    qty =  parseFloat((wtNum*2.2).toFixed(2));
                else
                if(quotationDtls.weightUnit == 'Lbs')
                    qty =  parseFloat((wtNum).toFixed(2));
            }
            else
            if(rateBasis===64){
                qty =  1;
            }

            if(!_.isUndefined(qResDtls.rate))
                qResDtls.responseItemAmt = vm.calcAmt(qResDtls.rateBasis, qResDtls.rate, qty);

            return qty;

        };


        vm.acceptQuote = function(event, quotationResponse){
            event.preventDefault();
            event.stopPropagation();
            QuotationResponse.acceptQuote(quotationResponse);
            quotationResponse.responseStatus = 46;
            vm.isSaving = false;
        };

        vm.rejectQuote = function(event, quotationResponse){
            event.preventDefault();
            event.stopPropagation();
            QuotationResponse.rejectQuote(quotationResponse);
            quotationResponse.responseStatus = 44;
            vm.isSaving = false;
        };

        vm.isSubmitted = false;
        vm.popupUrl = 'submit_templ.html';
        $scope.isOpen = false;

        vm.submitQuote = function(event, quotationResponse){

            vm.isSubmitted = true;

            if(!$scope.editForm.$valid) {
                vm.invalidForm = "You have missed entering required fields. All fields marked with an * are required. " +
                    "Please identify the fields in error marked in red, enter appropriate data and Save again.";
                $scope.editForm.submitted=true;
                vm.isOpen = false;
                return false;

            } else{

                vm.invalidForm = "";
                quotationResponse.responseStatus = 47;
                $scope.editForm.submitted=true;
                vm.save();
                vm.isSubmitted = true;

                $http.get('/api/quotation-response-submit-email/'+vm.quotationResponse.id);

            }

            console.log('Setting isOpen');



        };


        function success(data){
            vm.invalidForm = "";
            vm.loadData(vm.quotationResponse.id, vm.quotationResponse.serviceproviderId);
        }

        function error(data){

        }


        vm.matchValueFromLookup = function(id, obj){
            var val = _.find(obj, {id:id.id});
            if(val)
                return val.lookupvalue ;
            else
                return "";
        };

        vm.setBuffer = function(amt){

            if(_.isUndefined(amt) || _.isEmpty(amt))
                vm.bufferAmt = 0;
            else
                vm.bufferAmt = amt;

        };

        vm.getStausDesc = function (id) {
            var resStatus = _.find(vm.resStatus, {id: id});
            if(resStatus)
                return resStatus.lookupvalue;
            else
                return "";
        };

        vm.showHideStatusMessage = function(){
            vm.showStatusMessage ? vm.showStatusMessage = false : vm.showStatusMessage = true;
        };

        vm.showHideRFQList = function(){
            vm.newRequest = false;
            $state.go("quotation-response", {filter:'N'});
            //loadAll();
        };

        vm.errorClass ;
        vm.errorConfirmMessage ;

        vm.confirmSave = function(event){

            event.preventDefault();
            event.stopPropagation();

            vm.errorClass = '';
            vm.errorConfirmMessage = "";

            if( _.isUndefined(vm.quoteModReason)  || _.isEmpty(vm.quoteModReason) || vm.quoteModReason === '' ){
                vm.errorClass = 'invalidInput';
                vm.errorConfirmMessage = "**Please enter a detailed description of the change";
                return false;
            }

            save();
        };

        function save () {

            if(!$scope.editForm.$valid && vm.isSubmitted) {
                return false;
            }else{
                vm.invalidForm = "";
            }

            vm.invalidForm = "";
            vm.isSaving = true;

            var quotationResponseDtls = [];

            for(var i=0;i<vm.quotationResponse.quotationRequest.quotationRequestDtls.length; i++) {

                var qResponseDtls = vm.quotationResponse.quotationRequest.quotationRequestDtls[i].quotationResponseDtls;

                for (var j = 0; j < qResponseDtls.length; j++) {

                    var response =  _.clone(vm.quotationResponse);
                    response.quotationRequest = null;
                    response.quotationResponseDtls = null;
                    qResponseDtls[j].quotationResponse = response;

                    var request = _.clone(vm.quotationResponse.quotationRequest.quotationRequestDtls[i]);
                    request.quotationResponseDtls = null;
                    request.quotationRequest = null;

                    qResponseDtls[j].requestDetail = request;

                    var res ;

                    if(qResponseDtls[j].id != null) {
                        res = QuotationResponseDtl.update(qResponseDtls[j], onSaveSuccess, onSaveError);
                    }else {
                        res = QuotationResponseDtl.save(qResponseDtls[j], onSaveSuccess, onSaveError);
                    }
                }
            }

            // delete ....

            for(var i=0;i<vm.quotationResponseDtlsToDelete.length; i++) {
                $http.delete('/api/quotation-response-dtls/'+vm.quotationResponseDtlsToDelete[i].id);
                //QuotationResponseDtl.delete(vm.quotationResponseDtlsToDelete.id , onDeleteSucess, onDeleteFailure );
            }


        }

        function onDeleteSucess(){
            console.log('Deleted sucessfully ....');
        }

        function onDeleteFailure(){

        }

        vm.port = [];

        vm.setPortValue = function(quotationDtls, reqId, resId, port){

            if(vm.quotationResponse.quotationResponseHeaders){
                var qHeader = _.find(vm.quotationResponse.quotationResponseHeaders, { reqId : reqId} );
                if(qHeader){
                    qHeader.port = port;
                    qHeader.reqId = reqId;
                    vm.quotationResponse.quotationResponseHeaders = qHeader;
                }else{
                    var qHeader = {};
                    qHeader.port = port;
                    qHeader.reqId = reqId;
                    //qHeader.quotationRequestDtl = {};
                    //qHeader.quotationRequestDtl.id = reqId;

                    vm.quotationResponse.quotationResponseHeaders.push( qHeader );
                }
            }else{
                vm.quotationResponse.quotationResponseHeaders = [{}];
                vm.quotationResponse.quotationResponseHeaders[0].port = port;
                vm.quotationResponse.quotationResponseHeaders[0].reqId = reqId;
                //vm.quotationResponse.quotationResponseHeaders[0].quotationRequestDtl = {};
                //vm.quotationResponse.quotationResponseHeaders[0].quotationRequestDtl.id = reqId;
            }

        };

        vm.submitEmailSent = false;

        function onSaveSuccess (result) {

            // send mod email only if response is submitted ...

            vm.isSaving = false;
            $scope.editForm.$setPristine();
            $scope.editForm.$setUntouched();
            $scope.editForm.$submitted = false;

            $timeout(   function () {

                vm.quotationResponse = vm.loadData(result.quotationResponse.id, result.quotationResponse.serviceproviderId);

            }   , 1000);


            var data = vm.quoteModReason;

            if(vm.quotationResponse.responseStatus === 47){
                $http.post('/api/quotation-response-mod-email/'+vm.quotationResponse.id, data );
                vm.quoteModReason = "";
            }

        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.showAddButton = function(idx,quotationDtls){
            if(idx+1 === quotationDtls.length){
                ////console.log('in vm.showAddButton');
                return true;
            }
        };

        vm.addQuoteResponse = function (idx, quotationResponseDtls) {
            //var quotationResponseDtls = { 'requestDetailId' : id };
            quotationResponseDtls.push({});
        };

        vm.quotationResponseDtlsToDelete = [];

        vm.removeQuoteResponse = function (idx, quotationResponseDtls, indx) {

            var amt = parseFloat(quotationResponseDtls[idx].responseItemAmt);

            vm.quotationResponseDtlsToDelete.push(quotationResponseDtls[idx]);

            if(_.isUndefined(amt) || _.isEmpty(amt))
                vm.bufferAmt[idx] = 0;
            else
                vm.bufferAmt[idx] = amt;

            if(_.isUndefined(vm.quoteTotal[idx]) || _.isEmpty(vm.quoteTotal[idx]) || _.isNaN(vm.quoteTotal[idx]))
                vm.quoteTotal[idx] = 0;

            vm.quoteTotal[idx] = parseFloat(vm.quoteTotal[idx]) - parseFloat(vm.bufferAmt[idx]);

            quotationResponseDtls.splice(idx,1);

            if(quotationResponseDtls.length === 0)
                quotationResponseDtls.push({});

        };

        vm.reqSpHeader;

        vm.loadData = function(resId, spId){

            if(!spId)
                spId = 0;

            //$rootScope.count = $rootScope.count + 10;

            console.log('  $stateParams.spId '+spId);

            $http.get('api/quotation-responses/'+resId+'/'+spId).then(function(response) {

                vm.quotationResponse = response.data;

                vm.asyncOrigSelected = vm.quotationResponse.quotationRequest.origin;
                vm.asyncDestSelected = vm.quotationResponse.quotationRequest.destination;

                vm.quotationResponse.quotationRequest.serviceDate = DateUtils.convertLocalDateFromServer(response.data.quotationRequest.serviceDate);
                vm.quotationResponse.quotationRequest.createdOn = DateUtils.convertLocalDateFromServer(response.data.createdOn);
                vm.quotationResponse.quotationRequest.modifiedOn = DateUtils.convertLocalDateFromServer(response.data.modifiedOn);

                $scope.serviceproviderId = vm.quotationResponse.serviceproviderId;
                $scope.quotationResponseId = vm.quotationResponse.id;
                vm.getFileRef($scope.serviceproviderId, $scope.quotationResponseId);

                $scope.rfqserviceproviderId = vm.quotationResponse.quotationRequest.serviceproviderId;
                $scope.rfqquotationResponseId = vm.quotationResponse.quotationRequest.id;
                vm.getRFQFileRef($scope.rfqserviceproviderId, $scope.rfqquotationResponseId);

                var qReqDtls = vm.quotationResponse.quotationRequest.quotationRequestDtls;

                for(var i=0; i<qReqDtls.length; i++){
                   // //console.log('qReqDtls[i].quotationResponseDtls '+   JSON.stringify(qReqDtls[i].quotationResponseDtls));
                    if( _.isUndefined(qReqDtls[i].quotationResponseDtls) || _.isEmpty(qReqDtls[i].quotationResponseDtls) ) {
                        qReqDtls[i].quotationResponseDtls = [{}];
                        vm.quotationResponse.quotationResponseDtls = [{}];
                    }
                }


                vm.reqSpHeader  = null;
                vm.newRequest = true;
                vm.totalAmt = [];

                var reqObj = angular.copy(vm.quotationResponse.quotationRequest);
                reqObj.quotationResponses = null;
                vm.quotationRequestArray = [reqObj];

                $http.post('api/quotation-requestor-sps', vm.quotationRequestArray).then(
                    function(result){
                        if(result && result.data) {
                            vm.mxConnectedSPReqNames = result.data;
                            vm.reqSpHeader  = vm.getReqSPNames(vm.quotationResponse.quotationRequest.id);
                        }
                    }
                );

                vm.getLineTotals();
               // getNotifications();

            });
        };




        function showDetail(){
            if(vm.newRequest && vm.newRequest === '1') {
                vm.newRequest = true;
                vm.loadAll();
                vm.loadData($stateParams.resId, $stateParams.spId);
            }
        };

        showDetail();

        vm.requestorEmail = "";

        vm.getReqSPNames = function(id){

            var spNames = _.find(vm.mxConnectedSPReqNames,{ quoteRequestId: id});

            if(!_.isUndefined(spNames) && spNames.spNames!=="") {

                vm.requestorEmail = spNames.email;
                return spNames.spNames.toString();
            }
            else
                return "(No contact information available, request created by admin account ?)";
        };


        vm.getValidatedStatus = function (id) {

            //console.log(' id '+id+' vm.mxConnectedSPReqNames '+ JSON.stringify(vm.mxConnectedSPReqNames));

            var spNames = _.find(vm.mxConnectedSPReqNames,{ quoteRequestId : id});

            if(spNames && spNames.validated)
                return true;
            else
                return false;
        };


        vm.getRppStatus = function (id) {


            var spNames = _.find(vm.mxConnectedSPReqNames,{ quoteRequestId : id});

            if(spNames && spNames.rpp)
                return true;
            else
                return false;
        };

        loadAll();

        vm.mxConnectedSPNames;

        vm.clearNotification = function($event, notification){
            $event.preventDefault();
            $event.stopPropagation();
            notification.seen = true;
            notification.active = false;
            vm.notifications = Notifications.update(notification, onUpdateSuccess, onUpdateFailure);
        };

        function onUpdateSuccess(){
            getNotifications();
        };

        function onUpdateFailure(){
            //getNotifications();
        };

        vm.archiveRequest = function(qReq){

            console.log('Archiving .....');

            qReq.archived = true;
            QuotationResponse.update(qReq,onArchiveSuccess,onArchiveFailure);

            /*_.forEach(vm.quotationResponses, function(qReq) {
                _.remove(vm.quotationResponses, {id: qReq.id});
            });*/

        };

        function onArchiveSuccess(){
            loadAll();
        };
        function onArchiveFailure(){

        };

        vm.shAllRecs = $rootScope.showAllRecs ;

        vm.showAll = function () {
            if(!$rootScope.showAllRecs){
                $rootScope.showAllRecs = true;
            }else{
                $rootScope.showAllRecs = false;
            }
            loadAll();
        };

        vm.showAllResRecFlag = $stateParams.filter;

        vm.showAllResRecs = function(){

            if(!$stateParams.filter)
                $stateParams.filter = 'N';

            vm.showAllResRecFlag = $stateParams.filter;

            console.log('reloading ...');
            $state.transitionTo('quotation-response', {filter: 'N'}, {
                reload: true,
                inherit: false,
                notify: true
            });
        };

        function loadAll() {


            if (pagingParams.search) {
                QuotationResponseSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    res_status: $stateParams.res_status
                }, onSuccess, onError);
            } else {

                QuotationResponse.query({
                    showall: $rootScope.showAllRecs,
                    page: pagingParams.page -1,
                    size: 10,
                    sort: sort(),
                    res_status: $stateParams.res_status
                },function(result, headers) {

                    var response = {};
                    response.data = result;
                    response.headers = headers();
                    vm.quotationResponses = result;
                    vm.searchQuery = null;

                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.totalCount = headers('X-Total-Count');
                    vm.queryCount = 100;
                    vm.page = pagingParams.page;


                    var eulaAccepted = response.headers["x-rfq-eula-not-accepted"];
                    if(eulaAccepted){
                        $state.go('disclaimer-detail', {id: parseInt(eulaAccepted)}, {reload: true});
                    }

                    $http.post('api/quotation-response-sps', vm.quotationResponses).then(
                        function(response){
                            if(response && response.data)
                                vm.mxConnectedSPNames = response.data;
                            var reqMap = {};
                            for(var i=0; i<vm.quotationResponses.length; i++){
                                var req = vm.quotationResponses[i].quotationRequest;
                                if(!req.id) {
                                    var refId = req;
                                    var refObj = _.find(vm.quotationResponses, function(o){  return o.quotationRequest.id == refId; });
                                    vm.quotationResponses[i].quotationRequest = refObj.quotationRequest;
                                }
                            }
                        }
                    );
                });


            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }


            getNotifications();

        }


        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }


        vm.getSPNames = function(id){
            var spNames = _.find(vm.mxConnectedSPNames,{ quoteRequestId : id});
            if(!_.isUndefined(spNames))
                return (spNames.spNames).toString();
            else
                return "";
        };

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            QuotationResponseSearch.query({query: vm.searchQuery}, function(result) {
                vm.quotationResponses = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            vm.newRequest = false;
            if(vm.quotationResponse.quotationRequest)
                vm.quotationResponse.quotationRequest.quotationRequestDtls = [{}];
            loadAll();
        }

        vm.notifications;

        function reset() {
            /*var status = _.find(vm.reqStatus, {id: vm.quotationRequest.requestStatus.id});
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.quotationRequest = {};
            vm.quotationRequest.requestStatus = status;
            vm.quotationRequest.quotationRequestDtls = [{}];
            vm.asyncOrigSelected = {};
            vm.asyncDestSelected = {};*/
            vm.transition();
        }

        function getNotifications(){
            //$timeout(function(){
                vm.notifications = Notifications.getNotificationsForSP(onSuccess, onFailure);
            //}, 2000);
        };

        getNotifications();

        function onSuccess(results){
            vm.notifications  = results;
            if(vm.notifications)
                $rootScope.count = vm.notifications.length;
        };

        function onFailure(){
        };

        vm.getNotificationCount = function(key){
            var count = 0;
            if(vm.notifications && vm.notifications.length > 0){
                for(var j=0; j<vm.notifications.length; j++) {
                    var notifications = vm.notifications[j].key;
                    if(notifications === key)
                        count++;
                }
                return count;
            }
            return 0;
        };


        vm.getNotificationCountByType = function(type){
            var count = 0;
            if(vm.notifications && vm.notifications.length > 0){
                for(var j=0; j<vm.notifications.length; j++) {
                    var notifications = vm.notifications[j].key;
                    if(notifications === key)
                        count++;
                }
                return count;
            }
            return 0;
        };


        vm.getTotalCountByType = function( types ){
            var count = 0;
            for(var i=0; i<types.length; i++){
                if(_.find(vm.notifications, {type: types[i]})){
                    count++;
                }
            }
            return count;
        };

        vm.keyCount = 0;


        vm.getTotalCountByKeys = function( keys ){

            var count = 0;

            var result = _.filter(vm.notifications, {keyText: keys[0]}) ;

            var resTypes = ["RFQ_ASSIGNED","RFQ_REQUEST_MODIFIED","RFQ_ACCEPTED","RFQ_CLOSED"];

            for(var i=0; i<resTypes.length; i++){

                var result = _.filter(vm.notifications, { keyText: keys[0] ,  type: resTypes[i]  }) ;

                if(result)
                    count = count + result.length;

            }

            return count;

        };
        vm.messagesTemplUrl = "messages_templ2.html";

        vm.activenotifications = [];

        vm.getMessageList = function( keys ){

            console.log(JSON.stringify('****  '+keys+'-----'+JSON.stringify(vm.notifications)));

            if(vm.notifications) {

                var result = _.filter(vm.notifications, {keyText: keys[0]});
                var resTypes = ["RFQ_ASSIGNED","RFQ_REQUEST_MODIFIED","RFQ_ACCEPTED","RFQ_CLOSED"];

                vm.activenotifications = [];
                for (var i = 0; i < resTypes.length; i++) {
                    var result = _.filter(vm.notifications, {keyText: keys[0], type: resTypes[i]});
                    if (result && result[0]) {
                        vm.activenotifications.push(result[0]);
                    }
                }
            }


        };


        /*/get-uploaded/files/{spid}/{itemId}*/

        vm.uploadedFiles = [];
        vm.uploadedRFQFiles = [];

        vm.getFileRef = function(spid, reqId){
            $http.get('api/get-uploaded/files/'+spid+'/'+reqId).then( function(result){
                vm.uploadedFiles = [];
                for(var i=0; i<result.data.length; i++){
                    vm.uploadedFiles.push(result.data[i]);
                }
            });
        };


        vm.getRFQFileRef = function(spid, reqId){
            $http.get('api/get-uploaded/files/'+spid+'/'+reqId).then( function(result){
                vm.uploadedRFQFiles = [];
                for(var i=0; i<result.data.length; i++){
                    vm.uploadedRFQFiles.push(result.data[i]);
                }
            });
        };


        vm.detailedSave="detailed_save.html";


        vm.sanitizeImagePath = function(imagePath){
            var  imageP =  _.replace(imagePath, '/apps', '');

            return imageP;
        }

        /*****/
        var uploader = $scope.uploader = new FileUploader({
            url: '/api/fileupload?spid=' + vm.quotationResponse.id + '&type=profile',
            headers: {
                'X-CSRF-TOKEN': $cookies.get('CSRF-TOKEN')
            }
        });

        uploader.onWhenAddingFileFailed = function (item /*{File|FileLikeObject}*/, filter, options) {
            console.info('onWhenAddingFileFailed', item, filter, options);
        };

        uploader.onAfterAddingFile = function (fileItem) {

            console.info('onAfterAddingFile', fileItem);

            vm.uploading = true;

            $scope.showSpinner = true;
            /*if (fileItem.type == 'uploadCert')
             $scope.uploadCertNow[fileItem.id] = fileItem.file.name;
             if (fileItem.type == 'uploadMemberShip')
             $scope.uploadMemberShipNow[fileItem.id] = fileItem.file.name;
             if (fileItem.type == 'established')
             $scope.establishedNow[fileItem.id] = fileItem.file.name;
             if (fileItem.type == 'profileImage')
             $scope.profileImageNow[fileItem.id] = fileItem.file.name;
             if (fileItem.type == 'logoImage')
             $scope.logoImageNow[fileItem.id] = fileItem.file.name;*/
            /*if($scope.saveChanges)
             save();*/

            fileItem.url = '/api/fileupload?spid='+$scope.serviceproviderId+'&docdesc=rfq&type=rfq&typeid='+$scope.quotationResponseId;


            if (uploader.queue) {
                uploader.uploadAll();
            }
        };

        uploader.onAfterAddingAll = function (addedFileItems) {
            console.info('onAfterAddingAll', addedFileItems);
        };

        uploader.onBeforeUploadItem = function (item) {
            console.info('onBeforeUploadItem', item);
        };

        uploader.onProgressItem = function (fileItem, progress) {
            console.info('onProgressItem', fileItem, progress);
        };

        uploader.onProgressAll = function (progress) {
            console.info('onProgressAll', progress);
        };

        uploader.onSuccessItem = function (fileItem, response, status, headers) {
            console.info('onSuccessItem', fileItem, response, status, headers);



            /*if(vm.serviceProviders.serviceproviderFiles) {
             delete response.serviceprovider;
             response.serviceprovider = vm.serviceProviders.id;
             vm.serviceProviders.serviceproviderFiles.push(response);
             }
             else{
             delete response.serviceprovider;
             vm.serviceProviders.serviceproviderFiles = [];
             response.serviceprovider = vm.serviceProviders.id;
             vm.serviceProviders.serviceproviderFiles.push(response);
             }

             if (fileItem.type == 'uploadCert'){
             $scope.uploadCertError[fileItem.id] = "";
             $scope.uploadCertNow[fileItem.id] = "";
             }

             if (fileItem.type == 'uploadMemberShip'){
             $scope.uploadMemberShipError[fileItem.id] = "";
             $scope.uploadMemberShipNow[fileItem.id] =  "";
             }

             if (fileItem.type == 'established'){
             $scope.establishedError = "";
             $scope.establishedNow[fileItem.id] =  "";
             }

             if (fileItem.type == 'profileImage'){
             $scope.profileImageError = "";
             $scope.profileImageNow[fileItem.id] =  "";
             }

             if (fileItem.type == 'logoImage'){
             $scope.logoImageError = "";
             $scope.logoImageNow[fileItem.id] =  "";
             }*/



            //$window.location.reload();
        };
        uploader.onErrorItem = function (fileItem, response, status, headers) {

            var errorMessage = "Error uploading file, please check that the file's size is 3 MB or lesser. Please contact support@mobilityexchange.com " +
                " if the problem persists.";

            /*if (fileItem.type == 'uploadCert')
             $scope.uploadCertError[fileItem.id] = errorMessage;

             if (fileItem.type == 'uploadMemberShip')
             $scope.uploadMemberShipError[fileItem.id] = errorMessage;

             if (fileItem.type == 'established')
             $scope.establishedError = errorMessage;

             if (fileItem.type == 'profileImage')
             $scope.profileImageError = errorMessage;

             if (fileItem.type == 'logoImage')
             $scope.logoImageError = errorMessage;*/

        };

        uploader.onCancelItem = function (fileItem, response, status, headers) {
            console.info('onCancelItem', fileItem, response, status, headers);
        };

        uploader.onCompleteItem = function (fileItem, response, status, headers) {
            console.info('onCompleteItem', fileItem, response, status, headers);
        };

        uploader.onCompleteAll = function () {
            console.info('onCompleteAll');
            /*$scope.showSpinner = false;
             //$window.location.reload();
             $scope.saveChanges = false;
             $scope.getFileRef();
             getValidationCheclkist(vm.serviceProviders.id);*/
            vm.getFileRef($scope.serviceproviderId, $scope.quotationResponseId);

            vm.uploading = false;
        };

        /*******/





    }
})();
