(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('Communication_extractDeleteController',Communication_extractDeleteController);

    Communication_extractDeleteController.$inject = ['$uibModalInstance', 'entity', 'Communication_extract'];

    function Communication_extractDeleteController($uibModalInstance, entity, Communication_extract) {
        var vm = this;

        vm.communication_extract = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Communication_extract.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
