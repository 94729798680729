(function() {
	angular.module('mobilityexchangeApp').config(stateConfig);
	stateConfig.$inject = [ '$stateProvider' ];
	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'support',
						{
							parent : 'entity',
							url : '/support',
							data : {
								authorities : [ 'ROLE_USER' ]
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/support/support-dialog.html',
													controller : 'SupportController',
													controllerAs : 'vm',
													backdrop : 'static',
													size : 'lg',
													resolve : {
														entity : function() {
															return {
																name : null,
																email : null,
																description : null
															};
														}
													}
												}).result.then(function() {
											$state.go('support', null, {
												reload : 'support'
											});
										}, function() {
											$state.go('support');
										});
									} ]
						});
	}

})();
