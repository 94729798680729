/*eslint-env jquery*/
(function () {
    //'use strict';
    angular
        .module('mobilityexchangeApp')
        .factory('ServiceProviders', ServiceProviders);

    ServiceProviders.$inject = ['$resource', 'DateUtils', 'Lookups'];

    function ServiceProviders($resource, DateUtils, Lookups) {
        var resourceUrl = 'api/service-providers/:id';

        var transformCurrentUserResponse = function(data) {

            if (data) {
                data = angular.fromJson(data);
                data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);

                $.each(data.subscriptionControls,
                    function (index, subscriptionControl) {
                         subscriptionControl.startDate = DateUtils.convertLocalDateFromServer(subscriptionControl.startDate);
                         subscriptionControl.endDate = DateUtils.convertLocalDateFromServer(subscriptionControl.endDate);
                    }
                );

                // convert quality expiration date
                $.each(data.quality,
                    function (index, quality) {
                        quality.expirydate = DateUtils.convertLocalDateFromServer(quality.expirydate);
                        quality.reviewdate = DateUtils.convertLocalDateFromServer(quality.reviewdate);
                    }
                );

                $.each(data.memberAssociations,
                    function (index, memberAssociations) {
                        memberAssociations.valExpiryDate = DateUtils.convertLocalDateFromServer(memberAssociations.valExpiryDate);
                        memberAssociations.valReviewDate = DateUtils.convertLocalDateFromServer(memberAssociations.valReviewDate);
                    }
                );

                $.each(data.compliance,
                    function (index, compliance) {
                        compliance.expirydate = DateUtils.convertLocalDateFromServer(compliance.expirydate);
                        compliance.reviewdate = DateUtils.convertLocalDateFromServer(compliance.reviewdate);
                    }
                );

                $.each(data.destinationServices,
                    function (index, destinationService) {
                        destinationService.expirydate = DateUtils.convertLocalDateFromServer(destinationService.expirydate);
                        destinationService.reviewdate = DateUtils.convertLocalDateFromServer(destinationService.reviewdate);
                    }
                );

                $.each(data.serviceproviderContacts,
                    function (index, serviceproviderContacts) {
                        $.each(serviceproviderContacts.contactQualifications,
                            function (index, contactQualifications) {
                                contactQualifications.certDatedate = DateUtils.convertLocalDateFromServer(contactQualifications.expirydate);
                                contactQualifications.expDate= DateUtils.convertLocalDateFromServer(contactQualifications.expDate);
                            }
                        )
                    }
                );
            }
            return data;
        }


        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'getprofile': {
                method: 'GET',
                url: 'api/service-providers-profile/:id',
                transformResponse: function (data) {
                    ////console.log('GOT DATA, TRANSFORMING!!');
                    if (data) {

                        data = angular.fromJson(data);
                        data.serviceProviders.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                        data.serviceProviders.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);

                        $.each(data.serviceProviders.subscriptionControls,
                            function (index, subscriptionControl) {
                                 subscriptionControl.startDate = DateUtils.convertLocalDateFromServer(subscriptionControl.startDate);
                                 subscriptionControl.endDate = DateUtils.convertLocalDateFromServer(subscriptionControl.endDate);
                            }
                        );

                        // convert quality expiration date
                        $.each(data.serviceProviders.quality,
                            function (index, quality) {
                                quality.expirydate = DateUtils.convertLocalDateFromServer(quality.expirydate);
                                quality.reviewdate = DateUtils.convertLocalDateFromServer(quality.reviewdate);
                            }
                        );

                        $.each(data.serviceProviders.memberAssociations,
                            function (index, memberAssociations) {
                                memberAssociations.valExpiryDate = DateUtils.convertLocalDateFromServer(memberAssociations.valExpiryDate);
                                memberAssociations.valReviewDate = DateUtils.convertLocalDateFromServer(memberAssociations.valReviewDate);
                            }
                        );

                        $.each(data.serviceProviders.compliance,
                            function (index, compliance) {
                                compliance.expirydate = DateUtils.convertLocalDateFromServer(compliance.expirydate);
                                compliance.reviewdate = DateUtils.convertLocalDateFromServer(compliance.reviewdate);
                            }
                        );

                        $.each(data.serviceProviders.destinationServices,
                            function (index, destinationService) {
                                destinationService.expirydate = DateUtils.convertLocalDateFromServer(destinationService.expirydate);
                                destinationService.reviewdate = DateUtils.convertLocalDateFromServer(destinationService.reviewdate);
                            }
                        );

                        $.each(data.serviceProviders.serviceproviderContacts,
                            function (index, serviceproviderContacts) {
                                $.each(serviceproviderContacts.contactQualifications,
                                    function (index, contactQualifications) {
                                        contactQualifications.certDatedate = DateUtils.convertLocalDateFromServer(contactQualifications.expirydate);
                                        contactQualifications.expDate= DateUtils.convertLocalDateFromServer(contactQualifications.expDate);
                                    }
                                )
                            }
                        );
                    }
                    return data;
                }
            },
            'getServiceProvidersCurrentUser': {
                method: 'GET',
                url: 'api/service-providers-current-user',
                transformResponse: function (data) {
                    return transformCurrentUserResponse(data);
                }
            },
            // Don't return 404 if user is not found to avoid an error message displayed for admins that don't have
            // a service provider associated with them
            'getServiceProvidersCurrentUserLenient': {
                method: 'GET',
                url: 'api/service-providers-current-user?strict=false',
                transformResponse: function (data) {
                    return transformCurrentUserResponse(data);
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    ////console.log('GOT DATA, TRANSFORMING!!');
                    if (data) {
                        data = angular.fromJson(data);
                        if (data.businessRegExpiry){
                        	data.businessRegExpiry = DateUtils.convertLocalDateFromServer(data.businessRegExpiry);
                        }

                        if (data.createddate)
                            data.createdDateForDisplay = data.createddate;
                        if (data.modifieddate)
                            data.modifiedDateForDisplay = data.modifieddate;

                        $.each(data.subscriptionControls,
                            function (index, subscriptionControl) {
                                 subscriptionControl.startDate = DateUtils.convertLocalDateFromServer(subscriptionControl.startDate);
                                 subscriptionControl.endDate = DateUtils.convertLocalDateFromServer(subscriptionControl.endDate);
                            }
                        );

                        $.each(data.quality,
                            function (index, quality) {
                                quality.expirydate = DateUtils.convertLocalDateFromServer(quality.expirydate);
                                quality.reviewdate = DateUtils.convertLocalDateFromServer(quality.reviewdate);
                            }
                        );

                        $.each(data.compliance,
                            function (index, compliance) {
                                compliance.expirydate = DateUtils.convertLocalDateFromServer(compliance.expirydate);
                                compliance.reviewdate = DateUtils.convertLocalDateFromServer(compliance.reviewdate);
                            }
                        );

                        $.each(data.destinationServices,
                            function (index, destinationService) {
                                destinationService.expirydate = DateUtils.convertLocalDateFromServer(destinationService.expirydate);
                                destinationService.reviewdate = DateUtils.convertLocalDateFromServer(destinationService.reviewdate);
                            }
                        );

                        $.each(data.memberAssociations,
                            function (index, memberAssociations) {
                                memberAssociations.valExpiryDate = DateUtils.convertLocalDateFromServer(memberAssociations.valExpiryDate);
                                memberAssociations.valReviewDate = DateUtils.convertLocalDateFromServer(memberAssociations.valReviewDate);

                                memberAssociations.statusDate = DateUtils.convertLocalDateFromServer(memberAssociations.statusDate);
                                memberAssociations.originalJoinDate = DateUtils.convertLocalDateFromServer(memberAssociations.originalJoinDate);
                                memberAssociations.currentJoinDate = DateUtils.convertLocalDateFromServer(memberAssociations.currentJoinDate);
                                memberAssociations.expiryDate = DateUtils.convertLocalDateFromServer(memberAssociations.expiryDate);
                                memberAssociations.terminationDate = DateUtils.convertLocalDateFromServer(memberAssociations.terminationDate);
                            }
                        );
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {

                	if (data.businessRegExpiry){
                		data.businessRegExpiry = DateUtils.convertLocalDateToServer(data.businessRegExpiry);
                	}

                	$.each(data.subscriptionControls,
                        function (index, subscriptionControl) {
                    		if (subscriptionControl) {
                                subscriptionControl.startDate = DateUtils.convertDateFromServerToUTC(subscriptionControl.startDate);
                                subscriptionControl.endDate = DateUtils.convertDateFromServerToUTC(subscriptionControl.endDate);
                           	}
                        }
                    );

                    $.each(data.memberAssociations,
                        function (index, memberAssociations) {
                            if (memberAssociations && memberAssociations.valExpiryDate)
                                memberAssociations.valExpiryDate = DateUtils.convertLocalDateToServerDateTime(memberAssociations.valExpiryDate);
                            if (memberAssociations && memberAssociations.valReviewDate)
                                memberAssociations.valReviewDate = DateUtils.convertLocalDateToServerDateTime(memberAssociations.valReviewDate);
                            if (memberAssociations && memberAssociations.statusDate)
                                memberAssociations.statusDate = DateUtils.convertLocalDateToServerDateTime(memberAssociations.statusDate);
                            if (memberAssociations && memberAssociations.originalJoinDate)
                                memberAssociations.originalJoinDate = DateUtils.convertLocalDateToServerDateTime(memberAssociations.originalJoinDate);
                            if (memberAssociations && memberAssociations.currentJoinDate)
                                memberAssociations.currentJoinDate = DateUtils.convertLocalDateToServerDateTime(memberAssociations.currentJoinDate);
                            if (memberAssociations && memberAssociations.expiryDate)
                                memberAssociations.expiryDate = DateUtils.convertLocalDateToServerDateTime(memberAssociations.expiryDate);
                            if (memberAssociations && memberAssociations.terminationDate)
                                memberAssociations.terminationDate = DateUtils.convertLocalDateToServerDateTime(memberAssociations.terminationDate);
                        }
                    );

                    $.each(data.compliance,
                        function (index, compliance) {
                        	 if (compliance && compliance.expirydate)
                                compliance.expirydate = DateUtils.convertLocalDateToServerDateTime(compliance.expirydate);
                        	 if (compliance && compliance.reviewdate)
                        	    compliance.reviewdate = DateUtils.convertLocalDateToServerDateTime(compliance.reviewdate);
                        }
                    );

                    $.each(data.destinationServices,
                        function (index, destinationService) {
                        	 if (destinationService && destinationService.expirydate)
                                destinationService.expirydate = DateUtils.convertLocalDateToServerDateTime(destinationService.expirydate);
                        	 if (destinationService && destinationService.reviewdate)
                        	    destinationService.reviewdate = DateUtils.convertLocalDateToServerDateTime(destinationService.reviewdate);
                        }
                    );

                    $.each(data.quality,
                        function (index, quality) {
                        	 if (quality && quality.expirydate)
                        		 quality.expirydate = DateUtils.convertLocalDateToServerDateTime(quality.expirydate);
                        	 if (quality && quality.reviewdate)
                        		 quality.reviewdate = DateUtils.convertLocalDateToServerDateTime(quality.reviewdate);
                        }
                    );

                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {

                        data = angular.fromJson(data);
                        data.businessRegExpiry = DateUtils.convertLocalDateFromServer(data.businessRegExpiry);

                        // convert quality expiration date
                        $.each(data.subscriptionControls,
                            function (index, subscriptionControl) {
                        		 if( subscriptionControl){
                               	  subscriptionControl.startDate = DateUtils.convertLocalDateFromServer(subscriptionControl.startDate);
                                  subscriptionControl.endDate = DateUtils.convertLocalDateFromServer(subscriptionControl.endDate);
                               }
                            }
                        );

                        $.each(data.quality,
                            function (index, quality) {
                                quality.expirydate = DateUtils.convertLocalDateFromServer(quality.expirydate);
                                quality.reviewdate = DateUtils.convertLocalDateFromServer(quality.reviewdate);
                            }
                        );

                        $.each(data.compliance,
                            function (index, compliance) {
                                compliance.expirydate = DateUtils.convertLocalDateFromServer(compliance.expirydate);
                                compliance.reviewdate = DateUtils.convertLocalDateFromServer(compliance.reviewdate);
                            }
                        );

                        $.each(data.destinationServices,
                            function (index, destinationService) {
                                destinationService.expirydate = DateUtils.convertLocalDateFromServer(destinationService.expirydate);
                                destinationService.reviewdate = DateUtils.convertLocalDateFromServer(destinationService.reviewdate);
                            }
                        );

                        $.each(data.memberAssociations,
                            function (index, memberAssociations) {
                                memberAssociations.valExpiryDate = DateUtils.convertLocalDateFromServer(memberAssociations.valExpiryDate);
                                memberAssociations.valReviewDate = DateUtils.convertLocalDateFromServer(memberAssociations.valReviewDate);

                                memberAssociations.statusDate = DateUtils.convertLocalDateFromServer(memberAssociations.statusDate);
                                memberAssociations.originalJoinDate = DateUtils.convertLocalDateFromServer(memberAssociations.originalJoinDate);
                                memberAssociations.currentJoinDate = DateUtils.convertLocalDateFromServer(memberAssociations.currentJoinDate);
                                memberAssociations.expiryDate = DateUtils.convertLocalDateFromServer(memberAssociations.expiryDate);
                                memberAssociations.terminationDate = DateUtils.convertLocalDateFromServer(memberAssociations.terminationDate);
                            }
                        );

                        if (data.createddate) {
                            data.createdDateForDisplay = data.createddate;
                        }

                        if (data.modifieddate) {
                            data.modifiedDateForDisplay = data.modifieddate;
                        }
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {

                	if (data.businessRegExpiry)
                		data.businessRegExpiry = DateUtils.convertLocalDateToServer(data.businessRegExpiry);

                	$.each(data.subscriptionControls,
                        function (index, subscriptionControl) {
                    		if(subscriptionControl){
                           	 subscriptionControl.startDate = DateUtils.convertLocalDateToServer(subscriptionControl.startDate);
                           	 subscriptionControl.endDate = DateUtils.convertLocalDateToServer(subscriptionControl.endDate);
                           	}
                        }
                    );

                    $.each(data.memberAssociations,
                        function (index, memberAssociations) {
                            if (memberAssociations && memberAssociations.valExpiryDate)
                                memberAssociations.valExpiryDate = DateUtils.convertLocalDateToServerDateTime(memberAssociations.valExpiryDate);
                            if (memberAssociations && memberAssociations.valReviewDate)
                                memberAssociations.valReviewDate = DateUtils.convertLocalDateToServerDateTime(memberAssociations.valReviewDate);
                            if (memberAssociations && memberAssociations.statusDate)
                                memberAssociations.statusDate = DateUtils.convertLocalDateToServerDateTime(memberAssociations.statusDate);
                            if (memberAssociations && memberAssociations.originalJoinDate)
                                memberAssociations.originalJoinDate = DateUtils.convertLocalDateToServerDateTime(memberAssociations.originalJoinDate);
                            if (memberAssociations && memberAssociations.currentJoinDate)
                                memberAssociations.currentJoinDate = DateUtils.convertLocalDateToServerDateTime(memberAssociations.currentJoinDate);
                            if (memberAssociations && memberAssociations.expiryDate)
                                memberAssociations.expiryDate = DateUtils.convertLocalDateToServerDateTime(memberAssociations.expiryDate);
                            if (memberAssociations && memberAssociations.terminationDate)
                                memberAssociations.terminationDate = DateUtils.convertLocalDateToServerDateTime(memberAssociations.terminationDate);
                        }
                    );

                    $.each(data.compliance,
                        function (index, compliance) {
                        	 if (compliance && compliance.expirydate)
                                compliance.expirydate = DateUtils.convertLocalDateToServerDateTime(compliance.expirydate);
                        	 if (compliance && compliance.reviewdate)
                        	    compliance.reviewdate = DateUtils.convertLocalDateToServerDateTime(compliance.reviewdate);
                        }
                    );

                    $.each(data.destinationServices,
                        function (index, destinationService) {
                        	 if (destinationService && destinationService.expirydate) {
                                destinationService.expirydate = DateUtils.convertLocalDateToServerDateTime(destinationService.expirydate);
                             }
                        	 if (destinationService && destinationService.reviewdate) {
                        	    destinationService.reviewdate = DateUtils.convertLocalDateToServerDateTime(destinationService.reviewdate);
                        	 }
                        }
                    );

                    $.each(data.quality,
                        function (index, quality) {
                        	 if (quality && quality.expirydate)
                        		 quality.expirydate = DateUtils.convertLocalDateToServerDateTime(quality.expirydate);
                        	 if (quality && quality.reviewdate)
                        		 quality.reviewdate = DateUtils.convertLocalDateToServerDateTime(quality.reviewdate);
                        }
                    );

                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {

                        data = angular.fromJson(data);

                        $.each(data.subscriptionControls,
                            function (index, subscriptionControl) {
                        		 if (subscriptionControl){
                               	  subscriptionControl.startDate = DateUtils.convertLocalDateFromServer(subscriptionControl.startDate);
                                  subscriptionControl.endDate = DateUtils.convertLocalDateFromServer(subscriptionControl.endDate);
                               }
                            }
                        );

                        // convert quality expiration date
                        $.each(data.quality,
                            function (index, quality) {
                                quality.expirydate = DateUtils.convertLocalDateFromServer(quality.expirydate);
                                quality.reviewdate = DateUtils.convertLocalDateFromServer(quality.reviewdate);
                            }
                        );

                        $.each(data.compliance,
                            function (index, compliance) {
                                compliance.expirydate = DateUtils.convertLocalDateFromServer(compliance.expirydate);
                                compliance.reviewdate = DateUtils.convertLocalDateFromServer(compliance.reviewdate);
                            }
                        );

                        $.each(data.memberAssociations,
                            function (index, memberAssociations) {

                                memberAssociations.valExpiryDate = DateUtils.convertLocalDateFromServer(memberAssociations.valExpiryDate);
                                memberAssociations.valReviewDate = DateUtils.convertLocalDateFromServer(memberAssociations.valReviewDate);

                                memberAssociations.statusDate = DateUtils.convertLocalDateFromServer(memberAssociations.statusDate);
                                memberAssociations.originalJoinDate = DateUtils.convertLocalDateFromServer(memberAssociations.originalJoinDate);
                                memberAssociations.currentJoinDate = DateUtils.convertLocalDateFromServer(memberAssociations.currentJoinDate);
                                memberAssociations.expiryDate = DateUtils.convertLocalDateFromServer(memberAssociations.expiryDate);
                                memberAssociations.terminationDate = DateUtils.convertLocalDateFromServer(memberAssociations.terminationDate);
                            }
                        );
                    }
                    return data;
                }
            },
            'copyChild': {
                method: 'GET',
                url: 'api/service-providers/copy/:id',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'confirmSave': {
                method: 'POST',
                url: 'api/service-providers/:id?confirm=Y',
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'updateMemberCapabilities': {
                method: 'PUT',
                url: 'api/service-providers/membercapabilities',
                params: {id: '@id'},
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'saveMemberCapabilities': {
                method: 'POST',
                url: 'api/service-providers/membercapabilities',
                isArray: true,
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'updateMovingCapabilities': {
                method: 'PUT',
                url: 'api/service-providers/movingcapabilities',
                params: {id: '@id'},
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'saveMovingCapabilities': {
                method: 'POST',
                url: 'api/service-providers/movingcapabilities',
                isArray: true,
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'updateRelocationCapabilities': {
                method: 'PUT',
                url: 'api/service-providers/relocationcapabilities',
                params: {id: '@id'},
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'saveRelocationCapabilities': {
                method: 'POST',
                url: 'api/service-providers/relocationcapabilities',
                isArray: true,
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'updateSupplierCapabilities': {
                method: 'PUT',
                url: 'api/service-providers/suppliercapabilities',
                params: {id: '@id'},
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'saveSupplierCapabilities': {
                method: 'POST',
                url: 'api/service-providers/suppliercapabilities',
                isArray: true,
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'updateFreightServicesCapabilities': {
                method: 'PUT',
                url: 'api/service-providers/freightcapabilities',
                params: {id: '@id'},
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'saveFreightServicesCapabilities': {
                method: 'POST',
                url: 'api/service-providers/freightcapabilities',
                isArray: true,
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'updateMemberAssociations': {
                method: 'PUT',
                url: 'api/service-providers/associations',
                params: {id: '@id'},
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'saveMemberAssociations': {
                method: 'POST',
                url: 'api/service-providers/associations',
                isArray: true,
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'updateQuality': {
                method: 'PUT',
                url: 'api/service-providers/quality',
                params: {id: '@id'},
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'saveQuality': {
                method: 'POST',
                url: 'api/service-providers/quality',
                isArray: true,
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'updateUSGovtCapabilities': {
                method: 'PUT',
                url: 'api/service-providers/usGovtCapabilities',
                params: {id: '@id'},
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'saveUSGovtCapabilities': {
                method: 'POST',
                url: 'api/service-providers/usGovtCapabilities',
                isArray: true,
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'updateLanguageCapabilities': {
                method: 'PUT',
                url: 'api/service-providers/languageCapabilities',
                params: {id: '@id'},
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'saveLanguageCapabilities': {
                method: 'POST',
                url: 'api/service-providers/languageCapabilities',
                isArray: true,
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'saveIamtAffirmation': {
                method: 'PUT',
                url: 'api/service-providers/:id/iamt-affirmation',
                params: {id: '@id'},
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            }
        });
    }
})();
