(function () {
	// 'use strict';

	angular.module('mobilityexchangeApp').factory('DateUtils', DateUtils);

	DateUtils.$inject = ['$filter'];

	function DateUtils($filter) {

		var service = {
			convertDateTimeFromServer: convertDateTimeFromServer,
			convertLocalDateFromServer: convertLocalDateFromServer,
			convertLocalDateToServer: convertLocalDateToServer,
			convertDateFromServerToUTC: convertDateFromServerToUTC,
			dateformat: dateformat,
			getHoursList: getHoursList,
			getLocalDateTime: getLocalDateTime,
			getUTCDate: getUTCDate,
			convertLocalDateToServerDateTime: convertLocalDateToServerDateTime,
			displayDateFormat: "yyyy-MMM-dd",
			fromServerLocalDateTimeToDate: fromServerLocalDateTimeToDate,
			fromServerLocalDateTimeToTimestamp: fromServerLocalDateTimeToTimestamp
		};

		return service;

		function convertDateTimeFromServer(date) {
			if (date) {
				var dateString = date.split('-');
				return new Date(dateString[0], dateString[1] - 1, dateString[2]);
			} else {
				return null;
			}
		}

		function convertLocalDateFromServer(date) {
			if (date) {
				var dateString = date.split('-');
				return new Date(dateString[0], dateString[1] - 1, dateString[2]);
			}
			return null;
		}

		function convertLocalDateToServer(date) {
			if (date) {
				return $filter('date')(date, 'yyyy-MM-dd');
			} else {
				return null;
			}
		}

		function convertDateFromServerToUTC(date) {
			if (date) {
				return new Date(date);
			} else {
				return null;
			}
		}

		function getLocalDateTime(timezoneOffset) {
			var nd = getUTCDate(timezoneOffset);
			var minutes = nd.getMinutes() > 9 ? '' + nd.getMinutes() : '0' + nd.getMinutes();
			var seconds = nd.getSeconds() > 9 ? '' + nd.getSeconds() : '0' + nd.getSeconds();
			var date = (nd.getMonth() + 1) + '/' + +nd.getDate() + '/' + nd.getFullYear() + ' ' + nd.getHours() + ':' + minutes + ':' + seconds;
			return date;
		}

		function getUTCDate(timezoneOffset) {
			var today = new Date();
			var utc = today.getTime() + (today.getTimezoneOffset() * 60000);
			var newOffset = timezoneOffset.split(':');
			var x = parseInt(newOffset[1]);
			if (x > 1) {
				timezoneOffset = '' + newOffset[0] + '.5';
			}

			var nd = new Date(utc + (3600000 * timezoneOffset));
			return nd;
		}

		function getHoursList() {
			return [{
				id: '1:00',
				value: '1:00 AM'
			}, {
				id: '1:30',
				value: '1:30 AM'
			}, {
				id: '2:00',
				value: '2:00 AM'
			}, {
				id: '2:30',
				value: '2:30 AM'
			}, {
				id: '3:00',
				value: '3:00 AM'
			}, {
				id: '3:30',
				value: '3:30 AM'
			}, {
				id: '4:00',
				value: '4:00 AM'
			}, {
				id: '4:30',
				value: '4:30 AM'
			}, {
				id: '5:00',
				value: '5:00 AM'
			}, {
				id: '5:30',
				value: '5:30 AM'
			}, {
				id: '6:00',
				value: '6:00 AM'
			}, {
				id: '6:30',
				value: '6:30 AM'
			}, {
				id: '7:00',
				value: '7:00 AM'
			}, {
				id: '7:30',
				value: '7:30 AM'
			}, {
				id: '8:00',
				value: '8:00 AM'
			}, {
				id: '8:30',
				value: '8:30 AM'
			}, {
				id: '9:00',
				value: '9:00 AM'
			}, {
				id: '9:30',
				value: '9:30 AM'
			}, {
				id: '10:00',
				value: '10:00 AM'
			}, {
				id: '10:30',
				value: '10:30 AM'
			}, {
				id: '11:00',
				value: '11:00 AM'
			}, {
				id: '11:30',
				value: '11:30 AM'
			}, {
				id: '12:00',
				value: '12:00 PM'
			}, {
				id: '12:30',
				value: '12:30 PM'
			}, {
				id: '13:00',
				value: '1:00 PM'
			}, {
				id: '13:30',
				value: '1:30 PM'
			}, {
				id: '14:00',
				value: '2:00 PM'
			}, {
				id: '14:30',
				value: '2:30 PM'
			}, {
				id: '15:00',
				value: '3:00 PM'
			}, {
				id: '15:30',
				value: '3:30 PM'
			}, {
				id: '16:00',
				value: '4:00 PM'
			}, {
				id: '16:30',
				value: '4:30 PM'
			}, {
				id: '17:00',
				value: '5:00 PM'
			}, {
				id: '17:30',
				value: '5:30 PM'
			}, {
				id: '18:00',
				value: '6:00 PM'
			}, {
				id: '18:30',
				value: '6:30 PM'
			}, {
				id: '19:00',
				value: '7:00 PM'
			}, {
				id: '19:30',
				value: '7:30 PM'
			}, {
				id: '20:00',
				value: '8:00 PM'
			}, {
				id: '20:30',
				value: '8:30 PM'
			}, {
				id: '21:00',
				value: '9:00 PM'
			}, {
				id: '21:30',
				value: '9:30 PM'
			}, {
				id: '22:00',
				value: '10:00 PM'
			}, {
				id: '22:30',
				value: '10:30 PM'
			}, {
				id: '23:00',
				value: '11:00 PM'
			}, {
				id: '23:30',
				value: '11:30 PM'
			}, {
				id: '24:00',
				value: '12:00 AM'
			}, {
				id: '24:30',
				value: '12:30 AM'
			}

			];
		}
		function convertLocalDateToServerDateTime(date) {
			if (date) {
				return $filter('date')(date, 'yyyy-MM-ddThh:mm:ss');
			} else {
				return null;
			}
		}

        function fromServerLocalDateTimeToDate(date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }

        function fromServerLocalDateTimeToTimestamp(date) {
			if (date) {
				var dateAndTime = date.split('T');
				if (dateAndTime && dateAndTime.length > 1) {
				    var d = dateAndTime[0].split("-");
				    var t = dateAndTime[1].split(":");
				    if (d && d.length > 2 && t && t.length > 2) {
				        return new Date(d[0], d[1] - 1, d[2], t[0], t[1], t[2]).getTime();
				    }
				}
			}
			return 0;
        }

		function dateformat() {
			return 'yyyy-MM-dd';
		}

	}

})();
