(function() {
	//'use strict';

	angular.module('mobilityexchangeApp').controller(
			'PaymentProcessingController', PaymentProcessingController)
			.controller('PaymentProcessingProcessController',
					PaymentProcessingProcessController).controller(
					'PaymentProcessingAllocateController',
					PaymentProcessingAllocateController).directive(
					'format',
					[
							'$filter',
							function($filter) {
								return {
									require : '?ngModel',
									link : function(scope, elem, attrs, ctrl) {
										if (!ctrl)
											return;

										ctrl.$formatters.unshift(function(a) {
											return $filter(attrs.format)(
													ctrl.$modelValue)
										});

										elem.bind('blur', function(event) {
											var plainNumber = elem.val()
													.replace(/[^\d|\-+|\.+]/g,
															'');
											elem.val($filter(attrs.format)(
													plainNumber));
										});
									}
								};
							} ]);

	PaymentProcessingController.$inject = [ '$rootScope', '$scope', '$state',
			'PaymentProcessing', 'PaymentProcessingSearch', 'ParseLinks',
			'AlertService', 'pagingParams', 'paginationConstants', 'Principal',
			'$log', '$stateParams', 'Lookups' ];

	PaymentProcessingProcessController.$inject = [ '$state',
			'PaymentProcessing', 'PaymentProcessingSearch', '$stateParams',
			'$http', '$scope', 'DateUtils', '$q', 'Lookups', '$timeout',
			'$window', 'entity' ];

	PaymentProcessingAllocateController.$inject = [ '$state',
			'PaymentProcessing', 'PaymentProcessingSearch', '$stateParams',
			'$http', '$scope', 'DateUtils', '$q', 'Lookups', '$timeout',
			'$window', 'entity' ];

	function PaymentProcessingController($rootScope, $scope, $state,
			PaymentProcessing, PaymentProcessingSearch, ParseLinks,
			AlertService, pagingParams, paginationConstants, Principal, $log,
			$stateParams, Lookups) {
		var vm = this;
		vm.loadPage = loadPage;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.clear = clear;
		vm.transitionOnKeyUp = transitionOnKeyUp;
		vm.search = search;
		vm.loadAll = loadAll;
		vm.searchQuery = pagingParams.search;
		vm.currentSearch = pagingParams.search;

		vm.id = $stateParams.id;
		vm.paymentAmount = $stateParams.paymentAmount;
		vm.paymentDate = $stateParams.paymentDate;
		vm.balanceAmount = $stateParams.balanceAmount;
		vm.company = $stateParams.company;
		vm.spid = $stateParams.spid;

		loadAll();

		function loadAll() {
			if (pagingParams.search) {
				PaymentProcessingSearch.query({
					query : pagingParams.search,
					page : pagingParams.page - 1,
					size : vm.itemsPerPage,
					sort : sort(),
					id : vm.id ? vm.id : $stateParams.id,
					paymentAmount : vm.paymentAmount ? vm.paymentAmount
							: $stateParams.paymentAmount,
					paymentDate : vm.paymentDate ? vm.paymentDate
							: $stateParams.paymentDate,
					balanceAmount : vm.balanceAmount ? vm.balanceAmount
							: $stateParams.balanceAmount,
					company : vm.company ? vm.company : $stateParams.company,
					spid : vm.spid ? vm.spid : $stateParams.spid

				}, onSuccess, onError);
			} else {
				PaymentProcessing.query({
					page : pagingParams.page - 1,
					size : vm.itemsPerPage,
					sort : sort()
				}, onSuccess, onError);
			}

		}

		function sort() {
			var result = [ vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc') ];
			if (vm.predicate !== 'id') {
				result.push('id');
			}
			return result;
		}

		function onSuccess(data, headers) {
			vm.links = ParseLinks.parse(headers('link'));
			vm.totalItems = headers('X-Total-Count');
			vm.queryCount = vm.totalItems;
			vm.PaymentProcessing = data;
			vm.page = pagingParams.page;

		}

		function onError(error) {
			AlertService.error(error.data.message);
		}

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch,
				id : vm.id ? vm.id : $stateParams.id,
				paymentAmount : vm.paymentAmount ? vm.paymentAmount
						: $stateParams.paymentAmount,
				paymentDate : vm.paymentDate ? vm.paymentDate
						: $stateParams.paymentDate,
				balanceAmount : vm.balanceAmount ? vm.balanceAmount
						: $stateParams.balanceAmount,
				company : vm.company ? vm.company : $stateParams.company,
				spid: vm.spid ? vm.spid : $stateParams.spid
			});
		}

		function search(searchQuery) {
			searchQuery = '-';
			if (!searchQuery) {
				return vm.clear();
			}
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			vm.currentSearch = searchQuery;
			vm.transition();
		}

		function clear() {
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = true;
			vm.currentSearch = null;
			vm.transition();
		}

		$scope.goToOnKeyUp = function(e, searchQuery) {
			if (event.keyCode === 13) {
				vm.transitionOnKeyUp(searchQuery);
			}
		}

		function transitionOnKeyUp(searchQuery) {
			searchQuery = '-';
			if (!searchQuery) {
				return vm.clear();
			}
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			vm.currentSearch = searchQuery;
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch,
				id : vm.id,
				paymentAmount : vm.paymentAmount,
				paymentDate : vm.paymentDate,
				balanceAmount : vm.balanceAmount,
				company : vm.company,
				spid : vm.spid
			});
		}
	}

	function PaymentProcessingProcessController($state, PaymentProcessing,
			PaymentProcessingSearch, $stateParams, $http, $scope, DateUtils,
			$q, Lookups, $timeout, $window, entity) {
		var vm = this;
		vm.id = $stateParams.id;
		vm.paymentProcessings = entity;
	}

	function PaymentProcessingAllocateController($state, PaymentProcessing,
			PaymentProcessingSearch, $stateParams, $http, $scope, DateUtils,
			$q, Lookups, $timeout, $window, entity) {
		var vm = this;
		vm.id = $stateParams.id;
		vm.invNum = $stateParams.invNum;
		vm.paymentProcessings = entity;
		vm.receiptBalance = vm.paymentProcessings.balanceAmount;
		vm.recalculateBalance = recalculateBalance;

		vm.save = save;
		vm.fullAllocate = fullAllocate;
		vm.calculateInvoiceLineAmountTotal = calculateInvoiceLineAmountTotal;
		vm.invoiceLineAmountTotal = vm.calculateInvoiceLineAmountTotal();

		function fullAllocate() {
			var balance = vm.paymentProcessings.balanceAmount;
			var recalculate = false;
			console.log('Full allocate - processing balance: ' + balance + ', invoice line amount total: ' + vm.invoiceLineAmountTotal);

            // MX-1425 Check whether the balance would cover all invoice lines. If so, allocate payment for each invoice
            // line.
            if (vm.invoiceLineAmountTotal <= balance) {
                processFullAllocationFullPayment();
                return;
            }

            // MX-1425 This logic does not work correctly with negative payments. It runs recursively but the starting amount
            // for the recursion is not adjusted based on the allocation in the initial run. The initial run incorrectly
            // handles negative values and incorrectly increases the balance. Considering effort involved in rewriting
            // and regression testing it was decided to add a check above and leave this logic untouched. This means that
            // the case when the balance is less than total invoice amount and there are invoice lines with negative amounts
            // is still not handled correctly.
			for (var i = 0; i < vm.paymentProcessings.invoiceLines.length; i++) {
			    console.log('-- Processing invoice line index: ' + i + ', invoice line: ' + vm.paymentProcessings.invoiceLines[i]);
				if (vm.paymentProcessings.invoiceLines[i].receiptNum != vm.paymentProcessings.receiptNumber
						&& vm.paymentProcessings.invoiceLines[i].paymentAmount == 0) {
					console.log('Invoice line receipt number [' + vm.paymentProcessings.invoiceLines[i].receiptNum +
					    '] is not the same as processing receipt number [' + vm.paymentProcessings.receiptNumber +
					    '] and payment amount is 0');
					if (balance >= vm.paymentProcessings.invoiceLines[i].amount) {
					    console.log('Invoice line index [' + i + '] - balance [' + balance + '] greater than or equal the invoice line amount [' + vm.paymentProcessings.invoiceLines[i].amount + ']');

						vm.paymentProcessings.invoiceLines[i].receiptNum = vm.paymentProcessings.receiptNumber;
						vm.paymentProcessings.invoiceLines[i].paymentAmount = parseFloat(vm.paymentProcessings.invoiceLines[i].amount)
								.toFixed(2);
						balance -= vm.paymentProcessings.invoiceLines[i].amount;
                        console.log('Invoice line index [' + i + '] - set invoice line receipt number to [' + vm.paymentProcessings.invoiceLines[i].receiptNum +
                            '], payment amount [' + vm.paymentProcessings.invoiceLines[i].paymentAmount + '], balance [' +
                            balance + '], payment processing balance amount [' + vm.paymentProcessings.balanceAmount + ']');

						if (vm.paymentProcessings.invoiceLines[i].amount < 0) {
						    console.log('Invoice line index [' + i + '] - invoice line amount is less than 0, set the flag to trigger recalculation');
							recalculate = true;
						}
					} else if (balance > 0) {
					    console.log('Invoice line index [' + i + '] - balance is greater than 0 but less than invoice line amount');
						vm.paymentProcessings.invoiceLines[i].receiptNum = vm.paymentProcessings.receiptNumber;
						vm.paymentProcessings.invoiceLines[i].paymentAmount = parseFloat(balance)
								.toFixed(2);
						balance = 0;
                        console.log('Invoice line index [' + i + '] - set invoice line receipt number to [' + vm.paymentProcessings.invoiceLines[i].receiptNum +
                            '], payment amount [' + vm.paymentProcessings.invoiceLines[i].paymentAmount + '], balance [' +
                            balance + '], payment processing balance amount [' + vm.paymentProcessings.balanceAmount + ']');
					} else {
					    console.log('Invoice line index [' + i + '] - balance is less than or equal 0 [' + balance +
					    '], payment processing balance amount [' + vm.paymentProcessings.balanceAmount + ']');
					}
				} else {
					console.log('Invoice line index [' + i + '] - invoice line receipt number [' + vm.paymentProcessings.invoiceLines[i].receiptNum +
					    '] is  the same as processing receipt number [' + vm.paymentProcessings.receiptNumber +
					    '] or the payment amount is not 0');
				}
			}

			if (recalculate) {
			    console.log('Recalculate full allocation - start');
				fullAllocate();
				console.log('Recalculate full allocation - end, balance [' + balance +
				    '], payment processing balance amount [' + vm.paymentProcessings.balanceAmount + ']');
			}

			vm.paymentProcessings.balanceAmount = parseFloat(balance).toFixed(2);
            console.log('Balance [' + balance + '], payment processing balance amount: ' + vm.paymentProcessings.balanceAmount);
		}

		$scope.disableFullAllocate = false;

        function processFullAllocationFullPayment() {
            var balance = vm.paymentProcessings.balanceAmount;

			for (var i = 0; i < vm.paymentProcessings.invoiceLines.length; i++) {
			    console.log('-- Processing full allocation full payment, invoice line index: ' + i + ', invoice line: ' + vm.paymentProcessings.invoiceLines[i]);
				if (vm.paymentProcessings.invoiceLines[i].receiptNum != vm.paymentProcessings.receiptNumber
						&& vm.paymentProcessings.invoiceLines[i].paymentAmount == 0) {

						vm.paymentProcessings.invoiceLines[i].receiptNum = vm.paymentProcessings.receiptNumber;
						vm.paymentProcessings.invoiceLines[i].paymentAmount = parseFloat(vm.paymentProcessings.invoiceLines[i].amount)
								.toFixed(2);
						balance -= vm.paymentProcessings.invoiceLines[i].amount;
                        console.log('Processing full allocation full payment, invoice line index [' + i +
                            '] - set invoice line receipt number to [' + vm.paymentProcessings.invoiceLines[i].receiptNum +
                            '], payment amount [' + vm.paymentProcessings.invoiceLines[i].paymentAmount + '], balance [' +
                            balance + '], payment processing balance amount [' + vm.paymentProcessings.balanceAmount + ']');
                } else {
                    console.log('Processing full allocation full payment, invoice line index [' + i +
                        '] - invoice line receipt number [' + vm.paymentProcessings.invoiceLines[i].receiptNum +
                        '] is  the same as processing receipt number [' + vm.paymentProcessings.receiptNumber +
                        '] or the payment amount is not 0');
                }
            }

            vm.paymentProcessings.balanceAmount = parseFloat(balance).toFixed(2);
            console.log('Processing full allocation full payment, balance [' + balance + '], payment processing balance amount: ' + vm.paymentProcessings.balanceAmount);
        }

		function calculateInvoiceLineAmountTotal() {
			var total = 0;
			for (var i = 0; i < vm.paymentProcessings.invoiceLines.length; i++) {
				if (!vm.paymentProcessings.invoiceLines[i].paymentAmount
						|| vm.paymentProcessings.invoiceLines[i].paymentAmount == 0) {
					total += vm.paymentProcessings.invoiceLines[i].amount
				}
			}
			return total;
		}

		function save() {
			if (vm.paymentProcessings.balanceAmount < 0) {
				vm.paymentValMessage = 'Insufficient Balance, please reallocate.';
				return false;
			}
			PaymentProcessing.finalizeAllocation(vm.paymentProcessings,
					onSaveSuccess, onSaveError);
		}

		function onSaveSuccess(result) {
			vm.invoiceHeader = result;
			$scope.$emit('mobilityexchangeApp:invoiceHeaderUpdate', result);
			vm.isSaving = false;
		}

		function onSaveError() {
			vm.isSaving = false;
		}

		function recalculateBalance(obj) {
			if (obj && obj.paymentAmount != 0) {
				obj.receiptNum = vm.paymentProcessings.receiptNumber;
			} else{
				obj.receiptNum = null;
			}
			vm.paymentProcessings.balanceAmount = vm.receiptBalance;
			for (var i = 0; i < vm.paymentProcessings.invoiceLines.length; i++) {
				if (vm.paymentProcessings.invoiceLines[i].receiptNum == vm.paymentProcessings.receiptNumber)
					vm.paymentProcessings.balanceAmount = vm.paymentProcessings.balanceAmount
							- vm.paymentProcessings.invoiceLines[i].paymentAmount;
			}
		}
	}
})();
