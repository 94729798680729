(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('AddressesDeleteController', AddressesDeleteController);

    AddressesDeleteController.$inject = ['$uibModalInstance', 'entity', 'Addresses'];

    function AddressesDeleteController($uibModalInstance, entity, Addresses) {
        var vm = this;

        vm.addresses = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            Addresses.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
