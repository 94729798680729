(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('Communication_extractController', Communication_extractController);

    Communication_extractController.$inject = ['$state', 'Communication_extract', 'Communication_extractSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','$http'];

    function Communication_extractController($state, Communication_extract, Communication_extractSearch, ParseLinks, AlertService, paginationConstants, pagingParams, $http) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();
        
        vm.downloadReport = function(obj, type){
            $http.get('api/run-communication-extracts/'+obj.id, {responseType: 'arraybuffer'}).then(
                function successCallback(data) {
                    var file = new Blob([data.data], {type: 'text/csv'});
                    var fileURL = URL.createObjectURL(file);
                    //window.open(fileURL, "test.csv");
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    url = window.URL.createObjectURL(file);
                    a.href = url;
                    a.download = obj.reportname+"."+type;
                    a.click();
                    window.URL.revokeObjectURL(url);

            }, function errorCallback(response) {
            });
        };

        function loadAll () {
            if (pagingParams.search) {
                Communication_extractSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Communication_extract.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.communication_extracts = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
