(function() {
	// 'use strict';
	angular.module('mobilityexchangeApp').factory('AdInvoiceHeader', AdInvoiceHeader);

	AdInvoiceHeader.$inject = [ '$resource', 'DateUtils' ];

	function AdInvoiceHeader($resource, DateUtils) {
		var resourceUrl = 'api/adv-controls-activity/:id';

		return $resource(
				resourceUrl,
				{},
				{
					'query' : {
						method : 'GET',
						isArray : true,
						url : 'api/adv-controls-activity/:id?'
					},
					'get' : {
						method : 'GET',
						isArray : true,
						url : 'api/adv-controls-activity/:id?'
					}
				});
	}
})();
