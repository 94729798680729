(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('Register', Register);

    Register.$inject = ['$resource'];

    function Register($resource) {
        return $resource('api/register', {}, {});
    }
})();
