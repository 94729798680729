(function() {
	//'use strict';
	angular.module('mobilityexchangeApp').factory('MemberProfile',
			MemberProfile);

	MemberProfile.$inject = [ '$resource', 'DateUtils' ];

	function MemberProfile($resource, DateUtils) {
		var resourceUrl = 'api/member-profile/:id';

		return $resource(
				resourceUrl,
				{},
				{
					'query' : {
						method : 'GET',
						isArray : true
					},
					'get' : {
						method : 'GET',
						  transformResponse: function (data) {
			                    if (data) {
			                        data = angular.fromJson(data);
			                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
			                        data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
			                        data.dob = DateUtils.convertLocalDateFromServer(data.dob);
			                        $.each(
			                                data.contactQualifications,
			                                function (index, contactQualifications) {
			                                    contactQualifications.certDate = DateUtils.convertLocalDateFromServer(contactQualifications.certDate);
			                                    contactQualifications.expDate= DateUtils.convertLocalDateFromServer(contactQualifications.expDate);
			                                }
			                        );
			                    }
			                    return data;
			                }
					},
					'update' : {
						method : 'PUT',
						transformRequest : function(data) {
							data.createddate = DateUtils
									.convertLocalDateToServer(data.createddate);
							data.modifieddate = DateUtils
									.convertLocalDateToServer(data.modifieddate);
							data.dob = DateUtils
									.convertLocalDateToServer(data.dob);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							if (data) {
								data = angular.fromJson(data);
								data.createddate = DateUtils
										.convertLocalDateFromServer(data.createddate);
								data.modifieddate = DateUtils
										.convertLocalDateFromServer(data.modifieddate);
								data.dob = DateUtils
										.convertLocalDateFromServer(data.dob);
								$
										.each(
												data.contactQualifications,
												function(index,
														contactQualifications) {
													contactQualifications.certDate = DateUtils
															.convertLocalDateFromServer(contactQualifications.certDate);
													contactQualifications.expDate = DateUtils
															.convertLocalDateFromServer(contactQualifications.expDate);
												});
							}
							return data;
						}
					},
					'save' : {
						method : 'POST',
						transformRequest : function(data) {
							data.createddate = DateUtils
									.convertLocalDateToServer(data.createddate);
							data.modifieddate = DateUtils
									.convertLocalDateToServer(data.modifieddate);
							data.dob = DateUtils
									.convertLocalDateToServer(data.dob);
							return angular.toJson(data);
						}
					}
				});
	}
})();
