(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('CRMNotesDetailController', CRMNotesDetailController);

    CRMNotesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CRMNotes', 'ProviderContacts'];

    function CRMNotesDetailController($scope, $rootScope, $stateParams, previousState, entity, CRMNotes, ProviderContacts) {
        var vm = this;

        vm.cRMNotes = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:cRMNotesUpdate', function (event, result) {
            vm.cRMNotes = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
