(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('quality', {
                parent: 'entity',
                url: '/quality?page&sort&search',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'mobilityexchangeApp.quality.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/quality/qualities.html',
                        controller: 'QualityController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('quality');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('quality-detail', {
                parent: 'entity',
                url: '/quality/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'mobilityexchangeApp.quality.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/quality/quality-detail.html',
                        controller: 'QualityDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('quality');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Quality', function ($stateParams, Quality) {
                        return Quality.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'quality',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('quality-detail.edit', {
                parent: 'quality-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/quality/quality-dialog.html',
                        controller: 'QualityDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Quality', function (Quality) {
                                return Quality.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('quality.new', {
                parent: 'quality',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/quality/quality-dialog.html',
                        controller: 'QualityDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    awardedto: null,
                                    originalapprovaldate: null,
                                    expirydate: null,
                                    registrar: null,
                                    certificatenumber: null,
                                    mxvalidationdate: null,
                                    scope: null,
                                    createdby: null,
                                    createddate: null,
                                    modifiedby: null,
                                    modifieddate: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('quality', null, {reload: 'quality'});
                    }, function () {
                        $state.go('quality');
                    });
                }]
            })
            .state('quality.edit', {
                parent: 'quality',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/quality/quality-dialog.html',
                        controller: 'QualityDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Quality', function (Quality) {
                                return Quality.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('quality', null, {reload: 'quality'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('quality.delete', {
                parent: 'quality',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/quality/quality-delete-dialog.html',
                        controller: 'QualityDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Quality', function (Quality) {
                                return Quality.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('quality', null, {reload: 'quality'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
