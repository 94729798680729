(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('ComplianceDetailController', ComplianceDetailController);

    ComplianceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Compliance'];

    function ComplianceDetailController($scope, $rootScope, $stateParams, previousState, entity, Compliance) {
        var vm = this;

        vm.compliance = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:complianceUpdate', function(event, result) {
            vm.compliance = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
