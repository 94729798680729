(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('EmailLogsDeleteController',EmailLogsDeleteController);

    EmailLogsDeleteController.$inject = ['$uibModalInstance', 'entity', 'EmailLogs'];

    function EmailLogsDeleteController($uibModalInstance, entity, EmailLogs) {
        var vm = this;

        vm.emailLogs = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            EmailLogs.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
