(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('CRMNotesDeleteController', CRMNotesDeleteController);

    CRMNotesDeleteController.$inject = ['$uibModalInstance', 'entity', 'CRMNotes'];

    function CRMNotesDeleteController($uibModalInstance, entity, CRMNotes) {
        var vm = this;

        vm.cRMNotes = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            CRMNotes.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
