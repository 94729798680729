(function () {
    //'use strict';
    angular
        .module('mobilityexchangeApp')
        .factory('Lookups', Lookups);

    Lookups.$inject = ['$resource', 'DateUtils'];

    function Lookups($resource, DateUtils) {
        var resourceUrl = 'api/lookups/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                cache: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                        data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'getByLookupType': {
                method: 'GET',
                url: 'api/lookups/lookuptypes/:id',
                params: {id: '@id'},
                isArray: true,
                cache: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                        data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                    }
                    return data;
                }
            },'getByMultiLookupType': {
                method: 'GET',
                url: 'api/lookups/multilookuptypes/:id',
                params: {id: '@id'},
                isArray: true,
                cache: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                        data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                    }
                    return data;
                }
            },
            'getActiveByLookupType': {
                method: 'GET',
                url: 'api/activelookups/lookuptypes/:id',
                params: {id: '@id'},
                isArray: true,
                cache: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                        data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                    }
                    return data;
                }
            }
            ,
            'getByLookupById': {
                method: 'GET',
                url: 'api/lookups/:id',
                params: {id: '@id'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                        data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                    }
                    return data;
                }
            },
            'getMemberTypes': {
                method: 'GET',
                isArray: true,
                cache: true,
                    url: 'api/lookups/memberTypes',
                    transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                        data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                    }
                    return data;
                }
            }
        });

    }
})();
