(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('DisclaimerDetailController', DisclaimerDetailController);

    DisclaimerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Disclaimer', '$sce', 'DisclaimerTracking', '$state'];

    function DisclaimerDetailController($scope, $rootScope, $stateParams, previousState, entity, Disclaimer, $sce, DisclaimerTracking, $state) {
        var vm = this;

        vm.disclaimer = entity;
        vm.previousState = '#';

        vm.formattedDisclaimerText = $sce.trustAsHtml(vm.disclaimer.text);
        vm.save = save;
        vm.accept = accept;

        function accept () {
            vm.disclaimer = { id: $stateParams.id };
            vm.disclaimerTracking = { disclaimer: vm.disclaimer };
            DisclaimerTracking.save(vm.disclaimerTracking, onSaveSuccess, onSaveError);

        }

        function decline () {

        }

        function save () {

            vm.isSaving = true;
            if (vm.disclaimer.id !== null) {
                Disclaimer.update(vm.disclaimer, onSaveSuccess, onSaveError);
            } else {
                Disclaimer.save(vm.disclaimer, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('mobilityexchangeApp:disclaimerUpdate', result);
            vm.isSaving = false;
            console.log('previousState.name '+ JSON.stringify(previousState));
            $state.go(previousState.name, previousState.params);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:disclaimerUpdate', function(event, result) {
            vm.disclaimer = result;
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();
