(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('ContentControlSearch', ContentControlSearch);

    ContentControlSearch.$inject = ['$resource'];

    function ContentControlSearch($resource) {
        var resourceUrl = 'api/_search/content-control/?id=:id&description=:description&website=:website&type=:type';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }
})();