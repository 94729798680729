(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('BillingControlDeleteController',BillingControlDeleteController);

    BillingControlDeleteController.$inject = ['$uibModalInstance', 'entity', 'BillingControl'];

    function BillingControlDeleteController($uibModalInstance, entity, BillingControl) {
        var vm = this;

        vm.billingControl = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            BillingControl.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
