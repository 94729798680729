(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('member-contacts', {
                parent: 'entity',
                url: '/member-contacts?page&sort&search&mid&firstname&lastname&company&title&city&email&country&mxstatus&navtype',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN'],
                    pageTitle: 'mobilityexchangeApp.memberContacts.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/member-contacts/member-contacts.html',
                        controller: 'MemberContactsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'lastname,asc',
                        squash: true
                    },
                    search: null,
                    mid: null,
                    firstname: null,
                    lastname: null,
                    title: null,
                    company: null,
                    mxstatus: null,
                    city: null,
                    email: null,
                    country: null,
                    navtype: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('memberContacts');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            
            .state('member-lookup', {
                parent: 'entity',
                url: '/member-contacts?page&sort&search&navtype',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN'],
                    pageTitle: 'mobilityexchangeApp.memberContacts.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/member-contacts/member-contacts.html',
                        controller: 'MemberContactsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'lastname,asc',
                        squash: true
                    },
                    search: null,
                    mid: null,
                    firstname: null,
                    lastname: null,
                    title: null,
                    company: null,
                    mxstatus: null,
                    city: null,
                    email: null,
                    country: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('memberContacts');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            
            .state('member-profile', {
                parent: 'entity',
                url: '/member-profile',
                data: {
                	//authorities: ['ROLE_USER']
                },
                params : {
                    navtype: '1'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/member-contacts/member-contacts-dialog.html',
                        controller: 'MemberContactsDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                    entity: ['$stateParams', 'MemberProfile', function ($stateParams, MemberProfile) {
                        return MemberProfile.get({id: $stateParams.id}).$promise;
                    }],

                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            isCopy: 'false',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            
            .state('member-contacts.edit', {
                parent: 'member-contacts',
                url: '/{id}/edit?navtype={navtype}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN']
                },
                params : {
                    navtype: '1'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/member-contacts/member-contacts-dialog.html',
                        controller: 'MemberContactsDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                    entity: ['$stateParams', 'MemberContacts', function ($stateParams, MemberContacts) {
                        return MemberContacts.get({id: $stateParams.id}).$promise;
                    }],

                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            isCopy: 'false',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }

            })
            
            
             .state('member-contacts.edit.customer-notes-list', {
                url: '/customer-notes-list/{providerContactsId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ANALYST']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/customer-notes/customer-notes.html',
                        controller: 'ProviderContactsDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ProviderContacts', function (ProviderContacts) {
                                return ProviderContacts.get({id: $stateParams.providerContactsId}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader',
                                function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customerNotes');
                                return $translate.refresh();
                            }],
                        }
                    }).result.finally(function () {
                        $state.go('^');
                    });
                }]
            })

            
                   
            .state('service-providers.editcopymc', {
                parent: 'service-providers',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/service-providers/service-providers-dialog.html',
                        controller: 'ServiceProvidersDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'ServiceProviders', function ($stateParams, ServiceProviders) {
                        return ServiceProviders.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            isCopy: 'true',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }

            })
            .state('member-contacts.new', {
            	parent: 'entity',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER']
                },
                params : {
                    navtype: '1',
                    serviceProviderId: null,
                    company: null, 
                    phonePrefix:null,
                    iammembertype:null
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/member-contacts/member-contacts-dialog.html',
                        controller: 'MemberContactsDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                	entity: ['$stateParams', function ($stateParams) {
                        return {
                            firstname: null,
                            lastname: null,
                            serviceProviderId: $stateParams.serviceProviderId,
                            title: null,
                            officephone: null,
                            cellphone: null,
                            email: null,
                            skype: null,
                            company: $stateParams.company,
                            functionalRole: null,
                            city: null,
                            email: null,
                            country: null,
                            mxstatus: null,
                            id: -1,
                            countryCode: $stateParams.phonePrefix
                        };
                    }],

                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            isCopy: 'false',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }

            })
            .state('member-contacts.delete', {
            parent: 'member-contacts',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/member-contacts/member-contacts-delete-dialog.html',
                    controller: 'MemberContactsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['MemberContacts', function (MemberContacts) {
                            return MemberContacts.get({id: $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    $state.go('member-contacts', null, {reload: 'member-contacts'});
                }, function () {
                    $state.go('^');
                });
            }]
        })
        .state('member-contacts.move', {
            parent: 'member-contacts',
            url: '/{id}/move',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/member-contacts/member-contacts-move-dialog.html',
                    controller: 'MemberContactsMoveController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['MemberContacts', function (MemberContacts) {
                            return MemberContacts.get({id: $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    $state.go('member-contacts.edit', {id: $stateParams.id });
                }, function () {
                    $state.go('^');
                });
            }]
        })
        .state('member-contacts-gateway', {
            parent: 'entity',
            url: '/member-gateway?ref={r}',
            data: {
            	authorities: []
            },
            params : {
                navtype: '1'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/member-contacts/member-contacts-dialog.html',
                    controller: 'MemberContactsDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {

                entity: ['$stateParams', 'MemberContacts', function ($stateParams, MemberContacts) {
                	return MemberContacts.getByRef({ref: $stateParams.ref}).$promise;
                }]
            }
        })
        .state('member-credentials', {
            parent: 'entity',
            url: '/member-credentials?ref={r}',
            data: {
            	authorities: []
            },
            params : {
                navtype: '1'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/member-contacts/member-credentials-dialog.html',
                    controller: 'MemberContactsDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {

                entity: ['$stateParams', 'MemberContacts', function ($stateParams, MemberContacts) {
                	return MemberContacts.getMemberCredential({ref: $stateParams.ref}).$promise;
                }]
            }
        })
        .state('member-credentials-linkedIn', {
            parent: 'entity',
            url: '/member-credentials/{ref}',
            data: {
            	authorities: []
            },
            params : {
                navtype: '1'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/member-contacts/member-credentials-dialog.html',
                    controller: 'MemberContactsDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {

                entity: ['$stateParams', 'MemberContacts', function ($stateParams, MemberContacts) {
                	return MemberContacts.getMemberCredential({ref: $stateParams.ref}).$promise;
                }]
            }
        })
      .state('member-privacy-setting', {
        parent: 'entity',
        url: '/member-privacy-setting?ref={r}',
        data: {
        	authorities: []
        },
        params : {
            navtype: '1'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/member-contacts/member-contacts-dialog.html',
                controller: 'MemberContactsDialogController',
                controllerAs: 'vm'
            }
        },
        resolve: {

            entity: ['$stateParams', 'MemberContacts', function ($stateParams, MemberContacts) {
            	return MemberContacts.getByRef({ref: $stateParams.ref}).$promise;
            }]
        }
    })

        ;
    }

})();
