(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('CustomerNotesDetailController', CustomerNotesDetailController);

    CustomerNotesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CustomerNotes', 'ProviderContacts'];

    function CustomerNotesDetailController($scope, $rootScope, $stateParams, previousState, entity, CustomerNotes, ProviderContacts) {
        var vm = this;
        vm.customerNotes = entity;
        vm.previousState = previousState.name;
    }
})();
