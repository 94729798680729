(function() {
	// 'use strict';

	angular.module('mobilityexchangeApp').controller('SupportController',
			SupportController);

	SupportController.$inject = [ '$timeout', '$scope', '$stateParams',
			'$uibModalInstance', 'entity', 'MXSupport'];

	function SupportController($timeout, $scope, $stateParams,
			$uibModalInstance, entity, MXSupport) {
		var vm = this;

		vm.support = entity;
		vm.clear = clear;
		vm.save = save;

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function save() {
			vm.isSaving = true;
			MXSupport.save(vm.support, onSaveSuccess, onSaveError);
		}

		function onSaveSuccess(result) {
			$scope.message = "Your request has been sent successfully";
			vm.isSaving = false;
		}

		function onSaveError() {
			vm.isSaving = false;
		}
	}
})();
