(function () {
    //'use strict';
    angular
        .module('mobilityexchangeApp')
        .factory('CustomerNotes', CustomerNotes);

    CustomerNotes.$inject = ['$resource', 'DateUtils'];

    function CustomerNotes($resource, DateUtils) {
        var resourceUrl = 'api/customer-notes/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.targetDate = DateUtils.convertLocalDateFromServer(data.targetDate);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                        data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                        // Add a displayNote property. To avoid issues with long notes limit, the number of
                        // characters displayed in the table. Edit a note shows the complete note.
                        var displayNoteLength = 100;
                        data.displayNote = (data.note && data.note.length < displayNoteLength) ? data.note :
                            (data.note.substring(0, displayNoteLength) + " ...");
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.targetDate = DateUtils.convertLocalDateToServer(copy.targetDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.targetDate = DateUtils.convertLocalDateToServer(copy.targetDate);
                    copy.createddate = DateUtils.convertLocalDateToServer(copy.createddate);
                    copy.modifieddate = DateUtils.convertLocalDateToServer(copy.modifieddate);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
