(function() {
    //'use strict';
    angular
        .module('mobilityexchangeApp')
        .factory('InvoiceHeader', InvoiceHeader);

    InvoiceHeader.$inject = ['$resource', 'DateUtils'];

    function InvoiceHeader ($resource, DateUtils) {
        var resourceUrl =  'api/invoice-headers/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                url: 'api/invoice-headers/:id?spn=:spn&invdate=:invdate&pdate=:pdate&invstat=:invstat&paymeth=:paymeth&invamt=:invamt&invnum=:invnum&payref=:payref&spname=:spname&invfromdate=:invfromdate&invtodate=:invtodate&pstat=:pstat&r=:r',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data, headers, status) {

                    if(status !== 200)
                        return status;

                    if (data || !_.isEmpty(data)) {
                        data = angular.fromJson(data);
                        data.status = status;
                        data.invoiceDate = DateUtils.convertLocalDateFromServer(data.invoiceDate);
                        data.fromDate = DateUtils.convertLocalDateFromServer(data.fromDate);
                        data.toDate = DateUtils.convertLocalDateFromServer(data.toDate);
                        data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'getByRef': {
                method: 'GET',
                url: 'api/invoice-headers/ref/:ref',
                transformResponse: function (data, headers, status) {

                    if(status !== 200)
                        return status;

                    if (data || !_.isEmpty(data)) {
                        data = angular.fromJson(data);
                        data.status = status;
                        data.invoiceDate = DateUtils.convertLocalDateFromServer(data.invoiceDate);
                        data.fromDate = DateUtils.convertLocalDateFromServer(data.fromDate);
                        data.toDate = DateUtils.convertLocalDateFromServer(data.toDate);
                        data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            }
            ,
            'getAll': {
                method: 'GET',
                url: 'api/invoice-headers/sp/:spId',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.invoiceDate = DateUtils.convertLocalDateFromServer(data.invoiceDate);
                        data.fromDate = DateUtils.convertLocalDateFromServer(data.fromDate);
                        data.toDate = DateUtils.convertLocalDateFromServer(data.toDate);
                        data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'getBilledQualifications': {
                method: 'GET',
                url: 'api/billed-qualifications/:spId',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.invoiceDate = DateUtils.convertLocalDateFromServer(data.invoiceDate);
                        data.fromDate = DateUtils.convertLocalDateFromServer(data.fromDate);
                        data.toDate = DateUtils.convertLocalDateFromServer(data.toDate);
                        data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'getBillingDiscounts': {
                method: 'GET',
                isArray: true,
                url: 'api/billing-line-discounts/:billingPlanId',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.invoiceDate = DateUtils.convertLocalDateFromServer(data.invoiceDate);
                        data.fromDate = DateUtils.convertLocalDateFromServer(data.fromDate);
                        data.toDate = DateUtils.convertLocalDateFromServer(data.toDate);
                        data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'getBalances': {
                method: 'GET',
                isArray: false,
                url: '/api/invoice-balance-lines/:spId',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.invoiceDate = DateUtils.convertLocalDateFromServer(data.invoiceDate);
                        data.fromDate = DateUtils.convertLocalDateFromServer(data.fromDate);
                        data.toDate = DateUtils.convertLocalDateFromServer(data.toDate);
                        data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.invoiceDate = DateUtils.convertLocalDateToServer(copy.invoiceDate);
                    copy.fromDate = DateUtils.convertLocalDateToServer(copy.fromDate);
                    copy.toDate = DateUtils.convertLocalDateToServer(copy.toDate);
                    copy.paymentDate = DateUtils.convertLocalDateToServer(copy.paymentDate);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.invoiceDate = DateUtils.convertLocalDateFromServer(data.invoiceDate);
                        data.fromDate = DateUtils.convertLocalDateFromServer(data.fromDate);
                        data.toDate = DateUtils.convertLocalDateFromServer(data.toDate);
                        data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'updateDonationLines': {
                method: 'PUT',
                url: 'api/invoice-headers/donations/sp/:spId/invoiceid/:invid/donation/:donation/billingPlan/:billingPlanId',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.invoiceDate = DateUtils.convertLocalDateToServer(copy.invoiceDate);
                    copy.fromDate = DateUtils.convertLocalDateToServer(copy.fromDate);
                    copy.toDate = DateUtils.convertLocalDateToServer(copy.toDate);
                    copy.paymentDate = DateUtils.convertLocalDateToServer(copy.paymentDate);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.invoiceDate = DateUtils.convertLocalDateFromServer(data.invoiceDate);
                        data.fromDate = DateUtils.convertLocalDateFromServer(data.fromDate);
                        data.toDate = DateUtils.convertLocalDateFromServer(data.toDate);
                        data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'updateDonationLinesMemoryHonor': {
                method: 'PUT',
                url: 'api/invoice-headers/donations/sp/:spId/invoiceid/:invid/billingPlan/:billingPlanId/donationForOption/:donationForOption/donationForName/:donationForName',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.invoiceDate = DateUtils.convertLocalDateToServer(copy.invoiceDate);
                    copy.fromDate = DateUtils.convertLocalDateToServer(copy.fromDate);
                    copy.toDate = DateUtils.convertLocalDateToServer(copy.toDate);
                    copy.paymentDate = DateUtils.convertLocalDateToServer(copy.paymentDate);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.invoiceDate = DateUtils.convertLocalDateFromServer(data.invoiceDate);
                        data.fromDate = DateUtils.convertLocalDateFromServer(data.fromDate);
                        data.toDate = DateUtils.convertLocalDateFromServer(data.toDate);
                        data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.invoiceDate = DateUtils.convertLocalDateToServer(copy.invoiceDate);
                    copy.fromDate = DateUtils.convertLocalDateToServer(copy.fromDate);
                    copy.toDate = DateUtils.convertLocalDateToServer(copy.toDate);
                    copy.paymentDate = DateUtils.convertLocalDateToServer(copy.paymentDate);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.invoiceDate = DateUtils.convertLocalDateFromServer(data.invoiceDate);
                        data.fromDate = DateUtils.convertLocalDateFromServer(data.fromDate);
                        data.toDate = DateUtils.convertLocalDateFromServer(data.toDate);
                        data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            }
        });
    }
})();
