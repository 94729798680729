(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('payment-processing', {
            parent: 'entity',
            url: '/payment-processing?page&sort&search&paymentAmount&paymentDate&company&spid&id&balanceAmount&navtype',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'mobilityexchangeApp.paymentProcessing.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/payment-processing/payment-processing.html',
                    controller: 'PaymentProcessingController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc'
                },
                search: null,
                paymentAmount:null,
                paymentDate:null,
                company:null,
                balanceAmount:null,
                spid:null,
                id:null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('PaymentProcessing');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

            .state('payment-processing-lookup', {
                parent: 'entity',
                url: '/payment-processing?spId&page&search&spn',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'mobilityexchangeApp.paymentProcessing.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/payment-processing/payment-processing.html',
                        controller: 'PaymentProcessingController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('PaymentProcessing');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

        .state('payment-processing-detail', {
            parent: 'payment-processing',
            url: '/payment-processing/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'mobilityexchangeApp.PaymentProcessing.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/payment-processing/payment-processing-detail.html',
                    controller: 'PaymentProcessingDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('PaymentProcessing');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PaymentProcessing', function($stateParams, PaymentProcessing) {
                    return PaymentProcessing.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'payment-processing',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })


        .state('payment-processing-detail.edit', {
            parent: 'payment-processing-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
            'content@': {
                templateUrl: 'app/entities/payment-processing/payment-processing-dialog.html',
                controller: 'PaymentProcessingDialogController',
                controllerAs: 'vm',
            }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('PaymentProcessing');
                    return $translate.refresh();
                }],
                entity: ['PaymentProcessing', function(PaymentProcessing) {
                    return PaymentProcessing.get({id : $stateParams.id}).$promise;
                }],
                    previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name ,
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('payment-processing.new', {
        	 parent: 'payment-processing',
             url: '/new/{sp}/',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/payment-processing/payment-processing-dialog.html',
                    controller: 'PaymentProcessingDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                entity: function () {
                    return {
                    	balanceAmount: null,
                        paymentMethod: null,
                        paymentDate: null,
                        paymentAmount: null,
                        comments: null,
                        paymentRef: null,
                        payor: null,
                        createdDate: null,
                        createdBy: null,
                        modifiedDate: null,
                        modifiedBy: null,
                        serviceProviders: null,
                        id: null
                    };
                }
            },
            previousState: ["$state", function ($state) {
                var currentStateData = {
                    isCopy: 'false',
                    params: $state.params,
                    url: $state.href($state.current.name, $state.params)
                };
                return currentStateData;
            }]
        })
        .state('payment-processing.edit', {
                parent: 'payment-processing',
                url: '/{id}/edit?navtype={navtype}',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                params : {
                    navtype: '1'
                },
                views: {
                    'content@': {
                    	templateUrl: 'app/entities/payment-processing/payment-processing-dialog.html',
                        controller: 'PaymentProcessingDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                    entity: ['$stateParams', 'PaymentProcessing', function ($stateParams, PaymentProcessing) {
                        return PaymentProcessing.get({id: $stateParams.id}).$promise;
                    }],

                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            isCopy: 'false',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }

            })
            
        .state('payment-processing.process', {
        	 parent: 'payment-processing',
        	 url: '/{id}/process',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/payment-processing/payment-processing-process.html',
                    controller: 'PaymentProcessingProcessController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                entity: ['$stateParams', 'PaymentProcessing', function ($stateParams, PaymentProcessing) {
                        return PaymentProcessing.process({id: $stateParams.id}).$promise;
                }]
            }
        })
        
        .state('payment-processing.allocate', {
        	 parent: 'payment-processing',
        	 url: '/{id}/{invNum}/allocate',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/payment-processing/payment-processing-allocate.html',
                    controller: 'PaymentProcessingAllocateController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                entity: ['$stateParams', 'PaymentProcessing', function ($stateParams, PaymentProcessing) {
                        return PaymentProcessing.allocate({id: $stateParams.id, invNum: $stateParams.invNum}).$promise;
                }]
            }
        })
   
        .state('payment-processing.delete', {
            parent: 'payment-processing',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/payment-processing/payment-processing-delete-dialog.html',
                    controller: 'PaymentProcessingDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PaymentProcessing', function(PaymentProcessing) {
                            return PaymentProcessing.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('payment-processing', null, { reload: 'payment-processing' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
