(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('MemberContactsSearch', MemberContactsSearch);

    MemberContactsSearch.$inject = ['$resource'];

    function MemberContactsSearch($resource) {
        var resourceUrl = 'api/_search/member-contacts/:id?&mid=:mid&firstname=:firstname&lastname=:lastname&company=:company&title=:title&city=:city&state=:state&country=:country&mxstatus=:mxstatus';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }
})();