(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('QuotationRequestDialogController', QuotationRequestDialogController);

    QuotationRequestDialogController.$inject = ['$timeout', '$scope', '$stateParams',  'entity', 'QuotationRequest', 'ServiceProviders', 'Lookups'];

    function QuotationRequestDialogController ($timeout, $scope, $stateParams, entity, QuotationRequest, ServiceProviders, Lookups) {
        var vm = this;

        vm.quotationRequest = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.serviceproviders = ServiceProviders.query();

        vm.modes = Lookups.getByLookupType({id: 60});
        vm.serviceTypes = Lookups.getByLookupType({id: 56});
        vm.containerTypes = Lookups.getByLookupType({id: 57});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            //$uibModalInstance.dismiss('cancel');
        }

        vm.asyncOrigSelected = {};
        vm.asyncDestSelected = {};
        var params = {};
        vm.quotationRequest.quotationRequestDtls = [];

        vm.quoteOptionIdx = 0;
        vm.quoteOptionsSize = vm.quotationRequest.quotationRequestDtls.length;

        $scope.addQuoteOption = function (event) {
            event.preventDefault();
            event.stopPropagation();
            vm.quoteOptionsSize = vm.quotationRequest.quotationRequestDtls.length;
            vm.quotationRequest.quotationRequestDtls[vm.quoteOptionsSize] = {};

        };

        function save () {

            if(vm.asyncOrigSelected && vm.asyncOrigSelected.geometry){
                vm.quotationRequest.origin = vm.asyncOrigSelected.formatted_address;
                var lat_lng = vm.asyncOrigSelected.geometry.location;
                params.lat = lat_lng.lat();
                params.lng = lat_lng.lng();
                vm.quotationRequest.originGeocode = params.lat+","+params.lng;
            }

            if(vm.asyncDestSelected && vm.asyncDestSelected.geometry){
                vm.quotationRequest.destination = vm.asyncDestSelected.formatted_address;
                var lat_lng = vm.asyncDestSelected.geometry.location;
                params.lat = lat_lng.lat();
                params.lng = lat_lng.lng();
                vm.quotationRequest.destinationGeocode = params.lat+","+params.lng;
            }


            vm.isSaving = true;
            if (vm.quotationRequest.id !== null) {
                QuotationRequest.update(vm.quotationRequest, onSaveSuccess, onSaveError);
            } else {
                QuotationRequest.save(vm.quotationRequest, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('mobilityexchangeApp:quotationRequestUpdate', result);
            //$uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.serviceDate = false;
        vm.datePickerOpenStatus.createdOn = false;
        vm.datePickerOpenStatus.modifiedOn = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
