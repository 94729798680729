(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('MemberCapabilitiesDetailController', MemberCapabilitiesDetailController);

    MemberCapabilitiesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MemberCapabilities', 'Capabilities', 'Lookups'];

    function MemberCapabilitiesDetailController($scope, $rootScope, $stateParams, previousState, entity, MemberCapabilities, Capabilities, Lookups) {
        var vm = this;

        vm.memberCapabilities = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:memberCapabilitiesUpdate', function (event, result) {
            vm.memberCapabilities = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
