(function() {
	//'use strict';
	angular.module('mobilityexchangeApp').factory('PaymentProcessing',
			PaymentProcessing);

	PaymentProcessing.$inject = [ '$resource', 'DateUtils' ];

	function PaymentProcessing($resource, DateUtils) {
		var resourceUrl = 'api/payment-processing/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
						data.paymentDate = DateUtils
								.convertLocalDateFromServer(data.paymentDate);
						// Let created date and modified date flow through, they are in the ISO8601 date/time format.
						// It also seems that there's a disconnect in some places regarding property names
						// (createddate/modifieddate vs. createdDate/modifiedDate in payment-processing.state.js).
						// Server-side returns modifieddate and createddate.
					}
					return data;
				}
			},
			'update' : {
				method : 'PUT',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					copy.paymentDate = DateUtils
							.convertLocalDateToServer(copy.paymentDate);
					// Don't set createddate and modifieddate. Let the server side control those.
					return angular.toJson(copy);
				},
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
						data.paymentDate = DateUtils
								.convertLocalDateFromServer(data.paymentDate);
						// Let created date and modified date flow through, they are in the ISO8601 date/time format.
						// It also seems that there's a disconnect in some places regarding property names
						// (createddate/modifieddate vs. createdDate/modifiedDate in payment-processing.state.js).
						// Server-side returns modifieddate and createddate.
					}
					return data;
				}
			},
			'save' : {
				method : 'POST',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					copy.paymentDate = DateUtils
							.convertLocalDateToServer(copy.paymentDate);
					// Don't set createddate and modifieddate. Let the server side control those.
					return angular.toJson(copy);
				},
				transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
						// Let created date and modified date flow through, they are in the ISO8601 date/time format.
						// It also seems that there's a disconnect in some places regarding property names
						// (createddate/modifieddate vs. createdDate/modifiedDate in payment-processing.state.js).
						// Server-side returns modifieddate and createddate.
                    }
                    return data;
                }
			},
			'finalizeAllocation' : {
				method : 'PUT',
				url: 'api/payment-processing/finalizeAllocation',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					copy.paymentDate = DateUtils
							.convertLocalDateToServer(copy.paymentDate);
					copy.createddate = DateUtils
							.convertLocalDateToServer(copy.createddate);

					return angular.toJson(copy);
				},
				transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
                        data.createddate = DateUtils.convertDateTimeFromServer(data.createddate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
			},
			'process': {
                method: 'GET',
                url: 'api/payment-processing/process/:id',
                isArray: true,
                transformResponse: function (data, headers, status) {

                    if(status !== 200)
                        return status;

                    if (data || !_.isEmpty(data)) {
                        data = angular.fromJson(data);
                        data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
                        data.createddate = DateUtils.convertDateTimeFromServer(data.createddate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'allocate': {
                method: 'GET',
                url: 'api/payment-processing/allocate/:id/:invNum',
                transformResponse: function (data, headers, status) {

                    if(status !== 200)
                        return status;

                    if (data || !_.isEmpty(data)) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertDateTimeFromServer(data.createddate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            }
		});
	}
})();
