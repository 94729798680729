(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('ClaimHeaderSearch', ClaimHeaderSearch);

    ClaimHeaderSearch.$inject = ['$resource'];

    function ClaimHeaderSearch($resource) {
        var resourceUrl =  'api/_search/claim-headers/:id?&claimId=:claimId&filedBy=:filedBy&bIamId=:bIamId&aIamId=:aIamId&filedAgainist=:filedAgainist&filedAgainstMxStatus=:filedAgainstMxStatus&fileDate=:fileDate&modifiedDate=:modifiedDate&totalClaim=:totalClaim&status=:status';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false }
        });
    }
})();
