(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('MemberCapabilitiesSearch', MemberCapabilitiesSearch);

    MemberCapabilitiesSearch.$inject = ['$resource'];

    function MemberCapabilitiesSearch($resource) {
        var resourceUrl = 'api/_search/member-capabilities/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }
})();
