(function () {
	angular.module('mobilityexchangeApp').controller('AdvCompaignController',
		AdvCompaignController).controller('AdvBillingController',
			AdvBillingController).controller('AdvBillingSetupController',
				AdvBillingSetupController).controller(
					'AdvCompaignActivityController', AdvCompaignActivityController)
		.controller('AdvCompaignActivityDetailController',
			AdvCompaignActivityDetailController);

	AdvCompaignController.$inject = ['AdHeader', 'pagingParams', '$http',
		'$scope', 'Lookups', 'AlertService', 'AdHeaderSearch',
		'paginationConstants', '$stateParams', 'ParseLinks', '$state',
		'Principal'];

	AdvCompaignActivityDetailController.$inject = ['entity'];

	AdvCompaignActivityController.$inject = ['AdInvoiceHeader',
		'pagingParams', '$http', '$scope', 'Lookups', 'AlertService',
		'AdInvoiceHeaderSearch', 'paginationConstants', '$stateParams',
		'ParseLinks', '$state'];

	AdvBillingController.$inject = ['$state', 'AdHeader', 'AdHeaderSearch',
		'ParseLinks', 'AlertService', 'paginationConstants',
		'pagingParams', '$stateParams', '$http', '$scope', 'DateUtils',
		'$q', '$cookies'];

	AdvBillingSetupController.$inject = ['$state', 'AdHeader',
		'AdHeaderSearch', 'ParseLinks', 'AlertService',
		'paginationConstants', 'pagingParams', '$stateParams', '$http',
		'$scope', 'DateUtils', '$q', '$cookies', 'entity'];

	function AdvCompaignActivityDetailController(entity) {

		var vm = this;
		vm.logs = entity;

	}
	;

	function AdvBillingSetupController($state, AdHeader, AdHeaderSearch,
		ParseLinks, AlertService, paginationConstants, pagingParams,
		$stateParams, $http, $scope, DateUtils, $q, $cookies, entity) {

		var vm = this;

		vm.serviceProvidersSearchResult = entity;

		if (vm.serviceProvidersSearchResult.length == 1) {
			var x = vm.serviceProvidersSearchResult[0];
			if (x.stripeCustomerId) {
				vm.serviceProviders = vm.serviceProvidersSearchResult[0];
			}
		}

		$scope.showMessage = false;
		vm.stripe = {};
		vm.stripe.processing = false;
		vm.submitAdError = false;
		$scope.amount = 50.00;
		$scope.progressValue = '100%';
		$scope.progressText = ' Processing Credit Card Payment completed.';

		$scope.handleStripe = function () {

			$scope.showMessage = true;
			$scope.progressValue = '80%';
			$scope.progressText = ' Processing Credit Card Payment...';
			vm.stripe.processing = true;

			processPayment();
		};

		vm.setServiceProvider = function (serviceprovider) {
			console.log("I am here + " + serviceprovider.id);
			vm.serviceProviders = serviceprovider;
		};

		function processPayment() {
			validateStripeForm();

			if (vm.stripe.processing) {

				var $payInfo = {
					'token': '',
					'name': vm.stripe.name,
					'phone': vm.stripe.phone,
					'email': vm.stripe.email,
					'number': vm.stripe.number,
					'expiry': vm.stripe.expiry,
					'cvc': vm.stripe.cvc,
					'amount': $scope.amount,
					'invId': 1,
					'spId': vm.serviceProviders.id
				};

				$http
					.post(
						'/api/advsubscription?spid='
						+ vm.serviceProviders.id, $payInfo)
					.success(
						function (data) {
							vm.stripe.processing = true;

							if (data.status == "OK") {
								vm.stripe.paid = true;
								vm.stripe.status = "OK";
								vm.stripe.responsemessage = data.message;
								vm.stripe.number = '';
								vm.stripe.expiry = '';
								vm.stripe.cvc = '';
								// $scope.showMessage = false;
								vm.stripe.processing = false;
							} else {
								vm.stripe.paid = false;
								vm.stripe.status = "NOK";
								vm.stripe.message = data.message;
								vm.stripe.number = '';
								vm.stripe.expiry = '';
								vm.stripe.cvc = '';
								// $scope.showMessage = false;
								vm.stripe.processing = false;
							}
							$scope.progressValue = '100%';
							$scope.progressText = ' Processing Credit Card Payment completed.';
						})
					.error(
						function (data) {
							vm.stripe.paid = false;
							vm.stripe.status = "NOK";
							vm.stripe.message = data.message;
							vm.stripe.number = '';
							vm.stripe.expiry = '';
							vm.stripe.cvc = '';
							// $scope.showMessage = false;
							vm.stripe.processing = false;
							$scope.progressValue = '100%';
							$scope.progressText = ' Processing Credit Card Payment completed.';
						});
			}
		}

		function validateStripeForm() {
			vm.stripe.processing = true;

			if (!vm.stripe.name) {
				vm.stripe.processing = false;
				vm.stripeNameError = true;
			} else {
				vm.stripeNameError = false;
			}

			if (!vm.stripe.phone) {
				vm.stripe.processing = false;
				vm.stripePhoneError = true;
			} else {
				vm.stripePhoneError = false;
			}

			if (!vm.stripe.email) {
				vm.stripe.processing = false;
				vm.stripeEmailError = true;
			} else {
				vm.stripeEmailError = false;
			}

			if (!vm.stripe.number) {
				vm.stripe.processing = false;
				vm.stripeNumberError = true;
			} else {
				vm.stripeNumberError = false;
			}

			if (!vm.stripe.expiry) {
				vm.stripe.processing = false;
				vm.stripeExpiryError = true;
			} else {
				vm.stripeExpiryError = false;
			}

			if (!vm.stripe.cvc) {
				vm.stripe.processing = false;
				vm.stripeCvcError = true;
			} else {
				vm.stripeCvcError = false;
			}

		}

	}

	function AdvBillingController($state, AdHeader, AdHeaderSearch, ParseLinks,
		AlertService, paginationConstants, pagingParams, $stateParams,
		$http, $scope, DateUtils, $q, $cookies) {

		var vm = this;

		vm.batchPeriod = null;
		vm.invoiceDate = null;
		vm.datePickerOpenStatus = [];
		vm.datePickerOpenStatus.invoiceDate = false;
		vm.datePickerOpenStatus.invoiceAdvDate = false;
		vm.datePickerOpenStatus.invoiceFromDate = false;
		vm.datePickerOpenStatus.invoiceToDate = false;
		$scope.displayDateFormat = DateUtils.displayDateFormat;
		vm.openCalendar = openCalendar;

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}

		vm.showProgress = false;
		vm.showAdProgress = false;
		vm.invoiceHeaders = null;

		vm.searchInvoices = function () {
			vm.invoiceHeaders = InvoiceHeader.get();
		};

		vm.batchAlreadyRunning = false;
		vm.batchRunning = false;
		vm.advRunningCompleted = false;
		vm.emailInvoiceDate;

		vm.isBatchRunning = function () {
			$http
				.get('/api/isadvbatchrunning')
				.then(
					function (res) {
						vm.batchAlreadyRunning = false;
					},
					function (res) {
						if (res
							&& res.data
							&& (res.data.batch_status === 'BATCH_ALREADY_RUNNING')) {
							vm.batchAlreadyRunning = true;
						}
					});
		};

		vm.isBatchRunning();

		vm.validateForm = function () {

			if (!vm.logStartDate) {
				vm.batchRunning = false;
				vm.logStartDateError = true;
			} else {
				vm.logStartDateError = false;
			}

			if (!vm.logEndDate) {
				vm.batchRunning = false;
				vm.logEndDateError = true;
			} else {
				vm.logEndDateError = false;
			}

			if (!vm.invoiceDate) {
				vm.batchRunning = false;
				vm.invoiceDateError = true;
			} else {
				vm.invoiceDateError = false;
			}
		}

		vm.executeStripeCharge = function () {
			if (!vm.stripeInvoiceDate) {
				vm.stripeInvoiceDateError = true;
			} else {
				var invDate = DateUtils
					.convertLocalDateToServer(vm.stripeInvoiceDate);
				var url = '/api/adv-stripe-charge/' + invDate;

				processRequest(url).then(function (data) {
					vm.showProgress = false;
					vm.advRunningCompleted = false;
					vm.stripChargeResult = data;
				});
			}
		}

		vm.identifyLowBalanceCustomers = function () {
			var url = '/api/identify-low-balance/';

			processRequest(url).then(function (data) {
				vm.lowBalanceCustomers = data;
			});

		}

		vm.emailReceipts = function (e) {
			if (!vm.emailInvoiceDate) {
				vm.emailInvoiceDateError = true;
				return;
			} else {
				vm.emailInvoiceDateError = false;
			}
			vm.emailReceiptsMessage = " Email send is in progress...."
			e.stopPropagation();
			e.preventDefault();
			vm.emailsent = true;
			var invDate = DateUtils
				.convertLocalDateToServer(vm.emailInvoiceDate);

			// $http.post('/api/email-invoices/'+invDate);

			$http({
				method: 'POST',
				url: '/api/email-adv-invoices',
				headers: {
					'X-CSRF-TOKEN': $cookies.get('CSRF-TOKEN'),
					'cookie': $cookies.cookiename
				},
				data: {
					invDate: invDate
				}
			}).then(function (response) {
				console.log(response.data);
				if (response.data.status === 'SUCCESS') {
					vm.emailReceiptsMessage = " Email send is successfully completed."
				} else {
					vm.emailReceiptsMessage = " Email send is completed with error. Please see the log."
				}

			});

		};

		function processRequest(url) {
			var defer = $q.defer();
			$http.get(url).then(function (result) {
				defer.resolve(result.data);
			}, function () {
				defer.reject();
			});
			return defer.promise;
		}

		vm.executeBatchBilling = function () {

			vm.batchRunning = true;

			vm.validateForm();
			if (vm.batchRunning) {
				var invDate = DateUtils
					.convertLocalDateToServer(vm.invoiceDate);
				var logStartDate = DateUtils
					.convertLocalDateToServer(vm.logStartDate);
				var logEndDate = DateUtils
					.convertLocalDateToServer(vm.logEndDate);
				vm.showProgress = true;
				var promises = [];

				$http({
					method: 'POST',
					url: '/api/adv-batch-billing',
					headers: {
						'X-CSRF-TOKEN': $cookies.get('CSRF-TOKEN'),
						'cookie': $cookies.cookiename
					},
					data: {
						logStartDate: logStartDate,
						logEndDate: logEndDate,
						invoiceDate: invDate
					}
				}).then(function (response) {
					vm.showProgress = false;
					vm.batchRunning = false;
					vm.advRunningCompleted = true;
				});
			}
		};

		vm.billableCount = null;

		vm.getBillableCount = function () {
			$http.get('/api/billable-count').then(function (response) {
				vm.billableCount = response.data;
			});
		}
	}
	;
	function AdvCompaignActivityController(AdInvoiceHeader, pagingParams,
		$http, $scope, Lookups, AlertService, AdInvoiceHeaderSearch,
		paginationConstants, $stateParams, ParseLinks, $state) {

		var vm = this;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.loadAll = loadAll;

		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.clear = clear;
		vm.transitionOnKeyUp = transitionOnKeyUp;
		vm.search = search;
		vm.searchQuery = pagingParams.search;
		vm.currentSearch = pagingParams.search;

		loadAll();

		vm.downloadReport = function (obj) {
			$http.get('api/adv-activity-csv/' + obj.id, {
				responseType: 'arraybuffer'
			}).then(function successCallback(data) {
				var file = new Blob([data.data], {
					type: 'text/csv'
				});
				var fileURL = URL.createObjectURL(file);
				// window.open(fileURL, "test.csv");
				var a = document.createElement("a");
				document.body.appendChild(a);
				a.style = "display: none";
				url = window.URL.createObjectURL(file);
				a.href = url;
				a.download = "IAMX_Advertisement_Activity_Report_Detail.csv";
				a.click();
				window.URL.revokeObjectURL(url);

			}, function errorCallback(response) {
			});
		};

		function loadAll() {
			if (pagingParams.search) {
				AdInvoiceHeaderSearch.query({
					query: pagingParams.search,
					page: pagingParams.page - 1,
					size: vm.itemsPerPage,
					sort: sort()
				}, onSuccess, onError);
			} else {
				AdInvoiceHeader.query({
					page: pagingParams.page - 1,
					size: vm.itemsPerPage,
					sort: sort()

				}, onSuccess, onError);
			}

			function sort() {

				var result = [vm.predicate + ','
					+ (vm.reverse ? 'asc' : 'desc')];

				if (vm.predicate !== 'id') {
					result.push('id');
				}
				return result;
			}

		}

		function onSuccess(data, headers) {
			vm.links = ParseLinks.parse(headers('link'));
			vm.totalItems = headers('X-Total-Count');
			vm.queryCount = vm.totalItems;
			vm.activities = data;
			vm.page = pagingParams.page;

		}

		vm.previewReceipt = function (event, invoiceId) {

			event.preventDefault();
			event.stopPropagation();

			if (_.isEmpty(vm.taxId))
				vm.taxId = " ";

			$http.get('api/generate-adv-receipt/' + invoiceId, {
				responseType: 'arraybuffer'
			}).then(function successCallback(data) {

				var file = new Blob([data.data], {
					type: 'application/pdf'
				});
				var fileURL = URL.createObjectURL(file);
				window.open(fileURL);

			}, function errorCallback(response) {
			});

		};

		function onError(error) {
			AlertService.error(error.data.message);
		}

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page: vm.page,
				sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search: vm.currentSearch,
			});
		}

		function search(searchQuery) {
			searchQuery = '-';
			if (!searchQuery) {
				return vm.clear();
			}
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			vm.currentSearch = searchQuery;
			vm.transition();
		}

		function clear() {
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = true;
			vm.currentSearch = null;
			vm.transition();
		}

		$scope.goToOnKeyUp = function (e, searchQuery) {
			if (event.keyCode === 13) {
				vm.transitionOnKeyUp(searchQuery);
			}
		}

		function transitionOnKeyUp(searchQuery) {
			searchQuery = '-';
			if (!searchQuery) {
				return vm.clear();
			}
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			vm.currentSearch = searchQuery;
			$state.transitionTo($state.$current, {
				page: vm.page,
				sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search: vm.currentSearch
			});
		}

	}
	;

	function AdvCompaignController(AdHeader, pagingParams, $http, $scope,
		Lookups, AlertService, AdHeaderSearch, paginationConstants,
		$stateParams, ParseLinks, $state, Principal) {

		var vm = this;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.loadAll = loadAll;

		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.clear = clear;
		vm.transitionOnKeyUp = transitionOnKeyUp;
		vm.search = search;
		vm.activeOnly = activeOnly;
		vm.searchQuery = pagingParams.search;
		vm.currentSearch = pagingParams.search;

		vm.aid = $stateParams.aid;
		vm.spid = $stateParams.spid;
		vm.spName = $stateParams.spName;
		vm.campaignDisplay = $stateParams.campaignDisplay;
		vm.campaignName = $stateParams.campaignName;
		vm.pauseAdFrom = $stateParams.pauseAdFrom;
		vm.displayDirectory = $stateParams.displayDirectory;
		vm.pauseAdTo = $stateParams.pauseAdTo;
		vm.monthlyMaximum = $stateParams.monthlyMaximum;
		vm.monthlyBalance = $stateParams.monthlyBalance;
		vm.bidPrice = $stateParams.bidPrice;
		vm.submissionDate = $stateParams.submissionDate;
		vm.activationDate = $stateParams.activationDate;
		vm.active = $stateParams.active;;

		$scope.isAdminUser = false;
		Principal.identity().then(function (account) {
			$scope.account = account;
			$scope.isAuthenticated = Principal.isAuthenticated;
			for (var i = 0; i < $scope.account.authorities.length; i++) {
				if ($scope.account.authorities[i] === 'AD_ADMIN') {
					$scope.isAdminUser = true;
					break;
				}
			}
		});

		loadAll();

		function loadAll() {
			if (pagingParams.search) {
				AdHeaderSearch.query({
					query: pagingParams.search,
					page: pagingParams.page - 1,
					size: vm.itemsPerPage,
					sort: sort(),
					aid: vm.aid ? vm.aid : $stateParams.aid,
					spid: vm.spid ? vm.spid : $stateParams.spid,
					spName: vm.spName ? vm.spName : $stateParams.spName,
					campaignDisplay: vm.campaignDisplay ? vm.campaignDisplay
						: $stateParams.campaignDisplay,
					campaignName: vm.campaignName ? vm.campaignName
						: $stateParams.campaignName,
					pauseAdFrom: vm.pauseAdFrom ? vm.pauseAdFrom
						: $stateParams.pauseAdFrom,
					pauseAdTo: vm.pauseAdTo ? vm.pauseAdTo
						: $stateParams.pauseAdTo,
					displayDirectory: vm.displayDirectory ? vm.displayDirectory
					    : $stateParams.displayDirectory,
					monthlyMaximum: vm.monthlyMaximum ? vm.monthlyMaximum
						: $stateParams.monthlyMaximum,
					monthlyBalance: vm.monthlyBalance ? vm.monthlyBalance
						: $stateParams.monthlyBalance,
					bidPrice: vm.bidPrice ? vm.bidPrice
						: $stateParams.bidPrice,
					submissionDate: vm.submissionDate ? vm.submissionDate
						: $stateParams.submissionDate,
					activationDate: vm.activationDate ? vm.activationDate
						: $stateParams.activationDate,
					active: $stateParams.active,
				}, onSuccess, onError);
			} else {
				AdHeader.query({
					page: pagingParams.page - 1,
					size: vm.itemsPerPage,
					sort: sort()

				}, onSuccess, onError);
			}

			function sort() {

				var result = [vm.predicate + ','
					+ (vm.reverse ? 'asc' : 'desc')];

				if (vm.predicate !== 'id') {
					result.push('id');
				}
				return result;
			}

		}

		function onSuccess(data, headers) {
			vm.links = ParseLinks.parse(headers('link'));
			vm.totalItems = headers('X-Total-Count');
			vm.queryCount = vm.totalItems;
			vm.ads = data;
			vm.page = pagingParams.page;

		}

		function onError(error) {
			AlertService.error(error.data.message);
		}

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			//console.log("656: " + vm.active);
			$state.transitionTo($state.$current, {
				page: vm.page,
				sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search: vm.currentSearch,
				aid: vm.aid ? vm.aid : $stateParams.aid,
				spid: vm.spid ? vm.spid : $stateParams.spid,
				spName: vm.spName ? vm.spName : $stateParams.spName,
				campaignDisplay: vm.campaignDisplay ? vm.campaignDisplay
					: $stateParams.campaignDisplay,
				campaignName: vm.campaignName ? vm.campaignName
					: $stateParams.campaignName,
				pauseAdFrom: vm.pauseAdFrom ? vm.pauseAdFrom
					: $stateParams.pauseAdFrom,
				pauseAdTo: vm.pauseAdTo ? vm.pauseAdTo
					: $stateParams.pauseAdTo,
				displayDirectory: vm.displayDirectory ? vm.displayDirectory
					    : $stateParams.displayDirectory,
				monthlyMaximum: vm.monthlyMaximum ? vm.monthlyMaximum
					: $stateParams.monthlyMaximum,
				monthlyBalance: vm.monthlyBalance ? vm.monthlyBalance
					: $stateParams.monthlyBalance,
				bidPrice: vm.bidPrice ? vm.bidPrice : $stateParams.bidPrice,
				submissionDate: vm.submissionDate ? vm.submissionDate
					: $stateParams.submissionDate,
				activationDate: vm.activationDate ? vm.activationDate
					: $stateParams.activationDate,
				active: vm.active
			});
		}

		function search(searchQuery) {
			//console.log("685: " + vm.active);
			searchQuery = '-';
			if (!searchQuery) {
				return vm.clear();
			}
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			vm.currentSearch = searchQuery;
			vm.transition();
		}

		function activeOnly() {
			//console.log("699. " + active);
			vm.search("-");
		}
		function clear() {
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = true;
			vm.currentSearch = null;
			vm.transition();
		}

		$scope.goToOnKeyUp = function (e, searchQuery) {
			if (event.keyCode === 13) {
				vm.transitionOnKeyUp(searchQuery);
			}
		}

		function transitionOnKeyUp(searchQuery) {
			searchQuery = '-';
			if (!searchQuery) {
				return vm.clear();
			}
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			vm.currentSearch = searchQuery;
			$state.transitionTo($state.$current, {
				page: vm.page,
				sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search: vm.currentSearch,
				aid: vm.aid ? vm.aid : $stateParams.aid,
				spid: vm.spid ? vm.spid : $stateParams.spid,
				spName: vm.spName ? vm.spName : $stateParams.spName,
				campaignDisplay: vm.campaignDisplay ? vm.campaignDisplay
					: $stateParams.campaignDisplay,
				campaignName: vm.campaignName ? vm.campaignName
					: $stateParams.campaignName,
				pauseAdFrom: vm.pauseAdFrom ? vm.pauseAdFrom
					: $stateParams.pauseAdFrom,
				pauseAdTo: vm.pauseAdTo ? vm.pauseAdTo
					: $stateParams.pauseAdTo,
				displayDirectory: vm.displayDirectory ? vm.displayDirectory
					    : $stateParams.displayDirectory,
				monthlyMaximum: vm.monthlyMaximum ? vm.monthlyMaximum
					: $stateParams.monthlyMaximum,
				monthlyBalance: vm.monthlyBalance ? vm.monthlyBalance
					: $stateParams.monthlyBalance,
				bidPrice: vm.bidPrice ? vm.bidPrice : $stateParams.bidPrice,
				submissionDate: vm.submissionDate ? vm.submissionDate
					: $stateParams.submissionDate,
				activationDate: vm.activationDate ? vm.activationDate
					: $stateParams.activationDate,
				active: vm.active,
			});
		}

	}
})();
