(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('IndustryPartnerCapabilitiesSearch', IndustryPartnerCapabilitiesSearch);

    IndustryPartnerCapabilitiesSearch.$inject = ['$resource'];

    function IndustryPartnerCapabilitiesSearch($resource) {
        var resourceUrl = 'api/_search/industry-supplier-capabilities/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }
})();
