(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('ProviderContactsDeleteController', ProviderContactsDeleteController);

    ProviderContactsDeleteController.$inject = ['$uibModalInstance', 'entity', 'ProviderContacts'];

    function ProviderContactsDeleteController($uibModalInstance, entity, ProviderContacts) {
        var vm = this;

        vm.providerContacts = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            ProviderContacts.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
