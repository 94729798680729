(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('AdInvoiceHeaderSearch', AdInvoiceHeaderSearch);

    AdInvoiceHeaderSearch.$inject = ['$resource'];

    function AdInvoiceHeaderSearch($resource) {
        var resourceUrl =  'api/_search/adv-control-activity/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false }
        });
    }
})();
