(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('AddressesDialogController', AddressesDialogController);

    AddressesDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'Addresses', 'ServiceProviders', 'Lookups'];

    function AddressesDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Addresses, ServiceProviders, Lookups) {
        var vm = this;


        vm.addressTypes = Lookups.getByLookupType({id: 16});
        vm.memberCapabilities = Lookups.getByLookupType({id: 2});
        vm.movingCapabilities = Lookups.getByLookupType({id: 5});
        vm.relocationCapabilities = Lookups.getByLookupType({id: 6});
        // TODO: Remove
        vm.supplierCapabilities = Lookups.getByLookupType({id: 7});
        vm.freightServicesCapabilities = Lookups.getByLookupType({id: 8});

        vm.industryPartnerCapabilities = Lookups.getByLookupType({id: 7});
        vm.industrySupplierCapabilities = Lookups.getByLookupType({id: 8});

        vm.associations = Lookups.getByLookupType({id: 9});
        vm.quality = Lookups.getByLookupType({id: 11});
        vm.usGovt = Lookups.getByLookupType({id: 19});
        vm.mxStatus = Lookups.getByLookupType({id: 1});
        vm.countryCodes = Lookups.getByLookupType({id: 50});
        vm.states = Lookups.getByLookupType({id: 51});


        vm.addresses = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.serviceproviders = ServiceProviders.query();
        vm.lookups = Lookups.query();
        vm.spid = $stateParams.spid;

        ////console.log('SP ID '+$stateParams.spid);

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.addresses.id !== null) {
                Addresses.update(vm.addresses, onSaveSuccess, onSaveError);
            } else {
                Addresses.save(vm.addresses, vm.spid, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('mobilityexchangeApp:addressesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createddate = false;
        vm.datePickerOpenStatus.modifieddate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
