(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('SearchService', SearchService);


    SearchService.$inject = ['$q', '$rootScope'];

    function SearchService($rootScope) {

        /*var service = {
         setValue: setValue,
         getValue: getValue
         };

         return service;

         var v;*/

        /*function setValue(value){
         var markers = [];
         v = value;
         _.forEach(v, function(results, key) {
         var latlng = _.split(results.serviceproviderAddresses[0].geocode,',');
         var latitude = latlng[0];
         var longitude = latlng[1];
         var ret = {
         latitude: latitude,
         longitude: longitude,
         title: 'm' + key
         };
         ret[key] = key;
         markers.push(ret);
         });
         $rootScope.randomMarkers = markers;
         }*/

        /*function getValue(){
         return v;
         }*/

    }


})();
