(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('NotificationsSearch', NotificationsSearch);

    NotificationsSearch.$inject = ['$resource'];

    function NotificationsSearch($resource) {
        var resourceUrl =  'api/_search/notifications/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
