(function() {
    //'use strict';
    angular
        .module('mobilityexchangeApp')
        .factory('Disclaimer', Disclaimer);

    Disclaimer.$inject = ['$resource', 'DateUtils'];

    function Disclaimer ($resource, DateUtils) {
        var resourceUrl =  'api/disclaimers/:id/:url';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    console.log('transformResponse method called !!');
                    if (data) {
                        data = angular.fromJson(data);
                        data.effective_date = DateUtils.convertLocalDateFromServer(data.effective_date);
                        data.createdDate = DateUtils.convertLocalDateFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertLocalDateFromServer(data.modifiedDate);
                        data.modifiedBy = DateUtils.convertLocalDateFromServer(data.modifiedBy);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.effective_date = DateUtils.convertLocalDateToServer(copy.effective_date);
                    copy.createdDate = DateUtils.convertLocalDateToServer(copy.createdDate);
                    copy.modifiedDate = DateUtils.convertLocalDateToServer(copy.modifiedDate);
                    copy.modifiedBy = DateUtils.convertLocalDateToServer(copy.modifiedBy);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.effective_date = DateUtils.convertLocalDateToServer(copy.effective_date);
                    copy.createdDate = DateUtils.convertLocalDateToServer(copy.createdDate);
                    copy.modifiedDate = DateUtils.convertLocalDateToServer(copy.modifiedDate);
                    copy.modifiedBy = DateUtils.convertLocalDateToServer(copy.modifiedBy);
                    return angular.toJson(copy);
                }
            },
            
            'getMemberDisclaimers': {
                method: 'GET',
                isArray: true,
                cache: true,
                    url: 'api/disclaimers/memberContact',
                    transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                        data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                    }
                    return data;
                }
            }
        });
    }
})();
