(function() {
	// 'use strict';

	angular.module('mobilityexchangeApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'content-control',
						{
							parent : 'entity',
							url : '/content-control?page&sort&search&id&description&website&type&navtype',
							data : {
								authorities : [ 'ROLE_ADMIN' ],
								pageTitle : 'mobilityexchangeApp.contentControl.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/content-control/content-control.html',
									controller : 'ContentControlController',
									controllerAs : 'vm'
								}
							},
							params : {
								page : {
									value : '1',
									squash : true
								},
								sort : {
									value : 'id,desc'
								},
								search : null,
								description : null,
								website : null,
								type : null,
								id : null
							},
							resolve : {
								pagingParams : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												page : PaginationUtil
														.parsePage($stateParams.page),
												sort : $stateParams.sort,
												predicate : PaginationUtil
														.parsePredicate($stateParams.sort),
												ascending : PaginationUtil
														.parseAscending($stateParams.sort),
												search : $stateParams.search
											};
										} ],
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('ContentControl');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'content-control-detail',
						{
							parent : 'content-control',
							url : '/content-control/{id}',
							data : {
								authorities : [ 'ROLE_ADMIN' ],
								pageTitle : 'mobilityexchangeApp.ContentControl.detail.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/content-control/content-control-detail.html',
									controller : 'ContentControlDetailController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('ContentControl');
											return $translate.refresh();
										} ],
								entity : [ '$stateParams', 'ContentControl',
										function($stateParams, ContentControl) {
											return ContentControl.get({
												id : $stateParams.id
											}).$promise;
										} ],
								previousState : [
										"$state",
										function($state) {
											var currentStateData = {
												name : $state.current.name
														|| 'content-control',
												params : $state.params,
												url : $state.href(
														$state.current.name,
														$state.params)
											};
											return currentStateData;
										} ]
							}
						})
				.state(
						'content-control-lookup',
						{
							parent : 'entity',
							url : '/content-control?page&search',
							data : {
								authorities : [ 'ROLE_ADMIN' ],
								pageTitle : 'mobilityexchangeApp.contentControl.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/content-control/content-control.html',
									controller : 'ContentControlController',
									controllerAs : 'vm'
								}
							},
							params : {
								page : {
									value : '1',
									squash : true
								},
								sort : {
									value : 'id,asc',
									squash : true
								},
								search : null
							},
							resolve : {
								pagingParams : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												page : PaginationUtil
														.parsePage($stateParams.page),
												sort : $stateParams.sort,
												predicate : PaginationUtil
														.parsePredicate($stateParams.sort),
												ascending : PaginationUtil
														.parseAscending($stateParams.sort),
												search : $stateParams.search
											};
										} ],
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('ContentControl');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})
				.state(
						'content-control.new',
						{
							parent : 'content-control',
							url : '/new/',
							data : {
								authorities : [ 'ROLE_ADMIN' ]
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/content-control/content-control-dialog.html',
									controller : 'ContentControlDialogController',
									controllerAs : 'vm',
								}
							},
							resolve : {
								entity : function() {
									return {
										description : null,
										website : null,
										type : null,
										id : null
									};
								}
							},
							previousState : [
									"$state",
									function($state) {
										var currentStateData = {
											isCopy : 'false',
											params : $state.params,
											url : $state.href(
													$state.current.name,
													$state.params)
										};
										return currentStateData;
									} ]
						})
				.state(
						'content-control.edit',
						{
							parent : 'content-control',
							url : '/{id}/edit?navtype={navtype}',
							data : {
								authorities : [ 'ROLE_ADMIN' ]
							},
							params : {
								navtype : '1'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/content-control/content-control-dialog.html',
									controller : 'ContentControlDialogController',
									controllerAs : 'vm'
								}
							},
							resolve : {

								entity : [ '$stateParams', 'ContentControl',
										function($stateParams, ContentControl) {
											return ContentControl.get({
												id : $stateParams.id
											}).$promise;
										} ],

								previousState : [
										"$state",
										function($state) {
											var currentStateData = {
												isCopy : 'false',
												params : $state.params,
												url : $state.href(
														$state.current.name,
														$state.params)
											};
											return currentStateData;
										} ]
							}

						})

				.state(
						'content-control.delete',
						{
							parent : 'content-control',
							url : '/{id}/delete',
							data : {
								authorities : [ 'ROLE_ADMIN' ]
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/content-control/content-control-delete-dialog.html',
													controller : 'ContentControlDeleteController',
													controllerAs : 'vm',
													size : 'md',
													resolve : {
														entity : [
																'ContentControl',
																function(
																		ContentControl) {
																	return ContentControl
																			.get({
																				id : $stateParams.id
																			}).$promise;
																} ]
													}
												}).result
												.then(
														function() {
															$state
																	.go(
																			'content-control',
																			null,
																			{
																				reload : 'content-control'
																			});
														}, function() {
															$state.go('^');
														});
									} ]
						});
	}

})();
