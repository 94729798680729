(function() {
    //'use strict';
    angular
        .module('mobilityexchangeApp')
        .factory('ReportSchedule', ReportSchedule);

    ReportSchedule.$inject = ['$resource', 'DateUtils'];

    function ReportSchedule ($resource, DateUtils) {
        var resourceUrl =  'api/report-schedule/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdBy = DateUtils.convertLocalDateFromServer(data.createdBy);
                        data.createdDate = DateUtils.convertLocalDateFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertLocalDateFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'getBySqlExtractId': {
                method: 'GET',
                url: 'api/report-schedule/sql-extract/:id',
                params: {id: '@id'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertLocalDateFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertLocalDateFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                url: 'api/report-schedule/:id',
                params: {id: '@id'},
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.createdBy = DateUtils.convertLocalDateToServer(copy.createdBy);
                    copy.createdDate = DateUtils.convertLocalDateToServer(copy.createdDate);
                    copy.modifiedDate = DateUtils.convertLocalDateToServer(copy.modifiedDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    // Convert string to id
                    copy.user = Number(copy.user);
                    copy.createdBy = DateUtils.convertLocalDateToServer(copy.createdBy);
                    copy.createdDate = DateUtils.convertLocalDateToServer(copy.createdDate);
                    copy.modifiedDate = DateUtils.convertLocalDateToServer(copy.modifiedDate);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
