(function () {
    //'use strict';
    angular
        .module('mobilityexchangeApp')
        .factory('Quality', Quality);

    Quality.$inject = ['$resource', 'DateUtils'];

    function Quality($resource, DateUtils) {
        var resourceUrl = 'api/qualities/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.originalapprovaldate = DateUtils.convertLocalDateFromServer(data.originalapprovaldate);
                        data.expirydate = DateUtils.convertLocalDateFromServer(data.expirydate);
                        data.mxvalidationdate = DateUtils.convertLocalDateFromServer(data.mxvalidationdate);
                        data.createdby = DateUtils.convertLocalDateFromServer(data.createdby);
                        data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.originalapprovaldate = DateUtils.convertLocalDateToServer(data.originalapprovaldate);
                    data.expirydate = DateUtils.convertLocalDateToServer(data.expirydate);
                    data.mxvalidationdate = DateUtils.convertLocalDateToServer(data.mxvalidationdate);
                    data.createdby = DateUtils.convertLocalDateToServer(data.createdby);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.originalapprovaldate = DateUtils.convertLocalDateToServer(data.originalapprovaldate);
                    data.expirydate = DateUtils.convertLocalDateToServer(data.expirydate);
                    data.mxvalidationdate = DateUtils.convertLocalDateToServer(data.mxvalidationdate);
                    data.createdby = DateUtils.convertLocalDateToServer(data.createdby);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            }
        });
    }
})();
