(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('customer-notes', {
                parent: 'entity',
                url: '/customer-notes-consolidated',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'mobilityexchangeApp.customerNotes.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/customer-notes/customer-notes-consolidated.html',
                        controller: 'CustomerNotesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('customerNotes');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })



            .state('customer-notes-detail.edit', {
                parent: 'customer-notes-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/customer-notes/customer-notes-dialog.html',
                        controller: 'CustomerNotesDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'sm',
                        resolve: {
                            entity: ['CustomerNotes', function (CustomerNotes) {
                                return CustomerNotes.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('customer-notes.new', {
                parent: 'customer-notes',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/customer-notes/customer-notes-dialog.html',
                        controller: 'CustomerNotesDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'sm',
                        resolve: {
                            entity: function () {
                                return {
                                    provider_contact_id: null,
                                    notes_type: null,
                                    note: null,
                                    action: null,
                                    targetDate: null,
                                    status: null,
                                    createdby: null,
                                    createddate: null,
                                    modifiedby: null,
                                    modifieddate: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('customer-notes', null, {reload: 'customer-notes'});
                    }, function () {
                        $state.go('customer-notes');
                    });
                }]
            })
            .state('customer-notes.edit', {
                parent: 'customer-notes',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/customer-notes/customer-notes-dialog.html',
                        controller: 'CustomerNotesDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'sm',
                        resolve: {
                            entity: ['CustomerNotes', function (CustomerNotes) {
                                return CustomerNotes.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('customer-notes', null, {reload: 'customer-notes'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            ;
    }

})();
