(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        //     .module('mobilityexchangeApp', ['reCAPTCHA'])
        //        .config(function (reCAPTCHAProvider) {
        //        	reCAPTCHAProvider.setPublicKey('6LeF4g4UAAAAAHRCXRcP3ovblFAlzLWpzEvHySOf');
        //        	reCAPTCHAProvider.setOptions({
        //              theme: 'clean'
        //          });
        //        })
        .config(stateConfig);


//    angular.module('mobilityexchangeApp', ['reCAPTCHA'])
//    .config(function (reCAPTCHAProvider) {
//
//        // required, please use your own key :)
//        reCAPTCHAProvider.setPublicKey('6LfyK-0SAAAAAAl6V9jBGQgPxemtrpIZ-SPDPd-n');
//
//        // optional
//        reCAPTCHAProvider.setOptions({
//            theme: 'clean'
//        });
//    });


    stateConfig.$inject = ['$stateProvider'];


    /*
     *
     *         .module('mobilityexchangeApp', ['reCAPTCHA'])
     config(function (reCAPTCHAProvider) {
     //            // required: please use your own key :)
     reCAPTCHAProvider.setPublicKey('6LeF4g4UAAAAAHRCXRcP3ovblFAlzLWpzEvHySOf');
     //
     //            // optional: gets passed into the Recaptcha.create call
     reCAPTCHAProvider.setOptions({
     theme: 'clean'
     });
     })
     */
    function stateConfig($stateProvider) {

        /*
         $stateProvider.state('registercompany', {
         parent: 'account',
         url: '/registercompany',
         data: {
         authorities: [],
         pageTitle: 'registercompany.title'
         },
         views: {
         'content@': {
         templateUrl: 'app/account/registercompany/registercompany.html',
         controller: 'RegisterCompanyController',
         controllerAs: 'vm'
         }
         },
         resolve: {
         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
         $translatePartialLoader.addPart('registercompany');
         return $translate.refresh();
         }]
         }
         });*/


        $stateProvider.state('registercompany', {
            parent: 'account',
            url: '/registercompany',
            data: {
                //authorities: [],
                pageTitle: 'registercompany.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/registercompany/registercompanydetail.html',
                    controller: 'RegisterCompanyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('registercompany');
                    return $translate.refresh();
                }],

                previousState: ['$stateParams', function ($stateParams) {
                    var currentStateData = {
                        spid: ''
                    };
                    return currentStateData;
                }]

            }
        });

        $stateProvider.state('claimbusiness', {
            parent: 'account',
            url: '/claimbusiness/{id}',
            data: {
                authorities: [],
                pageTitle: 'claimbusiness.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/registercompany/claimbusiness.html',
                    controller: 'ClaimBusinessController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'ServiceProviders', function ($stateParams, ServiceProviders) {
                    return ServiceProviders.get({id: $stateParams.id}).$promise;
                }],
                previousState: ['$stateParams', function ($stateParams) {
                    var currentStateData = {
                        spid: $stateParams.id
                    };
                    return currentStateData;
                }]
            }

        });

        $stateProvider.state('claimsuccess', {
            parent: 'account',
            url: '/claimsuccess/{id}',
            data: {
                authorities: [],
                pageTitle: 'claimsuccess.title',
                controller: 'ClaimBusinessController',
                controllerAs: 'vm'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/registercompany/claimsuccess.html',
                    controller: 'ClaimBusinessController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'ServiceProviders', function ($stateParams, ServiceProviders) {
                    return ServiceProviders.get({id: $stateParams.id}).$promise;
                }],
                previousState: ['$stateParams', function ($stateParams) {
                    var currentStateData = {
                        spid: $stateParams.id
                    };
                    return currentStateData;
                }]
            }
        });


        $stateProvider.state('registersuccess', {
            parent: 'account',
            url: '/registersuccess',
            data: {
                authorities: [],
                pageTitle: 'registersuccess.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/registercompany/registersuccess.html',
                    controller: 'RegisterCompanyController',
                    controllerAs: 'vm'
                }
            }
        });

    }


})();
