(function() {
	//'use strict';

	angular.module('mobilityexchangeApp').controller(
			'PaymentProcessingDialogController',
			PaymentProcessingDialogController);

	PaymentProcessingDialogController.$inject = [ '$timeout', '$scope',
			'$stateParams', 'entity', 'PaymentProcessing', 'Lookups',
			'DateUtils', '$http', '$q', 'ServiceProviders', 'Principal' ];

	function PaymentProcessingDialogController($timeout, $scope, $stateParams,
			entity, PaymentProcessing, Lookups, DateUtils, $http, $q,
			ServiceProviders, Principal) {
		var vm = this;
		vm.receiptRegister = entity;
		vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;
		vm.datePickerOpenStatus.paymentDate = false;
		vm.save = save;
		vm.clear = clear;
		vm.paymentMethods = Lookups.getByLookupType({
			id : 40
		});

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}

		vm.sp = $stateParams.sp;
		if (vm.sp) {
			vm.serviceProviders = ServiceProviders.getprofile({
				id : vm.sp
			});
			vm.receiptRegister.serviceProviders = vm.serviceProviders.serviceProviders;
		}

		vm.discountEligiblePlans = [];

		function clear() {
		}

		function save() {
			vm.isSaving = true;

			// Ensure that service providers is populated. Initialization in the constructor is asynchronous and service
			// provider may not be initialized at that point.
            if (!vm.receiptRegister.serviceProviders) {
                if (vm.serviceProviders.serviceProviders) {
			        vm.receiptRegister.serviceProviders = vm.serviceProviders.serviceProviders;
			    } else {
			        vm.receiptRegister.serviceProviders = {
			            id: vm.sp
			        }
			    }
			}

			if (vm.receiptRegister.id !== null) {
				PaymentProcessing.update(vm.receiptRegister, onSaveSuccess,
						onSaveError);
			} else {
				PaymentProcessing.save(vm.receiptRegister, onSaveSuccess,
						onSaveError);
			}

			vm.paymentValMessage = null;
		}

		function onSaveSuccess(result) {
			vm.receiptRegister = result;
			$scope.$emit('mobilityexchangeApp:receiptRegisterUpdate', result);
			vm.isSaving = false;
		}

		function onSaveError() {
			vm.isSaving = false;
		}
	}
})();
