(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('CRMNotesController', CRMNotesController);

    CRMNotesController.$inject = ['$rootScope','$state', '$stateParams', '$scope', 'CRMNotes', 'CRMNotesSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function CRMNotesController($rootScope, $state, $stateParams, $scope, CRMNotes, CRMNotesSearch, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.transitionOnKeyUp = transitionOnKeyUp;
        vm.itemsPerPage = 10;//paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.companyLegalName = $stateParams.companyLegalName;
        vm.providerContactName = $stateParams.providerContactName;
        vm.actor = $stateParams.actor;
        vm.note = $stateParams.note;
        vm.action = $stateParams.action;
        vm.targetDate = $stateParams.targetDate;
        vm.createddate = $stateParams.createddate;

        $rootScope.showSearchBox = true;
        $rootScope.mainLogoLink = "#";
        loadAll();

        function loadAll() {
            if (pagingParams.search) {
                CRMNotesSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    companyLegalName : vm.companyLegalName ? vm.companyLegalName : $stateParams.companyLegalName,
                    providerContactName : vm.providerContactName ? vm.providerContactName : $stateParams.providerContactName,
                    actor : vm.actor ? vm.actor : $stateParams.actor,
                    note : vm.note ? vm.note : $stateParams.note,
                    action : vm.action ? vm.action : $stateParams.action,
                    targetDate : vm.targetDate ? vm.targetDate : $stateParams.targetDate,
                    createddate : vm.createddate ? vm.createddate : $stateParams.createddate,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                CRMNotes.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {

                ////console.log(angular.toJson(data));

                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.cRMNotes = data;
                vm.page = pagingParams.page;

                _.forEach(vm.cRMNotes, function (crmNote) {
                    var displayNoteLength = 100;
                    crmNote.displayNote = (crmNote.note && crmNote.note.length < displayNoteLength) ?
                        crmNote.note : (crmNote.note.substring(0, displayNoteLength) + " ...");
                });
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        /*
        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        */

		function transition() {
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch,
				companyLegalName : vm.companyLegalName ? vm.companyLegalName : $stateParams.companyLegalName,
				providerContactName : vm.providerContactName ? vm.providerContactName : $stateParams.providerContactName,
				actor : (vm.actor != null) ? vm.actor : $stateParams.actor,
				note : (vm.note != null) ? vm.note : $stateParams.note,
				action : (vm.action != null) ? vm.action : $stateParams.action,
				targetDate : (vm.targetDate != null) ? vm.targetDate : $stateParams.targetDate,
				createddate : (vm.createddate != null) ? vm.createddate : $stateParams.createddate
			});
		}

        function transitionOnKeyUp(searchQuery) {
			searchQuery = '-';
			if (!searchQuery) {
				return vm.clear();
			}
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			vm.currentSearch = searchQuery;
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch,
				companyLegalName : vm.companyLegalName ? vm.companyLegalName : $stateParams.companyLegalName,
				providerContactName : vm.providerContactName ? vm.providerContactName : $stateParams.providerContactName,
				actor : vm.actor ? vm.actor : $stateParams.actor,
				note : vm.note ? vm.note : $stateParams.note,
				action : vm.action ? vm.action : $stateParams.action,
				targetDate : vm.targetDate ? vm.targetDate : $stateParams.targetDate,
				createddate : vm.createddate ? vm.createddate : $stateParams.createddate
			});
		}

		$scope.goToOnKeyUp = function (e, searchQuery) {
			if (event.keyCode === 13) {
			    vm.transitionOnKeyUp(searchQuery);
			    // vm.search(vm.searchQuery);
			}
		}

        function search(searchQuery) {
            searchQuery = '-';
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            // TODO: Review
            // vm.predicate = '_score';
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
