(function() {
    //'use strict';
    angular
        .module('mobilityexchangeApp')
        .factory('VolunteerEngagement', VolunteerEngagement);

    VolunteerEngagement.$inject = ['$resource', 'DateUtils'];

    function VolunteerEngagement ($resource, DateUtils) {
        var resourceUrl =  'api/volunteer-engagement/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                url: 'api/volunteer-engagement/:id?'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data, headers, status) {

                    if(status !== 200)
                        return status;

                    if (data || !_.isEmpty(data)) {
                        data = angular.fromJson(data);

                        data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                        data.expDate = DateUtils.convertDateTimeFromServer(data.expDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'getAll': {
                method: 'GET',
                url: 'api/volunteer-engagement/provider-contact/:providerContactId',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                url: 'api/volunteer-engagement/:id',
                params: {id: '@id'},
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                        data.expDate = DateUtils.convertDateTimeFromServer(data.expDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                        data.expDate = DateUtils.convertDateTimeFromServer(data.expDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            }
        });
    }
})();
