(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('ContactQualificationsDetailController', ContactQualificationsDetailController);

    ContactQualificationsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ContactQualifications', 'FileControl'];

    function ContactQualificationsDetailController($scope, $rootScope, $stateParams, previousState, entity, ContactQualifications, FileControl) {
        var vm = this;

        vm.contactQualifications = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:contactQualificationsUpdate', function(event, result) {
            vm.contactQualifications = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
