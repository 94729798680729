(function () {
	angular.module('mobilityexchangeApp').controller(
		'AdHeaderDialogController', AdHeaderDialogController)
        .directive('supplierAdImageFileUpload', function () {
            return {
                scope: true, // create a new scope
                link: function (scope, el, attrs) {
                    el.bind('change', function (event) {
                        var files = event.target.files;
                        // iterate files since 'multiple' may be specified
                        // on the element
                        for (var i = 0; i < files.length; i++) {
                            // emit event upward
                            scope.$emit("supplierAdImageFileSelected", {
                                file: files[i]
                            });
                        }
                    });
                }
            };
        })
        .directive('homePageImageFileUpload', function () {
            return {
                scope: true, // create a new scope
                link: function (scope, el, attrs) {
                    el.bind('change', function (event) {
                        var files = event.target.files;
                        // iterate files since 'multiple' may be specified
                        // on the element
                        for (var i = 0; i < files.length; i++) {
                            // emit event upward
                            scope.$emit("homePageImageFileSelected", {
                                file: files[i]
                            });
                        }
                    });
                }
            };
        })
        ;

	AdHeaderDialogController.$inject = ['$timeout', '$scope', '$stateParams',
		'entity', 'AdHeader', 'Lookups', 'DateUtils', '$http', '$q',
		'Principal', 'MemberContacts', 'DTOptionsBuilder',
		'DTColumnDefBuilder', '$uibModal', 'AuditLog'];

	function AdHeaderDialogController($timeout, $scope, $stateParams, entity,
		AdHeader, Lookups, DateUtils, $http, $q, Principal, MemberContacts,
		DTOptionsBuilder, DTColumnDefBuilder, $uibModal, AuditLog) {
		var vm = this;
		vm.adv = entity;
		//
		$http.get('api/adv-controls/serviceproviders').then(function (response) {
			var data = angular.fromJson(response.data);
			data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
			data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
			vm.serviceProvidersSearchResult = data;
			if (!vm.adv.serviceprovider && vm.serviceProvidersSearchResult.length === 1) {
				vm.adv.serviceprovider = vm.serviceProvidersSearchResult[0];
			}
		});
		//
		initializeChildren();

		$scope.isAdminUser = false;
		Principal.identity().then(function (account) {
			$scope.account = account;
			$scope.isAuthenticated = Principal.isAuthenticated;
			for (var i = 0; i < $scope.account.authorities.length; i++) {
				if ($scope.account.authorities[i] === 'AD_ADMIN') {
					$scope.isAdminUser = true;
					break;
				}
			}
		});

		$scope.displayDateFormat = DateUtils.displayDateFormat;

		// an array of files selected
        $scope.files = [];

		$scope.$on("supplierAdImageFileSelected", function (event, args) {
            console.log('Supplier ad image file selected');

            $scope.$apply(function () {
                // add the file object to the scope's files collection
                $scope.files.push(args.file);
                $scope.supplierAdImageFileToUpload = args.file;
                if (vm.adv.id) {
                    doUploadSupplierAdImageFile(vm.adv.id, $scope.supplierAdImageFileToUpload);
                }
            });
        });

		function doUploadSupplierAdImageFile(id, file) {
		    vm.isSaving = true;
			var url = "/api/adv-controls/" + id + "/supplier-ad-image-upload";

			var data = new FormData();
			data.append('uploadfile', file);

			var config = {
				transformRequest : angular.identity,
				transformResponse : angular.identity,
				headers : {
					'Content-Type' : undefined
				}
			}

			$http
                .post(url, data, config)
                .then(
                    function(response) {
                        $scope.uploadResult = "File uploaded successfully.";
                        $scope.showUploadClaimAlert = true;
                        $scope.uploadSuccess = true;
                        console.log('Response: ' + response);
                        var responseData = angular.fromJson(response.data);
                        console.log('Response data: ' + responseData);
                        vm.adv.adImage = responseData.adImage;
                        $scope.$emit('mobilityexchangeApp:advControlUpdate', response);
                        vm.isSaving = false;
                    },
                    function(response) {
                        $scope.uploadResult = "Error uploading file, files must be 3 megabyte or less. Only PDF and image files are permitted. Please contact support@mobilityex.com if you require assistance.";
                        $scope.showUploadClaimAlert = true;
                        $scope.uploadSuccess = false;
                        vm.isSaving = false;
                        $scope.files.pop();
                    });
		};


		$scope.$on("homePageImageFileSelected", function (event, args) {
            console.log('Home page image file selected');

            $scope.$apply(function () {
                // add the file object to the scope's files collection
                $scope.files.push(args.file);
                $scope.homePageImageFileToUpload = args.file;
                if (vm.adv.id) {
                    doUploadHomePageImageFile(vm.adv.id, $scope.homePageImageFileToUpload);
                }
            });
        });

		function doUploadHomePageImageFile(id, file) {
		    vm.isSaving = true;
			var url = "/api/adv-controls/" + id + "/home-page-image-upload";

			var data = new FormData();
			data.append('uploadfile', file);

			var config = {
				transformRequest : angular.identity,
				transformResponse : angular.identity,
				headers : {
					'Content-Type' : undefined
				}
			}

			$http
                .post(url, data, config)
                .then(
                    function(response) {
                        $scope.uploadResult = "File uploaded successfully.";
                        $scope.showUploadClaimAlert = true;
                        $scope.uploadSuccess = true;
                        console.log('Response: ' + response);
                        var responseData = angular.fromJson(response.data);
                        console.log('Response data: ' + responseData);
                        vm.adv.imagePath = responseData.imagePath;
                        $scope.$emit('mobilityexchangeApp:advControlUpdate', response);
                        vm.isSaving = false;
                    },
                    function(response) {
                        $scope.uploadResult = "Error uploading file, files must be 3 megabyte or less. Only PDF and image files are permitted. Please contact support@mobilityex.com if you require assistance.";
                        $scope.showUploadClaimAlert = true;
                        $scope.uploadSuccess = false;
                        vm.isSaving = false;
                        $scope.files.pop();
                    });
		};


		vm.openCalendar = openCalendar;
		vm.calculateDailyImpression = calculateDailyImpression;
		vm.openCapabilityCalendar = openCapabilityCalendar;
		vm.datePickerOpenStatus = {};
		vm.datePickerCapabilityOpenStatus = {};

		calculateDailyImpression();

		vm.compaignTypes = Lookups.getByLookupType({
			id: 84
		});

		vm.serviceScopeTypes = Lookups.getByLookupType({
			id: 87
		});

		vm.campaignDisplays = Lookups.getByLookupType({
			id: 86
		});

        vm.displayDirectories = Lookups.getByLookupType({
			id: 93
		});

		vm.countries = Lookups.getByLookupType({
			id: 50
		});

		vm.states = Lookups.getByLookupType({
			id: 51
		});

		vm.capabilityTypes = Lookups.getByMultiLookupType({
			id: [7, 8]
		});

		vm.dtInstance = {};

		vm.dtOptions1 = DTOptionsBuilder.newOptions('className',
			'details-control').withDOM('C<"clear">lfrtip');
		vm.dtColumnDefs1 = [DTColumnDefBuilder.newColumnDef(5).notSortable()];
		vm.dtColumnDefs2 = [DTColumnDefBuilder.newColumnDef(0).notSortable()];

		vm.save = save;

		vm.addlocation = function () {
			vm.advLocationSetups.push({});
		};

		vm.removelocation = function (idx) {
			vm.advLocationSetups.splice(idx, 1);
			if (vm.advLocationSetups.length === 0) {
				vm.advLocationSetups = [{}];
			}
		};

		vm.setServiceProvider = function (serviceprovider) {
			vm.adv.serviceprovider = serviceprovider;
		};

        vm.isAdvertisingAvailable = function(serviceProvider) {
            return serviceProvider && (serviceProvider.mxstatus.id === 4 || serviceProvider.mxstatus.id === 5);
        }

		vm.setServiceProviderWitParentBilling = function (serviceprovider) {
			vm.adv.serviceprovider = serviceprovider;
			for (var i = 0; i < vm.serviceProvidersSearchResult.length; i++) {
				var tempServiceProvider = vm.serviceProvidersSearchResult[i];
				if (tempServiceProvider.stripeCustomerId) {
					vm.adv.serviceprovider.stripeCustomerId = tempServiceProvider.stripeCustomerId;
					break;
				}
			}
		}
		vm.addUserlocation = function () {
			vm.advUserLocationSetups.push({});
		};

		vm.removeUserlocation = function (idx) {
			vm.advUserLocationSetups.splice(idx, 1);
			if (vm.advUserLocationSetups.length === 0) {
				vm.advUserLocationSetups = [{}];
			}
		};

		vm.addcapability = function () {
			vm.advCapabilitySetups.push({});
		};

		vm.removecapability = function (idx) {
			vm.advCapabilitySetups.splice(idx, 1);
			if (vm.advCapabilitySetups.length === 0) {
				vm.advCapabilitySetups = [{}];
			}
		};

		vm.addservice = function () {
			vm.advServices.push({});
		};

		vm.removeservice = function (idx) {
			vm.advServices.splice(idx, 1);
			if (vm.advServices.length === 0) {
				vm.advServices = [{}];
			}
		};

		vm.addIpAddress = function () {
			vm.advSpIpExcludes.push({});
		};

		vm.removeIpAddress = function (idx) {
			vm.advSpIpExcludes.splice(idx, 1);
			if (vm.advSpIpExcludes.length === 0) {
				vm.advSpIpExcludes = [{}];
			}
		};

		vm.onCountryChange = function (location) {
			if (location.country && location.country !== 'United States'
				&& location.country !== 'Canada') {
				location.state = '';
			}
		}
		vm.onStateChange = function (state, location) {
			console.log("158. State = " + state);
			vm.states
			var selected = vm.states.filter(function (e) {
				return e.lookupcode === state;
			});

			if (selected[0].flex1 === 'US') {
				location.country = 'United States';
			} else if (selected[0].flex1 === 'CA') {
				location.country = 'Canada';
			}

		}
		vm.setAddressValues = function (location) {

			if (location.campaignType) {
				if (location.campaignType.id == 5902) {
					// console.log(vm.adv.serviceprovider.id);
					for (var i = 0; i < vm.adv.serviceprovider.serviceproviderAddresses.length; i++) {
						var address = vm.adv.serviceprovider.serviceproviderAddresses[i];
						if (address.addressType.id == 1530) {
							location.address = address.addressline1;
							location.state = address.state;
							location.country = address.country;
							location.geocode = address.geocode;
							break;
						}
					}
				} /*
				else if (location.campaignType.id == 5901) {
					location.country = 'United States';
				}*/
			}
		}

		vm.updateGeoCode = function (location) {
			location.geocode = '';
		}

		vm.IsMxStatus = function (mxStatus) {
			if (serviceprovider.mxstatus.id === mxStatus) {
				return true;
			}
			return false;
		}

		function save() {
			vm.isSaving = true;
			vm.validationError = false;
			vm.validateForm();
			if (vm.isSaving) {
				vm.adv.advLocationSetups = vm.advLocationSetups;
				vm.adv.advCapabilitySetups = vm.advCapabilitySetups;
				vm.adv.advServices = vm.advServices;
				vm.adv.advUserLocationSetups = vm.advUserLocationSetups;
				vm.adv.advSpIpExcludes = vm.advSpIpExcludes;
				if (vm.adv.id !== null) {
					AdHeader.update(vm.adv, onSaveSuccess, onSaveError);
				} else {
					AdHeader.save(vm.adv, onSaveSuccess, onSaveError);
				}
			}
		}

		vm.validateForm = function () {

			if (!vm.adv.campaignName) {
				vm.isSaving = false;
				vm.campaignNameError = true;
			} else {
				vm.campaignNameError = false;
			}

			if (!vm.adv.monthlyMaximum) {
				vm.isSaving = false;
				vm.monthlyMaximumError = true;
			} else if (vm.adv.campaignDisplay
				&& (vm.adv.monthlyMaximum < vm.adv.campaignDisplay.flex1)) {
				vm.isSaving = false;
				vm.monthlyMaximumErrorMin = true;
			} else {
				vm.monthlyMaximumError = false;
				vm.monthlyMaximumErrorMin = false;
			}

			if (!vm.adv.pauseAdFrom) {
				vm.isSaving = false;
				vm.pauseAdFromError = true;
			} else {
				vm.pauseAdFromError = false;
			}

            // TODO: Display directory validation

			var today = new Date();
			today.setDate(today.getDate() - 1);

			// console.log("vm.adv.pauseAdFrom = " + oldDate);
			// console.log("today = " + today);
			if (!vm.adv.id && !vm.pauseAdFromError
				&& vm.adv.pauseAdFrom <= today) {
				vm.isSaving = false;
				vm.pauseAdFromPastError = true;
			} else {
				vm.pauseAdFromPastError = false;
			}

			if (!vm.adv.pauseAdTo) {
				vm.isSaving = false;
				vm.pauseAdToError = true;
			} else {
				vm.pauseAdToError = false;
			}

			if (!vm.pauseAdFromError && !vm.pauseAdToError
				&& vm.adv.pauseAdFrom > vm.adv.pauseAdTo) {
				vm.isSaving = false;
				vm.pauseAdToPastError = true;
			} else {
				vm.pauseAdToPastError = false;
			}

			// vm.pauseAdToAtLeast30days
			var temp
			var temp = new Date(vm.adv.pauseAdFrom.getTime() + 1000 * 60 * 60
				* 24 * 30);

			if (!vm.pauseAdFromError && !vm.pauseAdToError
				&& !vm.pauseAdToPastError && vm.adv.pauseAdTo <= temp) {
				vm.isSaving = false;
				vm.pauseAdToAtLeast30days = true;
			} else {
				vm.pauseAdToAtLeast30days = false;
			}

			if (vm.adv.campaignDisplay.flex3 !== 'N') {
				if (!vm.adv.bidPrice) {
					vm.isSaving = false;
					vm.bidPriceError = true;
				} else if (vm.adv.campaignDisplay
					&& (vm.adv.bidPrice < vm.adv.campaignDisplay.flex2)) {
					vm.isSaving = false;
					vm.bidPriceErrorMin = true;
				} else {
					vm.bidPriceError = false;
					vm.bidPriceErrorMin = false;
				}
			}
		if (!vm.adv.campaignDisplay) {
			vm.isSaving = false;
			vm.campaignDisplayError = true;
		} else {
			vm.campaignDisplayError = false;
		}

		for (var i = 0; i < vm.advLocationSetups.length; i++) {
			var location = vm.advLocationSetups[i];

			if (location.campaignType && !location.country) {

				vm.isSaving = false;
				vm.validationError = true;
				vm.advLocationSetups[i].countryError = true;
			}

			if (location.campaignType && location.campaignType.id == 5901
				&& !location.state) {
				vm.isSaving = false;
				vm.validationError = true;
				vm.advLocationSetups[i].stateError = true;
			}

			if (location.campaignType && location.campaignType.id == 5902
				&& !location.address) {
				vm.isSaving = false;
				vm.validationError = true;
				vm.advLocationSetups[i].addressError = true;
			}

			if (location.geocode
				&& (!location.distance || location.distance == 0)) {
				vm.isSaving = false;
				vm.validationError = true;
				vm.advLocationSetups[i].distanceError = true;
			}
		}

		if (!vm.isSaving) {
			vm.adv.submissionDate = null;
		}
	}

	function calculateDailyImpression() {
		$timeout(function () {
			vm.adv.bidPrice = Math.ceil(vm.adv.bidPrice / 0.05) * 0.05;
			if (vm.adv.monthlyMaximum > 0 && vm.adv.bidPrice > 0) {
				vm.predictHits = Math.round(vm.adv.monthlyMaximum
					/ (30.4 * vm.adv.bidPrice));
			}
		}, 2000);

	}

	function onSaveSuccess(result) {
		vm.adv = result;
		initializeChildren();
		$scope.$emit('mobilityexchangeApp:claimHeaderUpdate', result);
		vm.isSaving = false;
		vm.getAllAuditData();
	}

	function onSaveError() {
		vm.isSaving = false;
	}

	function openCalendar(field, id) {
		if (id) {
			if (!vm.datePickerOpenStatus[field]) {
				vm.datePickerOpenStatus[field] = [];

			}
			vm.datePickerOpenStatus[field][id] = true;

		} else {
			vm.datePickerOpenStatus[field] = true;

		}
	}

	function openCapabilityCalendar(field, id) {
		if (id) {
			if (!vm.datePickerCapabilityOpenStatus[field]) {
				vm.datePickerCapabilityOpenStatus[field] = [];

			}
			vm.datePickerCapabilityOpenStatus[field][id] = true;

		} else {
			vm.datePickerCapabilityOpenStatus[field] = true;

		}
	}

	function initializeChildren() {
		if (vm.adv.advLocationSetups && vm.adv.advLocationSetups.length > 0) {
			vm.advLocationSetups = vm.adv.advLocationSetups;
		} else {
			vm.advLocationSetups = [{}];
		}

		if (vm.adv.advCapabilitySetups
			&& vm.adv.advCapabilitySetups.length > 0) {
			vm.advCapabilitySetups = vm.adv.advCapabilitySetups;
		} else {
			vm.advCapabilitySetups = [{}];
		}

		if (vm.adv.advServices
			&& vm.adv.advServices.length > 0) {
			vm.advServices = vm.adv.advServices;
		} else {
			vm.advServices = [{}];
		}

		if (vm.adv.advUserLocationSetups
			&& vm.adv.advUserLocationSetups.length > 0) {
			vm.advUserLocationSetups = vm.adv.advUserLocationSetups;
		} else {
			vm.advUserLocationSetups = [{}];
		}

		if (vm.adv.advUserLocationSetups
			&& vm.adv.advUserLocationSetups.length > 0) {
			vm.advUserLocationSetups = vm.adv.advUserLocationSetups;
		} else {
			vm.advUserLocationSetups = [{}];
		}

		if (vm.adv.advSpIpExcludes && vm.adv.advSpIpExcludes.length > 0) {
			vm.advSpIpExcludes = vm.adv.advSpIpExcludes;
		} else {
			vm.advSpIpExcludes = [{}];
		}
	}

	$scope.validateSubmitAd = function () {
		if (!vm.adv.serviceprovider.stripeCustomerId
			&& !vm.adv.serviceprovider.depositCustomer) {
			vm.submitAdError = true;
		} else {
			vm.adv.submissionDate = new Date();
			vm.submitAdError = false;
			save();
		}

	}

	$scope.showAll = 'false';

	vm.getAllAuditData = function () {
		if (vm.adv.id) {
			vm.auditLogs = AuditLog.getAdvData({
				advId: vm.adv.id
			});
			$scope.showAll = 'true';
		}
	};

	vm.getAllAuditData();

}
}) ();
