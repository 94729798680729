(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('ServiceProvidersDetailController', ServiceProvidersDetailController);

    ServiceProvidersDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ServiceProviders', 'Addresses', 'MovingCapabilities', 'RelocationCapabilities', 'SupplierCapabilities', 'LanguageCapabilities'];

    function ServiceProvidersDetailController($scope, $rootScope, $stateParams, previousState, entity, ServiceProviders, Addresses, MovingCapabilities, RelocationCapabilities, SupplierCapabilities, LanguageCapabilities) {
        var vm = this;

        ////console.log(entity);

        vm.serviceProviders = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:serviceProvidersUpdate', function (event, result) {
            vm.serviceProviders = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
