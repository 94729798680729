(function () {
    'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('MembershipModalContentCtrl', MembershipModalContentCtrl);

    MembershipModalContentCtrl.$inject = ['$scope', '$uibModalInstance'];

    function MembershipModalContentCtrl($scope, $uibModalInstance) {
    	$scope.ok = function(){
    	    $uibModalInstance.close("Ok");
    	  }
    	   
    	  $scope.cancel = function(){
    	    $uibModalInstance.dismiss();
    	  } 
    }
})();
