(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('crm-notes', {
                parent: 'entity',
                url: '/crm-notes?page&search&companyLegalName&providerContactName&actor&note&action&createddate&sort=targetDate,asc',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ANALYST'],
                    pageTitle: 'mobilityexchangeApp.cRMNotes.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/c-rm-notes/c-rm-notes.html',
                        controller: 'CRMNotesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: false
                    },
                    sort: {
                        value: 'targetDate,asc',
                        squash: false
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('cRMNotes');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('crm-notes.crm-notes-edit', {
                url: '/customer-notes-edit/{customerNotesId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ANALYST']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/customer-notes/customer-notes-dialog.html',
                        controller: 'CustomerNotesDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['CustomerNotes', function (CustomerNotes) {
                                return CustomerNotes.get({id: $stateParams.customerNotesId}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customerNotes');
                                return $translate.refresh();
                            }],
                        }
                    }).result.finally(function () {
                        $state.go('^', {}, {reload: true});
                    });
                }]
            })

            .state('crm-notes-detail', {
                parent: 'crm-notes',
                url: '/crm-notes/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'mobilityexchangeApp.cRMNotes.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/c-rm-notes/crm-notes-detail.html',
                        controller: 'CRMNotesDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('cRMNotes');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'CRMNotes', function ($stateParams, CRMNotes) {
                        return CRMNotes.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'crm-notes',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('crm-notes-detail.edit', {
                parent: 'crm-notes-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/c-rm-notes/crm-notes-dialog.html',
                        controller: 'CRMNotesDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['CRMNotes', function (CRMNotes) {
                                return CRMNotes.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('crm-notes.new', {
                parent: 'crm-notes',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/c-rm-notes/crm-notes-dialog.html',
                        controller: 'CRMNotesDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    providerContactId: null,
                                    notesType: null,
                                    note: null,
                                    action: null,
                                    targetDate: null,
                                    status: null,
                                    createdby: null,
                                    createddate: null,
                                    modifiedby: null,
                                    modifieddate: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('crm-notes', null, {reload: 'crm-notes'});
                    }, function () {
                        $state.go('crm-notes');
                    });
                }]
            })
            .state('crm-notes.edit', {
                parent: 'crm-notes',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/c-rm-notes/crm-notes-dialog.html',
                        controller: 'CRMNotesDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['CRMNotes', function (CRMNotes) {
                                return CRMNotes.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('crm-notes', null, {reload: 'crm-notes'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state(
                    'crm-notes.lookup',
                    {
                        parent : 'entity',
                        url : '/crm-notes?page&sort&search&companyLegalName&providerContactName&actor&note&action&targetDate&createddate&navtype',
                        data : {
                            authorities: ['ROLE_ADMIN', 'ROLE_ANALYST'],
                            pageTitle: 'mobilityexchangeApp.cRMNotes.home.title'
                        },
                        views : {
                            'content@': {
                                templateUrl: 'app/entities/c-rm-notes/c-rm-notes.html',
                                controller: 'CRMNotesController',
                                controllerAs: 'vm'
                            }
                        },
                        params : {
                            page : {
                                value : '1',
                                squash : true
                            },
                            sort : {
                                value : 'id,desc',
                                squash : true
                            },
                            search : null,
                            companyLegalName : null,
                            providerContactName : null,
                            actor: null,
                            note: null,
                            action: null,
                            targetDate: null,
                            createddate: null
                        },
                        resolve : {
                            pagingParams : [
                                    '$stateParams',
                                    'PaginationUtil',
                                    function($stateParams, PaginationUtil) {
                                        return {
                                            page : PaginationUtil
                                                    .parsePage($stateParams.page),
                                            sort : $stateParams.sort,
                                            predicate : PaginationUtil
                                                    .parsePredicate($stateParams.sort),
                                            ascending : PaginationUtil
                                                    .parseAscending($stateParams.sort),
                                            search : $stateParams.search
                                        };
                                    } ],
                            translatePartialLoader : [
                                    '$translate',
                                    '$translatePartialLoader',
                                    function($translate,
                                            $translatePartialLoader) {
                                        $translatePartialLoader
                                                .addPart('cRMNotes');
                                        $translatePartialLoader
                                                .addPart('global');
                                        return $translate.refresh();
                                    } ]
                        }
                    })

            .state('crm-notes.delete', {
                parent: 'crm-notes',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/c-rm-notes/crm-notes-delete-dialog.html',
                        controller: 'CRMNotesDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['CRMNotes', function (CRMNotes) {
                                return CRMNotes.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('crm-notes', null, {reload: 'crm-notes'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
