(function() {
    //'use strict';
    angular
        .module('mobilityexchangeApp')
        .factory('ClaimInvoiceDetail', ClaimInvoiceDetail);

    ClaimInvoiceDetail.$inject = ['$resource', 'DateUtils'];

    function ClaimInvoiceDetail ($resource, DateUtils) {
        var resourceUrl =  'api/claim-invoice-details/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                url: 'api/claim-invoice-details/:id?'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data, headers, status) {

                    if(status !== 200)
                        return status;

                    if (data || !_.isEmpty(data)) {
                        data = angular.fromJson(data);
                        data.invoiceDate = DateUtils.convertDateTimeFromServer(data.invoiceDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'getAll': {
                method: 'GET',
                url: 'api/claim-invoice-details/claim/:claimId',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.invoiceDate = DateUtils.convertDateTimeFromServer(data.invoiceDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.invoiceDate = DateUtils.convertDateTimeFromServer(data.invoiceDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            }
        });
    }
})();
