(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('BillingPlanDialogController', BillingPlanDialogController);

    BillingPlanDialogController.$inject = ['$timeout', '$scope', '$stateParams' ,'entity', 'BillingPlan','Lookups', 'DateUtils'];

    function BillingPlanDialogController ($timeout, $scope, $stateParams,  entity, BillingPlan,Lookups, DateUtils) {
        var vm = this;

        vm.billingPlan = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        //vm.ns = N.query();

        vm.billingCategories = Lookups.getByLookupType({id: 41});
        vm.ypMemberType = Lookups.getByLookupById({id: 1710});
        //vm.memberTypes = Lookups.getByLookupType({id: 21});

        vm.memberTypes = Lookups.getMemberTypes();
        vm.effectiveStatus = Lookups.getByLookupType({id: 32});
        vm.parentAssociation = Lookups.getByLookupType({id: 9});
        vm.orderTypes = Lookups.getByLookupType({id: 35});
        vm.accountingBooks = Lookups.getByLookupType({id: 37});
        vm.billingBasis = Lookups.getByLookupType({id: 42});
        vm.currencies = Lookups.getByLookupType({id: 52});
        vm.associations = Lookups.getByLookupType({id: 9});

        $scope.displayDateFormat = DateUtils.displayDateFormat;

        $timeout(function(){
            vm.billingPlan.currency = _.find(vm.currencies, { lookupcode: 'USD'});
        }, 1000);


        vm.iamFilter = function(association){
            return association.flex2 === 'Y';
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
           // $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.billingPlan.id !== null) {
                BillingPlan.update(vm.billingPlan, onSaveSuccess, onSaveError);
            } else {
                BillingPlan.save(vm.billingPlan, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('mobilityexchangeApp:billingPlanUpdate', result);
           // $uibModalInstance.close(result);
            vm.isSaving = false;
            vm.billingPlan = result;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.effectiveDate = false;
        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.modifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
