(function() {
    //'use strict';
    angular
        .module('mobilityexchangeApp')
        .factory('ContactCommunicationpreference', ContactCommunicationpreference);

    ContactCommunicationpreference.$inject = ['$resource', 'DateUtils'];

    function ContactCommunicationpreference ($resource, DateUtils) {
        var resourceUrl =  'api/contact-communicationpreferences';

        return $resource(resourceUrl, {}, {            
        	 'save': {
                 method: 'PUT',
                params: {id: '@id'},
                 transformRequest: function (data) {
                     data.createddate = null;
                     //data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                     return angular.toJson(data);
                 }
             },
             'delete': {
                 method: 'DELETE'
                 
             } 
        });
    }
})();
