(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('QualityDetailController', QualityDetailController);

    QualityDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Quality', 'ServiceProviders', 'Lookups'];

    function QualityDetailController($scope, $rootScope, $stateParams, previousState, entity, Quality, ServiceProviders, Lookups) {
        var vm = this;

        vm.quality = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:qualityUpdate', function (event, result) {
            vm.quality = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
