(function () {
    // 'use strict';

    angular
        .module('mobilityexchangeApp')
        .config([
            '$compileProvider',
            function ($compileProvider) {
                $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|skype):/);
                // Angular before v1.2 uses
                // $compileProvider.urlSanitizationWhitelist(...)
            }
        ])
        .service('SPDataService', function () {
            var serviceProviderRef;

            var setSP = function (sp) {
                serviceProviderRef = sp;
            };

            var getSP = function () {
                return serviceProviderRef;
            };

            return {
                setSP: setSP,
                getSP: getSP
            };

        })
        .controller('SearchController', SearchController)
        .controller('SearchDetailsController', SearchDetailsController)

        .controller('PayController', ['$scope', '$http', '$location',
            'SPDataService', '$state', 'Principal',
            'LoginService', '$stateParams', 'entity', '$analytics',

            function ($scope, $http, $location,
                SPDataService, $state,
                Principal, LoginService/* , stripe */,
                $stateParams, entity, $analytics) {


                var vm = this;

                vm.processingPayment = false;

                vm.serviceProviders = entity;

                if (vm.serviceProviders)
                    vm.id = vm.serviceProviders.id;
                else
                    vm.id = null;

                vm.res_id = '';
                vm.paid = false;
                vm.responsemessage = '';
                vm.status = '';
                vm.login = login;
                vm.account = null;

                $scope.name;
                $scope.phone;
                $scope.email;
                $scope.number;
                $scope.expiry;
                $scope.cvc;

                vm.getFees = function () {
                    var fees = 990;
                    if (vm.serviceProviders.memberAssociations) {
                        for (var i = 0; i < vm.serviceProviders.memberAssociations.length; i++) {
                            var memberType = vm.serviceProviders.memberAssociations[i].memberType;
                            if (memberType !== null) {
                                var mFees = parseInt(memberType.flex4);
                                if (mFees < fees)
                                    fees = mFees;
                            }
                        }
                    }
                    return '$' + fees;
                };

                function login() {
                    LoginService.open();
                }

                vm.claimedStatusBadge = function () {
                    if (vm.serviceProviders && vm.serviceProviders.mxstatus.lookupcode === '2') {
                        return "timeline-badge success";
                    } else {
                        return "timeline-badge danger";
                    }
                };

                vm.validationStatusBadge = function () {
                    if (vm.serviceProviders && vm.serviceProviders.mxstatus.lookupcode === '3') {
                        return "timeline-badge greeen";
                    } else if (vm.serviceProviders && vm.serviceProviders.mxstatus.lookupcode < 3) {
                        return "timeline-badge danger";
                    }
                };

                vm.showClaimText = function () {
                    if (vm.serviceProviders && vm.serviceProviders.mxstatus.lookupcode === '1') {
                        return true;
                    } else {
                        return false;
                    }
                };

                getAccount();
                function getAccount() {
                    Principal.identity().then(function (account) {
                        vm.account = account;
                        vm.isAuthenticated = Principal.isAuthenticated;
                    });
                }

                vm.getSignAndAuthInStatus = function () {
                    if (vm.account !== null && vm.account.login !== null && vm.account.login !== 'anonymoususer') {
                        return true;
                    } else {
                        return false;
                    }
                };

                // $scope.processingPayment = false;

                $scope.handleStripe = function (status, response) {

                    vm.processingPayment = true;

                    if (response.error) {

                        if (!$scope.myForm.exp.$valid)
                            $scope.message = "INVALID EXPIRATION DATE";
                        else if (!$scope.myForm.cvc.$valid)
                            $scope.message = "INVALID CVS";
                        else if (!$scope.myForm.cardn.$valid)
                            $scope.message = "INVALID CARD NUMBER";
                        else
                            $scope.message = "There was an error processing the information you entered. " +
                                "Please check the information you entered and try again. " +
                                "If this problem persists, please contact support@mobilityex.com for assistance.";

                        $scope.status = "NOK";
                        $scope.paid = false;
                        $scope.responsemessage = $scope.message;
                        $scope.number = '';
                        $scope.expiry = '';
                        $scope.cvc = '';

                    } else {
                        vm.amount = vm.getFees();
                        vm.amount = vm.amount.replace("$", "");
                        var $payInfo = {
                            'token': response.id,
                            'name': $scope.name,
                            'phone': $scope.phone,
                            'email': $scope.email,
                            'number': $scope.number,
                            'expiry': $scope.expiry,
                            'cvc': $scope.cvc,
                            'amount': vm.amount
                        };

                        $http.post('/api/payreservation?spid=' + vm.id, $payInfo).success(function (data) {
                            vm.processingPayment = false;
                            if (data.status == "OK") {

                                $scope.paid = true;
                                $scope.status = "OK";
                                $scope.responsemessage = data.message;
                                $scope.number = '';
                                $scope.expiry = '';
                                $scope.cvc = '';
                                $scope.processingPayment = false;
                            } else {

                                $scope.paid = false;
                                $scope.status = "NOK";
                                $scope.responsemessage = data.message;
                                $scope.declinemessage = data.message;
                                $scope.number = '';
                                $scope.expiry = '';
                                $scope.cvc = '';
                            }
                        });
                    }
                };

                $scope.init = function () {
                    $scope.loaded = false;
                    $http.get('/api/reservation/' + $scope.res_id).success(function (data) {
                        // $scope.processingPayment = false;
                        $scope.reservation_info = data;
                        $scope.loaded = true;
                    });
                };

                $scope.init();
            }]
        );


    SearchDetailsController.$inject = ['$timeout', '$scope', 'entity', 'ServiceProviders',
        'Lookups', '$http', '$rootScope', '$location', '$anchorScroll',
        '$window', 'LoginService', '$state', 'SPDataService', '$log', 'Principal', '$analytics',
        'QuotationResponse', 'QuotationRequest', '$document', '$sce', '$stateParams', '$uibModal', 'DateUtils', '$filter'];

    var date = new Date();

    function SearchDetailsController($timeout, $scope, entity, ServiceProviders,
        Lookups, $http, $rootScope, $location, $anchorScroll, $window,
        LoginService, $state, SPDataService, $log, Principal, $analytics,
        QuotationResponse, QuotationRequest, $document, $sce, $stateParams, $uibModal, DateUtils, $filter) {

        $rootScope.showSearchBox = true;
        $rootScope.mainLogoLink = "#";

        var vm = this;
        vm.controllerName = "SearchDetailsController";
        vm.serviceProviders = entity.serviceProviders;
        vm.functionalRoles = Lookups.getByLookupType({ id: 12 });
        //SPDataService.setSP(vm.serviceProviders);

        vm.parentSP = entity.parentName;
        vm.validationRequestBtnLabel = "Validate My Listing";
        vm.login = login;
        vm.newAddress = false;
        vm.activeProfiles = [];
        vm.hasCredential = [];
        vm.relatedCompanies = null;
        vm.relatedCompaniesParentId = null;
        vm.relatedCompaniesParentLegalName = null;
        vm.showEdit = false;
        vm.showMap = false;
        vm.ctry = $stateParams.ctry;
        vm.todayDate = new Date();
        $scope.status = {
            contactopen: true
        }

        $scope.showMap = function () {
            vm.showMap = true;
        }

        Principal.identity().then(function (account) {
            $scope.account = account;
        });

        vm.countryCodes = Lookups.getByLookupType({ id: 50 }, ctryCodesSuccess, ctryCodesFailure);
        function ctryCodesSuccess() {
            vm.searchedCountry = $stateParams.ctry === undefined ? vm.serviceProviders.serviceproviderAddresses[0].country : $stateParams.ctry;
            vm.countryObj = _.find(vm.countryCodes, { lookupvalue: vm.searchedCountry });
            if (vm.countryObj) {
                $rootScope.countryGuideLink = vm.countryObj.flex4;
                if (vm.searchedCountry === '')
                    vm.searchedCountry = vm.countryObj.lookupvalue;
            }
        }

        function ctryCodesFailure() {
        }

        vm.pcgFlag = false;
        vm.setPCGFlags = function (sps) {
            if (sps && sps.compliance) {
                var com = sps.compliance;
                for (var j = 0; j < com.length; j++) {
                    if (com[j] && com[j].awardedto === 'PCG') {
                        vm.pcgFlag = true;
                    }
                }
            }
        };

        vm.setPCGFlags(vm.serviceProviders);

        vm.map2 = {
            center: {
                latitude: 40.1451,
                longitude: -99.6680
            },
            options: {
                minZoom: 2
            },
            zoom: 2,
            bounds: {
                northeast: {
                    latitude: 45.1451,
                    longitude: -80.6680
                },
                southwest: {
                    latitude: 30.000,
                    longitude: -120.6680
                }
            },
            markersEvents: {
                click: function (marker, eventName, model) {
                    vm.map2.window.model = model;
                    vm.map2.window.show = true;
                }
            },
            window: {
                marker: {},
                show: false,
                closeClick: function () {
                    vm.show = false;
                },
                options: {
                    minZoom: 2
                }
            }
        };

        vm.gotoBack = function () {
            $window.history.back();
        };

        $scope.uploadCert = [];
        $scope.uploadGuide = [];
        $scope.uploadMemberShip = [];
        $scope.established = [];
        $scope.profileImage = [];
        $scope.logoImage = [];

        $scope.getWebsitLink = function (sp, mbrAss, type) {
            if ($scope.isValidatedOrIamTrustedMxStatus(sp.mxstatus)) {

                if (mbrAss.website) {
                    return mbrAss.website;
                }

                return '/customer/images/' + mbrAss.memberAssociation.lookupvalue + '_' + type;
            } else {
                console.log('Not validated status, mbrAss: ' + mbrAss + ', association lookup value: ' + (mbrAss.memberAssociation && mbrAss.memberAssociation.lookupvalue) + ', type: ' + type);
                return '#';
            }
        }

        $scope.getUploadGuide = function (sp, destinationService) {

            if (sp && $scope.isValidatedOrIamTrustedMxStatus(sp.mxstatus)) {

                if (destinationService && destinationService.website) {
                    return destinationService.website;
                }
                return $scope.uploadGuide[destinationService.dspType.id];
            }
        }

        $scope.isValidatedOrIamTrustedMxStatus = function(mxStatus) {
            return mxStatus && (mxStatus.lookupcode === '4' || mxStatus.lookupcode === '5');
        }

        $scope.getContactQualDoc = function (sp, contact, qualification) {
            if ($scope.isValidatedOrIamTrustedMxStatus(sp.mxstatus)) {
                if (contact && contact.providerContactFiles) {

                    for (var j = 0; j < contact.providerContactFiles.length; j++) {

                        var fileItem = contact.providerContactFiles[j];
                        if (fileItem && fileItem.itemId == qualification.id) {
                            return _.replace(fileItem.imagePath, '/apps', '');
                        }
                    }
                }
            }
        }

        $scope.whatsappUrl = function (phone) {
            if (phone != null) {
                // Remove spaces and leading +, if present
                phone = phone.replace(/\s|\+/g, "");
                $window.open('https://api.whatsapp.com/send?phone=' + phone);
            }
        }

        $scope.whatsappUrl =
            function (phone, ga4EventName, ga4EventDetails) {
                if (phone != null) {
                    // Remove spaces and leading +, if present
                    phone = phone.replace(/\s|\+/g, "");
                    $window.open('https://api.whatsapp.com/send?phone=' + phone);
                }
            }

        $scope.openWebsite =
            function (website, target, ga4EventName, ga4EventDetails) {
                $window.open(website, target);
            }

        $scope.skypeUrl =
            function (contact, type, ga4EventName, ga4EventDetails) {

                if ($scope.account.login != null && $scope.account.login != 'anonymoususer') {
                    if (vm.isProviderContactAvailable(contact)) {
                        $window.open('skype:' + contact.skype + '?' + type);
                    } else {
                        if (contact.timezone) {
                            var localTime = DateUtils.getLocalDateTime(contact.timezone.flex1);
                            var days = '';
                            if (contact.skypeMon) days = days + 'Mon&nbsp;';
                            if (contact.skypeTue) days = days + 'Tue&nbsp;';
                            if (contact.skypeWed) days = days + 'Wed&nbsp;';
                            if (contact.skypeThr) days = days + 'Thr&nbsp;';
                            if (contact.skypeFri) days = days + 'Fri&nbsp;';
                            if (contact.skypeSat) days = days + 'Sat&nbsp;';
                            if (contact.skypeSun) days = days + 'Sun&nbsp;';
                            var body = '<fieldset class="scheduler-border"><legend class="scheduler-border">Normal Business Hours</legend>'
                                + '<table><tr>'
                                + '<td><span style="height: 25px;"> Local time:&nbsp;</span></td>'
                                + '<td><a style="pointer-events: none;">' + localTime + '</a></td>'
                                + '</tr>'
                                + '<tr>'
                                + '<td><span style="height: 25px;"> Available days:&nbsp;</span></td>'
                                + '<td><a style="pointer-events: none;">' + days + '</a></td>'
                                + '</tr>'
                                + '<tr>'
                                + '<td><span style="height: 25px;"> Office hours:&nbsp; </span></td>'
                                + '<td><a style="pointer-events: none;">' + contact.skypeTimeFrom + ' - ' + contact.skypeTimeTo + ' hrs</a></td>'
                                + '</tr></table></fieldset>';
                        }
                        var modalInstance = $uibModal.open({
                            template: '<div class="modal-header"><h4>Currently Not Available!</h4></div>'
                                + '<div class="modal-body">' + body + '</div>'
                                + '<div class="modal-footer"><button class="btn btn-primary" ng-click="ok()">OK</button></div>',
                            controller: 'MembershipModalContentCtrl'
                        });
                    }
                } else {
                    LoginService.open();
                }
            }

        vm.isProviderContactAvailable = function (contact) {

            if (!contact.timezone || (!contact.skypeTimeFrom && !contact.skypeTimeTo)) {
                return true;
            }

            if (contact.timezone && !contact.skypeDisable) {
                var date = DateUtils.getLocalDateTime(contact.timezone.flex1);
                var nd = DateUtils.getUTCDate(contact.timezone.flex1);
                var dateTo = nd.getFullYear() + '/' + (nd.getMonth() + 1) + '/' + nd.getDate() + ' ' + contact.skypeTimeTo + ':' + nd.getSeconds();
                var dateFrom = nd.getFullYear() + '/' + (nd.getMonth() + 1) + '/' + nd.getDate() + ' ' + contact.skypeTimeFrom + ':' + nd.getSeconds();

                if (Date.parse(date) >= Date.parse(dateFrom) && Date.parse(date) <= Date.parse(dateTo)) {
                    var day = nd.getDay();

                    switch (day) {
                        case 1:
                            if (contact.skypeMon) return true;
                            break;
                        case 2:
                            if (contact.skypeTue) return true;
                            break;
                        case 3:
                            if (contact.skypeWed) return true;
                            break;
                        case 4:
                            if (contact.skypeThr) return true;
                            break;
                        case 5:
                            if (contact.skypeFri) return true;
                            break;
                        case 6:
                            if (contact.skypeSat) return true;
                            break;
                        case 0:
                            if (contact.skypeSun) return true;
                            break;
                    }
                }
            }
            return false;
        }
        $scope.getContactProfilePic = function (contact) {

            if (contact && contact.providerContactFiles) {

                for (var j = 0; j < contact.providerContactFiles.length; j++) {

                    var fileItem = contact.providerContactFiles[j];
                    if (fileItem && fileItem.itemId == 1) {
                        return _.replace(fileItem.imagePath, '/apps', '');

                    }
                }
            }

            return '/content/images/img_avatar.png';
        }

        $scope.getFileRef = function () {
            try {
                if (vm.serviceProviders.serviceproviderFiles) {
                    for (i = 0; i < vm.serviceProviders.serviceproviderFiles.length; i++) {

                        if (vm.serviceProviders.serviceproviderFiles[i]
                            && vm.serviceProviders.serviceproviderFiles[i].active === true
                        ) {

                            var tkns = _.split(vm.serviceProviders.serviceproviderFiles[i].imageName, '_');
                            var spid = tkns[0];
                            var itemId = tkns[1];
                            var itemType = vm.serviceProviders.serviceproviderFiles[i].image_type;
                            var path = _.replace(vm.serviceProviders.serviceproviderFiles[i].imagePath, '/apps', '');
                            console.log('Processing service provider file, index: ' + i + ', itemType: ' + itemType + ', itemId: ' + itemId + ', path: ' + path);
                            if (itemType === 'certificate') {
                                $scope.uploadCert[itemId] = path;
                                console.log('Processed service provider file, index: ' + i + ', itemType: ' + itemType + ', itemId: ' + itemId + ', path: ' + path + ', upload cert: ' + $scope.uploadCert[itemId]);
                            }
                            if (itemType === 'guide') {
                                $scope.uploadGuide[itemId] = path;
                                var altItemId = vm.serviceProviders.serviceproviderFiles[i].itemId;
                                $scope.uploadGuide[altItemId] = path;
                            }
                            if (itemType === 'membership')
                                $scope.uploadMemberShip[itemId] = path;
                            if (itemType === 'established')
                                $scope.established[spid] = path;
                            if (itemType === 'profile')
                                $scope.profileImage[spid] = path;
                            if (itemType === 'logo')
                                $scope.logoImage[spid] = path;

                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }
        };

        $scope.destinationServicesAccordionCheck = function () {
            if ($rootScope.goToDestinationServicesAccordion) {
                $scope.status.destinatonservicesopen=true;
                $rootScope.goToDestinationServicesAccordion = false;
            }
        }

        $scope.getFileRef();
        $scope.destinationServicesAccordionCheck();

        vm.scrollTo = function (id) {
            $location.hash(id);
            $anchorScroll();
        };

        vm.goToDestinationServicesAccordion = function(open) {
            vm.scrollTo('destinationServicesId');
            if (open) {
                $scope.status.destinatonservicesopen=true;
            }
        }

        // the button will always be
        vm.canRequestValidation = function () {
            return vm.serviceProviders.mxstatus.lookupcode < 3;
        };

        setValidationBtnLabel();
        function setValidationBtnLabel() {
            if (vm.serviceProviders.mxstatus.lookupcode === '3') {
                vm.validationRequestBtnLabel = "Validation Underway";
            }
        }

        vm.getValidationRequestUnderwayStatus = getValidationRequestUnderwayStatus;
        function getValidationRequestUnderwayStatus() {
            if (vm.serviceProviders.mxstatus.lookupcode === '3') {
                return true;
            }
            else {
                return false;
            }
        }

        vm.getFunctionalRoleLabel = function (id) {
            var i = 0;
            for (i = 0; i < vm.functionalRoles.length; i++) {
                if (vm.functionalRoles[i].id === id) {
                    return vm.functionalRoles[i].lookupvalue;
                }
            }
        };

        vm.getBusinessRegistrationFile = function (files) {
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                if (file.image_type === 'business_reg') {
                    var path = '';
                    if (file.imagePath)
                        path = file.imagePath.replace('/apps', '')
                    return path;
                }
            }
            return '';
        }

        vm.getIamMembershipsLogo = function (memberAssociations, companydescription) {
            for (var i = 0; i < memberAssociations.length; i++) {
                var memberAssoc = memberAssociations[i];
                if (memberAssoc.memberAssociation.id === 800 && memberAssoc.memberStatus.id === 1201) {
                    if (memberAssoc.memberType.id === 1660 || memberAssoc.memberType.id === 1676) {
                        return '/content/images/coremember.png';
                    } else if (memberAssoc.memberType.id === 1661) {
                        return '/content/images/governingmember.png';
                    } else if (memberAssoc.memberType.id === 1662) {
                        return '/content/images/suppliermember.png';
                    } else if (memberAssoc.memberType.id === 5555) {
                        return '/content/images/IAM-premier-icon.png';
                    } else if (memberAssoc.memberType.id === 5556 || memberAssoc.memberType.id === 1678 || memberAssoc.memberType.id === 1679) {
                        return '/content/images/IAM-core-icon.png';
                    } else if (memberAssoc.memberType.id === 5557) {
                        return '/content/images/IAM-value-icon.png';
                    } else if (memberAssoc.memberType.id === 5558) {
                        return '/content/images/IAM-industry-partner-icon.png';
                    }
                }
            }

            if (companydescription === 'Student Member'){
                return '/content/images/IAM_student.png';
            }

            if (companydescription === 'Industry Veteran'){
                return '/content/images/IAM_veteran.png';
            }

            return '/content/images/IAM_non.png';
        }

        vm.getDabLogo = function(memberAssociations) {
            return vm.getMembershipLogo(memberAssociations, 927, '/content/images/IAMDAB_Icon.png');
        }

        vm.hasDab = function(memberAssociations) {
            return vm.hasMembership(memberAssociations, 927);
        }

        vm.getDabAllyLogo = function(memberAssociations) {
            return vm.getMembershipLogo(memberAssociations, 951, '/content/images/IAMDABAlly_Icon.png');
        }

        vm.hasDabAlly = function(memberAssociations) {
             return vm.hasMembership(memberAssociations, 951);
        }

        vm.hasDestinationServices = function (serviceProvider) {
            return serviceProvider.mxstatus &&
                (serviceProvider.mxstatus.lookupcode === '3' || serviceProvider.mxstatus.lookupcode === '4' || serviceProvider.mxstatus.lookupcode==='5') &&
                serviceProvider.destinationServices && serviceProvider.destinationServices.length > 0;
        }

        vm.getIsaMembershipLogo = function(memberAssociations) {
            return vm.getMembershipLogo(memberAssociations, 866, '/content/images/ISA_membership.png');
        }

        vm.hasIsaMembership = function(memberAssociations) {
            return vm.hasMembership(memberAssociations, 866);
        }

        vm.hasMembership = function(memberAssociations, memberAssociationId) {
            if (!memberAssociations) {
                return false;
            }

            for (var i = 0; i < memberAssociations.length; i++) {
                var memberAssoc = memberAssociations[i];
                if (memberAssoc.memberAssociation.id === memberAssociationId && memberAssoc.memberStatus.id === 1201) {
                    return true;
                }
            }

            return false;
        }

        vm.getMembershipLogo = function(memberAssociations, memberAssociationId, logo) {
            if (!memberAssociations) {
                return '';
            }

            for (var i = 0; i < memberAssociations.length; i++) {
                var memberAssoc = memberAssociations[i];
                if (memberAssoc.memberAssociation.id === memberAssociationId && memberAssoc.memberStatus.id === 1201) {
                    return logo;
                }
            }

            return '';
        }

        vm.requestValidation = function () {

            $http.get('/api/can-request-validation?id=' + vm.serviceProviders.id)
                .then(canRequestValidation, cannotRequestValidation);
        };

        vm.hasOnlineRates = function (serviceProvider) {
            return serviceProvider.ratePortalAddress && (serviceProvider.mxstatus.lookupcode === '3' || serviceProvider.mxstatus.lookupcode === '4' || serviceProvider.mxstatus.lookupcode==='5');
        }

        function canRequestValidation() {
            $state.go('howtovalidate', {
                id: vm.serviceProviders.id,
                mxstatus: vm.serviceProviders.mxstatus.lookupcode
            }, { reload: true });
        }

        $scope.cannotRequestValidation;

        function cannotRequestValidation() {
            $scope.cannotRequestValidation
                = "You are not an authorized user of " + vm.serviceProviders.companylegalname
                + ". Please contact support@mobilityex.com for assistance.";
        }

        function login() {
            LoginService.open();
        }

        if (_.isEmpty(vm.serviceProviders.serviceproviderAddresses)) {
            vm.newAddress = true;
        }

        for (var i = 0; i < vm.serviceProviders.serviceproviderContacts.length; i++) {
            if(vm.serviceProviders.serviceproviderContacts[i].publicDisplay){
                vm.activeProfiles.push(vm.serviceProviders.serviceproviderContacts[i]);
                vm.hasCredential[vm.serviceProviders.serviceproviderContacts[i].id] = false;
                for (var j = 0; j < vm.serviceProviders.serviceproviderContacts[i].contactQualifications.length; j++) {
                    var q = vm.serviceProviders.serviceproviderContacts[i].contactQualifications[j];
                    if (q.qualification && q.qualification.lookuptypes
                        && q.qualification.lookuptypes.id === 33
                        && q.qualification.flex4
                        && q.qualification.flex4 === 'Y' && q.expDate
                        && new Date(q.expDate) >= new Date() && q.displayCert) {
                        vm.hasCredential[vm.serviceProviders.serviceproviderContacts[i].id] = true;
                        break;
                    }
                }
            }
        }

        vm.isValidPhoneNumber = function (phoneNumber) {
            if (!_.isEmpty(phoneNumber) && _.size(phoneNumber) > 6) {
                return true;
            }
            return false;
        };

        vm.getYearsOfService = function (contact) {
            var currentYear = new Date().getFullYear();
            var yearOfEmployment = contact.employyears;
            return (currentYear - yearOfEmployment);
        };

        vm.scrollTo = function (id) {
            $location.hash(id);
            $anchorScroll();
        };

        /*
        vm.goToDestinationServicesAccordion(open) = function() {
            vm.scrollTo('destinationServicesId');
            if (open) {
                $scope.status.destinatonservicesopen=true;
            }
        }
        */

        getShowEditButton();
        function getShowEditButton() {
            $http.get('/api/hasadminroleandisspadmin?spId=' + vm.serviceProviders.serviceProvidersId).then(function (response) {
                if (response.data.hasAdminAndSPAdmin === 'true') {
                    vm.showEdit = 'true';
                }
                else {
                    vm.showEdit = 'false';
                }
            });
        }

       // getRelatedProviders();

        function getRelatedProviders() {
            $http.get('api/_search/related-service-providers', {
                params: {
                    id: vm.serviceProviders.serviceProvidersId,
                    query: 'q= ',
                    sensor: false
                }
            }).then(function (response) {
                vm.noresults = false;
                vm.srd = response.data;
                vm.relatedCompanies = response.data;
                // SearchService.setValue(vm.sr);results.serviceproviderAddresses
                var markers = [];
                _.forEach(vm.srd, function (results, key) {
                    if (results && results.serviceproviderAddresses && results.serviceproviderAddresses[0]) {
                        var latlng = _.split(results.serviceproviderAddresses[0].geocode, ',');
                        var latitude = latlng[0];
                        var longitude = latlng[1];
                        var ret = {
                            latitude: latitude,
                            longitude: longitude,
                            title: 'm' + key,
                            options: {
                                /* label : ''+String.fromCharCode(i++) */
                            },
                            content: {
                                title: results.companylegalname,
                                idKey: results.id,
                                address: results.serviceproviderAddresses
                            }
                        };
                        ret["id"] = key;
                        markers.push(ret);
                    }
                    // TODO: Set parentId and parentName
                    console.log('Check for related companies parent');
                    if (results.id === results.serviceProvidersId) {
                        vm.relatedCompaniesParentId = results.id;
                        vm.relatedCompaniesParentLegalName = results.companylegalname;
                        console.log('Related companies parent id: ' + vm.relatedCompaniesParentId + ', parent legal name: ' + vm.relatedCompaniesParentLegalName);
                    }
                });
                if (!_.isUndefined(markers) && markers.length <= 2) {
                    vm.map2.zoom = 6;
                    vm.map2.center.latitude = markers[0].latitude;
                    vm.map2.center.longitude = markers[0].longitude;
                } else {
                    vm.map2.center.latitude = markers[0].latitude;
                    vm.map2.center.longitude = markers[0].longitude;
                    vm.map2.zoom = 3;
                }
                vm.randomMarkers2 = markers;
            });
            // ///

        }

        /**
		 * Checks expiring condition.
		 *
		 * @param dateString -
		 *            Date in ISO string format.
		 * @param daysLeft -
		 *            Number of days left to expiration when this date is
		 *            counted as expiring soon.
		 */

        vm.isExpiringSoon = function (dateString, daysLeft) {
            return Math.ceil((new Date(dateString).getTime() - new Date().getTime()) / (1000 * 3600 * 24)) < daysLeft;

        };

        vm.sendMail = function (emailId, subject, message) {
            $window.open("mailto:" + emailId + "?subject=" + subject + "&body=" + message, "_self");
        };

        vm.mxEmail = function (emailid) {
            vm.sendMail(emailid, "Query from IAM Mobility Exchange", "");
        };

        vm.mxEmail =
            function (emailid, ga4EventName, ga4EventDetails) {
                vm.sendMail(emailid, "Query from IAM Mobility Exchange", "");
            };

        vm.setGlobalGA4Params = function (ga4EventDetails) {
            if(!_.isEmpty(vm.serviceProviders) && !_.isEmpty(vm.serviceProviders.companylegalname) && _.isEmpty(ga4EventDetails.sp_legal_name)){
                ga4EventDetails.sp_legal_name = vm.serviceProviders.companylegalname;
            }
            ga4EventDetails.iamx_search_location = $stateParams.loc;
            ga4EventDetails.iamx_search_country = $stateParams.ctry;
            ga4EventDetails.iamx_search_term = $stateParams.svc;
        }

        vm.generateEventGa4 = function (ga4EventName, ga4EventDetails) {
            vm.setGlobalGA4Params(ga4EventDetails);
            if($rootScope.supplierPortal === true){
                ga4EventDetails = _.mapKeys(ga4EventDetails, function(value,key) { return key+'_SUP';} );
                ga4EventName = ga4EventName+'_SUP' ;
            }
            $window.gtag('event', ga4EventName, ga4EventDetails);
        };

        $scope.nonActiveFilter = function (association) {

            if (association.memberType && association.memberStatus) {

                if (association.memberType.flex2 === 'Y' || association.memberType.flex2 === 'A' || association.memberType.flex6 !== 'Y') {

                    if (association.memberStatus.id === 1200 || association.memberStatus.id === 1202) {
                        return false;
                    } else if (association.memberType.flex7 === 'N') {
                        return false;
                    }
                }
            }

            return true;
        };

        /** ****** */
        vm.popupUrl = 'req_templ.html';
        vm.quotationRequests = [];

        vm.mxConnectClickedOnSpId;
        vm.noQuotationRequests;
        vm.mxConnectedSPNames = [];
        vm.showCreateQuoteFromMXConnect = true;
        vm.mxConnectClickedOnSpName;

        vm.quoteSentMessage = "";
        vm.mxConnectClickedOnSpName = "";
        vm.quotationRequestId = [];
        vm.spAlreadySelected = "";
        vm.mxConnectedLimit = false;
        vm.mxConnectedLimitMessage = "";

        vm.spSelectedForCircle = null;

        vm.addToCircle = function () {

        };

        vm.removeFromCircle = function () {

        };

        vm.getQuoteReqData = function (spId, companylegalname) {
            vm.spSelectedForCircle = spId;
        };

        vm.isAlreadyAssignedVar = [];

        vm.isAlreadyAssigned = function (quotationRequest) {

            if (quotationRequest.quotationResponses) {
                for (var i = 0; i < quotationRequest.quotationResponses.length; i++) {
                    if (quotationRequest.quotationResponses[i].serviceproviderId === vm.mxConnectClickedOnSpId) {
                        vm.isAlreadyAssignedVar[quotationRequest.id] = true;
                        return true;
                    }
                }
            }
            vm.isAlreadyAssignedVar[quotationRequest.id] = false;
            return false;
        };

        vm.assignedSPNames = [];

        vm.getSPNames = function (id) {
            var spNames = _.find(vm.mxConnectedSPNames, { quoteRequestId: id });
            if (!_.isUndefined(spNames)) {
                vm.assignedSPNames[id] = spNames.spNames;
            }
        };

        vm.showquote = true;
        vm.showpartner = false;
        vm.showmessage = false;

        vm.showMXConnectContent = function (section) {
            if (section === 1) {
                vm.showquote = true;
                vm.showpartner = false;
                vm.showmessage = false;
            }
            if (section === 2) {
                vm.showquote = false;
                vm.showpartner = true;
                vm.showmessage = false;
            }
            if (section === 3) {
                vm.showquote = false;
                vm.showpartner = false;
                vm.showmessage = true;
            }

            section = true;
        };

        vm.quoteSentMessage = "";
        vm.mxConnectClickedOnSpName = "";
        vm.quotationRequestId = [];
        vm.spAlreadySelected = "";
        vm.mxConnectedLimit = false;
        vm.mxConnectedLimitMessage = "";

        vm.quoteReqSaved = null;

        vm.saveReponse = function (req) {

            if (vm.mxConnectedSPNames) {

                var spNames = _.find(vm.mxConnectedSPNames, { quoteRequestId: req.id });

                if (spNames && spNames.spNames && (spNames.spNames.split(",").length >= 4)) {
                    vm.mxConnectedLimit = true;
                    vm.quotationRequestId[req.id] = null;
                    vm.quoteSentMessage = "(MX) Connect limits the number of service providers that can be selected for an RFQ." +
                        "We respect the effort and expense with the RFQ response and suggest careful selection of service providers " +
                        "using the IAMX search and filtering tools";
                    return false;
                }
            }

            var spId = vm.mxConnectClickedOnSpId;
            vm.quotationResponse = {};
            vm.quotationResponse.quotationRequest = req;
            vm.quotationResponse.serviceproviderId = Number(spId);
            vm.quotationResponse.responseStatus = 41;
            vm.quoteReqSaved = req;

            QuotationResponse.save(vm.quotationResponse, sentSuccess, sentFailed);
            vm.quoteSentMessage = "Rate Quote request " + req.requestId + " sent to " + vm.mxConnectClickedOnSpName + ".";
        };

        function sentSuccess(result) {
            vm.quoteReqSaved.quotationResponses.push(result);
            vm.isAlreadyAssigned(vm.quoteReqSaved);
            vm.getSPNames(vm.quoteReqSaved.id);
        };

        function sentFailed(result) {
        };

        function isMobile() {
            if ($window.innerWidth < 500) {
                vm.isCollapsed = "collapse";
                vm.isMobile = true;
            } else {
                vm.isCollapsed = "collapse in";
                vm.isMobile = false;
            }
        }


        /** ******** */

        $scope.certlUrl = "cert_pop_detail.html";
        $scope.downloadCert = function (event, membership) {

            event.preventDefault();
            event.stopPropagation();

            $http.get('api/generate-certificate/' + membership + '_CERT/' + vm.serviceProviders.id + '/%20', { responseType: 'arraybuffer' }).then(function successCallback(data) {

                var file = new Blob([data.data], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);

            }, function errorCallback(response) {
                $uibModal.open({
                    templateUrl: "app/search/search-link-dialog.html",
                    controller: "SearchLinkDialogController",
                    size: '',
                });
            });
        };

        $scope.downloadFmcCert = function (event, organization) {

            event.preventDefault();
            event.stopPropagation();

            $http.get('api/generate-fmc/' + organization, { responseType: 'arraybuffer' }).then(function successCallback(data) {

                var file = new Blob([data.data], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);

            }, function errorCallback(response) {
                $uibModal.open({
                    templateUrl: "app/search/search-link-dialog.html",
                    controller: "SearchLinkDialogController",
                    size: '',
                });
            });
        };

        $scope.downloadletter = function (event) {

            event.preventDefault();
            event.stopPropagation();

            $http.get('api/generate-certificate/IAM_LETTER/' + vm.serviceProviders.id + '/%20', { responseType: 'arraybuffer' }).then(function successCallback(data) {

                var file = new Blob([data.data], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);

            }, function errorCallback(response) {

            });
        };

        $scope.hasAlerts = function(adlTrackers) {
            if (_.isEmpty(adlTrackers)) {
                return null;
            }

            return  _.some(adlTrackers, $scope.isActiveAlert);
        }

        $scope.isActiveAlert = function(adlTracker) {
            var started = adlTracker.dateStart && ((new Date()).getTime() - Date.parse(adlTracker.dateStart) >= 0);
            var notEnded = !adlTracker.dateEnd || ((new Date()).getTime() - Date.parse(adlTracker.dateEnd) < 0);
            return started && notEnded;
        }

        $scope.adlTrackerAlertModalInstance = null;
        $scope.showAlert = function(sp) {
            // var alert = $scope.findFirstAlert(sp.adlTrackers);
            var alert = $scope.findMostRecentAlert(sp.adlTrackers);

            $scope.alertPostedDate = alert ? $filter('date')(alert.dateStart, 'yyyy-MMM-dd') : 'N/A';
            $scope.alertPublicDisplayNotes = alert ? alert.notesPublic : 'N/A';

            $scope.adlTrackerAlertModalInstance = $uibModal.open({
                templateUrl: 'app/entities/adl-tracker/adl-tracker-alert-dialog.html',
                scope: $scope
            });
        }

        $scope.ok = function () {
            $scope.adlTrackerAlertModalInstance.close("Ok");
        }

        $scope.cancel = function () {
            $scope.adlTrackerAlertModalInstance.dismiss();
        }

        $scope.findFirstAlert = function(adlTrackers) {
            if (_.isEmpty(adlTrackers)) {
                return null;
            }
            return _.find(adlTrackers, $scope.isActiveAlert);
        }

        $scope.findMostRecentAlert = function(adlTrackers) {
            if (_.isEmpty(adlTrackers)) {
                return null;
            }

            var activeAdlTrackers = _.filter(adlTrackers, $scope.isActiveAlert);
            if (_.isEmpty(activeAdlTrackers)) {
                return null;
            }

            var sortedAdlTrackers = _.sortBy(activeAdlTrackers,
                function(adlTracker) {
                    // Sort in descending order
                    return -Date.parse(adlTracker.dateStart);
                }
            );

            return _.isEmpty(sortedAdlTrackers) ? null : sortedAdlTrackers[0];
        }

    }

    SearchController.$inject = ['$scope', '$state',
        '$http', '$location', '$anchorScroll', '$timeout', '$rootScope', '$stateParams', '$window',
        'ParseLinks', 'Lookups', '$analytics', 'QuotationResponse', '$uibModal', 'DateUtils', '$filter'];

    function SearchController($scope, $state,
        $http, $location, $anchorScroll, $timeout, $rootScope, $stateParams, $window,
        ParseLinks, Lookups, $analytics, QuotationResponse, $uibModal, DateUtils, $filter) {

        $rootScope.showSearchBox = true;
        $rootScope.mainLogoLink = "#";
        $rootScope.expandedView = _.isUndefined($rootScope.expandedView) ? false : $rootScope.expandedView;
        $rootScope.exptext = "Expand ";
        $rootScope.image_path = "";
        $rootScope.showSearchBox = true;
        $rootScope.mainLogoLink = "#";

        var vm = this;
        vm.controllerName = "SearchController";
        vm.memberCapabilities = Lookups.getByLookupType({ id: 2 });
        vm.movingCapabilities = Lookups.getByLookupType({ id: 5 });
        vm.relocationCapabilities = Lookups.getByLookupType({ id: 6 });

        vm.industryPartnerCapabilities = Lookups.getByLookupType({ id: 7 });
        vm.industrySupplierCapabilities = Lookups.getByLookupType({ id: 8 });

        vm.associations = Lookups.getByLookupType({ id: 9 });
        vm.quality = Lookups.getByLookupType({ id: 11 });
        vm.usGovt = Lookups.getByLookupType({ id: 19 });
        vm.languageCapabilities = Lookups.getByLookupType({ id: 53 });
        vm.compliance = Lookups.getByLookupType({ id: 23 });
        vm.contactQualifications = Lookups.getByLookupType({ id: 33 });
        vm.destinationServices = Lookups.getByLookupType({id: 95});

        vm.todayDate = new Date();

        var svcParam = $stateParams.svc;
        var locParam = $stateParams.loc;
        var ctryParam = $stateParams.ctry;
        var lat = $stateParams.lat;
        var lng = $stateParams.lng;
        var range = $stateParams.range;
        var spcParam = $stateParams.spc;
        var supplierParam = $stateParams.supplier;

        vm.location = locParam;
        $scope.globe = true;
        vm.countryCodes = Lookups.getByLookupType({ id: 50 }, ctryCodesSuccess, ctryCodesFailure);
        function ctryCodesSuccess() {
            vm.searchedCountry = $stateParams.ctry;
            vm.countryObj = _.find(vm.countryCodes, { lookupvalue: vm.searchedCountry });
            if (vm.countryObj)
                $rootScope.countryGuideLink = vm.countryObj.flex4;
        }

        function ctryCodesFailure() {
        }

        vm.showSlider = true;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.itemsPerPage = 30;
        vm.clear = clear;
        vm.search = search;
        vm.currYear = date.getFullYear();
        vm.mapDataLoading = false;

        vm.isCollapsed = "collapse";
        vm.isMobile = false;
        vm.req_sp_id = '';
        vm.popupUrl = 'req_templ.html';
        vm.quotationRequests = [];

        vm.mxConnectClickedOnSpId;
        vm.noQuotationRequests;
        vm.mxConnectedSPNames = [];
        vm.showCreateQuoteFromMXConnect = true;
        vm.mxConnectClickedOnSpName;

        $scope.getSubscriptionControl = function () {
            var supplierQuery = $stateParams.supplier ? ('&supplier=' + $stateParams.supplier) : '';

            $http
                .get('/api/subscription-control-search?loc=' + locParam + '&ctry=' + ctryParam + '&svc=' + svcParam + '&lat=' + lat + '&lng='
                    + lng + '&page=' + $stateParams.page + '&assoc=' + $stateParams.assocs + supplierQuery)
                .then(
                    function (response) {
                        vm.endorsment = response.data.serviceprovider;
                        vm.endorsment.resultTagline = response.data.resultTagline;

                        if (vm.endorsment) {
                            vm.generateEventGa4('IAMX Result Ad', {'sp_legal_name': vm.endorsment.companylegalname});
                            if ($scope.sr) {
                                $scope.sr = $scope.sr.filter(function (obj) {
                                    return obj.id !== vm.endorsment.id;
                                });
                            }
                        }
                    });
        };

        $scope.openWebsite =
            function (website, target, ga4EventName, ga4EventDetails) {
                $window.open(website, target);
            }

        $scope.supplierAdSearch = function () {
            var supplierQuery = $stateParams.supplier ?  ('&supplier=' + $stateParams.supplier) : '';

            $http
                .get('/api/supplier-ad-search?loc=' + locParam + '&ctry=' + ctryParam + '&svc=' + svcParam + '&lat=' + lat + '&lng='
                    + lng + '&page=' + $stateParams.page + '&assoc=' + $stateParams.assocs + supplierQuery)
                .then(
                    function (response) {
                        vm.supplierAd = response.data;
                        vm.supplierAd.resultTagline = response.data.resultTagline;

                        if (vm.supplierAd) {
                            vm.generateEventGa4('IAMX Supplier Ad', {'sp_legal_name': vm.supplierAd.serviceprovider.companylegalname});
                            if ($scope.sr) {
                                $scope.sr = $scope.sr.filter(function (obj) {
                                    return obj.id !== vm.supplierAd.id;
                                });
                            }
                        }
                    });
        };

        vm.setGlobalGA4Params = function (ga4EventDetails) {
            if(!_.isEmpty(vm.serviceProviders) && !_.isEmpty(vm.serviceProviders.companylegalname) && _.isEmpty(ga4EventDetails.sp_legal_name)){
                ga4EventDetails.sp_legal_name = vm.serviceProviders.companylegalname;
            }
            ga4EventDetails.iamx_search_location = $stateParams.loc;
            ga4EventDetails.iamx_search_country = $stateParams.ctry;
            ga4EventDetails.iamx_search_term = $stateParams.svc;
        }

        vm.generateEventGa4 = function (ga4EventName, ga4EventDetails) {
            vm.setGlobalGA4Params(ga4EventDetails);
            if($rootScope.supplierPortal === true){
                // ga4EventDetails = _.mapKeys(ga4EventDetails, (value,key) =>  key+'_SUP' );
                ga4EventDetails = _.mapKeys(ga4EventDetails, function(value,key) { return key+'_SUP';} );
                ga4EventName = ga4EventName+'_SUP' ;
            }
            $window.gtag('event', ga4EventName, ga4EventDetails);
        };

        vm.getQuoteReqData = function (spId, companylegalname) {

            vm.mxConnectClickedOnSpId = spId;
            vm.mxConnectClickedOnSpName = companylegalname;

            vm.quoteSentMessage = null;

            $http.get('api/published-quotation-requests', {
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_SP_PRICING']
                }

            }).then(
                function (response) {

                    var eulaAccepted = response.headers('x-rfq-eula-not-accepted');
                    if (eulaAccepted) {
                        $state.go('disclaimer-detail', { id: parseInt(eulaAccepted) }, { reload: true });
                    }

                    vm.quotationRequests = response.data;
                    if (!vm.quotationRequests || (vm.quotationRequests && vm.quotationRequests.length == 0)) {
                        vm.noQuotationRequests = "(MX) Connect provide an efficient way to send a rate request (RFQ) to up " +
                            "to 3 selected service providers. To start, click the link below to create your rate request.  " +
                            "Once your rate request is published, you may select up to 3 service providers to send the request to.";
                        vm.showCreateQuoteFromMXConnect = true;
                    }
                    $http.post('api/quotation-requests-sps', vm.quotationRequests).then(
                        function (response) {
                            if (response && response.data) {
                                vm.mxConnectedSPNames = response.data;
                                for (var i = 0; i < vm.quotationRequests.length; i++) {
                                    vm.getSPNames(vm.quotationRequests[i].id);
                                }
                            }
                        }
                    );
                }
            )
                .catch(function (data) {
                    if (data.status === 403) {

                        vm.noQuotationRequests = "Please Login to access (MX) Connect.  " +
                            "(MX) Connect provides an efficient way to send a rate request (RFQ) to up to 3 selected service providers. ";

                        vm.showCreateQuoteFromMXConnect = false;
                    } else {
                        vm.noQuotationRequests = "There was an error accessing (MX) Connect. " +
                            "Please contact support@mobilityex.com if this problem persists.";
                        vm.showCreateQuoteFromMXConnect = false;
                    }
                });
        };

        vm.isAlreadyAssignedVar = [];

        vm.isAlreadyAssigned = function (quotationRequest) {

            if (quotationRequest.quotationResponses) {
                for (var i = 0; i < quotationRequest.quotationResponses.length; i++) {
                    if (quotationRequest.quotationResponses[i].serviceproviderId === vm.mxConnectClickedOnSpId) {
                        vm.isAlreadyAssignedVar[quotationRequest.id] = true;
                        return true;
                    }
                }
            }
            vm.isAlreadyAssignedVar[quotationRequest.id] = false;
            return false;
        };

        $scope.hasSkype = function (sp) {
            if (sp.serviceproviderContacts) {
                for (var i = 0; i < sp.serviceproviderContacts.length; i++) {
                    var providerContact = sp.serviceproviderContacts[i];
                    if (providerContact.publicDisplay === true && providerContact.skype && providerContact.skype.length > 2) {
                        return true;
                    }
                }
            }
            return false;
        }

        $scope.hasWhatsapp = function (sp) {
            if (sp.serviceproviderContacts) {
                for (var i = 0; i < sp.serviceproviderContacts.length; i++) {
                    var providerContact = sp.serviceproviderContacts[i];
                    if (providerContact.publicDisplay === true && providerContact.whatsapEnabled) {
                        return true;
                    }
                }
            }
            return false;
        }

        $scope.getFileLink = function (sp, type, id) {

            if (sp && sp.serviceproviderFiles && $scope.isValidatedOrIamTrustedMxStatus(sp.mxstatus)) {
                for (var i = 0; i < sp.serviceproviderFiles.length; i++) {

                    if (sp.serviceproviderFiles[i]
                        && sp.serviceproviderFiles[i].active === true
                    ) {

                        var tkns = _.split(sp.serviceproviderFiles[i].imageName, '_');
                        var spid = tkns[0];
                        var itemId = tkns[1];
                        var itemType = sp.serviceproviderFiles[i].image_type;
                        var path = _.replace(sp.serviceproviderFiles[i].imagePath, '/apps', '');
                        if (itemType === type && id === itemId) {
                            return path;
                        }
                    }
                }
            } else if (sp && sp.serviceproviderFiles && !$scope.isValidatedOrIamTrustedMxStatus(sp.mxstatus)) {
                return '#';
            }

            if (type === 'certificate') {
                return '/customer/images/' + id + '_cert.png'
            }
        }

        $scope.getWebsitLink = function (sp, mbrAss, type) {

            if (sp && $scope.isValidatedOrIamTrustedMxStatus(sp.mxstatus)) {

                if (mbrAss.website) {
                    return mbrAss.website;
                }

                return '/customer/images/' + mbrAss.memberAssociation.lookupvalue + '_' + type;
            }
        }

        $scope.getUploadGuide = function (sp, destinationService) {

            if (sp && $scope.isValidatedOrIamTrustedMxStatus(sp.mxstatus)) {

                if (destinationService && destinationService.website) {
                    return destinationService.website;
                }

                return $scope.uploadGuide[destinationService.dspType.id];
            }
        }

        $scope.isValidatedOrIamTrustedMxStatus = function(mxStatus) {
            return mxStatus && (mxStatus.lookupcode === '4' || mxStatus.lookupcode === '5');
        }

        $scope.hasAlerts = function(adlTrackers) {
            if (_.isEmpty(adlTrackers)) {
                return null;
            }

            return  _.some(adlTrackers, $scope.isActiveAlert);
        }

        $scope.isActiveAlert = function(adlTracker) {
            var started = adlTracker.dateStart && ((new Date()).getTime() - Date.parse(adlTracker.dateStart) >= 0);
            var notEnded = !adlTracker.dateEnd || ((new Date()).getTime() - Date.parse(adlTracker.dateEnd) < 0);
            return started && notEnded;
        }

        $scope.adlTrackerAlertModalInstance = null;
        $scope.showAlert = function(sp) {
            // var alert = $scope.findFirstAlert(sp.adlTrackers);
            var alert = $scope.findMostRecentAlert(sp.adlTrackers);

            $scope.alertPostedDate = alert ? $filter('date')(alert.dateStart, 'yyyy-MMM-dd') : 'N/A';
            $scope.alertPublicDisplayNotes = alert ? alert.notesPublic : 'N/A';

            $scope.adlTrackerAlertModalInstance = $uibModal.open({
                templateUrl: 'app/entities/adl-tracker/adl-tracker-alert-dialog.html',
                scope: $scope
            });
        }

        $scope.ok = function () {
			$scope.adlTrackerAlertModalInstance.close("Ok");
		}

		$scope.cancel = function () {
			$scope.adlTrackerAlertModalInstance.dismiss();
		}

        $scope.findFirstAlert = function(adlTrackers) {
            if (_.isEmpty(adlTrackers)) {
                return null;
            }
            return _.find(adlTrackers, $scope.isActiveAlert);
        }

        $scope.findMostRecentAlert = function(adlTrackers) {
            if (_.isEmpty(adlTrackers)) {
                return null;
            }

            var activeAdlTrackers = _.filter(adlTrackers, $scope.isActiveAlert);
            if (_.isEmpty(activeAdlTrackers)) {
                return null;
            }

            var sortedAdlTrackers = _.sortBy(activeAdlTrackers,
                function(adlTracker) {
                    // Sort in descending order
                    return -Date.parse(adlTracker.dateStart);
                }
            );

            return _.isEmpty(sortedAdlTrackers) ? null : sortedAdlTrackers[0];
        }

        $scope.getSpacerHeight = function(sp, index) {
            var showAlert = $scope.hasAlerts(sp.adlTrackers);
            if (!showAlert) {
                return 0;
            }

            var statusImagesHeight = 44;
            if (sp.mxstatus.lookupcode === '5') {
                statusImagesHeight = 142;
            }

            var alertImageSpace = 45;
            var spacerHeight = 20;
            var leftPanelHeight = $scope.getElementHeight('left-panel-' + index);
            var middlePanelHeight = $scope.getElementHeight('middle-panel-' + index);
            var iamTrustedHeight = $scope.getElementHeight('right-panel-' + index + '-iam-trusted');

            if (Math.max(middlePanelHeight, leftPanelHeight, iamTrustedHeight) >  (statusImagesHeight + alertImageSpace)) {
                spacerHeight = Math.max(middlePanelHeight, leftPanelHeight, iamTrustedHeight) - statusImagesHeight - alertImageSpace;
            }

            var iamValidated = $scope.getElementHeight('right-panel-' + index + '-validated');
            var maxLeftMiddle = Math.max(middlePanelHeight, leftPanelHeight);

            // console.log("Spacer height: " + spacerHeight + ", left panel height: " + leftPanelHeight + ", middle panel height: " + middlePanelHeight + ", IAMT height: " + iamTrustedHeight + ", validated height: " + iamValidated);
            return spacerHeight;
        }

        $scope.getElementHeight = function(elementId) {
            var element = document.getElementById('middle-panel-2');
            if (!element) {
                return 0;
            }

            return element.getBoundingClientRect().height;
        }

        $scope.includeSpacer = function(sp) {
            var showAlert = $scope.hasAlerts(sp.adlTrackers);
            return (sp.mxstatus.lookupcode !== '5' && showAlert) || sp.mxstatus.lookupcode === '5';
        }

        vm.assignedSPNames = [];

        vm.getSPNames = function (id) {
            // //console.log("SP Names --- :::
            // "+JSON.stringify(vm.mxConnectedSPNames));
            var spNames = _.find(vm.mxConnectedSPNames, { quoteRequestId: id });
            if (!_.isUndefined(spNames)) {
                vm.assignedSPNames[id] = spNames.spNames.toString();
            }
        };

        vm.showquote = true;
        vm.showpartner = false;
        vm.showmessage = false;

        vm.showMXConnectContent = function (section) {
            if (section === 1) {
                vm.showquote = true;
                vm.showpartner = false;
                vm.showmessage = false;
            }
            if (section === 2) {
                vm.showquote = false;
                vm.showpartner = true;
                vm.showmessage = false;
            }
            if (section === 3) {
                vm.showquote = false;
                vm.showpartner = false;
                vm.showmessage = true;
            }

            section = true;
        };


        vm.quoteSentMessage = "";
        vm.mxConnectClickedOnSpName = "";
        vm.quotationRequestId = [];
        vm.spAlreadySelected = "";
        vm.mxConnectedLimit = false;
        vm.mxConnectedLimitMessage = "";

        vm.quoteReqSaved = null;

        vm.saveReponse = function (req) {

            if (vm.mxConnectedSPNames) {

                var spNames = _.find(vm.mxConnectedSPNames, { quoteRequestId: req.id });

                if (spNames && spNames.spNames && (spNames.spNames.length >= 3)) {
                    vm.mxConnectedLimit = true;
                    vm.quotationRequestId[req.id] = null;
                    vm.quoteSentMessage = "(MX) Connect limits the number of service providers that can be selected for an RFQ." +
                        "We respect the effort and expense with the RFQ response and suggest careful selection of service providers " +
                        "using the IAMX search and filtering tools";
                    return false;
                }
            }

            var spId = vm.mxConnectClickedOnSpId;
            vm.quotationResponse = {};
            vm.quotationResponse.quotationRequest = req;
            vm.quotationResponse.serviceproviderId = Number(spId);
            vm.quotationResponse.responseStatus = 41;
            vm.quoteReqSaved = req;

            // if(vm.quotationRequestId[req.id]) {
            QuotationResponse.save(vm.quotationResponse, sentSuccess, sentFailed);
            vm.quoteSentMessage = "Rate Quote request " + req.requestId + " sent to " + vm.mxConnectClickedOnSpName + ".";
            // }else{
            // QuotationResponse.delete(vm.quotationResponse);
            // vm.quoteSentMessage = "Rate Quote request " + req.requestId +
            // " sent to " + vm.mxConnectClickedOnSpName + ".";
            // }

        };

        function sentSuccess(result) {
            vm.quoteReqSaved.quotationResponses.push(result);
            vm.isAlreadyAssigned(vm.quoteReqSaved);
            vm.getSPNames(vm.quoteReqSaved.id);
        };

        $scope.skypeAndWhatsappUrl = function (contact, type, isSkype) {

            if (contact.skypeId != null || contact.whatsapp != null) {
                if (vm.isProviderAvailable(contact)) {
                    if (isSkype) {
                        $window.open('skype:' + contact.skypeId + '?' + type);
                    } else {
                        // Remove spaces and leading +, if present
                        var phone = contact.whatsapp.replace(/\s|\+/g, "");
                        $window.open('https://api.whatsapp.com/send?phone=' + phone);
                    }
                } else {
                    if (contact.timezone) {
                        var localTime = DateUtils.getLocalDateTime(contact.timezone.flex1);
                        var days = '';
                        if (contact.skypeMon) days = days + 'Mon&nbsp;';
                        if (contact.skypeTue) days = days + 'Tue&nbsp;';
                        if (contact.skypeWed) days = days + 'Wed&nbsp;';
                        if (contact.skypeThr) days = days + 'Thr&nbsp;';
                        if (contact.skypeFri) days = days + 'Fri&nbsp;';
                        if (contact.skypeSat) days = days + 'Sat&nbsp;';
                        if (contact.skypeSun) days = days + 'Sun&nbsp;';
                        var body = '<fieldset class="scheduler-border"><legend class="scheduler-border">Normal Business Hours</legend>'
                            + '<table><tr>'
                            + '<td><span style="height: 25px;"> Local time:&nbsp;</span></td>'
                            + '<td><a style="pointer-events: none;">' + localTime + '</a></td>'
                            + '</tr>'
                            + '<tr>'
                            + '<td><span style="height: 25px;"> Available days:&nbsp;</span></td>'
                            + '<td><a style="pointer-events: none;">' + days + '</a></td>'
                            + '</tr>'
                            + '<tr>'
                            + '<td><span style="height: 25px;"> Office hours:&nbsp; </span></td>'
                            + '<td><a style="pointer-events: none;">' + contact.skypeTimeFrom + ' - ' + contact.skypeTimeTo + ' hrs</a></td>'
                            + '</tr></table></fieldset>';
                    }
                    var modalInstance = $uibModal.open({
                        template: '<div class="modal-header"><h4>Currently Not Available!</h4></div>'
                            + '<div class="modal-body">' + body + '</div>'
                            + '<div class="modal-footer"><button class="btn btn-primary" ng-click="ok()">OK</button></div>',
                        controller: 'MembershipModalContentCtrl'
                    });
                }
            }
            //else {
            //     LoginService.open();
            // }
        }

        $scope.skypeAndWhatsappUrl =
            function (contact, type, isSkype, ga4EventName, ga4EventDetails) {
                if (contact.skypeId != null || contact.whatsapp != null) {
                    if (vm.isProviderAvailable(contact)) {
                        if (isSkype) {
                            $window.open('skype:' + contact.skypeId + '?' + type);
                        } else {
                            // Remove spaces and leading +, if present
                            var phone = contact.whatsapp.replace(/\s|\+/g, "");
                            $window.open('https://api.whatsapp.com/send?phone=' + phone);
                        }
                } else {
                    if (contact.timezone) {
                        var localTime = DateUtils.getLocalDateTime(contact.timezone.flex1);
                        var days = '';
                        if (contact.skypeMon) days = days + 'Mon&nbsp;';
                        if (contact.skypeTue) days = days + 'Tue&nbsp;';
                        if (contact.skypeWed) days = days + 'Wed&nbsp;';
                        if (contact.skypeThr) days = days + 'Thr&nbsp;';
                        if (contact.skypeFri) days = days + 'Fri&nbsp;';
                        if (contact.skypeSat) days = days + 'Sat&nbsp;';
                        if (contact.skypeSun) days = days + 'Sun&nbsp;';
                        var body = '<fieldset class="scheduler-border"><legend class="scheduler-border">Normal Business Hours</legend>'
                            + '<table><tr>'
                            + '<td><span style="height: 25px;"> Local time:&nbsp;</span></td>'
                            + '<td><a style="pointer-events: none;">' + localTime + '</a></td>'
                            + '</tr>'
                            + '<tr>'
                            + '<td><span style="height: 25px;"> Available days:&nbsp;</span></td>'
                            + '<td><a style="pointer-events: none;">' + days + '</a></td>'
                            + '</tr>'
                            + '<tr>'
                            + '<td><span style="height: 25px;"> Office hours:&nbsp; </span></td>'
                            + '<td><a style="pointer-events: none;">' + contact.skypeTimeFrom + ' - ' + contact.skypeTimeTo + ' hrs</a></td>'
                            + '</tr></table></fieldset>';
                    }
                    var modalInstance = $uibModal.open({
                        template: '<div class="modal-header"><h4>Currently Not Available!</h4></div>'
                            + '<div class="modal-body">' + body + '</div>'
                            + '<div class="modal-footer"><button class="btn btn-primary" ng-click="ok()">OK</button></div>',
                        controller: 'MembershipModalContentCtrl'
                    });
                }
            }
            //else {
            //     LoginService.open();
            // }

            //$window.gtag('event', ga4EventName, ga4EventDetails);
        }

        vm.isProviderAvailable = function (address) {

            if (!address.timezone || (!address.skypeTimeFrom && !address.skypeTimeTo)) {
                return true;
            }

            if (address.timezone) {
                var date = DateUtils.getLocalDateTime(address.timezone.flex1);
                var nd = DateUtils.getUTCDate(address.timezone.flex1);

                var dateTo = nd.getFullYear() + '/' + (nd.getMonth() + 1) + '/' + nd.getDate() + ' ' + address.skypeTimeTo + ':' + nd.getSeconds();
                var dateFrom = nd.getFullYear() + '/' + (nd.getMonth() + 1) + '/' + nd.getDate() + ' ' + address.skypeTimeFrom + ':' + nd.getSeconds();
                if (Date.parse(date) >= Date.parse(dateFrom) && Date.parse(date) <= Date.parse(dateTo)) {
                    var day = nd.getDay();
                    switch (day) {
                        case 1:
                            if (address.skypeMon) return true;
                            break;
                        case 2:
                            if (address.skypeTue) return true;
                            break;
                        case 3:
                            if (address.skypeWed) return true;
                            break;
                        case 4:
                            if (address.skypeThr) return true;
                            break;
                        case 5:
                            if (address.skypeFri) return true;
                            break;
                        case 6:
                            if (address.skypeSat) return true;
                            break;
                        case 0:
                            if (address.skypeSun) return true;
                            break;
                    }
                }
            }
            return false;
        }

        function isMobile() {
            if ($window.innerWidth < 500) {
                vm.isCollapsed = "collapse";
                vm.isMobile = true;
            } else {
                vm.isCollapsed = "collapse in";
                vm.isMobile = false;
            }
        }

        isMobile();

        function checkAssocsFilter() {

            if (!$rootScope.assocsfilters && $stateParams.assocs === '800') {
                $rootScope.assocsfilters = $stateParams.assocs;
                $rootScope.searchMode = 'IAM';
                $rootScope.assocs = [800];
                $rootScope.assocsfilters = "800";
                $rootScope.searchModeLabel = 'Display all Service Providers';
                $rootScope.searchModeText = 'Displaying IAM members Only';
            }
        }

        checkAssocsFilter();

        vm.filterOpenState = {
            mcOpen: !_.isEmpty($rootScope.mocsfilters),
            rcOpen: !_.isEmpty($rootScope.recsfilters),
            scOpen: !_.isEmpty($rootScope.sucsfilters),
            fsOpen: !_.isEmpty($rootScope.frcsfilters),
            usOpen: !_.isEmpty($rootScope.uscsfilters),
            lnOpen: !_.isEmpty($rootScope.lngsfilters),
            assocOpen: !_.isEmpty($rootScope.assocsfilters) && (_.isUndefined($stateParams.fvw)),
            certOpen: !_.isEmpty($rootScope.certsfilters),
            complianceOpen: !_.isEmpty($rootScope.compliancefilters),
            contactQualificationsOpen: !_.isEmpty($rootScope.contactQualificationfilters)
        };

        vm.zoom = 5;

        vm.map = {
            center: {
                latitude: lat,
                longitude: lng
            },
            bounds: {
                northeast: {
                    latitude: lat,
                    longitude: lng
                },
                southwest: {
                    latitude: lat,
                    longitude: lng
                }
            },
            markersEvents: {
                click: function (marker, eventName, model) {
                    vm.map.window.model = model;
                    vm.map.window.show = true;
                }
            },
            window: {
                marker: {},
                show: false,
                closeClick: function () {
                    vm.show = false;
                },
                options: {}
            }
        };

        vm.range = $stateParams.range;

        vm.slider = {
            value: $stateParams.range,
            options: {
                floor: 50,
                ceil: 1000,
                showTicks: 50,
                /* step: 50, */
                onEnd: function () {
                    $stateParams.range = vm.slider.value;
                    vm.range = vm.slider.value;
                    /*
					 * if (vm.range <= 200) { vm.map.zoom = 4; transition(); }
					 * else if (vm.range > 200 && vm.range <= 300) { vm.map.zoom =
					 * 3; transition(); } else if (vm.range > 300 && vm.range <=
					 * 400) { vm.map.zoom = 3; transition(); } else if (vm.range >
					 * 400 && vm.range <= 500) { vm.map.zoom = 2; transition(); }
					 * else if (vm.range > 500) { vm.map.zoom = 2; transition(); }
					 * else { transition(); }
					 */
                    vm.transition();
                }
            }
        };

        var today = new Date();

        $scope.downloadCert = function (event, membership, id) {

            event.preventDefault();
            event.stopPropagation();

            $http.get('api/generate-certificate/' + membership + '_CERT/' + id + '/%20', { responseType: 'arraybuffer' }).then(function successCallback(data) {

                var file = new Blob([data.data], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);

            }, function errorCallback(response) {
                $uibModal.open({
                    templateUrl: "app/search/search-link-dialog.html",
                    controller: "SearchLinkDialogController",
                    size: '',
                });
            });
        };

        $scope.downloadNonValidatedCertificate = function (event, certificate, spId) {

            event.preventDefault();
            event.stopPropagation();

            var lookupValue = certificate.lookupvalue;
            var id = certificate.id;
            var lookupTypesId = certificate.lookuptypes.id;

            $http.get('api/generate-non-validated-certificate?lookupValue=' + lookupValue + '&lookupTypesId=' + lookupTypesId + '&id=' + id + '&spId=' + spId, { responseType: 'arraybuffer' }).then(function successCallback(data) {

                var file = new Blob([data.data], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);

            }, function errorCallback(response) {
                $uibModal.open({
                    templateUrl: "app/search/search-link-dialog.html",
                    controller: "SearchLinkDialogController",
                    size: '',
                });
            });
        };

        $scope.showQualityCertificate = function (event, quality, sp) {

            event.preventDefault();
            event.stopPropagation();

            var url = quality.website && $scope.isValidatedOrIamTrustedMxStatus(sp.mxstatus) ? quality.website : $scope.getFileLink(sp, 'certificate', quality.certificateType.lookupvalue);
            if (url && url !== '#') {
                console.log('Display quality certificate, URL: ' + url);
                window.open(url, '_blank');
            } else {
                console.log('Display non-validated quality certificate, URL: ' + url);

                var spId = sp.id;
                var certificate = quality.certificateType;
                var lookupValue = certificate.lookupvalue;
                var id = certificate.id;
                var lookupTypesId = certificate.lookuptypes.id;

                $http.get('api/generate-non-validated-certificate?lookupValue=' + lookupValue + '&lookupTypesId=' + lookupTypesId + '&id=' + id + '&spId=' + spId, { responseType: 'arraybuffer' }).then(function successCallback(data) {

                    var file = new Blob([data.data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL, '_blank');
                }, function errorCallback(response) {
                    $uibModal.open({
                        templateUrl: "app/search/search-link-dialog.html",
                        controller: "SearchLinkDialogController",
                        size: '',
                    });
                });
            }
        };

        $scope.showComplianceCertificate = function (event, compliance, sp) {

            event.preventDefault();
            event.stopPropagation();

            var display = compliance.certificateType.lookupvalue !== 'FMC' && compliance.certificateType.lookupvalue !== 'DOT';
            var url = compliance.website && $scope.isValidatedOrIamTrustedMxStatus(sp.mxstatus) ? compliance.website : $scope.getFileLink(sp,'certificate', compliance.certificateType.lookupvalue);

            if (display && url && url !== '#') {
                console.log('Display compliance certificate, URL: ' + url);
                window.open(url, '_blank');
            } else {
                console.log('Display non-validated certificate, URL: ' + url);
                var spId = sp.id;
                var certificate = compliance.certificateType;
                var lookupValue = certificate.lookupvalue;
                var id = certificate.id;
                var lookupTypesId = certificate.lookuptypes.id;

                $http.get('api/generate-non-validated-certificate?lookupValue=' + lookupValue + '&lookupTypesId=' + lookupTypesId + '&id=' + id + '&spId=' + spId, { responseType: 'arraybuffer' }).then(function successCallback(data) {

                    var file = new Blob([data.data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL, '_blank');
                }, function errorCallback(response) {
                    $uibModal.open({
                        templateUrl: "app/search/search-link-dialog.html",
                        controller: "SearchLinkDialogController",
                        size: '',
                    });
                });
            }
        };

        $scope.downloadFmcCert = function (event, organization) {

            event.preventDefault();
            event.stopPropagation();

            $http.get('api/generate-fmc/' + organization, { responseType: 'arraybuffer' }).then(function successCallback(data) {

                var file = new Blob([data.data], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);

            }, function errorCallback(response) {
                $uibModal.open({
                    templateUrl: "app/search/search-link-dialog.html",
                    controller: "SearchLinkDialogController",
                    size: '',
                });
            });

        };

        $scope.nonActiveFilter = function (association) {

            if (association.memberType && association.memberStatus) {

                if (association.memberType.flex2 === 'Y' || association.memberType.flex2 === 'A' || association.memberType.flex6 !== 'Y') {

                    if (association.memberStatus.id === 1200 || association.memberStatus.id === 1202) {
                        return false;
                    } else if (association.memberType.flex7 === 'N') {
                        return false;
                    }
                }
            }

            return true;
        };

        vm.getApprovalStatusIndicator = function (mxstatus, appFlag, expiryDate, reviewDate) {

            if ((mxstatus === '3' || mxstatus === '4' || mxstatus === '5')) {

                if (expiryDate == null && reviewDate == null && appFlag === true) {
                    return 'glyphicon glyphicon-ok-sign icon-success';
                }

                if (expiryDate != null) {
                    var expDate = Date.parse(expiryDate);
                    if (appFlag === true && expDate > today) {
                        return 'glyphicon glyphicon-ok-sign icon-success';
                    }
                }

                if (reviewDate != null) {
                    var revDate = Date.parse(reviewDate);
                    if (appFlag == true && revDate > today) {
                        return 'glyphicon glyphicon-ok-sign icon-success';
                    }
                }

                return 'glyphicon glyphicon-remove-sign icon-alert';
            }
        };

        vm.getApprovalStatusIndicatorMemberships = function (mxstatus, appFlag, expiryDate, reviewDate, memberAssoc) {

            if ((mxstatus === '3' || mxstatus === '4' || mxstatus == '5')) {

                if (expiryDate == null && reviewDate == null && appFlag === true) {
                    return 'glyphicon glyphicon-ok-sign icon-success';
                }

                if (expiryDate != null) {
                    var expDate = Date.parse(expiryDate);
                    if (appFlag === true && expDate > today) {
                        return 'glyphicon glyphicon-ok-sign icon-success';
                    } else {
                        return 'glyphicon glyphicon-ok-sign icon-alert';
                    }
                }

                if (reviewDate != null) {
                    var revDate = Date.parse(reviewDate);
                    if (appFlag == true && revDate > today) {
                        return 'glyphicon glyphicon-ok-sign icon-success';
                    }
                }

                if (memberAssoc.memberType && memberAssoc.memberStatus) {

                    if (memberAssoc.memberType.flex2 === 'Y') {

                        if (memberAssoc.memberStatus.id === 1201) {

                            return 'glyphicon glyphicon-ok-sign icon-success';

                        } else {

                            return 'glyphicon glyphicon-remove-sign icon-alert';
                        }
                    }
                }

                return 'glyphicon glyphicon-remove-sign icon-alert';
            }

            if (memberAssoc.memberAssociation.id === 800) {
                if (expiryDate != null) {
                    var expDate = Date.parse(expiryDate);
                    if (appFlag === true && expDate > today) {
                        return 'glyphicon glyphicon-ok-sign icon-success';
                    } else {
                        return 'glyphicon glyphicon-ok-sign icon-alert';
                    }
                }
            }
        };

        vm.getApprovalStatusIndicatorBusinessRegistration = function (expiryDate) {
            if (expiryDate != null) {
                var expDate = Date.parse(expiryDate);
                if (expDate > today) {
                    return 'glyphicon glyphicon-ok-sign icon-success';
                }
            }
            return 'glyphicon glyphicon-ok-sign icon-alert';
        };

        vm.getIamMembershipsLogo = function (memberAssociations, companydescription) {
            for (var i = 0; i < memberAssociations.length; i++) {
                var memberAssoc = memberAssociations[i];
                if (memberAssoc.memberAssociation.id === 800 && memberAssoc.memberStatus.id === 1201) {
                    if (memberAssoc.memberType.id === 1660 || memberAssoc.memberType.id === 1676) {
                        return '/content/images/coremember.png';
                    } else if (memberAssoc.memberType.id === 1661) {
                        return '/content/images/governingmember.png';
                    } else if (memberAssoc.memberType.id === 1662) {
                        return '/content/images/suppliermember.png';
                    } else if (memberAssoc.memberType.id === 5555) {
                        return '/content/images/IAM-premier-icon.png';
                    } else if (memberAssoc.memberType.id === 5556  || memberAssoc.memberType.id === 1678 || memberAssoc.memberType.id === 1679) {
                        return '/content/images/IAM-core-icon.png';
                    } else if (memberAssoc.memberType.id === 5557) {
                        return '/content/images/IAM-value-icon.png';
                    } else if (memberAssoc.memberType.id === 5558) {
                        return '/content/images/IAM-industry-partner-icon.png';
                    }
                }
            }

            if (companydescription === 'Student Member'){
                return '/content/images/IAM_student.png';
            }

            if (companydescription === 'Industry Veteran'){
                return '/content/images/IAM_veteran.png';
            }

            return '/content/images/IAM_non.png';
        }

        vm.getDabLogo = function(memberAssociations) {
            return vm.getMembershipLogo(memberAssociations, 927, '/content/images/IAMDAB_Icon.png');
        }

        vm.hasDab = function(memberAssociations) {
            return vm.hasMembership(memberAssociations, 927);
        }

        vm.getDabAllyLogo = function(memberAssociations) {
            return vm.getMembershipLogo(memberAssociations, 951, '/content/images/IAMDABAlly_Icon.png');
        }

        vm.hasDabAlly = function(memberAssociations) {
            return vm.hasMembership(memberAssociations, 951);
        }

        vm.hasDestinationServices = function (serviceProvider) {
            return serviceProvider.mxstatus &&
                (serviceProvider.mxstatus.lookupcode === '3' || serviceProvider.mxstatus.lookupcode === '4' || serviceProvider.mxstatus.lookupcode==='5') &&
                serviceProvider.destinationServices && serviceProvider.destinationServices.length > 0;
        }

        vm.goToDestinationServicesAccordion = function() {
            vm.scrollTo('destinationServicesId');
            if (open) {
                $scope.status.destinatonservicesopen=true;
            }
        }

        vm.goToDestinationServices = function(sp) {
            vm.generateEventGa4('IAMX Featured Services Click', {'sp_legal_name': sp.companylegalname});
            $rootScope.goToDestinationServicesAccordion = true;
            $state.go('searchdetails', {id:sp.id}).then(
                function() {
                    $location.hash('destinationServicesId');
                    $anchorScroll();
                }
            );
        }

        vm.getIsaMembershipLogo = function(memberAssociations) {
            return vm.getMembershipLogo(memberAssociations, 866, '/content/images/ISA_membership.png');
        }

        vm.hasIsaMembership = function(memberAssociations) {
            return vm.hasMembership(memberAssociations, 866);
        }

        vm.hasMembership = function(memberAssociations, memberAssociationId) {
            if (!memberAssociations) {
                return false;
            }

            for (var i = 0; i < memberAssociations.length; i++) {
                var memberAssoc = memberAssociations[i];
                if (memberAssoc.memberAssociation.id === memberAssociationId && memberAssoc.memberStatus.id === 1201) {
                    return true;
                }
            }

            return false;
        }

        vm.hasOnlineRates = function (serviceProvider) {
            return serviceProvider.ratePortalAddress && (serviceProvider.mxstatus.lookupcode === '3' || serviceProvider.mxstatus.lookupcode === '4' || serviceProvider.mxstatus.lookupcode==='5');
        }

        vm.getMembershipLogo = function(memberAssociations, memberAssociationId, logo) {
            if (!memberAssociations) {
                return '';
            }

            for (var i = 0; i < memberAssociations.length; i++) {
                var memberAssoc = memberAssociations[i];
                if (memberAssoc.memberAssociation.id === memberAssociationId && memberAssoc.memberStatus.id === 1201) {
                    return logo;
                }
            }

            return '';
        }

        vm.getBusinessRegistrationFile = function (files) {
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                if (file.image_type === 'business_reg') {
                    var path = '';
                    if (file.imagePath)
                        path = file.imagePath.replace('/apps', '')
                    return path;
                }
            }
            return '';
        }

        vm.getDistinKM = function (miles) {
            return _.ceil(miles * 1.62);
        };

        vm.toggleMapView = function () {

            if ($rootScope.expandedView) {
                $rootScope.expandedView = false;
                $rootScope.exptext = "Expand ";
                vm.map.zoom = 5;
                return !vm.expandedView;
            }
            else {
                $rootScope.exptext = "Collapse ";
                $rootScope.expandedView = true;
                vm.map.zoom = 2;
                return vm.expandedView;
            }
        };

        if (!_.isNil(locParam) || !_.isUndefined(locParam)) {
            var wordsIdLoc = locParam.split(" ");
            if (wordsIdLoc.length === 1)
                range = 1500;
        }

        if (_.isUndefined(locParam) || _.isEmpty(locParam)) {
            vm.showSlider = false;
            vm.slider.options.disabled = true;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                reload: true,
                sort: 'companylegalname,asc',
                search: vm.currentSearch,
                svc: svcParam,
                loc: locParam,
                lat: lat,
                lng: lng,
                supplier: supplierParam,
                ctry: $stateParams.ctry,
                query: 'q= ' + Math.floor((Math.random() * 6) + 1),
                sensor: false,
                range: vm.range,
                size: 30,
                mcs: $rootScope.mcsfilters,
                mocs: $rootScope.mocsfilters,
                recs: $rootScope.recsfilters,
                sucs: $rootScope.sucsfilters,
                frcs: $rootScope.frcsfilters,
                uscs: $rootScope.uscsfilters,
                lngs: $rootScope.lngsfilters,
                assocs: !_.isEmpty($rootScope.assocsfilters) ? $rootScope.assocsfilters : $stateParams.assocs,
                certs: $rootScope.certsfilters,
                cmp: $rootScope.compliancefilters,
                /* inq: $rootScope.contactQualifications, */
                inq: $rootScope.contactQualificationfilters,
                spc: $stateParams.spc
            });
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        vm.getStyleBasedOnMXStatus = function (mxstatus) {
            if (mxstatus === '1') {
                return "box-shadow: 0 0 1px #003399;border-radius: 0 !important;margin-bottom: 8px;";
            }
            else if (mxstatus === '2') {
                return "box-shadow: 0 0 1px #003399;border-radius: 0 !important;margin-bottom: 8px;";
            }
            else if (mxstatus === '3') {
                return "box-shadow: 0 0 1px #003399;border-radius: 0 !important;margin-bottom: 8px;";
            }
            else if (mxstatus === '4') {
                return "box-shadow: 0 0 10px #2ecc71;border-radius: 0 !important;margin-bottom: 8px;";
            }
            else if (mxstatus === '5') {
                return "box-shadow: 0 0 10px #4B0082;border-radius: 0 !important;margin-bottom: 8px;";
            }
        };

        getServiceProvidersSearchResults(svcParam, locParam, supplierParam, lat, lng, $http, $scope, $rootScope, $stateParams, vm, ParseLinks, $analytics, $window);

        vm.formatTradeNames = function (tradename) {
            tradename = tradename.replace(/,\s*$/, "");
            var formatTradeNames = tradename.replace(/,/g, " |");
            return formatTradeNames;
        };

        vm.options = {
            scrollwheel: false
        };

        vm.sendMail = function (emailId, subject, message) {
            $window.open("mailto:" + emailId + "?subject=" + subject + "&body=" + message, "_self");
        };

        vm.displayGoogleMap = function () {
            $scope.globe = false;

            var markers = [];

            _.forEach(vm.fullres, function (results, key) {

                if (results.serviceproviderAddresses && results.serviceproviderAddresses[0]) {

                    var latlng = _.split(results.serviceproviderAddresses[0].geocode, ',');
                    var latitude = latlng[0];
                    var longitude = latlng[1];

                    var ret = {

                        latitude: latitude,
                        longitude: longitude,
                        title: 'm' + key,
                        bounds: {},
                        options: {
                            label: ''
                        },

                        content: {
                            title: results.companylegalname,
                            idKey: results.id,
                            address: results.serviceproviderAddresses
                        }
                    };

                    if (results.mxstatus.lookupcode === '4') {
                        ret.icon = "http://maps.google.com/mapfiles/ms/icons/green-dot.png";
                    }

                    ret["id"] = key;
                    markers.push(ret);
                }

            });

            if (!_.isEmpty(_.trim($rootScope.lat)) || !_.isEmpty(_.trim($rootScope.lng))) {

                vm.map.zoom = vm.zoom;
                vm.map.center.latitude = $rootScope.lat;
                vm.map.center.longitude = $rootScope.lng;

                var centerMarker = {
                    latitude: $rootScope.lat,
                    longitude: $rootScope.lng,
                    options: {
                        icon: 'home.ico'
                    }
                };

                centerMarker["id"] = 'C';
                markers.push(centerMarker);

            } else if (!_.isUndefined(markers) && markers.length <= 2) {

                vm.map.zoom = 14;
                vm.map.center.latitude = markers[0].latitude;
                vm.map.center.longitude = markers[0].longitude;

            } else {

                vm.map.zoom = 2;
                vm.map.center.latitude = markers[0].latitude;
                vm.map.center.longitude = markers[0].longitude;
            }

            vm.randomMarkers = markers;

            // $rootScope.fullMapMarkers = vm.randomMarkers;

            vm.mapDataLoading = false;

            // $window.scrollTo(0, 0);
        }

        vm.mxEmail = function (emailid) {
            vm.sendMail(emailid, "Query from IAM Mobility Exchange", "");
        };

        vm.mxEmail =
            function (emailid, ga4EventName, ga4EventDetails) {
                vm.sendMail(emailid, "Query from IAM Mobility Exchange", "");
                //$window.gtag('event', ga4EventName, ga4EventDetails);
            };

        vm.isPayedMember = function (mxstatus) {
            if (mxstatus === '4' || mxstatus === '5') {
                return true;
            }
            else {
                return false;
            }
        };

        $rootScope.mcs = [];
        $rootScope.mocs = [];
        $rootScope.recs = [];
        $rootScope.sucs = [];
        $rootScope.frcs = [];
        $rootScope.cscs = [];
        $rootScope.uscs = [];
        $rootScope.lngs = [];
        $rootScope.assocs = [];
        $rootScope.certs = [];
        $rootScope.compliance = [];
        $rootScope.contactQualifications = [];

        vm.toggleSearch =
            function (ga4EventName, ga4EventDetails) {

            if ($rootScope.searchMode === 'IAM') {
                $rootScope.searchMode = 'ALL';
                $rootScope.assocs = [];
                _.remove($rootScope.assocs);
                $rootScope.assocsfilters = undefined;
                $stateParams.assocs = '';
                $rootScope.searchModeLabel = 'Display IAM members Only';
                $rootScope.searchModeText = 'Displaying all Service Providers';
                vm.transition();
            } else if ($rootScope.searchMode === 'Validated') {
                $rootScope.searchMode = 'Validated';
                $rootScope.assocs = [920];
                $rootScope.assocsfilters = "920";
                $rootScope.searchModeLabel = 'Display all Service Providers';
                $rootScope.searchModeText = 'Displaying Validated members Only';
                vm.transition();
            } else if ($rootScope.searchMode === 'ILN') {
                $rootScope.searchMode = 'ILN';
                $rootScope.assocs = [881];
                $rootScope.assocsfilters = "881";
                $rootScope.searchModeLabel = 'Display all Service Providers';
                $rootScope.searchModeText = 'Displaying ILN members Only';
                vm.transition();
            } else if ($rootScope.searchMode === 'ISA') {
                $rootScope.searchMode = 'ISA';
                $rootScope.assocs = [866];
                $rootScope.assocsfilters = "866";
                $rootScope.searchModeLabel = 'Display all Service Providers';
                $rootScope.searchModeText = 'Displaying ISA members Only';
                vm.transition();
            } else {
                $rootScope.searchMode = 'IAM';
                $rootScope.assocs = [800];
                $stateParams.assocs = "800";
                $rootScope.assocsfilters = "800";
                $rootScope.searchModeLabel = 'Display all Service Providers';
                $rootScope.searchModeText = 'Displaying IAM members Only';
                vm.transition();
            }

            //$window.gtag('event', ga4EventName, ga4EventDetails);
        };

        // vm.toggelSearch();

        vm.clearFilters = function () {
            $rootScope.mcs = [];
            $rootScope.mocs = [];
            $rootScope.recs = [];
            $rootScope.sucs = [];
            $rootScope.frcs = [];
            $rootScope.cscs = [];
            $rootScope.uscs = [];
            $rootScope.lngs = [];
            $rootScope.assocs = [];
            $rootScope.certs = [];
            $rootScope.compliance = [];
            $rootScope.contactQualifications = [];
            $rootScope.mcsfilters = undefined;
            $rootScope.mocsfilters = undefined;
            $rootScope.recsfilters = undefined;
            $rootScope.sucsfilters = undefined;
            $rootScope.frcsfilters = undefined;
            $rootScope.cscsfilters = undefined;
            $rootScope.uscsfilters = undefined;
            $rootScope.lngsfilters = undefined;
            $rootScope.lngsfilters = undefined;
            $rootScope.assocsfilters = undefined;
            $rootScope.certsfilters = undefined;
            $rootScope.compliancefilters = undefined;
            $rootScope.contactQualificationfilters = undefined;

            vm.transition();

        };

        vm.showResetFilters = function () {
            if (!_.isEmpty($rootScope.mcsfilters) || !_.isEmpty($rootScope.mocsfilters) || !_.isEmpty($rootScope.recsfilters)
                || !_.isEmpty($rootScope.sucsfilters) || !_.isEmpty($rootScope.frcsfilters) || !_.isEmpty($rootScope.cscsfilters)
                || !_.isEmpty($rootScope.uscsfilters) || !_.isEmpty($rootScope.lngsfilters) || !_.isEmpty($rootScope.lngsfilters)
                || !_.isEmpty($rootScope.assocsfilters)
                || !_.isEmpty($rootScope.certsfilters) || !_.isEmpty($rootScope.compliancefilters) || !_.isEmpty($rootScope.contactQualificationfilters))
                return true;
            return false;
        };


        vm.filterCERTS =
            function (mc, ga4EventName, ga4EventDetails) {
                var checked = false;
                if (_.isEmpty($rootScope.certs) && !_.isUndefined($rootScope.certs) && !_.isUndefined($stateParams.certs)) {
                    $rootScope.certs = $stateParams.certs.split(',');
                }
                if (_.indexOf($rootScope.certs, mc + '') > -1) {
                    _.pull($rootScope.certs, mc + '');
                } else {
                    $rootScope.certs.push(mc);
                    checked = true;
                }
                $rootScope.certsfilters = _.map($rootScope.certs).join(',');
                transition();
            };

        vm.isCheckedCERTS = function (mcs) {
            if (!_.isUndefined($stateParams.certs)) {
                var m = $stateParams.certs.split(',');
                if (_.indexOf(m, mcs + '') > -1)
                    return true;
            }
            return false;
        };

        vm.filterCompliance =
            function (mc, ga4EventName, ga4EventDetails) {
                var checked = false;
                if (_.isEmpty($rootScope.compliance) && !_.isUndefined($rootScope.compliance)
                    && !_.isUndefined($stateParams.cmp)) {
                    $rootScope.compliance = $stateParams.cmp.split(',');
                }
                if (_.indexOf($rootScope.compliance, mc + '') > -1) {
                    _.pull($rootScope.compliance, mc + '');
                } else {
                    $rootScope.compliance.push(mc);
                    checked = true;
                }
                $rootScope.compliancefilters = _.map($rootScope.compliance).join(',');
                transition();
            };

        vm.isCheckedCompliance = function (mcs) {
            if (!_.isUndefined($stateParams.cmp)) {
                var m = $stateParams.cmp.split(',');
                if (_.indexOf(m, mcs + '') > -1)
                    return true;
            }
            return false;
        };

        vm.filterContactQualification =
            function (mc, ga4EventName, ga4EventDetails) {
                var checked = false;
                if (_.isEmpty($rootScope.contactQualifications) && !_.isUndefined($rootScope.contactQualifications)
                    && !_.isUndefined($stateParams.inq)) {
                    $rootScope.contactQualifications = $stateParams.inq.split(',');
                }
                if (_.indexOf($rootScope.contactQualifications, mc + '') > -1) {
                    _.pull($rootScope.contactQualifications, mc + '');
                } else {
                    $rootScope.contactQualifications.push(mc);
                    checked = true;
                }
                $rootScope.contactQualificationfilters = _.map($rootScope.contactQualifications).join(',');
                transition();
            };

        vm.isCheckedcontactQualification = function (mcs) {
            if (!_.isUndefined($stateParams.inq)) {
                var m = $stateParams.inq.toString().split(',');
                if (_.indexOf(m, mcs + '') > -1)
                    return true;
            }
            return false;
        };

        vm.filterASSOC =
            function (mc, ga4EventName, ga4EventDetails) {
                var checked = false;
                if (_.isEmpty($rootScope.assocs) && !_.isUndefined($rootScope.assocs) && !_.isUndefined($stateParams.assocs)) {
                    $rootScope.assocs = $stateParams.assocs.split(',');
                }
                if (_.indexOf($rootScope.assocs, mc + '') > -1) {
                    _.pull($rootScope.assocs, mc + '');
                } else {
                    $rootScope.assocs.push(mc);
                    checked = true;
                }
                $rootScope.assocsfilters = _.map($rootScope.assocs).join(',');
                transition();
            };

        vm.isCheckedASSOC = function (mcs) {
            if (!_.isUndefined($stateParams.assocs)) {
                var m = $stateParams.assocs.split(',');
                if (_.indexOf(m, mcs + '') > -1)
                    return true;
            }
            return false;
        };

        vm.filterLNGS =
            function (mc, ga4EventName, ga4EventDetails) {
                var checked = false;
                if (_.isEmpty($rootScope.lngs) && !_.isUndefined($rootScope.lngs) && !_.isUndefined($stateParams.lngs)) {
                    $rootScope.lngs = $stateParams.lngs.split(',');
                }
                if (_.indexOf($rootScope.lngs, mc + '') > -1) {
                    _.pull($rootScope.lngs, mc + '');
                } else {
                    $rootScope.lngs.push(mc);
                    checked = true;
                }
                $rootScope.lngsfilters = _.map($rootScope.lngs).join(',');
                transition();
            };

        vm.isCheckedLNGS = function (mcs) {
            if (!_.isUndefined($stateParams.lngs)) {
                var m = $stateParams.lngs.split(',');
                if (_.indexOf(m, mcs + '') > -1)
                    return true;
            }
            return false;
        };

        vm.filterMCS = function (mc) {
            if (_.isEmpty($rootScope.mcs) && !_.isUndefined($rootScope.mcs) && !_.isUndefined($stateParams.mcs)) {
                $rootScope.mcs = $stateParams.mcs.split(',');
            }
            if (_.indexOf($rootScope.mcs, mc + '') > -1) {
                _.pull($rootScope.mcs, mc + '');
            } else {
                $rootScope.mcs.push(mc);
            }
            $rootScope.mcsfilters = _.map($rootScope.mcs).join(',');
            transition();
        };

        vm.isCheckedMCS = function (mcs) {
            if (!_.isUndefined($stateParams.mcs)) {
                var m = $stateParams.mcs.split(',');
                if (_.indexOf(m, mcs + '') > -1)
                    return true;
            }
            return false;
        };

        vm.filterMOV = function (mc) {
            if (_.isEmpty($rootScope.mocs) && !_.isUndefined($rootScope.mocs) && !_.isUndefined($stateParams.mocs)) {
                $rootScope.mocs = $stateParams.mocs.split(',');
            }
            if (_.indexOf($rootScope.mocs, mc + '') > -1) {
                _.pull($rootScope.mocs, mc + '');
            } else {
                $rootScope.mocs.push(mc);
            }
            $rootScope.mocsfilters = _.map($rootScope.mocs).join(',');
            transition();
        };

        vm.filterMOV =
            function (mc, ga4EventName, ga4EventDetails) {
                var checked = false;
                if (_.isEmpty($rootScope.mocs) && !_.isUndefined($rootScope.mocs) && !_.isUndefined($stateParams.mocs)) {
                    $rootScope.mocs = $stateParams.mocs.split(',');
                }
                if (_.indexOf($rootScope.mocs, mc + '') > -1) {
                    _.pull($rootScope.mocs, mc + '');
                } else {
                    $rootScope.mocs.push(mc);
                    checked = true;
                }
                $rootScope.mocsfilters = _.map($rootScope.mocs).join(',');
                transition();
            };

        vm.isCheckedMOV = function (mcs) {

            if (!_.isUndefined($stateParams.mocs)) {
                var m = $stateParams.mocs.split(',');

                if (_.indexOf(m, mcs + '') > -1) {
                    return true;
                }
            }
            return false;
        };

        vm.filterREL =
            function (mc, ga4EventName, ga4EventDetails) {
                var checked = false;
                if (_.isEmpty($rootScope.recs) && !_.isUndefined($rootScope.recs) && !_.isUndefined($stateParams.recs)) {
                    $rootScope.recs = $stateParams.recs.split(',');
                }
                if (_.indexOf($rootScope.recs, mc + '') > -1) {
                    _.pull($rootScope.recs, mc + '');
                } else {
                    $rootScope.recs.push(mc);
                    checked = true;
                }
                $rootScope.recsfilters = _.map($rootScope.recs).join(',');
                transition();
            };

        vm.isCheckedREL = function (mcs) {
            if (!_.isUndefined($stateParams.recs)) {
                var m = $stateParams.recs.split(',');
                if (_.indexOf(m, mcs + '') > -1)
                    return true;
            }
            return false;
        };

        vm.filterSUP =
            function (mc, ga4EventName, ga4EventDetails) {
                var checked = false;
                if (_.isEmpty($rootScope.sucs) && !_.isUndefined($rootScope.sucs) && !_.isUndefined($stateParams.sucs)) {
                    $rootScope.sucs = $stateParams.sucs.split(',');
                }
                if (_.indexOf($rootScope.sucs, mc + '') > -1) {
                    _.pull($rootScope.sucs, mc + '');
                } else {
                    $rootScope.sucs.push(mc);
                    checked = true;
                }
                $rootScope.sucsfilters = _.map($rootScope.sucs).join(',');
                transition();
            };

        vm.isCheckedSUP = function (mcs) {
            if (!_.isUndefined($stateParams.sucs)) {
                var m = $stateParams.sucs.split(',');
                if (_.indexOf(m, mcs + '') > -1)
                    return true;
            }
            return false;
        };

        vm.filterFR =
            function (mc, ga4EventName, ga4EventDetails) {
                var checked = false;
                if (_.isEmpty($rootScope.frcs) && !_.isUndefined($rootScope.frcs) && !_.isUndefined($stateParams.frcs)) {
                    $rootScope.frcs = $stateParams.frcs.split(',');
                }
                if (_.indexOf($rootScope.frcs, mc + '') > -1) {
                    _.pull($rootScope.frcs, mc + '');
                } else {
                    $rootScope.frcs.push(mc);
                    checked = true;
                }
                $rootScope.frcsfilters = _.map($rootScope.frcs).join(',');
                transition();
            };

        vm.isCheckedFR = function (mcs) {
            if (!_.isUndefined($stateParams.frcs)) {
                var m = $stateParams.frcs.split(',');
                if (_.indexOf(m, mcs + '') > -1)
                    return true;
            }
            return false;
        };

        vm.filterCUS = function (mc) {
            if (_.isEmpty($rootScope.cucs) && !_.isUndefined($rootScope.cucs) && !_.isUndefined($stateParams.cucs)) {
                $rootScope.cucs = $stateParams.cucs.split(',');
            }
            if (_.indexOf($rootScope.cucs, mc + '') > -1) {
                _.pull($rootScope.cucs, mc + '');
            } else {
                $rootScope.cucs.push(mc);
            }
            $rootScope.cucsfilters = _.map($rootScope.cucs).join(',');
            transition();
        };

        vm.isCheckedCUS = function (mcs) {
            if (!_.isUndefined($stateParams.cucs)) {
                var m = $stateParams.cucs.split(',');
                if (_.indexOf(m, mcs + '') > -1)
                    return true;
            }
            return false;
        };

        vm.filterUSG =
            function (mc, ga4EventName, ga4EventDetails) {
                var checked = false;
                if (_.isEmpty($rootScope.uscs) && !_.isUndefined($rootScope.uscs) && !_.isUndefined($stateParams.uscs)) {
                    $rootScope.uscs = $stateParams.uscs.split(',');
                }
                if (_.indexOf($rootScope.uscs, mc + '') > -1) {
                    _.pull($rootScope.uscs, mc + '');
                } else {
                    $rootScope.uscs.push(mc);
                    checked = true;
                }
                $rootScope.uscsfilters = _.map($rootScope.uscs).join(',');
                transition();
            };

        vm.isCheckedUSG = function (mcs) {
            if (!_.isUndefined($stateParams.uscs)) {
                var m = $stateParams.uscs.split(',');
                if (_.indexOf(m, mcs + '') > -1)
                    return true;
            }
            return false;
        };

    }


    // ?query=id%3D"+$stateParams.spId+"&size=1"
    var response;

    var getServiceProvidersSearchResults = function (svcParam, locParam, supplierParam, lat, lng, $http, $scope, $rootScope, $stateParams,
        vm, ParseLinks, $analytics, $window) {

            if (_.isEmpty(lat))
                lat = "";

            if (_.isEmpty(lng))
                lng = "";

            if (_.isEmpty(svcParam))
                svcParam = " ";

            if (_.isEmpty(locParam))
                locParam = " ";

            if (_.isNil(svcParam)) svcParam = "";

            if (_.isNil(locParam)) locParam = "";

            if (_.isNil(supplierParam)) supplierParam = "";

            var range = $stateParams.range;

            if (!_.isNil(locParam) || !_.isUndefined(locParam)) {

                var wordsIdLoc = locParam.split(" ");

                if (wordsIdLoc.length === 1)
                    range = 1500;
            }

            vm.mapDataLoading = true;
            vm.fullres;

            function processFullresponse(response) {

                vm.fullres = response.data;
            }

            function getFullResults() {

                if (_.isNil(supplierParam)) {
                    supplierParam = "";
                }

                $http.get('api/_search/service-providerslistfull', {
                    cache: true,
                    params: {
                        svc: svcParam,
                        loc: locParam,
                        ctry: $stateParams.ctry,
                        lat: lat,
                        lng: lng,
                        query: 'q= ',
                        sensor: false,
                        sort: 'companylegalname,asc',
                        cacheBuster: '0',
                        range: range,
                        mcs: $rootScope.mcsfilters,
                        mocs: $rootScope.mocsfilters,
                        recs: $rootScope.recsfilters,
                        sucs: $rootScope.sucsfilters,
                        frcs: $rootScope.frcsfilters,
                        uscs: $rootScope.uscsfilters,
                        lngs: $rootScope.lngsfilters,
                        assocs: !_.isEmpty($rootScope.assocsfilters) ? $rootScope.assocsfilters : $stateParams.assocs,
                        certs: $rootScope.certsfilters,
                        cmp: $rootScope.compliancefilters,
                        inq: $rootScope.contactQualificationfilters,
                        spc: $stateParams.spc,
                        supplier: supplierParam
                    }

                }).then(function (response) {

                    processFullresponse(response);
                }
                );

            }

            getFullResults();

            vm.footerCountMessage = "";

            vm.pcgFlag = [];
            vm.setPCGFlags = function (sps) {
                for (var i = 0; i < sps.length; i++) {
                    if (sps[i] && sps[i].compliance) {
                        var com = sps[i].compliance;
                        for (var j = 0; j < com.length; j++) {
                            if (com[j] && com[j].awardedto === 'PCG') {
                                vm.pcgFlag['PCG_' + sps[i].id] = true;
                            }
                        }
                    }
                }
            };


            response = $http.get('api/_search/service-providerslist', {

                params: {
                    svc: svcParam,
                    loc: locParam,
                    ctry: $stateParams.ctry,
                    lat: lat,
                    lng: lng,
                    query: 'q= ',
                    sensor: false,
                    size: 30,
                    sort: 'companylegalname,asc',
                    page: $stateParams.page - 1,
                    range: range,
                    mcs: $rootScope.mcsfilters,
                    mocs: $rootScope.mocsfilters,
                    recs: $rootScope.recsfilters,
                    sucs: $rootScope.sucsfilters,
                    frcs: $rootScope.frcsfilters,
                    uscs: $rootScope.uscsfilters,
                    lngs: $rootScope.lngsfilters,
                    assocs: !_.isEmpty($rootScope.assocsfilters) ? $rootScope.assocsfilters : $stateParams.assocs,
                    certs: $rootScope.certsfilters,
                    cmp: $rootScope.compliancefilters,
                    inq: $rootScope.contactQualificationfilters,
                    spc: $stateParams.spc,
                    supplier: supplierParam
                }

            }).then(function (response, status, headers, config) {

                vm.footerCountMessage = response.headers('APP_IAM_COUNT_MESSAGE');
                $scope.sr = response.data;
                vm.totalItems = response.headers('X-Total-Count');

                $scope.getSubscriptionControl();

                $scope.supplierAdSearch();

                // MX-672
                if (vm.footerCountMessage != null && vm.footerCountMessage.startsWith('Displaying (0) IAM Member(s) of') && svcParam !== null && svcParam !== " ") {
                    $rootScope.searchModeLabel = 'Display IAM members Only';
                    $rootScope.searchModeText = 'Displaying all Service Providers';
                }

                if (vm.totalItems === 0
                    || vm.totalItems === null
                    || response.status !== 200)
                    vm.noresults = true;

                vm.queryCount = vm.totalItems;
                vm.itemsPerPage = 30;
                vm.links = ParseLinks.parse(response.headers('Link'));
                vm.page = $stateParams.page;

                if ($scope.sr && $scope.sr.length > 0) {
                    var slice = 10;
                    if ($scope.sr.length < 10) {
                        slice = 0;
                    }
                    var top10Rank = _.slice($scope.sr, 0, slice);
                    var top10RankSPs = '';
                    for (var i = 0; i < top10Rank.length; i++) {
                        top10RankSPs = top10RankSPs + ' ' + $scope.sr[i].companylegalname;
                    }
                    $scope.top10Sps = top10RankSPs;
                    $analytics.eventTrack('Top 10 Search Results', { category: 'search', label: top10RankSPs });
                    vm.generateEventGa4('IAMX Top 10 results', { 'top10_results_label': top10RankSPs });
                }

                vm.setPCGFlags($scope.sr);
            },
                function (response) {
                    vm.noresults = true;
                }
            );
        };
})();
