(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('AccessDeniedController', AccessDeniedController);

    AccessDeniedController.$inject = ['$http', '$scope', '$state', 'Auth', 'Principal',
        'ProfileService', 'LoginService', '$rootScope', '$scope', '$rootScope'];

    function AccessDeniedController($http, $scope, $state, Auth, Principal, ProfileService, LoginService, $rootScope) {

        $rootScope.isAuthenticated = false;
        $scope.login = function login() {
            LoginService.open();
            //$state.go('home');
        };

    }

})();
