(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('ContactQualificationsDialogController', ContactQualificationsDialogController);

    ContactQualificationsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ContactQualifications', 'FileControl'];

    function ContactQualificationsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ContactQualifications, FileControl) {
        var vm = this;

        vm.contactQualifications = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.filecontrols = FileControl.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.contactQualifications.id !== null) {
                ContactQualifications.update(vm.contactQualifications, onSaveSuccess, onSaveError);
            } else {
                ContactQualifications.save(vm.contactQualifications, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('mobilityexchangeApp:contactQualificationsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.certDate = false;
        vm.datePickerOpenStatus.expDate = false;
        vm.datePickerOpenStatus.dob = false;
        vm.datePickerOpenStatus.createdOn = false;
        vm.datePickerOpenStatus.modifiedOn = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
