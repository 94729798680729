(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('sql-extract', {
            parent: 'entity',
            url: '/sql-extract?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_ASSN_FINANCE'],
                pageTitle: 'mobilityexchangeApp.sQLExtract.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/sql-extract/sql-extracts.html',
                    controller: 'SQLExtractController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('sQLExtract');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('sql-extract-detail', {
            parent: 'sql-extract',
            url: '/sql-extract/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'mobilityexchangeApp.sQLExtract.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/sql-extract/sql-extract-detail.html',
                    controller: 'SQLExtractDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('sQLExtract');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SQLExtract', function($stateParams, SQLExtract) {
                    return SQLExtract.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'sql-extract',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('sql-extract-detail.edit', {
            parent: 'sql-extract-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sql-extract/sql-extract-dialog.html',
                    controller: 'SQLExtractDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['SQLExtract', function(SQLExtract) {
                            return SQLExtract.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('sql-extract.new', {
            parent: 'sql-extract',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sql-extract/sql-extract-dialog.html',
                    controller: 'SQLExtractDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                category: null,
                                report_name: null,
                                coresql: null,
                                header_names: null,
                                orderby: null,
                                conditioncount: null,
                                condition1Name: null,
                                condition1Field: null,
                                condition1Operator: null,
                                condition1Value: null,
                                condition2Name: null,
                                condition2Field: null,
                                condition2Operator: null,
                                condition2Value: null,
                                createdBy: null,
                                createdDate: null,
                                modifiedby: null,
                                modifiedDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('sql-extract', null, { reload: 'sql-extract' });
                }, function() {
                    $state.go('sql-extract');
                });
            }]
        })
        .state('sql-extract.edit', {
            parent: 'sql-extract',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sql-extract/sql-extract-dialog.html',
                    controller: 'SQLExtractDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['SQLExtract', function(SQLExtract) {
                            return SQLExtract.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('sql-extract', null, { reload: 'sql-extract' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('sql-extract.schedule', {
            parent: 'sql-extract',
            url: '/{id}/schedule',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sql-extract/report-schedule.html',
                    // controller: 'SQLExtractDialogController',
                    controller: 'ReportScheduleController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ReportSchedule', function(ReportSchedule) {
                            return ReportSchedule.getBySqlExtractId({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('sql-extract', null, { reload: 'sql-extract' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('sql-extract.delete', {
            parent: 'sql-extract',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sql-extract/sql-extract-delete-dialog.html',
                    controller: 'SQLExtractDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['SQLExtract', function(SQLExtract) {
                            return SQLExtract.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('sql-extract', null, { reload: 'sql-extract' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
