(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('MemberPaymentControlDeleteController',MemberPaymentControlDeleteController);

    MemberPaymentControlDeleteController.$inject = ['$uibModalInstance', 'entity', 'MemberPaymentControl'];

    function MemberPaymentControlDeleteController($uibModalInstance, entity, MemberPaymentControl) {
        var vm = this;

        vm.memberPaymentControl = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            MemberPaymentControl.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
