(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('service-providers', {
                parent: 'entity',
                url: '/service-providers?page&sort&search&navtype&searchBasic&searchType',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN'],
                    pageTitle: 'mobilityexchangeApp.serviceProviders.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/service-providers/service-providers.html',
                        controller: 'ServiceProvidersController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    searchBasic: null,
                    navtype: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            searchBasic: $stateParams.searchBasic
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('serviceProviders');
                        $translatePartialLoader.addPart('adlTracker');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('service-providers-detail', {
                parent: 'entity',
                url: '/service-providers/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN'],
                    pageTitle: 'mobilityexchangeApp.serviceProviders.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/service-providers/service-providers-detail.html',
                        controller: 'ServiceProvidersDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('serviceProviders');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ServiceProviders', function ($stateParams, ServiceProviders) {
                        return ServiceProviders.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'service-providers',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('service-providers-detail.edit', {
                parent: 'service-providers-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/service-providers/service-providers-dialog.html',
                        controller: 'ServiceProvidersDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'xs',
                        resolve: {
                            entity: ['ServiceProviders', function (ServiceProviders) {
                                return ServiceProviders.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('payment', {
                parent: 'entity',
                url: '/payment?spId={id}',
                data: {
                    authorities: [],
                    pageTitle: 'claimbusiness.title'
                },
                params: {
                  sp: null
                },
                views: {
                    'content@': {
                        templateUrl: 'app/payments/payment.html',
                        controller: 'PayController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'ServiceProviders', function ($stateParams, ServiceProviders) {
                        return ServiceProviders.get({id: $stateParams.id}).$promise;
                    }]
                }

            })

            .state('service-providers.new', {
                parent: 'service-providers',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/service-providers/service-providers-dialog.html',
                        controller: 'ServiceProvidersDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {


                    entity: function () {
                        return {
                            companyid: null,
                            companylegalname: null,
                            website: null,
                            tradenames: null,
                            established: null,
                            companydescription: null,
                            mxstatus: null,
                            movingservices: null,
                            relocationservices: null,
                            freightservices: null,
                            suppliers: null,
                            others: null,
                            createdby: null,
                            createddate: null,
                            modifiedby: null,
                            modifieddate: null,
                            id: null
                        };
                    },

                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            isCopy: 'false',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }


            })

            .state('service-providers.edit', {
                parent: 'service-providers',
                url: '/{id}/edit?navtype={navtype}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN']
                },
                params : {
                    navtype: '1'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/service-providers/service-providers-dialog.html',
                        controller: 'ServiceProvidersDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                    entity: ['$stateParams', 'ServiceProviders', function ($stateParams, ServiceProviders) {
                        return ServiceProviders.get({id: $stateParams.id}).$promise;
                    }],

                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            isCopy: 'false',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }

            })

            .state('service-providers.editcopy', {
                parent: 'service-providers',
                url: '/{id}/editcopy',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/service-providers/service-providers-dialog.html',
                        controller: 'ServiceProvidersDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'ServiceProviders', function ($stateParams, ServiceProviders) {
                        return ServiceProviders.copyChild({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            isCopy: 'true',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }

            })
            .state('service-providers.delete', {
                parent: 'service-providers.edit',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/service-providers/service-providers-delete-dialog.html',
                        controller: 'ServiceProvidersDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['ServiceProviders', function (ServiceProviders) {
                                return ServiceProviders.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('service-providers.edit', null, {reload: 'service-providers.edit'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('service-providers.deleteaddress', {
                parent: 'service-providers.edit',
                url: '/{spid}/{addid}/deleteaddress',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/service-providers/service-providers-addresses-delete-dialog.html',
                        controller: 'SPAddressesDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Addresses', function (Addresses) {
                                return Addresses.get({id: $stateParams.addid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('service-providers.edit', null, {reload: 'service-providers.edit'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('service-providers.movecontact', {
	            parent: 'service-providers.edit',
	            url: '/{cid}/movecontact',
	            data: {
	                authorities: ['ROLE_ADMIN']
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/entities/member-contacts/member-contacts-move-dialog.html',
	                    controller: 'MemberContactsMoveController',
	                    controllerAs: 'vm',
	                    size: 'md',
	                    resolve: {
                            entity: ['MemberContacts', function (MemberContacts) {
                                return MemberContacts.get({id: $stateParams.cid}).$promise;
                            }]
                        }
	                }).result.then(function () {
	                    $state.go('service-providers.edit', null, {reload: 'service-providers.edit'});
	                }, function () {
	                    $state.go('^');
	                });
	            }]
	        })
            .state('service-providers.deletecontact', {
                parent: 'service-providers.edit',
                url: '/{spid}/{contid}/deletecontact',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/service-providers/service-providers-contact-delete-dialog.html',
                        controller: 'SPContactDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['ProviderContacts', function (ProviderContacts) {
                                return ProviderContacts.get({id: $stateParams.contid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('service-providers.edit', null, {reload: 'service-providers.edit'});
                    }, function () {
                        $state.go('^');
                    });
                }],
            })

            .state('service-providers.edit.customer-notes-list', {
                url: '/customer-notes-list-sp/{spid}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ANALYST']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/customer-notes/customer-notes.html',
                        controller: 'ServiceProvidersDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        windowClass: 'crm-modal-dialog',
                        size: 'lg',
                        resolve: {
                            entity: ['ServiceProviders', function (ServiceProviders) {
                                return ServiceProviders.get({id: $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader',
                                function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customerNotes');
                                return $translate.refresh();
                            }],
                        }
                    }).result.finally(function () {
                        $state.go('^');
                    });
                }]
            })

            .state('service-providers.edit.customer-notes-list.customer-notes-new', {
                url: '/customer-notes-new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ANALYST']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/customer-notes/customer-notes-dialog.html',
                        controller: 'CustomerNotesDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                	ServiceProviders: {id: $stateParams.id,},
                                    notes_type: null,
                                    note: null,
                                    action: null,
                                    targetDate: null,
                                    status: null,
                                    createdby: null,
                                    createddate: null,
                                    modifiedby: null,
                                    modifieddate: null,
                                    id: null
                                };
                            },
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customerNotes');
                                return $translate.refresh();
                            }],
                        }
                    }).result.finally(function () {
                        $state.go('^');
                    });
                }]
            })

            .state('service-providers.edit.customer-notes-list.customer-notes-edit', {
                url: '/customer-notes-edit/{customerNotesId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ANALYST']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/customer-notes/customer-notes-dialog.html',
                        controller: 'CustomerNotesDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['CustomerNotes', function (CustomerNotes) {
                                return CustomerNotes.get({id: $stateParams.customerNotesId}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customerNotes');
                                return $translate.refresh();
                            }],
                        }
                    }).result.finally(function () {
                        $state.go('^');
                    });
                }]
            })

            .state('service-providers.edit.iamtrusted-affirmation-form', {
                url: '/iamtrusted-affirmation/form/{spid}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/iamtrusted-affirmation/iamtrusted-affirmation-form.html',
                        controller: 'ServiceProvidersDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ServiceProviders', function (ServiceProviders) {
                                return ServiceProviders.get({id: $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader',
                                function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('iamtrustedAffirmation');
                                return $translate.refresh();
                            }],
                        }
                    }).result.finally(function () {
                        $state.go('^');
                    });
                }]
            })

            .state('payment-gateway', {
                parent: 'entity',
                url: '/paymentgateway?spId={id}&invoiceid={invId}&ref={r}',
                data: {
                    authorities: [],
                    pageTitle: 'claimbusiness.title'
                },
                params: {
                    sp: null
                },
                views: {
                    'content@': {
                        templateUrl: 'app/payments/paymentgateway.html',
                        controller: 'PaymentGateWayController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'InvoiceHeader', function ($stateParams, InvoiceHeader) {
                        if($stateParams.invId)
                            return InvoiceHeader.get({id: $stateParams.invId}).$promise;
                        if($stateParams.ref)
                            return InvoiceHeader.getByRef({ref: $stateParams.ref}).$promise;
                    }]
                }

            })

        ;
    }

})();
