(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('Communication_extractDialogController', Communication_extractDialogController);

    Communication_extractDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Communication_extract','Lookups'];

    function Communication_extractDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Communication_extract,Lookups) {
        var vm = this;

        vm.communication_extract = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        
        vm.categories = Lookups.getByLookupType({id: 64});
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.communication_extract.id !== null) {
                Communication_extract.update(vm.communication_extract, onSaveSuccess, onSaveError);
            } else {
                Communication_extract.save(vm.communication_extract, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('mobilityexchangeApp:communication_extractUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createddate = false;
        vm.datePickerOpenStatus.modifieddate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
