(function() {
	// 'use strict';

	angular.module('mobilityexchangeApp').controller('IssueTrackerController', IssueTrackerController).
	    controller('IssueTrackerFileController', IssueTrackerFileController).
	    controller('IssueTrackerNonMemberSelectedController', IssueTrackerNonMemberSelectedController);

	IssueTrackerController.$inject = [ 'IssueTracker', 'pagingParams', '$http',
			'$scope', 'Lookups', 'AlertService', 'Principal',
			'IssueTrackerSearch', 'paginationConstants', '$stateParams',
			'ParseLinks', '$state' ];

	IssueTrackerFileController.$inject = [ '$http', '$scope',
			'DTOptionsBuilder', 'DTColumnDefBuilder', 'IssueTracker', 'entity', 'DateUtils', '$uibModal', '$state' ];

	IssueTrackerNonMemberSelectedController.$inject = ['$timeout', '$scope',
		'$stateParams', 'entity', 'IssueTracker', 'Lookups', 'BillingPlan',
		'DateUtils', '$http', 'BillingDiscount', '$q', 'ServiceProviders',
		'Principal', 'DTOptionsBuilder', 'DTColumnDefBuilder', '$compile',
		'$uibModal', '$cookies', 'MemberProfile', '$location'];

	function IssueTrackerController(IssueTracker, pagingParams, $http, $scope,
			Lookups, AlertService, Principal, IssueTrackerSearch,
			paginationConstants, $stateParams, ParseLinks, $state) {

		var vm = this;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.loadAll = loadAll;

		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.clear = clear;
		vm.transitionOnKeyUp = transitionOnKeyUp;
		vm.search = search;
		vm.searchQuery = pagingParams.search;
		vm.currentSearch = pagingParams.search;
		vm.issueId = $stateParams.issueId;
		vm.filedBy = $stateParams.filedBy;
		vm.filedByMxStatus = $stateParams.filedByMxStatus;
		vm.filedAgainst = $stateParams.filedAgainst;
		vm.filedAgainstMxStatus = $stateParams.filedAgainstMxStatus;
        vm.submittedOn = $stateParams.submittedOn;
        vm.acknowledgedTarget = $stateParams.acknowledgedTarget;
        vm.acknowledgedActual = $stateParams.acknowledgedActual;
        vm.resolutionTarget = $stateParams.resolutionTarget;
        vm.resolutionActual = $stateParams.resolutionActual;
		vm.type = $stateParams.type;
		vm.status = $stateParams.status;

		vm.issueType = Lookups.getByLookupType({
			id : 91
		});
		vm.issueStatus = Lookups.getByLookupType({
			id : 92
		});

		$scope.isIssueAdmin = function() {
			return Principal.hasAnyAuthority([ 'IIRS_ADMIN' ]);
		};

		loadAll();

		function loadAll() {
			if (pagingParams.search) {
				IssueTrackerSearch.query({
					query : pagingParams.search,
					page : pagingParams.page - 1,
					size : vm.itemsPerPage,
					sort : sort(),
					issueId : vm.issueId ? vm.issueId : $stateParams.issueId,
					filedBy : vm.filedBy ? vm.filedBy : $stateParams.filedBy,
					filedByMxStatus : vm.filedByMxStatus ? vm.filedByMxStatus : $stateParams.filedByMxStatus,
					filedAgainst : vm.filedAgainst ? vm.filedAgainst
                    							: $stateParams.filedAgainst,
					filedAgainstMxStatus : vm.filedAgainstMxStatus ? vm.filedAgainstMxStatus
                    							: $stateParams.filedAgainstMxStatus,
                    submittedOn : vm.submittedOn ? vm.submittedOn : $stateParams.submittedOn,
                    acknowledgedTarget : vm.acknowledgedTarget ? vm.acknowledgedTarget : $stateParams.acknowledgedTarget,
                    acknowledgedActual : vm.acknowledgedActual ? vm.acknowledgedActual : $stateParams.acknowledgedActual,
                    resolutionTarget : vm.resolutionTarget ? vm.resolutionTarget : $stateParams.resolutionTarget,
                    resolutionActual : vm.resolutionActual ? vm.resolutionActual : $stateParams.resolutionActual,
                    type : vm.type ? vm.type : $stateParams.type,
					status : vm.status ? vm.status : $stateParams.status
				}, onSuccess, onError);
			} else {
				IssueTracker.query({
					page : pagingParams.page - 1,
					size : vm.itemsPerPage,
					sort : sort()

				}, onSuccess, onError);
			}

			function sort() {

				var result = [ vm.predicate + ','
						+ (vm.reverse ? 'asc' : 'desc') ];

				if (vm.predicate !== 'id') {
					result.push('id');
				}
				return result;
			}
		}

		function onSuccess(data, headers) {
			vm.links = ParseLinks.parse(headers('link'));
			vm.totalItems = headers('X-Total-Count');
			vm.queryCount = vm.totalItems;
			// TODO: Mapping name (if it's referenced)
			vm.IssueTrackers = data;
			vm.claims = vm.IssueTrackers.claims;
			vm.delinquents = vm.IssueTrackers.delinquent;

			vm.page = pagingParams.page;
		}

		function onError(error) {
			AlertService.error(error.data.message);
		}

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch,
				issueId : vm.issueId ? vm.issueId : $stateParams.issueId,
				filedBy : vm.filedBy ? vm.filedBy : $stateParams.filedBy,
                filedByMxStatus : vm.filedByMxStatus ? vm.filedByMxStatus : $stateParams.filedByMxStatus,
				filedAgainst : vm.filedAgainst ? vm.filedAgainst
                						: $stateParams.filedAgainst,
				filedAgainstMxStatus : vm.filedAgainstMxStatus ? vm.filedAgainstMxStatus
                						: $stateParams.filedAgainstMxStatus,
                submittedOn : vm.submittedOn ? vm.submittedOn : $stateParams.submittedOn,
                acknowledgedTarget : vm.acknowledgedTarget ? vm.acknowledgedTarget : $stateParams.acknowledgedTarget,
                acknowledgedActual : vm.acknowledgedActual ? vm.acknowledgedActual : $stateParams.acknowledgedActual,
                resolutionTarget : vm.resolutionTarget ? vm.resolutionTarget : $stateParams.resolutionTarget,
                resolutionActual : vm.resolutionActual ? vm.resolutionActual : $stateParams.resolutionActual,
                type : vm.type ? vm.type : $stateParams.type,
				status : vm.status ? vm.status : $stateParams.status
			});
		}

		function search(searchQuery) {
			searchQuery = '-';
			if (!searchQuery) {
				return vm.clear();
			}
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			vm.currentSearch = searchQuery;
			vm.transition();
		}

		function clear() {
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = true;
			vm.currentSearch = null;
			vm.transition();
		}

		$scope.goToOnKeyUp = function(e, searchQuery) {
			if (event.keyCode === 13) {
				vm.transitionOnKeyUp(searchQuery);
			}
		}

		function transitionOnKeyUp(searchQuery) {
			searchQuery = '-';
			if (!searchQuery) {
				return vm.clear();
			}
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			vm.currentSearch = searchQuery;
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch,
				issueId: vm.issueId,
				filedBy : vm.filedBy,
				filedByMxStatus : vm.filedByMxStatus,
				filedAgainst : vm.filedAgainst,
				filedAgainstMxStatus : vm.filedAgainstMxStatus,
                submittedOn : vm.submittedOn,
                acknowledgedTarget : vm.acknowledgedTarget,
                acknowledgedActual : vm.acknowledgedActual,
                resolutionTarget : vm.resolutionTarget,
                resolutionActual : vm.resolutionActual,
				type : vm.type,
				status : vm.status
			});
		}
	}

	function IssueTrackerFileController($http, $scope, DTOptionsBuilder,
			DTColumnDefBuilder, IssueTracker, entity, DateUtils, $uibModal, $state) {
		var vm = this;

		vm.today = new Date();
		vm.dtOptions1 = DTOptionsBuilder.newOptions().withDOM(
				'C<"clear">lfrtip');
		vm.dtOptions2 = DTOptionsBuilder.newOptions().withDOM(
		'C<"clear">lfrtip').withDisplayLength(100).withLanguage({"sSearch":"Refine Results:"});
		vm.dtColumnDefs1 = [ DTColumnDefBuilder.newColumnDef(3).notSortable() ];

        $scope.terminationDates = [];
		$scope.isActiveRPP = [];
		$scope.ableTofileClaim = false;
		// This is the main service provider of the user that is creating an issue
		vm.claimantServiceProvider = entity;

		// For multi-SP admins that can select an SP other than the default SP
		vm.claimantServiceProviderCandidates = [];

        vm.selectedClaimantServiceProviderId = vm.claimantServiceProvider.id;
        console.log("selectedClaimantServiceProviderId: " + vm.selectedClaimantServiceProviderId);

        // Candidate claimant table options
        vm.dtOptions3 = DTOptionsBuilder.newOptions('className',
			'details-control').withDOM('C<"clear">lfrtip');
		vm.dtColumnDefs3 = [DTColumnDefBuilder.newColumnDef(5).notSortable()];
		vm.dtColumnDefs4 = [DTColumnDefBuilder.newColumnDef(0).notSortable()];


		if (vm.claimantServiceProvider
				&& vm.claimantServiceProvider.memberAssociations) {
			for (var k = 0; k < vm.claimantServiceProvider.memberAssociations.length; k++) {
				if (vm.claimantServiceProvider.memberAssociations[k].memberAssociation.id === 800
						&& (vm.claimantServiceProvider.memberAssociations[k].memberType.id === 1660
								|| vm.claimantServiceProvider.memberAssociations[k].memberType.id === 1661
								|| vm.claimantServiceProvider.memberAssociations[k].memberType.id === 1662
								|| vm.claimantServiceProvider.memberAssociations[k].memberType.id === 1676
								|| vm.claimantServiceProvider.memberAssociations[k].memberType.id === 1678
								|| vm.claimantServiceProvider.memberAssociations[k].memberType.id === 1679
								|| vm.claimantServiceProvider.memberAssociations[k].memberType.id === 5555
								|| vm.claimantServiceProvider.memberAssociations[k].memberType.id === 5556
								|| vm.claimantServiceProvider.memberAssociations[k].memberType.id === 5557
								|| vm.claimantServiceProvider.memberAssociations[k].memberType.id === 5558
								)
						&& vm.claimantServiceProvider.memberAssociations[k].memberStatus.id === 1201) {
					$scope.ableTofileClaim = true;
					break;
				}
			}
		}

		IssueTracker.getClaimantCandidates().$promise.then(function success(response) {
            vm.claimantServiceProviderCandidates = response;
            console.log('Claimant candidates: ' + vm.claimantServiceProviderCandidates);
        });

		vm.getServiceProviders = function(search) {
			$http
					.get(
							'api/_search/services/serviceprovidername/?includeNonOperational=true&query='
									+ search)
					.then(
							function(response) {
								vm.serviceProvidersSearchResult = response.data;

								if (vm.serviceProvidersSearchResult) {
									for (var i = 0; i < vm.serviceProvidersSearchResult.length; i++) {
										isActiveRPP(vm.serviceProvidersSearchResult[i].id);
									}
								}
							});
		};

		vm.changeSelectedClaimantServiceProviderId = function(value) {

		    console.log("Changed selected claimant service provider, value: " + value + ', current filing company: ' + vm.selectedClaimantServiceProviderId);
		}

		vm.initializeClaimantProvider = function (serviceProviderId) {
			console.log('Initialize filing company: ' + serviceProviderId);
			if (serviceProviderId == vm.claimantServiceProvider.id) {
			    return true;
			} else {
			    return false;
			}
		};

		function isActiveRPP(id) {
			$http.get('api/member-associations/rpp/' + id).then(
					function(response) {
						vm.rppMember = response.data;
						if (vm.rppMember) {
							$scope.isActiveRPP[id] = true;
							$scope.terminationDates[id] = DateUtils.convertLocalDateFromServer(vm.rppMember.terminationDate);
						}
					});
		}

        vm.processServiceProviderSelection = processServiceProviderSelection;
        function processServiceProviderSelection(serviceProvider) {
            console.log('Service provider selection: ' + serviceProvider.id + ', memberStatus: ' + serviceProvider.memberStatus);
            $scope.issueTrackerClaimAgainst = serviceProvider;

            $scope.nonMemberSelectedDialogInstance = $uibModal
                .open({
                    templateUrl: "app/entities/issue-tracker/non-member-company-selected-dialog.html",
                    scope: $scope,
                    size: 'lg'
                });
        }

		$scope.cancelIssueTrackerNonMember = function () {

            if ($scope.nonMemberSelectedDialogInstance) {
                $scope.nonMemberSelectedDialogInstance.close("Ok");
                $state.go('issue-tracker')
            }
        }
	}

    function IssueTrackerNonMemberSelectedController($timeout, $scope, $stateParams,
        entity, IssueTracker, Lookups, BillingPlan, DateUtils, $http,
        BillingDiscount, $q, ServiceProviders, Principal, DTOptionsBuilder,
        DTColumnDefBuilder, $compile, $uibModal, $cookies, MemberProfile, $location) {

        var vm = this;

        // Set filing service provider
        var filingServiceProvider = $location.search().filingServiceProvider;
        $scope.filingServiceProvider = filingServiceProvider;

        if (vm.filedAgainst == null) {
            vm.filedAgainst = IssueTracker.getServiceProvider({
                id: $stateParams.sp
            });
        }
    }

})();
