(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('EmailLogsSearch', EmailLogsSearch);

    EmailLogsSearch.$inject = ['$resource'];

    function EmailLogsSearch($resource) {
        var resourceUrl =  'api/_search/email-logs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
