(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('QuotationRequestDetailController', QuotationRequestDetailController);

    QuotationRequestDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'QuotationRequest', 'ServiceProviders'];

    function QuotationRequestDetailController($scope, $rootScope, $stateParams, previousState, entity, QuotationRequest, ServiceProviders) {
        var vm = this;

        vm.quotationRequest = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:quotationRequestUpdate', function(event, result) {
            vm.quotationRequest = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
