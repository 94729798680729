(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('ServiceProvidersController', ServiceProvidersController);

    ServiceProvidersController.$inject = ['$rootScope', '$scope', '$state', 'ServiceProviders',
        'ServiceProvidersSearch', 'ParseLinks', 'AlertService',
        'pagingParams', 'paginationConstants','Principal'];

    function ServiceProvidersController($rootScope, $scope, $state, ServiceProviders, ServiceProvidersSearch, ParseLinks,
                                        AlertService, pagingParams, paginationConstants,Principal) {
        var vm = this;
        $rootScope.image_path = "";
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;

        vm.clearBasic = clearBasic;
        vm.searchBasic = searchBasic;

        vm.loadAll = loadAll;

        vm.searchQuery = pagingParams.search;
        vm.searchBasicQuery = pagingParams.searchBasic;

        vm.currentSearch = pagingParams.search;
        vm.currentSearchBasic = pagingParams.searchBasic;

        $rootScope.showSearchBox = true;
        $rootScope.mainLogoLink = "#";
        loadAll();


        vm.displayInvoiceButton = function(s){
            return true;
        };

        $scope.ifUserAdmin = function () {
            return Principal.hasAnyAuthority(['ROLE_ADMIN']);
        };

        $scope.ifUserFinanceAdmin = function () {
            return Principal.hasAnyAuthority(['ROLE_ASSN_FINANCE']);
        };

        $scope.hasOnlineRates = function (serviceProvider) {
            return serviceProvider.mxstatus.lookupcode === '3' || serviceProvider.mxstatus.lookupcode === '4' || serviceProvider.mxstatus.lookupcode==='5';
        }

        function loadAll() {
            if (pagingParams.search) {
                ServiceProvidersSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else if (pagingParams.searchBasic) {
              ServiceProvidersSearch.query({
                  query: pagingParams.searchBasic,
                  searchType: 'basic',
                  page: pagingParams.page - 1,
                  size: vm.itemsPerPage,
                  sort: sort()
              }, onSuccess, onError);
            } else {
                ServiceProviders.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.serviceProviders = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                searchBasic: vm.currentSearchBasic
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function searchBasic(searchQuery) {
            if (!searchQuery) {
                return vm.clearBasic();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;

            vm.currentSearchBasic = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function clearBasic() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearchBasic = null;
            vm.transition();
        }

        vm.getMemberType = function (serviceProvider){
            if(serviceProvider.memberAssociations ) {
                var iammembertypeHeader = '';
                for (var i = 0; i < serviceProvider.memberAssociations.length; i++) {
                    if (serviceProvider.memberAssociations[i]
                        && serviceProvider.memberAssociations[i].memberType
                        && serviceProvider.memberAssociations[i].memberStatus
                        && serviceProvider.memberAssociations[i].memberStatus.lookupcode === 'ACT'
                    ) {
                        iammembertypeHeader = iammembertypeHeader +'  '+serviceProvider.memberAssociations[i].memberType.lookupcode;
                    }
                }
            }
            return iammembertypeHeader;
        };
    }
})();
