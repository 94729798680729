(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('quotation-request-dashboard', {
                parent: 'entity',
                url: '/quotation-request-dashboard?page&sort&search',
                data: {
                    /*authorities: ['ROLE_ADMIN','ROLE_SP_ADMIN','ROLE_SP_FINANCE','ROLE_SP_PRICING'],*/
                    authorities: ['ROLE_ADMIN','ROLE_SP_PRICING'],
                    pageTitle: 'mobilityexchangeApp.quotationRequest.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/quotation-request/quotation-dashboard.html',
                        controller: 'QuotationRequestController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('quotationRequest');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('quotation-request', {
                parent: 'entity',
                url: '/quotation-request?page&sort&search&create&req_status&res_status&filter',
                data: {
                    /*authorities: ['ROLE_ADMIN','ROLE_SP_ADMIN','ROLE_SP_FINANCE','ROLE_SP_PRICING'],*/
                    authorities: ['ROLE_ADMIN','ROLE_SP_PRICING'],
                    pageTitle: 'mobilityexchangeApp.quotationRequest.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/quotation-request/quotation-requests.html',
                        controller: 'QuotationRequestController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    create: null,
                    req_status: null

                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('quotationRequest');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('quotation-request-dtls', {
                parent: 'entity',
                url: '/quotation-request/{reqId}?newReq=1',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SP_PRICING'],
                    pageTitle: 'mobilityexchangeApp.quotationRequest.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/quotation-request/quotation-requests.html',
                        controller: 'QuotationRequestController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        //$translatePartialLoader.addPart('quotationRequest');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })



        .state('quotation-request-detail', {
            parent: 'quotation-request',
            url: '/quotation-request/{id}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SP_PRICING'],
                pageTitle: 'mobilityexchangeApp.quotationRequest.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/quotation-request/quotation-request-detail.html',
                    controller: 'QuotationRequestDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('quotationRequest');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'QuotationRequest', function($stateParams, QuotationRequest) {
                    return QuotationRequest.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'quotation-request',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('quotation-request.edit', {
            parent: 'quotation-request',
            url: '/detail/edit/{id}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SP_PRICING']
            },
            resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('quotationRequest');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'QuotationRequest', function($stateParams, QuotationRequest) {
                        return QuotationRequest.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'quotation-request',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/quotation-request/quotation-request-dialog.html',
                    controller: 'QuotationRequestDialogController',
                    controllerAs: 'vm'
                }
            }
        })
            .state('quotation-request.new', {
                parent: 'quotation-request',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SP_PRICING']
                },
                resolve: {
                    entity: function () {
                        return {
                            requestId: null,
                            serviceDate: null,
                            serviceproviderId: null,
                            providerContactId: null,
                            origin: null,
                            originGeocode: null,
                            destination: null,
                            destinationGeocode: null,
                            description: null,
                            fileControlId: null,
                            createdBy: null,
                            createdOn: null,
                            modofiedBy: null,
                            modifiedOn: null,
                            requestStatus: null,
                            id: null
                        };
                    }
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/quotation-request/quotation-request-dialog.html',
                        controller: 'QuotationRequestDialogController',
                        controllerAs: 'vm'
                    }
                },
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        isCopy: 'false',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            })


            .state('showQuoteDialog', {
                parent: 'search',
                url: '/request-quotation?spId',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SP_PRICING']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/quotation-request/request-quotation.html',
                        controller: 'QuotationRequestController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('quotationRequest');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }

            })
            .state('quotation-response-review', {
                parent: 'entity',
                url: '/quotation-response-review?resId&reqId&spId',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SP_PRICING'],
                    pageTitle: 'mobilityexchangeApp.quotationResponse.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/quotation-response/quotation-responses-review.html',
                        controller: 'QuotationResponseReviewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('quotationResponse');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

        .state('quotation-request.delete', {
            parent: 'quotation-request',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SP_PRICING']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/quotation-request/quotation-request-delete-dialog.html',
                    controller: 'QuotationRequestDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['QuotationRequest', function(QuotationRequest) {
                            return QuotationRequest.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('quotation-request', null, { reload: 'quotation-request' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
