(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('QuotationRequestController', QuotationRequestController)
        /*.controller('QuotationRequestDetailsController', QuotationRequestDetailsController)*/
    ;


    QuotationRequestController.$inject = ['$timeout', '$scope', '$stateParams', '$state',
        'QuotationRequest', 'QuotationRequestSearch',
        'ParseLinks', 'AlertService', 'paginationConstants',
        'pagingParams', 'Lookups', 'QuotationResponse','$http',
        'DateUtils', '$rootScope','QuotationRequestDtl','FileUploader','$cookies', 'Notifications', 'Principal'];

    function QuotationRequestController($timeout, $scope, $stateParams, $state, QuotationRequest,
                                        QuotationRequestSearch, ParseLinks, AlertService,
                                        paginationConstants, pagingParams,
                                        Lookups,QuotationResponse,$http,DateUtils,
                                        $rootScope, QuotationRequestDtl, FileUploader, $cookies, Notifications, Principal) {

        var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.reset = reset;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.quotationRequest = {};
        vm.quotationResponse = {};
        vm.quotationResponseData = [];
        vm.modes = Lookups.getByLookupType({id: 60});
        vm.serviceTypes = Lookups.getByLookupType({id: 56});
        vm.containerTypes = Lookups.getByLookupType({id: 57});
        vm.reqStatus = Lookups.getByLookupType({id: 58});
        vm.resStatus = Lookups.getByLookupType({id: 59});
        $rootScope.mcsfilters = undefined;
        $rootScope.mocsfilters = undefined;
        $rootScope.recsfilters = undefined;
        $rootScope.sucsfilters = undefined;
        $rootScope.frcsfilters = undefined;
        $rootScope.cscsfilters = undefined;
        $rootScope.uscsfilters = undefined;
        $rootScope.lngsfilters = undefined;
        $rootScope.lngsfilters = undefined;
        $rootScope.assocsfilters = "800";
        $rootScope.certsfilters = undefined;
        $rootScope.compliancefilters = undefined;
        $rootScope.searchMode = 'IAM';
        $rootScope.searchModeLabel = 'Display all Service Providers';
        $rootScope.searchModeText = 'Displaying IAM members Only';
        vm.sectionOpen = true;
        vm.uploading = false;

        vm.newRequest = false;
        vm.publishTemplUrl = 'publish_templ.html';
        vm.formclass = "{}";

        vm.datePickerOptions = { minDate: new Date() };
        vm.showStatusMessage = false;
        vm.isPublished = false;

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        vm.quotationRequestResponses;
        $scope.serviceproviderId ;

        vm.quoteModReason;

        vm.invalidForm = null;

        $scope.account = null;
        $scope.isAuthenticated = false;
        $scope.isAdminUser = false;



        vm.display = '';
        $timeout(function(){
            vm.display = 'display: block;';
        },2000);

        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
            for (var i = 0; i < $scope.account.authorities.length; i++) {
                if ($scope.account.authorities[i] === 'ROLE_ADMIN') {
                    $scope.isAdminUser = true;
                    break;
                }
            }

        });




        vm.showAddButton = function(idx,quotationDtls){
            if(vm.quotationRequest.requestStatus && vm.quotationRequest.requestStatus.id === 62)
                return false;
            if(idx+1 === quotationDtls.length){
                return true;
            }
        };


        vm.showHideStatusMessage = function(){
            vm.showStatusMessage ? vm.showStatusMessage = false : vm.showStatusMessage = true;

        };

        vm.showHideRFQList = function(){
            vm.newRequest = false;
            $state.go("quotation-request",{filter:'N'});
            loadAll();
        };


        vm.publishQuote = function(id){

            vm.isPublished = true;

            if(!$scope.editForm.$valid) {
                vm.invalidForm = "You have missed entering required fields. All fields marked with an * are required. " +
                    "Please identify the fields in error marked in red, enter appropriate data and Save again.";
                $scope.editForm.submitted=true;
                return false;
            }else{
                vm.invalidForm = "";
                var publishedStatus = _.find(vm.reqStatus, {id:61});
                vm.quotationRequest.requestStatus = {};
                vm.quotationRequest.requestStatus = publishedStatus;
                $scope.editForm.submitted=true;
                save();
            }

        };

        vm.closeQuote = function(id){
            var closedStatus = _.find(vm.reqStatus, {id:62});
            vm.quotationRequest.requestStatus = {};
            vm.quotationRequest.requestStatus = closedStatus;
            save();
        };

        vm.withdrawQuote = function(id){
            var withdrawStatus = _.find(vm.reqStatus, {id:63});
            vm.quotationRequest.requestStatus = {};
            vm.quotationRequest.requestStatus = withdrawStatus;
            save();
        };

        vm.deleteQuoteOption = function($event, quotationDtls, idx){
            $event.preventDefault();
            $event.stopPropagation();

            if(!quotationDtls.id){
                vm.quotationRequest.quotationRequestDtls[idx] = null;
                var qIndex = 0;
                _.remove(vm.quotationRequest.quotationRequestDtls, function (qtDtl) {
                    if(qIndex === idx)
                        return true;
                    qIndex++;
                });
            }else {
                _.remove(vm.quotationRequest.quotationRequestDtls, function (qtDtl) {
                    return qtDtl.id === quotationDtls.id;
                });
                QuotationRequestDtl.delete({id:quotationDtls.id});
            }


            if(vm.quotationRequest.quotationRequestDtls && vm.quotationRequest.quotationRequestDtls.length == 0){
                vm.quotationRequest.quotationRequestDtls = [{}];
            }
        };

        vm.createNewQuote = function(){
            vm.newRequest = true;
            vm.quotationRequest = {};
            var draftStatus = _.find(vm.reqStatus, {id:60});
            vm.quotationRequest.requestStatus = {};
            vm.quotationRequest.requestStatus = draftStatus;
            vm.asyncOrigSelected = {};
            vm.asyncDestSelected = {};
            vm.quotationRequest.quotationRequestDtls = [{}];
            vm.quotationRequestResponses = {};
            vm.noQuoteRequest = false;
            vm.uploadedFiles = [];
        };

        vm.searchLocation = function(lat_lng, loc){

            //console.log('lat_lng, loc '+lat_lng+' -- '+loc);

            if(!lat_lng || !loc){
                return false;
            }

            var latlng = _.split(lat_lng, ',', 2);
            params = {};
            params.assocs = 800;
            params.lat = latlng[0];
            params.lng = latlng[1];
            params.loc = loc;
            params.fvw = 'c';
            $state.go('search',params);
        };

        vm.setResponse = function(reqId){
            var spId = $stateParams.spId;
            var responseLength = vm.quotationResponseData.length;
            vm.quotationResponse = {};
            vm.quotationResponse.requestId = reqId;
            vm.quotationResponse.serviceproviderId = Number(spId);
            vm.quotationResponseData.push(vm.quotationResponse);
        };

        vm.saveReponse = function(){
            for(var i=0;i<vm.quotationResponseData.length; i++){
                QuotationResponse.save(vm.quotationResponseData[i]);
            }
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            //$uibModalInstance.dismiss('cancel');
        }

        vm.asyncOrigSelected = {};
        vm.asyncDestSelected = {};
        var params = {};
        vm.quotationRequest.quotationRequestDtls = [{}];
        vm.quoteOptionIdx = 0;
        vm.quoteOptionsSize = vm.quotationRequest.quotationRequestDtls.length;

        vm.addQuoteOption = function (event) {
            event.preventDefault();
            event.stopPropagation();
            vm.quotationRequest.quotationRequestDtls.push({});
        };

        vm.setWtUnit = function(wtUnit, volUnit){
            if(volUnit === "Cbm")
                wtUnit = "Kgs";
            else
                wtUnit = "Lbs";
            return wtUnit;
        };

        vm.setVolUnit = function(wtUnit, volUnit){
            if(wtUnit === "Lbs")
                volUnit = "Cft";
            else
                volUnit = "Cbm";
            return volUnit;
        };


        vm.showCreateButton = function(req){

            ////console.log(JSON.stringify(req.requestStatus));
            if( req && !req.id || (req.requestStatus && req.requestStatus.id === 61))
               return true;
            else
                return false;
        };


        vm.loadData = function(reqId){

            $http.get('api/quotation-requests/'+reqId).then(function(response) {

                vm.quotationRequest = response.data;
                vm.asyncOrigSelected = vm.quotationRequest.origin;
                vm.asyncDestSelected = vm.quotationRequest.destination;
                vm.quotationRequest.serviceDate = DateUtils.convertLocalDateFromServer(response.data.serviceDate);
                vm.quotationRequest.createdOn = DateUtils.convertLocalDateFromServer(response.data.createdOn);
                vm.quotationRequest.modifiedOn = DateUtils.convertLocalDateFromServer(response.data.modifiedOn);

                var draftStatus = _.find(vm.reqStatus, {id:60});
                if(!vm.quotationRequest.requestStatus)
                    vm.quotationRequest.requestStatus = draftStatus;

                vm.newRequest = true;

                $scope.serviceproviderId = vm.quotationRequest.serviceproviderId;
                $scope.quotationRequestId = vm.quotationRequest.id;

                vm.getFileRef($scope.serviceproviderId, $scope.quotationRequestId);

                $http.get('api/quotation-request-responses/'+reqId).then(function(response){

                    vm.quotationRequestResponses = response.data;

                    $http.post('api/quotation-response-sps', vm.quotationRequestResponses).then(

                        function(response){

                            if(response && response.data)
                                vm.mxConnectedSPNames = response.data;

                            var reqMap = {};

                            for(var i=0; i<vm.quotationRequestResponses.length; i++){

                                var req = vm.quotationRequestResponses[i].quotationRequest;

                                if(req && !req.id) {

                                    var refId = req;
                                    var refObj = _.find(vm.quotationRequestResponses, function(o){  return o.quotationRequest.id == refId; });
                                    vm.quotationRequestResponses[i].quotationRequest = refObj.quotationRequest;

                                }

                            }

                        }
                    );

                });

            });
        };



        vm.newRequest = $stateParams.newReq;

        function showDetail(){

            if(vm.newRequest && vm.newRequest === '1'){

                vm.newRequest = true;
                vm.loadAll();
                vm.loadData($stateParams.reqId);

            }
        }

        showDetail();


        vm.getSPNames = function(id){

            var spNames = _.find(vm.mxConnectedSPNames,{ quoteRequestId : id});

            if(!_.isUndefined(spNames))
                return (spNames.spNames).toString();
            else
                return "";
        };

        vm.getStausDesc = function (id) {
            var resStatus = _.find(vm.resStatus, {id: id});
            if(resStatus)
                return resStatus.lookupvalue;
            else
                return "";
        };

        vm.getReqStausDesc = function (id) {

            var reqStatus = _.find(vm.reqStatus, {id: id});
            if(reqStatus)
                return reqStatus.lookupvalue;
            else
                return "";
        };

        vm.getValidatedStatus = function (id) {

            var spNames = _.find(vm.mxConnectedSPNames,{ quoteRequestId : id});

            if(spNames && spNames.validated)
                return true;
            else
                return false;
        };


        vm.getRppStatus = function (id) {

            var spNames = _.find(vm.mxConnectedSPNames,{ quoteRequestId : id});

            if(spNames && spNames.rpp)
                return true;
            else
                return false;
        };

        vm.errorClass ;
        vm.errorConfirmMessage ;

        vm.confirmSave = function(event){

            event.preventDefault();
            event.stopPropagation();

            vm.errorClass = '';
            vm.errorConfirmMessage = "";

            if( _.isUndefined(vm.quoteModReason)  || _.isEmpty(vm.quoteModReason) || vm.quoteModReason === '' ){
                vm.errorClass = 'invalidInput';
                vm.errorConfirmMessage = "**Please enter a detailed description of the change";
                return false;
            }

            save();
        };


        function save () {


            if(!$scope.editForm.$valid && vm.isPublished) {
                    return false;
            }else{
                vm.invalidForm = "";
            }

            if(vm.asyncOrigSelected && vm.asyncOrigSelected.geometry){
                vm.quotationRequest.origin = vm.asyncOrigSelected.formatted_address;
                var lat_lng = vm.asyncOrigSelected.geometry.location;
                params.lat = lat_lng.lat();
                params.lng = lat_lng.lng();
                vm.quotationRequest.originGeocode = params.lat+","+params.lng;
            }

            if(vm.asyncDestSelected && vm.asyncDestSelected.geometry){
                vm.quotationRequest.destination = vm.asyncDestSelected.formatted_address;
                var lat_lng = vm.asyncDestSelected.geometry.location;
                params.lat = lat_lng.lat();
                params.lng = lat_lng.lng();
                vm.quotationRequest.destinationGeocode = params.lat+","+params.lng;
            }

            vm.isSaving = false;

            if(vm.quotationRequest.quotationResponses){
                for(var j=0; j<vm.quotationRequest.quotationResponses.length; j++){
                    vm.quotationRequest.quotationResponses[j].quotationResponseDtls = null;
                }
            }

           // console.log('  vm.quotationRequest.requestStatus.id '+vm.quotationRequest.requestStatus.id);

            var data = vm.quoteModReason;

            if( !_.isUndefined(vm.quotationRequest.id) && vm.quotationRequest.requestStatus.id === 61){
                $http.post('/api/quotation-request-mod-email/'+vm.quotationRequest.id , data);
                vm.quoteModReason = "";
            }
            if( _.isUndefined(vm.quotationRequest.id) ){
                var draftStatus = _.find(vm.reqStatus, {id:60});
                vm.quotationRequest.requestStatus = {};
                vm.quotationRequest.requestStatus = draftStatus;

            }


            if ( _.isUndefined(vm.quotationRequest.id) ) {
                QuotationRequest.save(vm.quotationRequest, onSaveSuccess, onSaveError);
            } else {
                QuotationRequest.update(vm.quotationRequest, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('mobilityexchangeApp:quotationRequestUpdate', result);
            //$uibModalInstance.close(result);
            vm.quotationRequest = result;
            vm.quotationRequest.serviceDate = DateUtils.convertLocalDateFromServer(result.serviceDate);
            vm.quotationRequests.push(vm.quotationRequest);
            $scope.serviceproviderId = vm.quotationRequest.serviceproviderId;
            $scope.quotationRequestId = vm.quotationRequest.id;
            vm.isSaving = false;

            $scope.editForm.$setPristine();
            $scope.editForm.$setUntouched();
            $scope.editForm.$submitted = false;

        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.createViaDash ;

        if($stateParams.create === 'c'){
            vm.createViaDash = $stateParams.create;
            var draftStatus = _.find(vm.reqStatus, {id:60});
            vm.quotationRequest.requestStatus = {};
            vm.quotationRequest.requestStatus = draftStatus;
            vm.createNewQuote();
        }


        vm.datePickerOpenStatus.serviceDate = false;
        vm.datePickerOpenStatus.createdOn = false;
        vm.datePickerOpenStatus.modifiedOn = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function reset() {
            var status = _.find(vm.reqStatus, {id: vm.quotationRequest.requestStatus.id});
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.quotationRequest = {};
            vm.quotationRequest.requestStatus = status;
            vm.quotationRequest.quotationRequestDtls = [{}];
            vm.asyncOrigSelected = {};
            vm.asyncDestSelected = {};
            vm.transition();
        }

        vm.uploadedFiles = [];

        vm.getFileRef = function(spid, reqId){
            $http.get('api/get-uploaded/files/'+spid+'/'+reqId).then( function(result){
                vm.uploadedFiles = [];
                for(var i=0; i<result.data.length; i++){
                    vm.uploadedFiles.push(result.data[i]);
                }
            });
        };


        vm.detailedSave="detailed_save.html";


        vm.sanitizeImagePath = function(imagePath){
            var  imageP =  _.replace(imagePath, '/apps', '');

            return imageP;
        }

        /*****/
        var uploader = $scope.uploader = new FileUploader({
            url: '/api/fileupload?spid=' + vm.quotationRequest.id + '&type=profile',
            headers: {
                'X-CSRF-TOKEN': $cookies.get('CSRF-TOKEN')
            }
        });

        uploader.onWhenAddingFileFailed = function (item /*{File|FileLikeObject}*/, filter, options) {
            console.info('onWhenAddingFileFailed', item, filter, options);
        };

        uploader.onAfterAddingFile = function (fileItem) {

            console.info('onAfterAddingFile', fileItem);

            vm.uploading = true;

            $scope.showSpinner = true;
            /*if (fileItem.type == 'uploadCert')
             $scope.uploadCertNow[fileItem.id] = fileItem.file.name;
             if (fileItem.type == 'uploadMemberShip')
             $scope.uploadMemberShipNow[fileItem.id] = fileItem.file.name;
             if (fileItem.type == 'established')
             $scope.establishedNow[fileItem.id] = fileItem.file.name;
             if (fileItem.type == 'profileImage')
             $scope.profileImageNow[fileItem.id] = fileItem.file.name;
             if (fileItem.type == 'logoImage')
             $scope.logoImageNow[fileItem.id] = fileItem.file.name;*/
            /*if($scope.saveChanges)
             save();*/

            fileItem.url = '/api/fileupload?spid='+$scope.serviceproviderId+'&docdesc=rfq&type=rfq&typeid='+$scope.quotationRequestId;


            if (uploader.queue) {
                uploader.uploadAll();
            }
        };

        uploader.onAfterAddingAll = function (addedFileItems) {
            console.info('onAfterAddingAll', addedFileItems);
        };

        uploader.onBeforeUploadItem = function (item) {
            console.info('onBeforeUploadItem', item);
        };

        uploader.onProgressItem = function (fileItem, progress) {
            console.info('onProgressItem', fileItem, progress);
        };

        uploader.onProgressAll = function (progress) {
            console.info('onProgressAll', progress);
        };

        uploader.onSuccessItem = function (fileItem, response, status, headers) {
            console.info('onSuccessItem', fileItem, response, status, headers);



            /*if(vm.serviceProviders.serviceproviderFiles) {
             delete response.serviceprovider;
             response.serviceprovider = vm.serviceProviders.id;
             vm.serviceProviders.serviceproviderFiles.push(response);
             }
             else{
             delete response.serviceprovider;
             vm.serviceProviders.serviceproviderFiles = [];
             response.serviceprovider = vm.serviceProviders.id;
             vm.serviceProviders.serviceproviderFiles.push(response);
             }

             if (fileItem.type == 'uploadCert'){
             $scope.uploadCertError[fileItem.id] = "";
             $scope.uploadCertNow[fileItem.id] = "";
             }

             if (fileItem.type == 'uploadMemberShip'){
             $scope.uploadMemberShipError[fileItem.id] = "";
             $scope.uploadMemberShipNow[fileItem.id] =  "";
             }

             if (fileItem.type == 'established'){
             $scope.establishedError = "";
             $scope.establishedNow[fileItem.id] =  "";
             }

             if (fileItem.type == 'profileImage'){
             $scope.profileImageError = "";
             $scope.profileImageNow[fileItem.id] =  "";
             }

             if (fileItem.type == 'logoImage'){
             $scope.logoImageError = "";
             $scope.logoImageNow[fileItem.id] =  "";
             }*/



            //$window.location.reload();
        };
        uploader.onErrorItem = function (fileItem, response, status, headers) {

            var errorMessage = "Error uploading file, please check that the file's size is 3 MB or lesser. Please contact support@mobilityexchange.com " +
                " if the problem persists.";

            /*if (fileItem.type == 'uploadCert')
             $scope.uploadCertError[fileItem.id] = errorMessage;

             if (fileItem.type == 'uploadMemberShip')
             $scope.uploadMemberShipError[fileItem.id] = errorMessage;

             if (fileItem.type == 'established')
             $scope.establishedError = errorMessage;

             if (fileItem.type == 'profileImage')
             $scope.profileImageError = errorMessage;

             if (fileItem.type == 'logoImage')
             $scope.logoImageError = errorMessage;*/

        };

        uploader.onCancelItem = function (fileItem, response, status, headers) {
            console.info('onCancelItem', fileItem, response, status, headers);
        };

        uploader.onCompleteItem = function (fileItem, response, status, headers) {
            console.info('onCompleteItem', fileItem, response, status, headers);
        };

        uploader.onCompleteAll = function () {
            console.info('onCompleteAll');
            /*$scope.showSpinner = false;
             //$window.location.reload();
             $scope.saveChanges = false;
             $scope.getFileRef();
             getValidationCheclkist(vm.serviceProviders.id);*/
            vm.getFileRef($scope.serviceproviderId, $scope.quotationRequestId);

            vm.uploading = false;
        };


        vm.requestStatusSearchFilters = [];
        vm.searchDraft = "";
        vm.searchPub = "";
        vm.searchAcc = "";
        vm.searchRej = "";
        loadAll();


        vm.quotationRespStatus = [];

        vm.clearNotification = function($event, notification){
            $event.preventDefault();
            $event.stopPropagation();
            notification.seen = true;
            notification.active = false;
            vm.notifications = Notifications.update(notification, onUpdateSuccess, onUpdateFailure);
        };

        function onUpdateSuccess(){
            getNotifications();
        };

        function onUpdateFailure(){
            //getNotifications();
        };


        vm.archiveRequest = function(qReq){

            qReq.archived = true;
            QuotationRequest.update(qReq, onArchiveSuccess, onArchiveFailure);

           /* _.forEach(vm.quotationRequests, function(qReq) {
                _.remove(vm.quotationRequests, {id: qReq.id});
            });*/

        };

        vm.reminderSent = [];

        vm.sendReminder = function(qRes){
            console.log('sending reminder ....');
            $http.post('/api/quotation-response-reminder', qRes).then( function(){
                vm.reminderSent[qRes.id] = true;
            });
        };

        function onArchiveSuccess(){
            loadAll();
        };
        function onArchiveFailure(){

        };

        vm.shAllRecs = $rootScope.showAllRecs ;

        vm.showAll = function () {
            if(!$rootScope.showAllRecs){
                $rootScope.showAllRecs = true;
            }else{
                $rootScope.showAllRecs = false;
            }
            loadAll();
        };

       // $rootScope.showAllReqRecFlag = true;

        vm.showAllReqRecFlag =  $stateParams.filter;

        vm.showAllReqRecs = function(){

            if(!$stateParams.filter)
                $stateParams.filter = 'N';

            vm.showAllReqRecFlag = $stateParams.filter;

            $state.transitionTo('quotation-request', { filter: 'N'}, {
                reload: true,
                inherit: false,
                notify: true
                });
        };

        function loadAll () {
            if (pagingParams.search) {
                QuotationRequestSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                QuotationRequest.query({
                    showall: $rootScope.showAllRecs,
                    page: pagingParams.page -1,
                    size: 10,
                    sort: sort(),
                    req_status: $stateParams.req_status,
                    res_status: $stateParams.res_status
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {

                var eulaAccepted = headers('X-RFQ-EULA-Not-Accepted');

                if(eulaAccepted){
                    $state.go('disclaimer-detail', {id: parseInt(eulaAccepted)}, {reload: true});
                }

                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.totalCount = headers('X-Total-Count');
                vm.queryCount = 100;
                vm.quotationRequests = data;
                vm.page = pagingParams.page;

                if(vm.quotationRequests && vm.quotationRequests.length == 0){
                    vm.noQuoteRequest = true;
                }

                for(var j=0; j<vm.quotationRequests.length; j++){
                    var responseCount = 0;
                    var quotationResponses = vm.quotationRequests[j].quotationResponses;

                    if(quotationResponses) {
                        for (var i = 0; i < quotationResponses.length; i++) {

                            if (quotationResponses[i] &&
                                (quotationResponses[i].responseStatus === 47
                                || quotationResponses[i].responseStatus === 46
                                || quotationResponses[i].responseStatus === 44)
                            ) {
                                ++responseCount;
                            } else if (quotationResponses[i] && quotationResponses[i].responseStatus === 46) {
                                vm.quotationRespStatus[vm.quotationRequests[j].id] = 'Accepted';
                            }

                        }
                        if(vm.quotationRespStatus[vm.quotationRequests[j].id] !==  'Accepted' )
                            vm.quotationRespStatus[vm.quotationRequests[j].id] = responseCount;
                    }
                }
                getNotifications();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.notifications;

        function getNotifications(){
            $timeout(function(){

                vm.notifications = Notifications.getNotificationsForSP(onNotifSuccess, onNotifFailure);

            }, 2000);
        };

        $scope.reqNotifsFilter = function (notif) {
            return notif.type === 'RFQ_SUBMITTED' || notif.type === 'RFQ_RESPONSE_MODIFIED';
        };
        $scope.resNotifsFilter = function (notif) {
            return notif.type === 'RFQ_ASSIGNED' || notif.type === 'RFQ_REQUEST_MODIFIED' || notif.type ===  'RFQ_ACCEPTED' || notif.type === 'RFQ_CLOSED';
        }

        function onNotifSuccess(notifications) {

        }

        function onNotifFailure(notifications) {
            //vm.getNotificationsByType('RFQ_REQ',['RFQ_SUBMITTED','RFQ_RESPONSE_MODIFIED']);
        }

        vm.getNotificationCount = function(key){
            var count = 0;
            if(vm.notifications && vm.notifications.length > 0){
                for(var j=0; j<vm.notifications.length; j++) {
                    var notifications = vm.notifications[j].key;
                    if(notifications === key)
                         count++;
                }
                return count;
            }
            return 0;
        };





        vm.getNotificationCountByType = function(key){
            var count = 0;
            if(vm.notifications && vm.notifications.length > 0){
                for(var j=0; j<vm.notifications.length; j++) {
                    var notifications = vm.notifications[j].key;
                    if(notifications === key) {
                        count++;
                    }
                }
                return count;
            }
            return 0;
        };


        /*vm.getTotalCountByType = function( types){
            var count = 0;
            for(var i=0; i<types.length; i++){
                if(_.find(vm.notifications, {type: types[i]})){
                    count++;
                }
            }
            return count;
        };*/

        vm.getTotalCountByType = function(acountArrayIdx, types){
            var count = 0;
            for(var i=0; i<types.length; i++){

                var result = _.filter(vm.notifications, { type: types[i]  }) ;
                if(result)
                    count = count + result.length;
            }
            return count;
        };



        vm.getTotalCountByKeys = function( keys ){

            var count = 0;

            if(vm.notifications) {
                var result = _.filter(vm.notifications, {keyText: keys[0]});
                var resTypes = ['RFQ_SUBMITTED', 'RFQ_RESPONSE_MODIFIED'];
                var actnotifications = [];
                for (var i = 0; i < resTypes.length; i++) {
                    var result = _.filter(vm.notifications, {keyText: keys[0], type: resTypes[i]});
                    if (result) {
                        count = count + result.length;
                        //actnotifications.push(result);
                    }
                }
            }
           // vm.activenotifications = actnotifications;
            return count;
        };

        vm.activenotifications = [];
        vm.getMessageList = function( keys ){
         if(vm.notifications) {
                var result = _.filter(vm.notifications, {keyText: keys[0]});
                var resTypes = ['RFQ_SUBMITTED', 'RFQ_RESPONSE_MODIFIED'];
                vm.activenotifications = [];
                for (var i = 0; i < resTypes.length; i++) {
                    var result = _.filter(vm.notifications, {keyText: keys[0], type: resTypes[i]});
                    if (result && result[0]) {
                        vm.activenotifications.push(result[0]);
                    }
                }
            }
            //console.log(JSON.stringify(vm.activenotifications));

        };


        function onSuccess(results){
            vm.notifications  = results;

            if(vm.notifications)
                $rootScope.count = vm.notifications.length;
        };

        function onFailure(){
            console.log('vm.notifications failure ::: '+JSON.stringify(vm.notifications));
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                req_status: $stateParams.req_status,
                res_status: $stateParams.res_status
            });
        }

        function clear() {
            reset();
            vm.newRequest = false;
            if(vm.quotationRequests && vm.quotationRequests.length == 0)
                vm.noQuoteRequest = true;
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            QuotationRequestSearch.query({query: vm.searchQuery}, function(result) {
                vm.quotationRequests = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        vm.filterResults = function(filter){

            var index = _.indexOf(vm.requestStatusSearchFilters, filter);
            if(index > -1){
                vm.requestStatusSearchFilters.splice(index,1);
            }else{
                vm.requestStatusSearchFilters.push(filter);
            }

            for(var i=0; i<vm.requestStatusSearchFilters.length; i++){
                vm.searchQuery = vm.searchQuery  + " " + vm.requestStatusSearchFilters[i];
            }

            search();

            vm.searchQuery = "";
        };



        vm.messagesTemplUrl = "messages_templ.html";


        vm.goToRequestDetail = function(reqId){

            $http.get('/api/quotation-request-id/'+reqId).then(function(response){

                console.log('Go to state '+'quotation-request/'+response.data+'?newReq=1');

                $state.go('quotation-request-dtls', {reqId: response.data, newReq: 1} );

            });


        };


        vm.goToResponseDetail = function(keyText, toId){

            $http.get('/api/quotation-request-id/'+keyText+'/'+toId).then(function(response){

                console.log('Go to state '+'quotation-response-dtls/'+response.data+'?newReq=1&spId='+toId);

                $state.go('quotation-response-dtls-v', {resId: response.data, spId: toId, newRes: 1} );

            });

        };

        /*******/



        /********Dashboard *********/

        vm.quotesInDraft = 0;
        vm.quotesPublished = 0;
        vm.quotesClosed = 0;
        vm.toreview = 0;

        vm.getQuoteRequestStatuses = $http.get('/api/quotation-request-status-counts').then( function(result){

            var response = result.data;
            for(var i=0; i<response.length; i++){
                var ele = response[i];
                if(ele){
                    if(ele[1].id === 60){
                        vm.quotesInDraft = ele[0];
                    }
                    if(ele[1].id === 61){
                        vm.quotesPublished = ele[0];
                    }
                    if(ele[1].id === 62){
                        vm.quotesClosed = ele[0];
                    }
                    if(ele[1] === 47){
                        vm.toreview = ele[0];
                    }
                }
            }

        });

        vm.reqSent = 0;
        vm.rejected = 0;
        vm.submitted = 0;
        vm.accepted = 0;

        vm.getQuoteResponseStatuses = $http.get('/api/quotation-response-status-counts').then( function(result){

            var response = result.data;
            for(var i=0; i<response.length; i++){
                var ele = response[i];
                if(ele){
                    if(ele[1] === 41){
                        vm.reqSent = ele[0];
                    }
                    if(ele[1] === 44){
                        vm.rejected = ele[0];
                    }
                    if(ele[1] === 46){
                        vm.accepted = ele[0];
                    }
                    if(ele[1] === 47){
                        vm.submitted = ele[0];
                    }
                }
            }

        });

        /********Dashboard End *********/
    }




})();
