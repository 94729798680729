(function() {
	// 'use strict';

	angular.module('mobilityexchangeApp').factory('AdHeaderSearch',
			AdHeaderSearch);

	AdHeaderSearch.$inject = [ '$resource' ];

	function AdHeaderSearch($resource) {
		var resourceUrl = 'api/_search/adv-controls/:id?&aid=:aid&spid=:spid&spName=:spName&campaignDisplay=:campaignDisplay&campaignName=:campaignName&pauseAdFrom=:pauseAdFrom&pauseAdTo=:pauseAdTo&monthlyMaximum=:monthlyMaximum&monthlyBalance=:monthlyBalance&bidPrice=:bidPrice&submissionDate=:submissionDate&activationDate=:activationDate&active=:active';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			}
		});
	}
})();
