(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('PaymentProcessingSearch', PaymentProcessingSearch);

    PaymentProcessingSearch.$inject = ['$resource'];

    function PaymentProcessingSearch($resource) {
        var resourceUrl = 'api/_search/payment-processing/?id=:id&paymentAmount=:paymentAmount&paymentDate=:paymentDate&company=:company&balanceAmount=:balanceAmount';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }
})();