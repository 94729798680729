(function () {
    //'use strict';
    angular
        .module('mobilityexchangeApp')
        .factory('Addresses', Addresses);

    Addresses.$inject = ['$resource', 'DateUtils', '$stateParams'];

    function Addresses($resource, DateUtils, $stateParams) {
        var resourceUrl = 'api/addresses/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                        data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',

                transformRequest: function (data) {
                    //data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    //data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    return angular.toJson(data);
                }
            }
        });
    }
})();
