(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('MovingCapabilitiesDeleteController', MovingCapabilitiesDeleteController);

    MovingCapabilitiesDeleteController.$inject = ['$uibModalInstance', 'entity', 'MovingCapabilities'];

    function MovingCapabilitiesDeleteController($uibModalInstance, entity, MovingCapabilities) {
        var vm = this;

        vm.movingCapabilities = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            MovingCapabilities.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
