(function() {
	//'use strict';

	angular.module('mobilityexchangeApp').controller(
			'ContentControlDialogController',
			ContentControlDialogController).directive("fileread", [function () {
			    return {
			        scope: {
			            fileread: "="
			        },
			        link: function (scope, element, attributes) {
			            element.bind("change", function (changeEvent) {
			                scope.$apply(function () {
			                    scope.fileread = changeEvent.target.files[0];
			                    // or all selected files:
			                    // scope.fileread = changeEvent.target.files;
			                });
			            });
			        }
			    }
			}]);;

	ContentControlDialogController.$inject = [ '$timeout', '$scope',
			'$stateParams', 'entity', 'ContentControl', 'Lookups',
			'DateUtils', '$http', '$q', 'Principal', 'FileUploader','$cookies' ];

	function ContentControlDialogController($timeout, $scope, $stateParams,
			entity, ContentControl, Lookups, DateUtils, $http, $q,
			ServiceProviders, Principal, FileUploader, $cookies) {
		var vm = this;
		vm.contentControl = entity;
		vm.save = save;
		vm.clear = clear;
		vm.types = Lookups.getByLookupType({
			id : 80
		});

		vm.displayDirectories = Lookups.getByLookupType({
            id : 93
        });

		vm.datePickerOpenStatus = [];
		vm.datePickerOpenStatus.endDate = false;
		vm.openCalendar = openCalendar;

		$scope.myFile;

		vm.uploadFile = function() {
			vm.downloadSuccess = null;
			vm.downloadError = null;
            var file = $scope.myFile;

            var uploadUrl = "/api/contentControlfileupload?type=content-control";
            var fd = new FormData();
            fd.append('file', file);

            $http.post(uploadUrl, fd, {
               transformRequest: angular.identity,
               headers: {'Content-Type': undefined}
            })
            .success(function(response) {
            	vm.downloadSuccess = "File successfuly uploaded."
            	//console.log("Successfuly uploaded.." + response);
            })
            .error(function(response) {
            	vm.downloadError = "Error uploading file: " + response.description;
            	//console.log("error uploading file = " + response);
            });
         };

		function clear() {
		}

		function save() {

			vm.isSaving = true;
			if($scope.myFile){
				vm.contentControl.imageName = $scope.myFile.name;
	        	vm.contentControl.imagePath = '/customer/images/content-control_' + $scope.myFile.name;
	        	vm.uploadFile();
			}

			if (vm.contentControl.id !== null) {
				ContentControl.update(vm.contentControl, onSaveSuccess,
						onSaveError);
			} else {
				ContentControl.save(vm.contentControl, onSaveSuccess,
						onSaveError);
			}
		}

		function onSaveSuccess(result) {
			vm.contentControl = result;
			vm.contentControl.endDate = DateUtils.convertDateTimeFromServer(result.endDate);
			$scope.$emit('mobilityexchangeApp:contentControlUpdate', result);
			vm.isSaving = false;
		}

		function onSaveError() {
			vm.isSaving = false;
		}

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}
	}
})();
