(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('QuotationResponseReviewController', QuotationResponseReviewController);

    QuotationResponseReviewController.$inject = ['QuotationResponse', 'QuotationResponseSearch',
        'Lookups', '$http','DateUtils','QuotationResponseDtl', 'QuotationRequest', 'QuotationRequestDtl', '$timeout', '$stateParams', '$scope', 'Principal'];

    function QuotationResponseReviewController(QuotationResponse, QuotationResponseSearch,
                                         Lookups, $http, DateUtils,
                                         QuotationResponseDtl, QuotationRequest, QuotationRequestDtl, $timeout, $stateParams, $scope, Principal) {

        var vm = this;

        vm.quotationResponses = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.save = save;
        vm.quoteTotal = [];

        vm.quotationResponse = {};
        vm.quotationResponse.quotationResponseDtls = [{}];

        vm.modes = Lookups.getByLookupType({id: 60});
        vm.serviceTypes = Lookups.getByLookupType({id: 56});
        vm.containerTypes = Lookups.getByLookupType({id: 57});
        vm.reqStatus = Lookups.getByLookupType({id: 58});
        vm.resStatus = Lookups.getByLookupType({id: 59});

        vm.currency = Lookups.getActiveByLookupType({id: 52});
        vm.rateBasis = Lookups.getByLookupType({id: 61});

        vm.quoteOptionIdx = 0;
        vm.quoteOptionsSize = vm.quotationResponse.quotationResponseDtls.length;

        vm.bufferAmt = [];
        vm.rescurrency = '';
        vm.totalAmt = [];

        $scope.account = null;
        $scope.isAuthenticated = false;
        $scope.isAdminUser = false;

        vm.display = '';
        $timeout(function(){
            vm.display = 'display: block;';
        },2000);

        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
            for (var i = 0; i < $scope.account.authorities.length; i++) {
                if ($scope.account.authorities[i] === 'ROLE_ADMIN') {
                    $scope.isAdminUser = true;
                    break;
                }
            }

        });

        function calculateTotals(qReqDtls){

            for(var i=0;i<qReqDtls.length; i++){

                vm.totalAmt[i] = 0;
                var qResDtls = qReqDtls[i].quotationResponseDtls;

                for(var j=0;j<qResDtls.length; j++){

                    if(_.isNaN(vm.totalAmt[i])  || _.isUndefined(vm.totalAmt[i])) {
                        vm.totalAmt[i] = 0.0;
                    }

                    vm.totalAmt[i] = vm.totalAmt[i] + qResDtls[j].responseItemAmt;

                }

                if( _.isNaN(vm.totalAmt[i]))
                    vm.totalAmt[i] = 0;
            }
        }


        vm.getLineTotals = function(){

            var qReqDtls = vm.quotationResponse.quotationRequest.quotationRequestDtls;
            calculateTotals(qReqDtls);
        };



        vm.quotationResponseDisplayArray = [];


        /*vm.getLineTotalsFrom = function(){

            for(var i=0; i<vm.quotationResponses.length; i++){

                var reqId = vm.quotationResponses[0].quotationRequest.id;

                $http.get( 'api/quotation-responses-review/'+vm.quotationResponses[i].id+'/'
                    +reqId +'/'
                    +vm.quotationResponses[i].serviceproviderId ).then (

                    function(response){

                        vm.quotationResponse = response.data;
                        var qReqDtls = vm.quotationResponse.quotationRequest.quotationRequestDtls;


                        /!*for(var i=0; i<qReqDtls.length; i++){
                            if( _.isUndefined(qReqDtls[i].quotationResponseDtls) || _.isEmpty(qReqDtls[i].quotationResponseDtls) ) {
                                qReqDtls[i].quotationResponseDtls = [{}];
                                vm.quotationResponse.quotationResponseDtls = [{}];
                            }
                        }*!/


                        /!*for(var i=0;i<qReqDtls.length; i++){

                            vm.totalAmt_temp = 0;
                            var qResDtls = qReqDtls[i].quotationResponseDtls;

                            for(var j=0;j<qResDtls.length; j++){

                                if(_.isNaN(vm.totalAmt_temp)  || _.isUndefined(vm.totalAmt_temp)) {
                                    vm.totalAmt_temp = 0.0;
                                }
                                vm.totalAmt_temp = vm.totalAmt_temp + qResDtls[j].responseItemAmt;

                            }

                            if( _.isNaN(vm.totalAmt_temp))
                                vm.totalAmt_temp = 0;

                            qReqDtls[i].amount = vm.totalAmt_temp;
                        }*!/

                        /!*vm.quotationResponseDisplayArray.push(vm.quotationResponse);

                        vm.allTotals = [];

                        var k = 0;

                        vm.quotationResponseDisplayObj = [{}];*!/

                        /!*while(k < vm.quotationResponseDisplayArray.length ){

                            var qReq = vm.quotationResponseDisplayArray[k].quotationRequest;

                            var qReqDtls =  qReq.quotationRequestDtls;

                            var totals = [];

                            var l = 0;

                            while(l < qReqDtls.length ){

                                totals.push(qReqDtls[l].amount);

                                l++;

                            }

                            allTotals.push(totals);

                            k++;

                        }*!/

                        /!*var totals = [];

                        for(var i=0; i<3; i++){


                            if(vm.quotationResponseDisplayArray[i]){

                                var a, b , c;

                                if(vm.quotationResponseDisplayArray[0])
                                     a = vm.quotationResponseDisplayArray[0].quotationRequest.quotationRequestDtls;


                                if(vm.quotationResponseDisplayArray[1])
                                     b = vm.quotationResponseDisplayArray[1].quotationRequest.quotationRequestDtls;


                                if(vm.quotationResponseDisplayArray[2])
                                     c = vm.quotationResponseDisplayArray[2].quotationRequest.quotationRequestDtls;


                                //console.log( JSON.stringify(vm.quotationResponseDisplayArray[0]));

                                totals = [];

                                for(var k=0; k< a.length; k++){

                                    if(a)
                                        totals.push(a[k].amount);

                                    if(b)
                                        totals.push(b[k].amount);

                                    if(c)
                                        totals.push(c[k].amount);

                                    vm.allTotals.push(totals);

                                    totals = [];


                                }

                            }

                            break;


                        }*!/


                        //console.log('  allTotals '+JSON.stringify(allTotals));





                    }

                );
            }

        };*/

        vm.quotationResponseDtlArray = [];
        vm.totalAmtDisp = 0;

        function onResponseDetailSuccess(qResDtls){

            for(var j=0;j<qResDtls.length; j++){

                if(_.isNaN(vm.totalAmtDisp)  || _.isUndefined(vm.totalAmtDisp)) {
                    vm.totalAmtDisp = 0.0;
                }

                vm.totalAmtDisp = vm.totalAmtDisp + qResDtls[j].responseItemAmt;

            }

            if( _.isNaN(vm.totalAmtDisp))
                vm.totalAmtDisp = 0;

        }

        function onResponseDetailFailure(result){

        }


        vm.calcAmt = function(rateBasis, responseItemRate,responseItemQty, index){

            var amt = 0;
            if(responseItemRate && responseItemQty) {
                if (rateBasis === 68) {  // percent
                    amt =  ((responseItemRate) / 100) * (responseItemQty);
                }else
                if (rateBasis === 69) {  // percent
                    amt = ((responseItemRate) / 100) * (responseItemQty);
                }else{
                   amt = (responseItemRate) * (responseItemQty);
                }
            }else{
                amt =  0;
            }
            return amt;

        };

        vm.addTotal = function(amt, idx){
            vm.getLineTotals();
        };

        vm.getWtDisplay = function(wt, unit){
            if(unit === 'Kgs'){
                return (wt).toFixed(2) +" "+unit+"  / "+ (wt*2.2).toFixed(2) +" Lbs ";
            }
            else
            if(unit === 'Lbs'){
                return (wt/2.2).toFixed(2)+" Kgs  / "+ (wt).toFixed(2) +" Lbs ";
            }
        };


        vm.getVolDisplay = function(vol, unit){

            if(unit === 'Cft'){
                return (vol/35.3).toFixed(2) +" Cbm / "+ (vol).toFixed(2)+" "+unit+" ";
            }
            else
            if(unit === 'Cbm'){
                return  (vol).toFixed(2) +" Cbm / "+(vol*35.3).toFixed(2)+" Cft ";
            }
        };


        vm.setQty= function(rateBasis,quotationDtls, qResDtls) {

            var wtNum = parseFloat(quotationDtls.weight);
            var volNum = parseFloat(quotationDtls.volume);
            var qty = 0;

            if(rateBasis===65){  //Per Cbm

                if(quotationDtls.volumeUnit == 'Cbm')
                    qty =  parseFloat( (volNum).toFixed(2) );
                else
                if(quotationDtls.volumeUnit == 'Cft')
                    qty =  parseFloat((volNum/35.3).toFixed(2));
            }else
            if(rateBasis===66){  //Per Cft
                if(quotationDtls.volumeUnit == 'Cbm')
                    qty =  parseFloat((volNum*35.3).toFixed(2));
                else
                if(quotationDtls.volumeUnit == 'Cft')
                    qty =  parseFloat((volNum).toFixed(2));

            }else
            if(rateBasis===67){  //Per Kg
                if(quotationDtls.weightUnit == 'Kgs')
                    qty =  parseFloat((wtNum).toFixed(2));
                else
                if(quotationDtls.weightUnit == 'Lbs')
                    qty =  parseFloat((wtNum/2.2).toFixed(2));
            }
            else
            if(rateBasis===69){  //Per Lbs
                if(quotationDtls.weightUnit == 'Kgs')
                    qty =  parseFloat((wtNum/2.2).toFixed(2));
                else
                if(quotationDtls.weightUnit == 'Lbs')
                    qty =  parseFloat((wtNum).toFixed(2));
            }
            else
            if(rateBasis===64){
                qty =  1;
            }

            ////console.log(' qResDtls.rateBasis, qResDtls.rate, qty '+qResDtls.rateBasis+'  '+qResDtls.rate+'  '+qty);

            if(!_.isUndefined(qResDtls.rate))
                qResDtls.responseItemAmt = vm.calcAmt(qResDtls.rateBasis, qResDtls.rate, qty);
            //vm.getLineTotals();

            return qty;

        };


        vm.acceptQuote = function(event, quotationResponse){

            event.preventDefault();
            event.stopPropagation();

            QuotationResponse.acceptQuote(quotationResponse, successRes, failureRes);

            quotationResponse.responseStatus = 46;
            vm.isSaving = false;
        };

        vm.rejectQuote = function(event, quotationResponse){
            event.preventDefault();
            event.stopPropagation();
            QuotationResponse.rejectQuote(quotationResponse);
            quotationResponse.responseStatus = 44;
            vm.isSaving = false;
        };

        function successRes(result){
            //console.log('successRes');
        };

        function failureRes(result){
            //console.log('failureRes');
        };

        vm.isSubmitted = false;
        vm.popupUrl = 'submit_templ.html';

        vm.submitQuote = function(event, quotationResponse){
            //event.preventDefault();
            //event.stopPropagation();
            quotationResponse.quotationRequest = null;
            //quotationResponse.quotationResponseDtls = null;
            //quotationResponse.responseStatus = 47;
            //vm.quotationResponse.responseStatus = 47;
            QuotationResponse.submitQuote(quotationResponse, success, error);
            vm.isSubmitted = true;
            //vm.isSaving = false;
        };


        vm.acceptQuote = function(event, quotationResponse){
            event.preventDefault();
            event.stopPropagation();
            quotationResponse.quotationRequest = null;
            QuotationResponse.acceptQuote(quotationResponse, success, error);
            vm.isSubmitted = true;
        };


        vm.rejectQuote = function(event, quotationResponse){
            event.preventDefault();
            event.stopPropagation();
            quotationResponse.quotationRequest = null;
            QuotationResponse.rejectQuote(quotationResponse, success, error);
            vm.isSubmitted = true;
        };


        function success(data){
          vm.loadData(vm.quotationResponse.id, $stateParams.reqId, vm.quotationResponse.serviceproviderId);
        }

        function error(data){
        }


        vm.matchValueFromLookupV = function(id, obj){
            var val = _.find(obj, {id:id});
            if(val)
                return val.lookupvalue ;
            else
                return "";
        };


        vm.matchValueFromLookup = function(id, obj){
            var val = _.find(obj, {id:id.id});
            if(val)
                return val.lookupvalue ;
            else
                return "";
        };

        vm.setBuffer = function(amt){

            if(_.isUndefined(amt) || _.isEmpty(amt))
                vm.bufferAmt = 0;
            else
                vm.bufferAmt = amt;

        };

        vm.getStausDesc = function (id) {
            var resStatus = _.find(vm.resStatus, {id: id});
            if(resStatus)
                return resStatus.lookupvalue;
            else
                return "";
        };

        function save () {

            vm.isSaving = true;

            for(var i=0;i<vm.quotationResponse.quotationRequest.quotationRequestDtls.length; i++) {

                var qResponseDtls = vm.quotationResponse.quotationRequest.quotationRequestDtls[i].quotationResponseDtls;

                for (var j = 0; j < qResponseDtls.length; j++) {

                    var response =  _.clone(vm.quotationResponse);
                    response.quotationRequest = null;
                    response.quotationResponseDtls = null;

                    if(vm.isSubmitted)
                        response.responseStatus = 47;

                    qResponseDtls[j].quotationResponse = response;

                    var request = _.clone(vm.quotationResponse.quotationRequest.quotationRequestDtls[i]);
                    request.quotationResponseDtls = null;
                    request.quotationRequest = null;

                    qResponseDtls[j].requestDetail = request;

                    if(qResponseDtls[j].id != null)
                        QuotationResponseDtl.update(qResponseDtls[j], onSaveSuccess, onSaveError);
                    else
                        QuotationResponseDtl.save(qResponseDtls[j], onSaveSuccess, onSaveError);
                }
            }

        }

        function onSaveSuccess (result) {
            //vm.quotationResponse = vm.loadData(vm.quotationResponse.id);
            //vm.isSaving = false;
        }

        function onSaveError () {
            //vm.isSaving = false;
        }

        vm.showAddButton = function(idx,quotationDtls){
            ////console.log(' idx '+idx +' quotationDtls.length '+ JSON.stringify(quotationDtls) );
            if(idx+1 === quotationDtls.length){
                return true;
            }
        };

        vm.addQuoteResponse = function (idx, quotationResponseDtls) {
            //var quotationResponseDtls = { 'requestDetailId' : id };
            quotationResponseDtls.push({});
        };

        vm.removeQuoteResponse = function (idx, quotationResponseDtls, indx) {
            var amt = quotationResponseDtls[idx].responseItemAmt;

            if(_.isUndefined(amt) || _.isEmpty(amt))
                vm.bufferAmt[idx] = 0;
            else
                vm.bufferAmt[idx] = amt;

            if(_.isUndefined(vm.quoteTotal[idx]) || _.isEmpty(vm.quoteTotal[idx]) || _.isNaN(vm.quoteTotal[idx]))
                vm.quoteTotal[idx] = 0;

            vm.quoteTotal[idx] = parseFloat(vm.quoteTotal[idx]) - parseFloat(vm.bufferAmt[idx]);

            quotationResponseDtls.splice(idx,1);
        };

        vm.reqSpHeader;

        vm.loadData = function(resId, reqId, spId){

            $http.get('api/quotation-responses-review/'+resId+'/'+reqId+'/'+spId).then(function(response) {

                vm.quotationResponse = response.data;

                vm.asyncOrigSelected = vm.quotationResponse.quotationRequest.origin;
                vm.asyncDestSelected = vm.quotationResponse.quotationRequest.destination;

                vm.quotationResponse.quotationRequest.serviceDate = DateUtils.convertLocalDateFromServer(response.data.quotationRequest.serviceDate);
                vm.quotationResponse.quotationRequest.createdOn = DateUtils.convertLocalDateFromServer(response.data.createdOn);
                vm.quotationResponse.quotationRequest.modifiedOn = DateUtils.convertLocalDateFromServer(response.data.modifiedOn);

                $scope.serviceproviderId = vm.quotationResponse.serviceproviderId;
                $scope.quotationResponseId = vm.quotationResponse.id;
                vm.getFileRef($scope.serviceproviderId, $scope.quotationResponseId);

                $scope.rfqserviceproviderId = vm.quotationResponse.quotationRequest.serviceproviderId;
                $scope.rfqquotationResponseId = vm.quotationResponse.quotationRequest.id;
                vm.getRFQFileRef($scope.rfqserviceproviderId, $scope.rfqquotationResponseId);

                var qReqDtls = vm.quotationResponse.quotationRequest.quotationRequestDtls;

                for(var i=0; i<qReqDtls.length; i++){
                    if( _.isUndefined(qReqDtls[i].quotationResponseDtls) || _.isEmpty(qReqDtls[i].quotationResponseDtls) ) {
                        qReqDtls[i].quotationResponseDtls = [{}];
                        vm.quotationResponse.quotationResponseDtls = [{}];
                    }
                }

                vm.reqSpHeader  = null;
                vm.newRequest = true;
                vm.totalAmt = [];

                var reqObj = angular.copy(vm.quotationResponse.quotationRequest);
                reqObj.quotationResponses = null;
                vm.quotationRequestArray = [reqObj];

                $http.post('api/quotation-requestor-sps', vm.quotationRequestArray).then(
                    function(result){
                        if(result && result.data) {
                            vm.mxConnectedSPReqNames = result.data;
                            vm.getSPNames(vm.quotationResponse.id);
                            vm.getValidatedStatus(vm.quotationResponse.id);
                            vm.getRppStatus(vm.quotationResponse.id);

                        }
                    }
                );

                vm.getLineTotals();

            });
        };



        loadAll();

        vm.mxConnectedSPNames;

        var rateDisplay = [{}];


        function loadAll() {

            QuotationResponse.queryForResponses({id: $stateParams.reqId}, function(result){

                vm.quotationResponses = result;

                $http.post('api/quotation-response-sps', vm.quotationResponses).then(

                    function(response){

                        if(response && response.data)
                            vm.mxConnectedSPNames = response.data;

                        var reqMap = {};

                        for(var i=0; i<vm.quotationResponses.length; i++){
                            var req = vm.quotationResponses[i].quotationRequest;
                            if(req && !req.id) {
                                var refId = req;
                                var refObj = _.find(vm.quotationResponses, function(o){  return o.quotationRequest.id == refId; });
                                vm.quotationResponses[i].quotationRequest = refObj.quotationRequest;
                            }
                        }

                        vm.getSPNamesResponses();

                        for(var j=0; j<vm.quotationResponses.length; j++){
                            var spNameTxt = _.find(vm.mxConnectedSPNames,{ quoteRequestId : vm.quotationResponses[j].id});
                            if(spNameTxt)
                                rateDisplay.push({ spNameId:  ((spNameTxt).spNames).toString() , options: []});
                        }

                    }
                );

                //vm.getLineTotalsFrom();

                vm.loadData($stateParams.resId, $stateParams.reqId, $stateParams.spId);

            });
        }



        vm.getSPNames = function(id){

            var spNames = _.find(vm.mxConnectedSPNames,{ quoteRequestId : id});

            if(!_.isUndefined(spNames)) {
                vm.reqSpHeader = (spNames.spNames).toString();
                vm.requestorEmail = spNames.email;
            }
            else
                vm.reqSpHeader  =  "";

            var spNames2 = _.find(vm.mxConnectedSPReqNames,{ quoteRequestId : id});
            if(spNames2)
                vm.requestorEmail = spNames2.email;


        };


        vm.spNamesResponseArray = [];
        vm.responderEmail = "";

        vm.getSPNamesResponses = function(){

            for(var k=0; k < vm.quotationResponses.length; k++){

                var idx = vm.quotationResponses[k].id ;
                var spName = _.find(vm.mxConnectedSPNames,{ quoteRequestId : idx});
                var responderObj = _.find(vm.mxConnectedSPNames,{ quoteRequestId : parseInt($stateParams.resId) });

                console.log(' responderObj for '+$stateParams.resId+' === '+JSON.stringify(responderObj)+'  in  '+JSON.stringify(vm.mxConnectedSPNames));

                if(responderObj)
                    vm.responderEmail = responderObj.email;

                if(spName) {
                    vm.spNamesResponseArray[idx] = spName.spNames;
                    vm.requestorEmail = spName.email;
                }
            }

        };

        vm.isValidDated = false;
        vm.isRpp = false;
        vm.selectOptionLabel = [];

        vm.chooseOption = function(event, id, reqId, obj){

            event.stopPropagation();
            event.preventDefault();

            if(obj.optionSelected === true ){
                vm.selectOptionLabel[obj.id] = "Un-Select this Option";
                obj.optionSelected = false;
            }else{
                vm.selectOptionLabel[obj.id] = "Select this Option";
                obj.optionSelected = true;
            }

            $http.put('/api/choose-quotationresponse-option/'+id+'/'+reqId);

        };

        vm.confirmAcceptTmpl = "confirmAccept.html";
        vm.acceptMessage = "No options are selected. Please choose one or more options using the 'Select this Option' button before accepting the quote response.";

        vm.acceptMessageFunc = function(){
            vm.acceptMessage = "No options are selected. Please choose one or more options using the 'Select this Option' button before accepting the quote response.";
            vm.acceptedAnOption = false;

            for(var k=0; k < vm.quotationResponse.quotationRequest.quotationRequestDtls.length ; k++){
                if(vm.quotationResponse.quotationRequest.quotationRequestDtls[k].quotationResponseDtls[0].optionSelected){
                    vm.acceptMessage = "Please confirm that you have reviewed and selected each Option that you wish to accept if there is more than Option.  " +
                        "Once you Accept this Rate Response, the RFQ details will be locked and no further changes will be permitted.  " +
                        "An e-mail will be sent to the responder to advise of your decision. ";
                    vm.acceptedAnOption = true;
                }

            }
        };



        vm.confirmAccept = function($event, quotationResponse){

            $event.preventDefault();
            $event.stopPropagation();

            vm.acceptMessage = "No options are selected. Please choose one or more options using the 'Select this Option' button before accepting the quote response.";
            vm.acceptedAnOption = false;

            for(var k=0; k < quotationResponse.quotationRequest.quotationRequestDtls.length ; k++){
                if(vm.quotationResponse.quotationRequest.quotationRequestDtls[k].quotationResponseDtls[0].optionSelected){
                    vm.acceptMessage = "Please confirm that you have reviewed and chosen your options. " +
                        "accepting the RFQ response will send an email to the responder informing them of your decision. ";
                    vm.acceptedAnOption = true;
                }

            }
            if(!vm.acceptedAnOption){
                return false;
            }

            vm.acceptQuote($event,quotationResponse);
        };

        vm.getValidatedStatus = function (id) {

            var spNames = _.find(vm.mxConnectedSPNames,{ quoteRequestId : id});

            if(spNames && spNames.validated)
                vm.isValidDated =  true;
            else
                vm.isValidDated =  false;
        };


        vm.getRppStatus = function (id) {


            var spNames = _.find(vm.mxConnectedSPNames,{ quoteRequestId : id});

            if(spNames && spNames.rpp)
                vm.isRpp =  true;
            else
                vm.isRpp =  false;
        };


        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            QuotationResponseSearch.query({query: vm.searchQuery}, function(result) {
                vm.quotationResponses = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            vm.newRequest = false;
            vm.quotationResponse.quotationRequest.quotationRequestDtls = [{}];
            loadAll();
        }


        vm.uploadedFiles = [];
        vm.uploadedRFQFiles = [];

        vm.getFileRef = function(spid, reqId){
            $http.get('api/get-uploaded/files/'+spid+'/'+reqId).then( function(result){
                vm.uploadedFiles = [];
                for(var i=0; i<result.data.length; i++){
                    vm.uploadedFiles.push(result.data[i]);
                }
            });
        };


        vm.getRFQFileRef = function(spid, reqId){
            $http.get('api/get-uploaded/files/'+spid+'/'+reqId).then( function(result){
                vm.uploadedRFQFiles = [];
                for(var i=0; i<result.data.length; i++){
                    vm.uploadedRFQFiles.push(result.data[i]);
                }
            });
        };


        vm.sanitizeImagePath = function(imagePath){
            var  imageP =  _.replace(imagePath, '/apps', '');

            return imageP;
        }


    }
})();
