(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('SupplierCapabilitiesDeleteController', SupplierCapabilitiesDeleteController);

    SupplierCapabilitiesDeleteController.$inject = ['$uibModalInstance', 'entity', 'SupplierCapabilities'];

    function SupplierCapabilitiesDeleteController($uibModalInstance, entity, SupplierCapabilities) {
        var vm = this;
        // TODO: Need equivalent on the industry partner side
        vm.supplierCapabilities = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            SupplierCapabilities.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
