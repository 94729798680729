(function () {
	angular.module('mobilityexchangeApp').config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
			.state(
				'adv-controls',
				{
					parent: 'entity',
					// TODO: displayDirectory?
					url: '/adv-controls?page&sort&search&navtype&aid&spid&spName&campaignDisplay&campaignName&pauseAdFrom&pauseAdTo&monthlyMaximum&monthlyBalance&bidPrice&submissionDate&activationDate&active',
					data: {
						authorities: ['AD_ADMIN', 'ROLE_SP_ADMIN',
							'ROLE_SP_FINANCE'],
						pageTitle: 'mobilityexchangeApp.adHeader.home.title'
					},
					views: {
						'content@': {
							templateUrl: 'app/entities/adv-control/adv-control.html',
							controller: 'AdvCompaignController',
							controllerAs: 'vm'
						}
					},
					params: {
						page: {
							value: '1',
							squash: true
						},
						sort: {
							value: 'id,desc',
							squash: true
						},
						search: null,
						navtype: null,
						aid: null,
						spid: null,
						spName: null,
						campaignDisplay: null,
						campaignName: null,
						pauseAdFrom: null,
						pauseAdTo: null,
						displayDirectory: null,
						monthlyMaximum: null,
						monthlyBalance: null,
						bidPrice: null,
						submissionDate: null,
						activationDate: null,
						active: 'true'

					},
					resolve: {
						pagingParams: [
							'$stateParams',
							'PaginationUtil',
							function ($stateParams, PaginationUtil) {
								return {
									page: PaginationUtil
										.parsePage($stateParams.page),
									sort: $stateParams.sort,
									predicate: PaginationUtil
										.parsePredicate($stateParams.sort),
									ascending: PaginationUtil
										.parseAscending($stateParams.sort),
									search: $stateParams.search
								};
							}],
						translatePartialLoader: [
							'$translate',
							'$translatePartialLoader',
							function ($translate,
								$translatePartialLoader) {
								$translatePartialLoader
									.addPart('advCampaignSetup');
								$translatePartialLoader
									.addPart('global');
								return $translate.refresh();
							}]
					}
				})
			.state(
				'adv-lookup',
				{
					parent: 'entity',
					url: '/adv-controls?page&sort&search&navtype',
					data: {
						authorities: ['AD_ADMIN', 'ROLE_SP_ADMIN',
							'ROLE_SP_FINANCE'],
						pageTitle: 'mobilityexchangeApp.adHeader.home.title'
					},
					views: {
						'content@': {
							templateUrl: 'app/entities/adv-control/adv-control.html',
							controller: 'AdvCompaignController',
							controllerAs: 'vm'
						}
					},
					params: {
						page: {
							value: '1',
							squash: true
						},
						sort: {
							value: 'id,asc',
							squash: true
						},
						search: null,
						aid: null,
						spid: null,
						spName: null,
						campaignDisplay: null,
						campaignName: null,
						pauseAdFrom: null,
						pauseAdTo: null,
						displayDirectory: null,
						monthlyMaximum: null,
						monthlyBalance: null,
						bidPrice: null,
						submissionDate: null,
						activationDate: null,
						active: true
					},
					resolve: {
						pagingParams: [
							'$stateParams',
							'PaginationUtil',
							function ($stateParams, PaginationUtil) {
								return {
									page: PaginationUtil
										.parsePage($stateParams.page),
									sort: $stateParams.sort,
									predicate: PaginationUtil
										.parsePredicate($stateParams.sort),
									ascending: PaginationUtil
										.parseAscending($stateParams.sort),
									search: $stateParams.search
								};
							}],
						translatePartialLoader: [
							'$translate',
							'$translatePartialLoader',
							function ($translate,
								$translatePartialLoader) {
								$translatePartialLoader
									.addPart('advCampaignSetup');
								$translatePartialLoader
									.addPart('global');
								return $translate.refresh();
							}]
					}
				})
			.state(
				'adv-controls-detail',
				{
					parent: 'adv-controls',
					url: '/adv-controls/{id}',
					data: {
						authorities: ['AD_ADMIN', 'ROLE_SP_ADMIN',
							'ROLE_SP_FINANCE'],
						pageTitle: 'mobilityexchangeApp.adHeader.detail.title'
					},
					views: {
						'content@': {
							templateUrl: 'app/entities/adv-control/adv-control-detail.html',
							controller: 'AdvCompaignDetailController',
							controllerAs: 'vm'
						}
					},
					resolve: {
						translatePartialLoader: [
							'$translate',
							'$translatePartialLoader',
							function ($translate,
								$translatePartialLoader) {
								$translatePartialLoader
									.addPart('advCampaignSetup');
								return $translate.refresh();
							}],
						entity: ['$stateParams', 'AdHeader',
							function ($stateParams, adHeader) {
								return AdHeader.get({
									id: $stateParams.id
								}).$promise;
							}],
						previousState: [
							"$state",
							function ($state) {
								var currentStateData = {
									name: $state.current.name
										|| 'adv-controls',
									params: $state.params,
									url: $state.href(
										$state.current.name,
										$state.params)
								};
								return currentStateData;
							}]
					}
				})
			.state(
				'adv-controls-detail.edit',
				{
					parent: 'adv-controls-detail',
					url: '/detail/edit',
					data: {
						authorities: ['AD_ADMIN', 'ROLE_SP_ADMIN',
							'ROLE_SP_FINANCE']
					},
					views: {
						'content@': {
							templateUrl: 'app/entities/adv-control/adv-control-dialog.html',
							controller: 'AdHeaderDialogController',
							controllerAs: 'vm',
						}
					},
					resolve: {
						translatePartialLoader: [
							'$translate',
							'$translatePartialLoader',
							function ($translate,
								$translatePartialLoader) {
								$translatePartialLoader
									.addPart('adHeader');
								return $translate.refresh();
							}],
						entity: ['AdHeader', function (AdHeader) {
							return AdHeader.get({
								id: $stateParams.id
							}).$promise;
						}],
						previousState: [
							"$state",
							function ($state) {
								var currentStateData = {
									name: $state.current.name,
									params: $state.params,
									url: $state.href(
										$state.current.name,
										$state.params)
								};
								return currentStateData;
							}]
					}
				})
			.state(
				'adv-controls.new',
				{
					parent: 'adv-controls',
					url: '/new/{sp}',
					data: {
						authorities: ['AD_ADMIN', 'ROLE_SP_ADMIN',
							'ROLE_SP_FINANCE']
					},
					views: {
						'content@': {
							templateUrl: 'app/entities/adv-control/adv-control-dialog.html',
							controller: 'AdHeaderDialogController',
							controllerAs: 'vm',
						}
					},
					resolve: {
						entity: function () {
							return {
								comments: null,
								createdDate: null,
								createdBy: null,
								modifiedDate: null,
								modifiedBy: null,
								id: null
							};
						}
					},
					previousState: [
						"$state",
						function ($state) {
							var currentStateData = {
								isCopy: 'false',
								params: $state.params,
								url: $state.href(
									$state.current.name,
									$state.params)
							};
							return currentStateData;
						}]
				})
			.state(
				'adv-controls.editcopy',
				{
					parent: 'adv-controls',
					url: '/{id}/editcopy',
					data: {
						authorities: ['AD_ADMIN', 'ROLE_SP_ADMIN',
							'ROLE_SP_FINANCE']
					},
					views: {
						'content@': {
							templateUrl: 'app/entities/adv-control/adv-control-dialog.html',
							controller: 'AdHeaderDialogController',
							controllerAs: 'vm',
						}
					},
					resolve: {
						entity: ['$stateParams', 'AdHeader',
							function ($stateParams, AdHeader) {
								return AdHeader.copy({
									id: $stateParams.id
								}).$promise;
							}]
					},
					previousState: [
						"$state",
						function ($state) {
							var currentStateData = {
								isCopy: 'false',
								params: $state.params,
								url: $state.href(
									$state.current.name,
									$state.params)
							};
							return currentStateData;
						}]
				})
			.state(
				'adv-controls.edit',
				{
					parent: 'adv-controls',
					url: '/{id}',
					data: {
						authorities: ['AD_ADMIN', 'ROLE_SP_ADMIN',
							'ROLE_SP_FINANCE'],
						pageTitle: 'mobilityexchangeApp.adHeader.detail.title'
					},
					views: {
						'content@': {
							templateUrl: 'app/entities/adv-control/adv-control-dialog.html',
							controller: 'AdHeaderDialogController',
							controllerAs: 'vm',
						}
					},
					resolve: {
						entity: ['$stateParams', 'AdHeader',
							function ($stateParams, AdHeader) {
								return AdHeader.get({
									id: $stateParams.id
								}).$promise;
							}],
						previousState: [
							"$state",
							function ($state) {
								var currentStateData = {
									name: $state.current.name
										|| 'adv-controls',
									params: $state.params,
									url: $state.href(
										$state.current.name,
										$state.params)
								};
								return currentStateData;
							}]
					}
				})
			.state(
				'adv-controls.delete',
				{
					parent: 'adv-controls',
					url: '/{id}/delete',
					data: {
						authorities: ['AD_ADMIN', 'ROLE_SP_ADMIN',
							'ROLE_SP_FINANCE']
					},
					onEnter: [
						'$stateParams',
						'$state',
						'$uibModal',
						function ($stateParams, $state, $uibModal) {
							$uibModal
								.open({
									templateUrl: 'app/entities/adv-control/adv-controls-delete-dialog.html',
									controller: 'AdHeaderDeleteController',
									controllerAs: 'vm',
									size: 'md',
									resolve: {
										entity: [
											'AdHeader',
											function (
												AdHeader) {
												return AdHeader
													.get({
														id: $stateParams.id
													}).$promise;
											}]
									}
								}).result.then(function () {
									$state.go('adv-controls', null, {
										reload: 'adv-controls'
									});
								}, function () {
									$state.go('^');
								});
						}]
				})
			.state(
				'adv-billing',
				{
					parent: 'adv-controls',
					url: '/adv-billing/{id}',
					data: {
						authorities: ['AD_ADMIN'],
						pageTitle: 'mobilityexchangeApp.adHeader.detail.title'
					},
					views: {
						'content@': {
							templateUrl: 'app/entities/adv-control/adv-billing.html',
							controller: 'AdvBillingController',
							controllerAs: 'vm'
						}
					},
					resolve: {
						translatePartialLoader: [
							'$translate',
							'$translatePartialLoader',
							function ($translate,
								$translatePartialLoader) {
								// $translatePartialLoader.addPart('invoiceHeader');
								return $translate.refresh();
							}],
						entity: ['$stateParams', 'AdHeader',
							function ($stateParams, AdHeader) {
								// return InvoiceHeader.get({id :
								// $stateParams.id}).$promise;
							}],
						previousState: [
							"$state",
							function ($state) {
								var currentStateData = {
									/*
									 * name: $state.current.name ||
									 * 'invoice-header',
									 */
									params: $state.params,
									url: $state.href(
										$state.current.name,
										$state.params)
								};
								return currentStateData;
							}]
					}
				})
			.state(
				'adv-billing-setup',
				{
					parent: 'adv-controls',
					url: '/adv-billing-setup/{id}',
					data: {
						authorities: ['AD_ADMIN', 'ROLE_SP_ADMIN',
							'ROLE_SP_FINANCE'],
						pageTitle: 'mobilityexchangeApp.adHeader.detail.title'
					},
					views: {
						'content@': {
							templateUrl: 'app/entities/adv-control/adv-billing-setup.html',
							controller: 'AdvBillingSetupController',
							controllerAs: 'vm'
						}
					},
					resolve: {
						translatePartialLoader: [
							'$translate',
							'$translatePartialLoader',
							function ($translate,
								$translatePartialLoader) {
								// $translatePartialLoader.addPart('invoiceHeader');
								return $translate.refresh();
							}],
						entity: [
							'$stateParams',
							'AdHeader',
							function ($stateParams, AdHeader) {
								return AdHeader.getServiceProvidersForBillSetup().$promise;
							}],
						previousState: [
							"$state",
							function ($state) {
								var currentStateData = {
									/*
									 * name: $state.current.name ||
									 * 'invoice-header',
									 */
									params: $state.params,
									url: $state.href(
										$state.current.name,
										$state.params)
								};
								return currentStateData;
							}]
					}
				})
			.state(
				'adv-controls-activity',
				{
					parent: 'entity',
					url: '/adv-controls-activity?page&sort&search&navtype',
					data: {
						authorities: ['AD_ADMIN', 'ROLE_SP_ADMIN',
							'ROLE_SP_FINANCE'],
						pageTitle: 'mobilityexchangeApp.adHeader.home.title'
					},
					views: {
						'content@': {
							templateUrl: 'app/entities/adv-control/adv-control-activity.html',
							controller: 'AdvCompaignActivityController',
							controllerAs: 'vm'
						}
					},
					params: {
						page: {
							value: '1',
							squash: true
						},
						sort: {
							value: 'id,desc',
							squash: true
						},
						search: null,
						navtype: null
					},
					resolve: {
						pagingParams: [
							'$stateParams',
							'PaginationUtil',
							function ($stateParams, PaginationUtil) {
								return {
									page: PaginationUtil
										.parsePage($stateParams.page),
									sort: $stateParams.sort,
									predicate: PaginationUtil
										.parsePredicate($stateParams.sort),
									ascending: PaginationUtil
										.parseAscending($stateParams.sort),
									search: $stateParams.search
								};
							}],
						translatePartialLoader: [
							'$translate',
							'$translatePartialLoader',
							function ($translate,
								$translatePartialLoader) {
								$translatePartialLoader
									.addPart('advCampaignSetup');
								$translatePartialLoader
									.addPart('global');
								return $translate.refresh();
							}]
					}
				})
			.state(
				'adv-controls-activity.detail',
				{
					parent: 'adv-controls-activity',
					url: '/{id}',
					data: {
						authorities: ['AD_ADMIN', 'ROLE_SP_ADMIN',
							'ROLE_SP_FINANCE'],
						pageTitle: 'mobilityexchangeApp.adHeader.detail.title'
					},
					views: {
						'content@': {
							templateUrl: 'app/entities/adv-control/adv-control-activity-details.html',
							controller: 'AdvCompaignActivityDetailController',
							controllerAs: 'vm',
						}
					},
					resolve: {
						entity: [
							'$stateParams',
							'AdInvoiceHeader',
							function ($stateParams, AdInvoiceHeader) {
								return AdInvoiceHeader.get({
									id: $stateParams.id
								}).$promise;
							}],
						previousState: [
							"$state",
							function ($state) {
								var currentStateData = {
									name: $state.current.name
										|| 'adv-controls-activity',
									params: $state.params,
									url: $state.href(
										$state.current.name,
										$state.params)
								};
								return currentStateData;
							}]
					}
				});
	}

})();
