(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('LookupsDetailController', LookupsDetailController);

    LookupsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Lookups', 'LookupTypes'];

    function LookupsDetailController($scope, $rootScope, $stateParams, previousState, entity, Lookups, LookupTypes) {
        var vm = this;

        vm.lookups = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:lookupsUpdate', function (event, result) {
            vm.lookups = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
