(function() {
	// 'use strict';

	angular.module('mobilityexchangeApp').directive('fileUpload', function() {
		return {
			scope : true, // create a new scope
			link : function(scope, el, attrs) {
				el.bind('change', function(event) {
					var files = event.target.files;
					// iterate files since 'multiple' may be specified on the
					// element
					for (var i = 0; i < files.length; i++) {
						// emit event upward
						scope.$emit("fileSelected", {
							file : files[i]
						});
					}
				});
			}
		};
	}).controller('ClaimInvoiceDetailController', ClaimInvoiceDetailController);

	ClaimInvoiceDetailController.$inject = [ '$timeout', '$scope',
			'$stateParams', 'entity', 'ClaimInvoiceDetail', 'Lookups',
			'DateUtils', '$http', '$q', 'ClaimHeader', 'FileUploader',
			'$cookies' ];

	function ClaimInvoiceDetailController($timeout, $scope, $stateParams,
			entity, ClaimInvoiceDetail, Lookups, DateUtils, $http, $q,
			ClaimHeader, FileUploader, $cookies) {
		var vm = this;

		vm.save = save;
		vm.claimInvoiceDetail = entity;
		vm.datePickerOpenStatus = {};
		vm.datePickerOpenStatus.invoiceDate = false;
		$scope.displayDateFormat = DateUtils.displayDateFormat;
		vm.openCalendar = openCalendar;

		if ($stateParams.claimId)
			vm.claimInvoiceDetail.claimHeader = ClaimHeader.get({
				id : $stateParams.claimId
			});

		vm.invoiceCurrencies = Lookups.getByLookupType({
			id : 52
		});

		vm.docTypes = Lookups.getByLookupType({
			id : 73
		});

		if (vm.claimInvoiceDetail.claimDocs) {
			vm.claimDocs = vm.claimInvoiceDetail.claimDocs;
			if (vm.claimDocs.length === 0)
				vm.claimDocs.push({});
		} else {
			vm.claimDocs = [ {} ];
		}

		vm.addDocLine = function() {
			vm.claimDocs.push({});
		};

		vm.removeDocLine = function(idx) {
			vm.claimDocs.splice(idx, 1);
			if (vm.claimDocs.length === 0)
				vm.claimDocs = [ {} ];
		};

		function save() {
			vm.isSaving = true;

			vm.claimInvoiceDetail.claimDocs = vm.claimDocs;

			if (vm.claimInvoiceDetail.id !== null) {
				ClaimInvoiceDetail.update(vm.claimInvoiceDetail, onSaveSuccess,
						onSaveError);
			} else {
				ClaimInvoiceDetail.save(vm.claimInvoiceDetail, onSaveSuccess,
						onSaveError);
			}

		}
		// vm.claimInvoiceDetail.id

		function onSaveSuccess(result) {
			vm.claimInvoiceDetail = result;
			if ($scope.fileToUpload){
				doUploadFile(vm.claimInvoiceDetail.id, $scope.fileToUpload);
			}
			$scope.$emit('mobilityexchangeApp:claimHeaderUpdate', result);
			vm.isSaving = false;
		}

		function onSaveError() {
			vm.isSaving = false;
		}

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}

		function doUploadFile(id, file) {
			var url = "/api/claim-file-upload?claimInvoiceId=" + id;

			var data = new FormData();
			data.append('uploadfile', file);

			var config = {
				transformRequest : angular.identity,
				transformResponse : angular.identity,
				headers : {
					'Content-Type' : undefined
				}
			}

			$http
                .post(url, data, config)
                .then(
                    function(response) {
                        $scope.uploadResult = "File uploaded successfully.";

                        // Refresh missing documents status
                        $http.get('/api/claim-invoice-details/' + id).then(function (res) {
                            console.log('Refreshed claim invoice detail: ' + res);
                            var responseData = angular.fromJson(res.data);
                            console.log('Response data: ' + responseData);
                            vm.claimInvoiceDetail.hasMissingDocuments = responseData.hasMissingDocuments;
                            vm.claimInvoiceDetail.missingDocumentsStatus = responseData.missingDocumentsStatus;
                        });
                    },
                    function(response) {
                        vm.claimInvoiceDetail.claimDocs.pop();
                        $scope.uploadResult = "Error uploading file, Files must be 3 megabyte or less. Only PDF and image files are permitted. Please contact support@mobilityex.com if you require assistance";
                    });
		}
		;

		// an array of files selected
		$scope.files = [];

		// listen for the file selected event
		$scope.$on("fileSelected", function(event, args) {
			$scope.$apply(function() {
				if(args.file){
				var fileSize = args.file.size/1024/1024;
					if(fileSize > 3){
						$scope.uploadResult = "Error uploading file, Files must be 3 megabyte or less. Only PDF and image files are permitted. Please contact support@mobilityex.com if you require assistance";
					} else {
						// add the file object to the scope's files collection
						$scope.files.push(args.file);
						for (var i = 0; i < vm.claimDocs.length; i++) {
							if (!vm.claimDocs[i].docName) {
								vm.claimDocs[i].docName = args.file.name;
								vm.claimDocs[i].docPath = '/customer/images/claim_'
										+ args.file.name;
							}
						}
						$scope.fileToUpload = args.file;
						if(!vm.isSaving){
							save();
						}
					}
				}
			});
		});
	}
})();
