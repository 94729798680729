(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('QuotationResponseSearch', QuotationResponseSearch);

    QuotationResponseSearch.$inject = ['$resource'];

    function QuotationResponseSearch($resource) {
        var resourceUrl =  'api/_search/quotation-responses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
