(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('invoice-header', {
            parent: 'entity',
            url: '/invoice-header?spId&page&search&spn&invdate&pdate&invstat&paymeth&invamt&spname&invfromdate&invtodate&pstat&invnum&payref&ref',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'mobilityexchangeApp.invoiceHeader.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/invoice-header/invoice-headers.html',
                    controller: 'InvoiceHeaderController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'invoiceDate,desc'
                },
                search: null,
                invdate:null,
                pdate:null,
                invstat:null,
                pstat:null,
                paymeth:null,
                invamt:null,
                spname:null,
                invnum:null,
                payref:null,
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('invoiceHeader');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

            .state('invoice-lookup', {
                parent: 'entity',
                url: '/invoice-header?spId&page&search&spn',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_ASSN_FINANCE'],
                    pageTitle: 'mobilityexchangeApp.invoiceHeader.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invoice-header/invoice-headers.html',
                        controller: 'InvoiceHeaderController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'invoiceDate,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('invoiceHeader');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

        .state('invoice-header-detail', {
            parent: 'invoice-header',
            url: '/invoice-header/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'mobilityexchangeApp.invoiceHeader.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/invoice-header/invoice-header-detail.html',
                    controller: 'InvoiceHeaderDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('invoiceHeader');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'InvoiceHeader', function($stateParams, InvoiceHeader) {
                    return InvoiceHeader.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'invoice-header',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })


            .state('batch-billing', {
                parent: 'invoice-header',
                url: '/batch-billing/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ASSN_FINANCE'],
                    pageTitle: 'mobilityexchangeApp.invoiceHeader.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invoice-header/batch-billing.html',
                        controller: 'BatchBillingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                      //  $translatePartialLoader.addPart('invoiceHeader');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'InvoiceHeader', function($stateParams, InvoiceHeader) {
                        //return InvoiceHeader.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            /*name: $state.current.name || 'invoice-header',*/
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })


        .state('invoice-header-detail.edit', {
            parent: 'invoice-header-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
            'content@': {
                templateUrl: 'app/entities/invoice-header/invoice-header-dialog.html',
                controller: 'InvoiceHeaderDialogController',
                controllerAs: 'vm',
            }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('invoiceHeader');
                    return $translate.refresh();
                }],
                entity: ['InvoiceHeader', function(InvoiceHeader) {
                    return InvoiceHeader.get({id : $stateParams.id}).$promise;
                }],
                    previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name ,
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('invoice-header.new', {
            parent: 'invoice-header',
            url: '/new/{sp}/{spn}',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/invoice-header/invoice-header-dialog.html',
                    controller: 'InvoiceHeaderDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                entity: function () {
                    return {
                        serviceproviderId: null,
                        invoiceNum: null,
                        invoiceDate: null,
                        invoiceStatus: null,
                        fromDate: null,
                        toDate: null,
                        amount: null,
                        paymentTerms: null,
                        paymentStatus: null,
                        paymentMethod: null,
                        paymentDate: null,
                        paymentAmount: null,
                        comments: null,
                        createdDate: null,
                        createdBy: null,
                        modifiedDate: null,
                        modifiedBy: null,
                        id: null
                    };
                }
            },
            previousState: ["$state", function ($state) {
                var currentStateData = {
                    isCopy: 'false',
                    params: $state.params,
                    url: $state.href($state.current.name, $state.params)
                };
                return currentStateData;
            }]
        })
        .state('invoice-header.edit', {
            parent: 'invoice-header',
            url: '/invoice-header/{id}/{sp}/{spn}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'mobilityexchangeApp.invoiceHeader.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/invoice-header/invoice-header-dialog.html',
                    controller: 'InvoiceHeaderDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('invoiceHeader');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'InvoiceHeader', function($stateParams, InvoiceHeader) {
                    return InvoiceHeader.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'invoice-header',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('invoice-header.delete', {
            parent: 'invoice-header',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/invoice-header/invoice-header-delete-dialog.html',
                    controller: 'InvoiceHeaderDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['InvoiceHeader', function(InvoiceHeader) {
                            return InvoiceHeader.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('invoice-header', null, { reload: 'invoice-header' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
