(function () {
    //'use strict';
    angular
        .module('mobilityexchangeApp')
        .factory('CRMNotes', CRMNotes);

    CRMNotes.$inject = ['$resource', 'DateUtils'];

    function CRMNotes($resource, DateUtils) {
        var resourceUrl = 'api/c-rm-notes/:id';

        return $resource(resourceUrl, {}, {
            // 'query': {method: 'GET', isArray: true},
            'query': {
                method: 'GET',
                isArray: true,
                url: 'api/c-rm-notes?'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.targetDate = DateUtils.convertLocalDateFromServer(data.targetDate);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                        data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.targetDate = DateUtils.convertLocalDateToServer(copy.targetDate);
                    copy.createddate = DateUtils.convertLocalDateToServer(copy.createddate);
                    copy.modifieddate = DateUtils.convertLocalDateToServer(copy.modifieddate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.targetDate = DateUtils.convertLocalDateToServer(copy.targetDate);
                    copy.createddate = DateUtils.convertLocalDateToServer(copy.createddate);
                    copy.modifieddate = DateUtils.convertLocalDateToServer(copy.modifieddate);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
