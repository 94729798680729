(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('ServiceProvidersSearch', ServiceProvidersSearch);

    ServiceProvidersSearch.$inject = ['$resource'];

    function ServiceProvidersSearch($resource) {
        var resourceUrl = 'api/_search/service-providers/:id';
        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }
})();
