(function() {
	// 'use strict';

	angular.module('mobilityexchangeApp').controller(
			'ContentControlController', ContentControlController);

	ContentControlController.$inject = [ '$rootScope', '$scope', '$state',
			'ContentControl', 'ContentControlSearch', 'ParseLinks',
			'AlertService', 'pagingParams', 'paginationConstants', 'Principal',
			'$log', '$stateParams', 'Lookups' ];

	function ContentControlController($rootScope, $scope, $state,
			ContentControl, ContentControlSearch, ParseLinks, AlertService,
			pagingParams, paginationConstants, Principal, $log, $stateParams,
			Lookups) {
		var vm = this;
		vm.loadPage = loadPage;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.clear = clear;
		vm.transitionOnKeyUp = transitionOnKeyUp;
		vm.search = search;
		vm.loadAll = loadAll;
		vm.searchQuery = pagingParams.search;
		vm.currentSearch = pagingParams.search;

		vm.id = $stateParams.id;
		vm.description = $stateParams.description;
		vm.website = $stateParams.website;
		vm.type = $stateParams.type;

		loadAll();

		function loadAll() {
			if (pagingParams.search) {
				ContentControlSearch.query({
					query : pagingParams.search,
					page : pagingParams.page - 1,
					size : vm.itemsPerPage,
					sort : sort(),
					id : vm.id ? vm.id : $stateParams.id,
					description : vm.description ? vm.description
							: $stateParams.description,
					website : vm.website ? vm.website : $stateParams.website,
					type : vm.type ? vm.type : $stateParams.type

				}, onSuccess, onError);
			} else {
				ContentControl.query({
					page : pagingParams.page - 1,
					size : vm.itemsPerPage,
					sort : sort()
				}, onSuccess, onError);
			}

		}

		function sort() {
			var result = [ vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc') ];
			if (vm.predicate !== 'id') {
				result.push('id');
			}
			return result;
		}

		function onSuccess(data, headers) {
			vm.links = ParseLinks.parse(headers('link'));
			vm.totalItems = headers('X-Total-Count');
			vm.queryCount = vm.totalItems;
			vm.ContentControl = data;
			vm.page = pagingParams.page;

		}

		function onError(error) {
			AlertService.error(error.data.message);
		}

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch,
				id : vm.id ? vm.id : $stateParams.id,
						description : vm.description ? vm.description
								: $stateParams.description,
						website : vm.website ? vm.website : $stateParams.website,
						type : vm.type ? vm.type : $stateParams.type
			});
		}

		function search(searchQuery) {
			if (!searchQuery) {
				return vm.clear();
			}
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			vm.currentSearch = searchQuery;
			vm.transition();
		}

		function clear() {
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = true;
			vm.currentSearch = null;
			vm.transition();
		}

		$scope.goToOnKeyUp = function(e, searchQuery) {
			if (event.keyCode === 13) {
				vm.transitionOnKeyUp(searchQuery);
			}
		}

		function transitionOnKeyUp(searchQuery) {
			if (!searchQuery) {
				return vm.clear();
			}
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			vm.currentSearch = searchQuery;
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch,
				id : vm.id,
				description : vm.description,
				website : vm.website,
				type : vm.type
			});
		}
	}
})();