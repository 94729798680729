(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('QuotationResponseDtlDetailController', QuotationResponseDtlDetailController);

    QuotationResponseDtlDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'QuotationResponseDtl'];

    function QuotationResponseDtlDetailController($scope, $rootScope, $stateParams, previousState, entity, QuotationResponseDtl) {
        var vm = this;

        vm.quotationResponseDtl = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:quotationResponseDtlUpdate', function(event, result) {
            vm.quotationResponseDtl = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
