(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('InvoiceHeaderDetailController', InvoiceHeaderDetailController);

    InvoiceHeaderDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'InvoiceHeader'];

    function InvoiceHeaderDetailController($scope, $rootScope, $stateParams, previousState, entity, InvoiceHeader) {
        var vm = this;

        vm.invoiceHeader = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:invoiceHeaderUpdate', function(event, result) {
            vm.invoiceHeader = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
