(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('LookupsSearch', LookupsSearch);

    LookupsSearch.$inject = ['$resource', 'DateUtils'];

    function LookupsSearch($resource, DateUtils) {
        var resourceUrl = 'api/_search/lookups/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'getByLookupType': {
                method: 'GET',
                url: 'api/_search/lookups/lookuptypes/:id',
                params: {id: '@id'},
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                        data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                    }
                    return data;
                }
            }
        });
    }
})();
