(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('ContactQualificationsDeleteController',ContactQualificationsDeleteController);

    ContactQualificationsDeleteController.$inject = ['$uibModalInstance', 'entity', 'ContactQualifications'];

    function ContactQualificationsDeleteController($uibModalInstance, entity, ContactQualifications) {
        var vm = this;

        vm.contactQualifications = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ContactQualifications.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
