(function() {
	//'use strict';
	angular.module('mobilityexchangeApp').factory('ContentControl',
			ContentControl);

	ContentControl.$inject = [ '$resource', 'DateUtils' ];

	function ContentControl($resource, DateUtils) {
		var resourceUrl = 'api/content-control/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
						data.endDate = DateUtils.convertDateTimeFromServer(data.endDate);
					}
					return data;
				}
			},
			'update' : {
				method : 'PUT',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				},
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'getIAMXNews': {
                method: 'GET',
                url: 'api/iamx-news',
                cache: true,
                isArray: true,
                params: {
                    supplier: null
                },
                transformResponse: function (data) {
                	if (data) {
                        data = angular.fromJson(data);
                	}
                    return data;
                }
            },
            'getIAMXhelp': {
                method: 'GET',
                url: 'api/iamx-help',
                cache: true,
                isArray: true,
                params: {
                    supplier: null
                },
                transformResponse: function (data) {
                	if (data) {
                        data = angular.fromJson(data);
                	}
                    return data;
                }
            },
            'getFeatures': {
                method: 'GET',
                url: 'api/content-control/feature',
                cache: true,
                isArray: true,
                transformResponse: function (data) {
                	if (data) {
                        data = angular.fromJson(data);
                	}
                    return data;
                }
            },
            'getResource': {
                method: 'GET',
                url: 'api/content-control/resource',
                cache: true,
                isArray: true,
                params: {
                    supplier: null
                },
                transformResponse: function (data) {
                	if (data) {
                        data = angular.fromJson(data);
                	}
                    return data;
                }
            },
            'getResource1': {
                method: 'GET',
                url: 'api/content-control/resource1',
                cache: true,
                isArray: true,
                params: {
                    supplier: null
                },
                transformResponse: function (data) {
                	if (data) {
                        data = angular.fromJson(data);
                	}
                    return data;
                }
            },
            'getResource2': {
                method: 'GET',
                url: 'api/content-control/resource2',
                cache: true,
                isArray: true,
                params: {
                    supplier: null
                },
                transformResponse: function (data) {
                	if (data) {
                        data = angular.fromJson(data);
                	}
                    return data;
                }
            },
			'save' : {
				method : 'POST',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					copy.createddate = DateUtils
							.convertLocalDateToServer(copy.createddate);
					return angular.toJson(copy);
				},
				transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertDateTimeFromServer(data.createddate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                        data.endDate = DateUtils.convertDateTimeFromServer(data.endDate);
                    }
                    return data;
                }
			}
		});
	}
})();
