(function () {
    //'use strict';
    angular
        .module('mobilityexchangeApp')
        .factory('MXSupport', MXSupport);

    MXSupport.$inject = ['$resource', 'DateUtils'];

    function MXSupport($resource, DateUtils) {
        var resourceUrl = 'api/mxsupport/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
