(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('ActivationController', ActivationController);

    ActivationController.$inject = ['$stateParams', 'Auth', 'LoginService'];

    function ActivationController($stateParams, Auth, LoginService) {
        var vm = this;
        vm.controllerName = "ActivationController";
        Auth.activateAccount({key: $stateParams.key}).then(function () {
            vm.error = null;
            vm.success = 'OK';
        }).catch(function () {
            vm.success = null;
            vm.error = 'ERROR';
        });
        vm.login = LoginService.open;
    }
})();
