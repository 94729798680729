(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('InvoiceHeaderDialogController', InvoiceHeaderDialogController);

    InvoiceHeaderDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'InvoiceHeader',
        'Lookups', 'BillingPlan', 'DateUtils', '$http', 'BillingDiscount', '$q', 'ServiceProviders', 'Principal'];

    function InvoiceHeaderDialogController ($timeout, $scope, $stateParams, entity, InvoiceHeader,
                                            Lookups, BillingPlan, DateUtils, $http, BillingDiscount, $q , ServiceProviders, Principal) {
        var vm = this;

        vm.invoiceHeader = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        //vm.balanceLines = InvoiceHeader.getBalances({spId: $stateParams.spId});

        vm.invoiceStatus = Lookups.getByLookupType({id: 36});
        vm.paymentTerms = Lookups.getByLookupType({id: 38});
        vm.paymentStatus = Lookups.getByLookupType({id: 39});
        vm.paymentMethods = Lookups.getByLookupType({id: 40});
        vm.currencies = Lookups.getByLookupType({id: 52});
        vm.billingPlans = BillingPlan.getAll({spId:$stateParams.spId});

        vm.billingBasis = Lookups.getByLookupType({id: 42});
        vm.billingCategories = Lookups.getByLookupType({id: 41});
        vm.billedQualifications = InvoiceHeader.getBilledQualifications({spId: $stateParams.spId});
        vm.spName = $stateParams.spn;
        vm.sp = $stateParams.sp;
        if(!vm.sp)
            vm.sp = $stateParams.spId;
        //vm.invoiceHeader.serviceproviderId = vm.sp;
        vm.discountEligiblePlans = [];
        ServiceProviders.getprofile({id:vm.sp}).$promise.then(function success(response) {
      		vm.invoiceHeader.serviceprovider = response.serviceProviders;
			// console.log('41. ' + JSON.stringify(vm.invoiceHeader.serviceprovider));
    	});


        vm.getBilledDiscounts = function(invcLine){

            $http.get('api/billing-line-discounts/'+invcLine.billingPlan.id).then(function(response){

                if(response.data && response.data.length>0) {

                    var selectedResponseIndex = -1;
                    for (var j = 0; j < response.data.length && selectedResponseIndex == -1; j++) {
                        var appliedWithPlan = response.data[j].appliedWithPlan;
                        var maxDiscount = response.data[j].maxDiscountAmt;
                        var eligibleForDiscount = false;

                        for(var i=0; i<vm.invoiceLines.length;i++){

                            if(vm.invoiceLines[i].billingPlan.id === appliedWithPlan.id){
                                selectedResponseIndex = j;
                                eligibleForDiscount = true;
                                break;
                            }
                        }
                    }

                    if(eligibleForDiscount){

                        var invLine = {};
                        invLine.billingPlan = response.data[selectedResponseIndex].appliedToPlan;
                        invLine.quantity = 1;
                        invLine.billingPlan.rates = - (response.data[selectedResponseIndex].discountRate * invcLine.quantity);
                        invLine.rate = - (response.data[selectedResponseIndex].discountRate * invcLine.quantity);
                        invLine.description = response.data[selectedResponseIndex].description;
                        invLine.amount =  invLine.billingPlan.rates;

                        if(-invLine.amount > maxDiscount) {
                            invLine.billingPlan.rates = maxDiscount;
                            invLine.rate = -maxDiscount;
                            invLine.amount =  -maxDiscount;
                        }


                        vm.invoiceLines.push(invLine);
                        vm.recalculateAmt();
                    }
                }
            });

        };


        if(vm.invoiceHeader.invoiceLines){
            vm.invoiceLines = vm.invoiceHeader.invoiceLines;
        } else {
            vm.invoiceLines = [{}];
        }

        $http.get('/api/invoice-balance-lines/'+vm.sp).then(function(response){
            vm.balanceLines = response.data;
            if(vm.invoiceHeader && vm.invoiceHeader.id == null) {
                if(vm.balanceLines){
                    vm.invoiceLines.splice(0,0,vm.balanceLines);
                }
            }
        });



        $scope.displayDateFormat = DateUtils.displayDateFormat;


        $('#editForm .rate').on('input', function(){
        });


        $scope.disablePayment= function (isInvoicePaidFlag, invoiceStatus) {
        	// console.log("1. isInvoicePaidFlag " + isInvoicePaidFlag);

        	if(invoiceStatus ==='Void')
        		return true;
        	else if (isInvoicePaidFlag || invoiceStatus === 'Draft' || invoiceStatus === 'Reviewed'){
        		if(Principal.hasAnyAuthority(['ROLE_IAM_PMT_MGR']))
        			return false;
        		return true;
        	}


        };

        vm.previewInvoice = function(event, invoiceId){

            event.preventDefault();
            event.stopPropagation();

            if( _.isEmpty(vm.taxId) ) vm.taxId = " ";
                $http.get('api/generate-invoice/'+invoiceId, {responseType: 'arraybuffer'}).then(function successCallback(data) {
                    var file = new Blob([data.data], {type: 'application/pdf'});
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }, function errorCallback(response) {
            });

        };

        vm.setValues = function(obj){

            if(obj){

                obj.rate = obj.billingPlan.rates;
                obj.description = obj.billingPlan.description;

                if( vm.billedQualifications[0] ){

                    var qtyP = vm.billedQualifications[0];
                    var descP = vm.billedQualifications[1];
                    var qty = qtyP[obj.billingPlan.id];
                    var descP = descP[obj.billingPlan.id];

                    obj.quantity = qty;

                    if(!descP)
                        descP = '';
                    else
                        obj.description = '';

                   obj.description = obj.description+" "+descP;
                }

                vm.recalculateAmtBasedOnBillingPlanChange();
            }
        };

        vm.recalculateAmtBasedOnBillingPlanChange = function(){

            vm.invoiceHeader.invoiceAmount = 0;

            for(var i=0; i<vm.invoiceLines.length; i++){

                if(!vm.invoiceLines[i].quantity) vm.invoiceLines[i].quantity = 1;
                if(!vm.invoiceLines[i].amount)
                    vm.invoiceLines[i].amount = vm.invoiceLines[i].billingPlan.rates * vm.invoiceLines[i].quantity;
                vm.invoiceLines[i].amount = Number((vm.invoiceLines[i].amount).toFixed(2)); //round to 2 decimal point
                vm.invoiceHeader.invoiceAmount = vm.invoiceHeader.invoiceAmount + vm.invoiceLines[i].amount;
            }
        };


        vm.recalculateAmt = function(){
            vm.invoiceHeader.invoiceAmount = 0;
            for(var i=0; i<vm.invoiceLines.length; i++){
                vm.invoiceLines[i].amount = vm.invoiceLines[i].rate*vm.invoiceLines[i].quantity;
                vm.invoiceLines[i].amount = Number((vm.invoiceLines[i].amount).toFixed(2)); //round to 2 decimal point
                vm.invoiceHeader.invoiceAmount = vm.invoiceHeader.invoiceAmount + vm.invoiceLines[i].amount;
            }
        };

        vm.recalculateAmt();

        vm.addInvoiceLine = function(){
            vm.invoiceLines.push({});
        };

        vm.removeInvoiceLine = function(idx){
            vm.invoiceLines.splice(idx, 1);
            if(vm.invoiceLines.length===0)
                vm.invoiceLines = [{}];
            vm.recalculateAmt();
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
        }

        vm.invoiceHeader.invoiceLines = [];

        vm.paymentValMessage = null;

        function save () {


            if(vm.invoiceHeader
                && vm.invoiceHeader.invoiceAmount
                && vm.invoiceHeader.paymentAmount
                &&
                (vm.invoiceHeader.invoiceAmount >  vm.invoiceHeader.paymentAmount)
                &&
                (vm.invoiceHeader.paymentStatus && vm.invoiceHeader.paymentStatus.lookupvalue === 'Fully Paid' )){

                vm.paymentValMessage = 'Error saving! Payment Status cannot be Fully Paid as the paid amount ' +
                    'is less that the invoice amount.';
                return false;
            }

            if(vm.invoiceHeader &&
                (vm.invoiceHeader.invoiceStatus.lookupvalue === 'Published'
                ||
                    vm.invoiceHeader.invoiceStatus.lookupvalue === 'Void'
                )
                    &&
                ( vm.invoiceHeader.paymentAmount )
                ){
                    if(  !vm.invoiceHeader.paymentDate
                            || !vm.invoiceHeader.paymentMethod
                        ) {
                        vm.paymentValMessage = 'Error saving! In order to save payment details, ' +
                            'you must enter the payment amount, ' +
                            'payment status, payment date and the payment method!';

                        return false;
                    }
            }


            vm.isSaving = true;
            vm.invoiceHeader.invoiceLines = vm.invoiceLines;

            if (vm.invoiceHeader.id !== null) {
                InvoiceHeader.update(vm.invoiceHeader, onSaveSuccess, onSaveError);
            } else {

                InvoiceHeader.save(vm.invoiceHeader, onSaveSuccess, onSaveError);
            }

            vm.paymentValMessage = null;
        }

        function onSaveSuccess (result) {
            vm.invoiceHeader = result;
            $scope.$emit('mobilityexchangeApp:invoiceHeaderUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.invoiceDate = false;
        vm.datePickerOpenStatus.fromDate = false;
        vm.datePickerOpenStatus.toDate = false;
        vm.datePickerOpenStatus.paymentDate = false;
        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.modifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.setDefaultStatus = function() {
            $timeout(function(){
                if(!vm.invoiceHeader.invoiceStatus) {
                    vm.invoiceHeader.invoiceStatus = _.find(vm.invoiceStatus, {id: 1760});
                }
                if(!vm.invoiceHeader.paymentStatus) {
                    vm.invoiceHeader.paymentStatus = _.find(vm.paymentStatus, {id: 1783});
                }
                if(!vm.invoiceHeader.paymentTerms) {
                    vm.invoiceHeader.paymentTerms = _.find(vm.paymentTerms, {id: 1781});
                }
                if(!vm.invoiceHeader.invoiceDate){
                    vm.invoiceHeader.invoiceDate = new Date();
                }
            },1000);
        };

        vm.isInvoicePaidFlag = false;

        function isInvoicePaid(){
            if(vm.invoiceHeader.invoiceStatus && (vm.invoiceHeader.paymentStatus.lookupcode === 'PAID')){
                vm.isInvoicePaidFlag = true;
            }else{
                vm.isInvoicePaidFlag = false;
            }

        };

        isInvoicePaid();




}})();
