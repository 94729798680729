(function() {
	angular.module('mobilityexchangeApp').controller(
			'SearchLinkDialogController', SearchLinkDialogController);

	SearchLinkDialogController.$inject = [ '$scope', '$uibModalInstance' ];

	function SearchLinkDialogController($scope, $uibModalInstance) {
		$scope.ok = function() {
			$uibModalInstance.close("Ok");
		}

		$scope.cancel = function() {
			$uibModalInstance.dismiss();
		}
	}
})();
