(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('CustomerNotesDialogController', CustomerNotesDialogController);

    CustomerNotesDialogController.$inject = ['$http', '$timeout', '$scope', '$state', '$stateParams',
        '$uibModalInstance', 'entity', 'CustomerNotes', 'ProviderContacts', '$window', 'Principal'];

    function CustomerNotesDialogController($http, $timeout, $scope, $state, $stateParams, $uibModalInstance, entity,
                                           CustomerNotes, ProviderContacts, $window, Principal) {
        $scope.controllerName = "CustomerNotesDialogController";

        var vm = this;

        vm.customerNotes = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.providercontacts = ProviderContacts.query();

        $scope.actor;
        $scope.actors;
        $scope.actions;

        // get actors
        $http.get
        (
            'api/userslist',
            {}
        )
            .then
            (
                function (response) {
                    ////console.log('response::::'+JSON.stringify(response));
                    $scope.actors =
                        response.data
                            .filter
                            (
                                function (user) {
                                    return user.activated && (user.authorities.indexOf("ROLE_ADMIN") != -1 || user.authorities.indexOf("ROLE_ANALYST") != -1);
                                }
                            )
                            .map
                            (
                                function (user) {
                                    user.name = user.firstName + " " + user.lastName;
                                    return user;
                                }
                            )
                    ;

                    // set actor
                    for (var i = 0; i < $scope.actors.length; i++) {
                        if (vm.customerNotes && $scope.actors[i].firstName + ' ' + $scope.actors[i].lastName == vm.customerNotes.actor) {
                            $scope.actor = $scope.actors[i];
                            break;
                        }
                    }
                }
            )
        ;

        // get actions

        $http.get
        (
            'api/lookups/lookuptypes/20',
            {}
        )
            .then
            (
                function (response) {
                    $scope.actions = response.data;

                    for (var i = 0; i < $scope.actions.length; i++) {
                        if (vm.customerNotes && vm.customerNotes.action != null && $scope.actions[i].id == vm.customerNotes.action.id) {
                            vm.customerNotes.action = $scope.actions[i];
                            break;
                        }
                    }
                }
            )
        ;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            // set actor fields
            vm.customerNotes.actor = $scope.actor.firstName + ' ' + $scope.actor.lastName;

            // update customer note
            if (vm.customerNotes.id !== null) {
                CustomerNotes.update(vm.customerNotes, onSaveSuccess, onSaveError);
            } else {
                CustomerNotes.save(vm.customerNotes, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('mobilityexchangeApp:customerNotesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            $uibModalInstance.dismiss('cancel');

            // $window.location.reload();
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.targetDate = false;
        vm.datePickerOpenStatus.createddate = false;
        vm.datePickerOpenStatus.modifieddate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
