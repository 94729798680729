(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('QuotationResponseDetailController', QuotationResponseDetailController);

    QuotationResponseDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'QuotationResponse'];

    function QuotationResponseDetailController($scope, $rootScope, $stateParams, previousState, entity, QuotationResponse) {
        var vm = this;

        vm.quotationResponse = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:quotationResponseUpdate', function(event, result) {
            vm.quotationResponse = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
