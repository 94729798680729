(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('LanguageCapabilitiesDeleteController', LanguageCapabilitiesDeleteController);

    LanguageCapabilitiesDeleteController.$inject = ['$uibModalInstance', 'entity', 'LanguageCapabilities'];

    function LanguageCapabilitiesDeleteController($uibModalInstance, entity, LanguageCapabilities) {
        var vm = this;

        vm.languageCapabilities = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            LanguageCapabilities.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
