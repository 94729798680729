(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('Account', Account);

    Account.$inject = ['$resource'];

    function Account($resource) {
        var service = $resource('api/account', {}, {
            'get': {
                method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function (response) {
                        // expose response
                        return response;
                    }
                }
            },
            'activateuser': {
                method: 'PUT',
                url: 'api/account/activate_user',
                params: {login: '@login'},
                transformResponse: function (data) {
                    return data;
                }
            },
            'makeAdmin': {
                method: 'PUT',
                url: 'api/account/grant_admin_role',
                params: {login: '@login'},
                transformResponse: function (data) {
                    return data;
                }
            },
            'isactive': {
                method: 'GET',
                url: 'isactive?login',
                params: {login: '@login'},
                transformResponse: function (data) {
                    return data.activated;
                }
            }
        });

        return service;
    }
})();
