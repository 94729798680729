(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', '$uibModalInstance', 'Notifications'];

    function LoginController($rootScope, $state, $timeout, Auth, $uibModalInstance, Notifications) {
        var vm = this;

        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;
        vm.notifications = null;

            $timeout(function () {
            angular.element('#username').focus();
        });

        function cancel() {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            $uibModalInstance.dismiss('cancel');
        }

        function login(event) {
            event.preventDefault();

            Auth.login({

                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe

            }).then(function () {

                vm.authenticationError = false;

                $uibModalInstance.close();

                if ($state.current.name === 'home'
                    || $state.current.name === 'register'
                    || $state.current.name === 'activate'
                    || $state.current.name === 'finishReset'
                    || $state.current.name === 'requestReset'
                    || $state.current.name === 'accessdenied'
                    || $state.current.name === 'iamlearning'
                ) {
                    console.log('r1');
                    //$state.reload();
                    $state.go('home',{reload: true});
                }
                //$state.go('home');
                //$state.reload();

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState() ) {
                    console.log('r2');
                    var previousState = Auth.getPreviousState();
                    ////console.log('Previous State ::: '+previousState.name);
                    //Auth.resetPreviousState();
                    //$state.reload();
                    $state.go(previousState.name, previousState.params, {reload: true});
                }
                $state.reload();
                $state.go('home',{'ref':'1'}, {reload: true});
            }).catch(function () {
                vm.authenticationError = true;
            });
        }




        function register() {
            cancel();
            $state.go('registercompany');
        }

        function requestResetPassword() {
            $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }
    }
})();
