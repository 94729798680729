(function () {
	// 'use strict';
	angular.module('mobilityexchangeApp').controller(
		'ClaimHeaderDialogController', ClaimHeaderDialogController)
		.directive('tmpl', testComp).directive('fileUpload', function () {
			return {
				scope: true, // create a new scope
				link: function (scope, el, attrs) {
					el.bind('change', function (event) {
						var files = event.target.files;
						// iterate files since 'multiple' may be specified
						// on the
						// element
						for (var i = 0; i < files.length; i++) {
							// emit event upward
							scope.$emit("fileSelected", {
								file: files[i]
							});
						}
					});
				}
			};
		})
		.directive('claimFileUpload', function () {
            return {
                scope: true, // create a new scope
                link: function (scope, el, attrs) {
                    el.bind('change', function (event) {
                        var files = event.target.files;
                        // iterate files since 'multiple' may be specified
                        // on the
                        // element
                        for (var i = 0; i < files.length; i++) {
                            // emit event upward
                            scope.$emit("claimFileSelected", {
                                file: files[i]
                            });
                        }
                    });
                }
            };
        });

	testComp.$inject = ['$compile'];

	function testComp($compile) {
		var directive = {};
		directive.restrict = 'A';
		directive.templateUrl = 'app/entities/claim-header/claim-docs.html';
		directive.transclude = true;
		directive.link = function (scope, element, attrs) {
            scope.isIssueDocument = function(documentPath) {
                console.log("Directive - is issue document: " + documentPath);
                return documentPath && documentPath.indexOf('_issue_') != -1;
            }
		}
		return directive;
	}

	ClaimHeaderDialogController.$inject = ['$timeout', '$scope',
		'$stateParams', 'entity', 'ClaimHeader', 'Lookups', 'BillingPlan',
		'DateUtils', '$http', 'BillingDiscount', '$q', 'ServiceProviders',
		'Principal', 'DTOptionsBuilder', 'DTColumnDefBuilder', '$compile',
		'$uibModal', '$cookies', 'AuditLog'];

	function ClaimHeaderDialogController($timeout, $scope, $stateParams,
		entity, ClaimHeader, Lookups, BillingPlan, DateUtils, $http,
		BillingDiscount, $q, ServiceProviders, Principal, DTOptionsBuilder,
		DTColumnDefBuilder, $compile, $uibModal, $cookies, AuditLog) {
		var vm = this;

		vm.claimHeader = entity;
		vm.claimInvoiceDetails = vm.claimHeader.claimInvoiceDetails;
		vm.claimDocs = [{}];

		vm.datePickerOpenStatus = {};
		vm.datePickerOpenStatus.submissionDate = false;
		vm.datePickerOpenStatus.staffReviewDate = false;
		vm.datePickerOpenStatus.firstInterventionDate = false;
		vm.datePickerOpenStatus.secondInterventionDate = false;
		vm.datePickerOpenStatus.thirdInterventionDate = false;
		vm.datePickerOpenStatus.adlPublicationDate = false;
		vm.datePickerOpenStatus.expulsionNoticeDate = false;
		vm.datePickerOpenStatus.explusionRequestDate = false;
		vm.datePickerOpenStatus.expulsionConfirmDate = false;
		vm.datePickerOpenStatus.informalInterventionDate = false;

		$scope.displayDateFormat = DateUtils.displayDateFormat;
		vm.openCalendar = openCalendar;

		if (vm.claimHeader.delinquent == null) {
			vm.claimHeader.delinquent = ClaimHeader.getServiceProvider({
				id: $stateParams.sp
			});
		}

		vm.claimStatus = Lookups.getByLookupType({
			id: 70
		});

		vm.docTypes = Lookups.getByLookupType({
			id: 73
		});

		vm.UICStatus = Lookups.getByLookupType({
			id: 71
		});

		vm.OIIStatus = Lookups.getByLookupType({
			id: 72
		});

		$scope.ifRppAdmin = function () {
			return Principal.hasAnyAuthority(['RPP_ADMIN']);
		};

		vm.dtInstance = {};

		vm.dtOptions1 = DTOptionsBuilder.newOptions('className',
			'details-control').withDOM('C<"clear">lfrtip');
		vm.dtColumnDefs1 = [DTColumnDefBuilder.newColumnDef(5).notSortable()];
		// vm.dtColumnDefs2 = [DTColumnDefBuilder.newColumnDef(0).notSortable()];
		vm.dtColumnDefs2 = [DTColumnDefBuilder.newColumnDef(0)];

        vm.submitClaimToIam = {};
        vm.submitClaimToIam.initiate = initiateSubmitClaimToIam;
        vm.submitClaimToIam.errors = null;
        vm.submitClaimToIam.errorsHtml = null;

        vm.submitClaimToIam.modalInstance = null;

        vm.setValuesForDocToBeEmailed = function(type, docId) {
			console.log(type + '    ' + docId);
			vm.typeOfDocToBeEmailed = type;
			vm.idOdDocToBeEmailed = docId;
			vm.emailsent = false;
		};

        vm.emailOnePopupUrl = "email_required_doc_templ.html";
        vm.emailOne = function(e) {
            e.stopPropagation();
            e.preventDefault();
            vm.emailsent = true;

            console.log("Sending emails ====>> " + vm.typeOfDocToBeEmailed);

            if (vm.typeOfDocToBeEmailed === 'RequiredDoc') {
                console.log('Add sending for required doc');

                $http({
                    method : 'POST',
                    url : '/api/claim-invoice-details/' + vm.idOdDocToBeEmailed + '/email-required-documents',
                    headers : {
                        'X-CSRF-TOKEN' : $cookies.get('CSRF-TOKEN'),
                        'cookie' : $cookies.cookiename
                    }
                }).then(function(response) {
                    console.log(response);
                    vm.showProgress = false;
                });
            }
        };

        $scope.submitClaimToIamIamInstance = null;

		$scope.closeSubmitClaimToIamModal = function () {
			if (vm.submitClaimToIam.modalInstance) {
			    vm.submitClaimToIam.modalInstance.close("Ok");
			    $scope.submitClaimToIamIamInstance.close("Ok");
			}
			if ($scope.submitClaimToIamIamInstance) {
			    $scope.submitClaimToIamIamInstance.close("Ok");
			}
		}

		vm.save = save;

		function save() {
            var updateEntity = vm.claimHeader.id != null;
            var createEntity = vm.claimHeader.id == null;
            var requireUploadForUpdateEntity = updateEntity && (vm.claimHeader.submissionDate === null) &&
                 (vm.claimHeader.claimStatus && vm.claimHeader.claimStatus.id === 5800) &&
                 (vm.claimHeader.claimOiiStatus && vm.claimHeader.claimOiiStatus.id == 5820) &&
                 (vm.claimHeader.numberOfInvoices === 0);

            // If there is no selected claim file to upload (!$scope.claimFileToUpload)
            // and no existing correspondence file (!vm.claimHeader.docPath)
            // and this is a create request or an update request that requires correspondence update show an error
            // message
            console.log('Claim file to upload: ' + $scope.claimFileToUpload + ', doc path: ' + vm.claimHeader.docPath +
                ', claim header id: ' + vm.claimHeader.id + ', submission date: ' + vm.claimHeader.submissionDate +
                ', claim status: ' + (vm.claimHeader.claimStatus && vm.claimHeader.claimStatus.id) +
                ', claim Oii status: ' + (vm.claimHeader.claimOiiStatus && vm.claimHeader.claimOiiStatus.id) +
                ', number of invoices: ' + vm.claimHeader.numberOfInvoices + ', create entity: ' + createEntity +
                ', update entity: ' + updateEntity + ', require upload for update: ' + requireUploadForUpdateEntity);

            if (!$scope.claimFileToUpload && !vm.claimHeader.docPath && (createEntity || requireUploadForUpdateEntity)) {
                vm.validationErrorMessage = "We could not save changes! Please upload a record of your correspondence that your company has made at least 3 attempts to collect the debt (please combine into one doc.).";
                return false;
            }

			vm.isSaving = true;
			// Clear validation error
            vm.validationErrorMessage = null;

			if (vm.claimHeader.id !== null) {
				ClaimHeader.update(vm.claimHeader, onSaveSuccess, onSaveError);
			} else {
				ClaimHeader.save(vm.claimHeader, onSaveSuccess, onSaveError);
			}
		}

		function onSaveSuccess(result) {
		    // With blind assignment of result to vm.claimHeader any follow-up changes to vm.claimHeader.invoiceDetails
		    // are not sent to the server when consecutive saves are done. To avoid that update vm.claimHeader only
		    // in case of a claim creation. For updates data that is in the vm should already be up-to-date as that's
		    // what was sent in the update request.
		    // vm.claimHeader = result;
		    var createEntity = vm.claimHeader.id == null;
            if (createEntity) {
              vm.claimHeader = result;
            }

            // Upload a correspondence claim file if this was a create callback. For update requests,
            // a correspondence claim file upload happens immediately after the file is selected
            if (createEntity) {
                doUploadClaimFile(vm.claimHeader.id, $scope.claimFileToUpload);
            }
			$scope.$emit('mobilityexchangeApp:claimHeaderUpdate', result);
			vm.isSaving = false;
		}

		function onSaveError() {
			vm.isSaving = false;
		}

		$scope.refreshCurrency = function () {
			$http.get('/api/claim-headers/currencyrefresh/' + vm.claimHeader.id).then(function (res) {
				vm.claimHeader = res.data;
				vm.claimInvoiceDetails = vm.claimHeader.claimInvoiceDetails;
			});
		}

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}

		vm.uploadedDocLine = null;
		$scope.claimInvoiceDocs = function (details, event) {
			var scope = $scope.$new(true);
			scope.docTypes = vm.docTypes;
			if (details) {
				scope.claimDocs = details.claimDocs;
				scope.selectedInvoice = details;
				vm.selectedInvoice = details;
			} else {
				scope.claimDocs = [{}];
			}

			var link = angular.element(event.currentTarget), icon = link
				.find('.glyphicon'), tr = link.parent().parent(), table = vm.dtInstance.DataTable, row = table
					.row(tr);
			//
			if (row.child.isShown()) {
				icon.removeClass('glyphicon-minus-sign').addClass(
					'glyphicon-plus-sign');
				row.child.hide();
				tr.removeClass('shown');
			} else {
				icon.removeClass('glyphicon-plus-sign').addClass(
					'glyphicon-minus-sign');
				row.child($compile('<div tmpl class="clearfix"></div>')(scope))
					.show();
				tr.addClass('shown');
			}
		}

        $scope.closeUploadClaimAlert = function() {
            console.log('Closing upload claim alert');
            $scope.showUploadClaimAlert = false;
        }

		function doUploadFile(id, file) {
			vm.isUploading = true;
			var url = "/api/claim-file-upload-additional?claimInvoiceId=" + id
				+ "&description=" + vm.uploadedDocLine.description
				+ "&type=" + vm.uploadedDocLine.docType.id;

			var data = new FormData();
			data.append('uploadfile', file);

			var config = {
				transformRequest: angular.identity,
				transformResponse: angular.identity,
				headers: {
					'Content-Type': undefined
				}
			}

			$http
				.post(url, data, config)
				.then(
					function (response) {
						$scope.uploadResult = "File uploaded successfully.";
						vm.isUploading = false;
						data = angular.fromJson(response.data);
						vm.selectedInvoice.claimDocs.push(data);

                        $http.get('/api/claim-invoice-details/' + id).then(function (res) {
                            console.log('Refreshed claim invoice detail: ' + res);
                            var responseData = angular.fromJson(res.data);
                            console.log('Response data: ' + responseData);
                            vm.selectedInvoice.hasMissingDocuments = responseData.hasMissingDocuments;
                            vm.selectedInvoice.missingDocumentsStatus = responseData.missingDocumentsStatus;
                        });
					},
					function (response) {
						vm.isUploading = false;
						$scope.uploadResult = "Error uploading file, Files must be 3 megabyte or less. Only PDF and image files are permitted. Please contact support@mobilityex.com if you require assistance";
					});
		};

		function doUploadClaimFile(id, file) {
		    vm.isSaving = true;
			var url = "/api/claim-headers/" + id + "/claim-correspondence-proof-upload";

			var data = new FormData();
			data.append('uploadfile', file);

			var config = {
				transformRequest : angular.identity,
				transformResponse : angular.identity,
				headers : {
					'Content-Type' : undefined
				}
			}

			$http
					.post(url, data, config)
					.then(
							function(response) {
								$scope.uploadResult = "File uploaded successfully.";
								$scope.showUploadClaimAlert = true;
								$scope.uploadSuccess = true;
                                console.log('Response: ' + response);
                                var responseData = angular.fromJson(response.data);
                                console.log('Response data: ' + responseData);
                                vm.claimHeader.docPath = responseData.docPath;
                                $scope.$emit('mobilityexchangeApp:claimHeaderUpdate', response);
                                vm.isSaving = false;
							},
							function(response) {
								// vm.claimInvoiceDetail.claimDocs.pop();
								$scope.uploadResult = "Error uploading file, Files must be 3 megabyte or less. Only PDF and image files are permitted. Please contact support@mobilityex.com if you require assistance";
								$scope.showUploadClaimAlert = true;
								$scope.uploadSuccess = false;
								vm.isSaving = false;
							});
		};

        function initiateSubmitClaimToIam($event, id) {
            console.log('Initiate submit claim to IAM');

            $event.preventDefault();
            $event.stopPropagation();

            // Submitting claim to IAM using HTTP POST doesn't work in production (it returns 403). That needs to be
            // investigated because HTTP POST works in QC. As a work-around, HTTP GET is used to submit claim request
            // to IAM until the authorization error in production is resolved.
            $http.get('api/claim-headers/' + id + '/submit-to-iam', {
			}).then(function(result) {
                console.log('Result: ' + result);
                if(result && result.data) {
                    console.log('Result data: ' + result.data);
                    if (result.data.submissionDate != null) {
                        vm.claimHeader.submissionDate = DateUtils.convertDateTimeFromServer(result.data.submissionDate);
                    } else {
                        vm.submitClaimToIam.errorsHtml = result.data.errorsHtml;
                        vm.submitClaimToIam.errors = result.data.errors;

                        $scope.submitClaimToIamIamInstance = $uibModal
                            .open({
                                templateUrl: "app/entities/claim-header/submit-claim-to-iam-errors.html",
                                scope: $scope
                            });
                    }
                }
			});
        }

		// an array of files selected
		$scope.files = [];

		// listen for the file selected event
		$scope.$on("fileSelected", function (event, args) {
			$scope.$apply(function () {
				// add the file object to the scope's files collection
				$scope.files.push(args.file);

				$scope.fileToUpload = args.file;
				if ($scope.fileToUpload && !vm.isUploading) {
					doUploadFile(vm.selectedInvoice.id, $scope.fileToUpload);
				}

			});
		});

        $scope.$on("claimFileSelected", function (event, args) {
            console.log('Claim file selected');
			$scope.$apply(function () {
				// add the file object to the scope's files collection
				$scope.files.push(args.file);
				$scope.claimFileToUpload = args.file;
                if (vm.claimHeader.id) {
                    doUploadClaimFile(vm.claimHeader.id, $scope.claimFileToUpload);
                }
			});
		});

		$scope.modalInstance = null;
		$scope.openUploadDocs = function (invoice) {
		    console.log('Open upload docs, invoice: ' + invoice);
			$scope.modalInstance = $uibModal
				.open({
					templateUrl: "app/entities/claim-header/claim-docs-upload.html",
					scope: $scope
				});
			vm.selectedInvoice = invoice;
			$scope.modalInstance.result.then(function (response) {
				$scope.result = '${response} button hit';
			});

		};

        $scope.sSpModalInstance = null;
		vm.openSwitchSP = function (claimHeader) {
        	$scope.sSpModalInstance = $uibModal
        		.open({
        			    templateUrl: "app/entities/claim-header/claim-header-switch-sp-dialog.html",
        			    scope: $scope
        			    //controller: 'ClaimHeaderController'
        			});
        };


		$scope.ok = function () {
			vm.uploadedDocLine = null;
			$scope.uploadResult = "";
			$scope.modalInstance.close("Ok");
		}

		$scope.cancel = function () {
			vm.uploadedDocLine = null;
			$scope.uploadResult = "";
			$scope.modalInstance.dismiss();
		}

		$scope.newClaimInvoice = function (claimHeader) {

			var modalInstance = $uibModal.open({
				templateUrl: 'app/entities/claim-header/claim-header-new-invoice-popup.html',
				controller: 'MembershipModalContentCtrl'
			});

		}

        vm.serviceProvidersSearchResult = [{}];
        vm.terminationDates = [];
        vm.isActiveRPP = [];
        vm.rppMember = {};
        vm.rppMembers = [{}];
        vm.delinquents = [{}];

        $scope.closeSspModal = function(){
            $scope.sSpModalInstance.dismiss();
        }

		vm.getServiceProviders = function(search) {
        			$http
        					.get(
        							'api/_search/services/serviceprovidername/?query='
        									+ search)
        					.then(
        							function(response) {
        								vm.serviceProvidersSearchResult = response.data;

        								if (vm.serviceProvidersSearchResult) {
        									for (var i = 0; i < vm.serviceProvidersSearchResult.length; i++) {
        										vm.isActiveRPP(vm.serviceProvidersSearchResult[i].id);
        										vm.delinquents[vm.serviceProvidersSearchResult[i].id] = vm.serviceProvidersSearchResult[i];
        									}
        								}
        							});
        };

         vm.isActiveRPP = function(id) {
        			$http.get('api/member-associations/rpp/' + id).then(
        					function(response) {
        						vm.rppMember = response.data;
        						if (vm.rppMember) {
        						    vm.rppMembers[id] = response.data;
        							vm.isActiveRPP[id] = true;
        							vm.terminationDates[id] = DateUtils.convertLocalDateFromServer(vm.rppMember.terminationDate);
        						}
        					});
        }

        vm.switchAndSaveSP = function (newSpId, iamId){
            vm.claimHeader.delinquent = vm.delinquents[newSpId];
            vm.claimHeader.delinquentIAMID = iamId;
            vm.save();
            $scope.sSpModalInstance.close("Ok");
        }

        $scope.showAll = 'false';

        vm.getClaimHeaderLogs = function () {
            if (vm.claimHeader.id) {
                vm.claimHeaderLogs = AuditLog.getClaimHeaderLogs({
                    claimHeaderId: vm.claimHeader.id
                });
                $scope.showAll = 'true';
            }
        };

        vm.getClaimHeaderLogs();
	}
})();
