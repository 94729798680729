(function() {
	// 'use strict';

	angular.module('mobilityexchangeApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'claim-invoice-detail',
						{
							parent : 'entity',
							url : '/claim-invoice-detail/{id}',
							data : {
								authorities : [ 'ROLE_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ],
								pageTitle : 'mobilityexchangeApp.claimInvoiceDetail.detail.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/claim-invoice-detail/claim-invoice-detail.html',
									controller : 'ClaimInvoiceDetailController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('claimInvoiceDetail');
											return $translate.refresh();
										} ],
								entity : [
										'$stateParams',
										'ClaimInvoiceDetail',
										function($stateParams,
												ClaimInvoiceDetail) {
											return ClaimInvoiceDetail.get({
												id : $stateParams.id
											}).$promise;
										} ],
								previousState : [
										"$state",
										function($state) {
											var currentStateData = {
												name : $state.current.name
														|| 'claim-invoice-detail',
												params : $state.params,
												url : $state.href(
														$state.current.name,
														$state.params)
											};
											return currentStateData;
										} ]
							}
						})
				.state(
						'claim-invoice-detail.new',
						{
							parent : 'entity',
							url : '/detail/new',
							data : {
								authorities : [ 'ROLE_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ]
							},
							params : {
			                    navtype: '1',
			                    claimId: null
			                },
							views : {
								'content@' : {
									templateUrl : 'app/entities/claim-invoice-detail/claim-invoice-detail.html',
									controller : 'ClaimInvoiceDetailController',
									controllerAs : 'vm',
								}
							},
							resolve : {
								entity : function() {
									return {
										comments : null,
										createdDate : null,
										createdBy : null,
										modifiedDate : null,
										modifiedBy : null,
										id : null
									};
								}
							},
							previousState : [
									"$state",
									function($state) {
										var currentStateData = {
											isCopy : 'false',
											params : $state.params,
											url : $state.href(
													$state.current.name,
													$state.params)
										};
										return currentStateData;
									} ]
						})

				.state(
						'claim-invoice-detail.file',
						{
							parent : 'claim-invoice-detail',
							url : '/file',
							data : {
								authorities : [ 'ROLE_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ]
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/claim-invoice-detail/step1.html',
									controller : 'ClaimInvoiceDetailController',
									controllerAs : 'vm',
								}
							},
							resolve : {
								entity : function() {
									return {
										comments : null,
										createdDate : null,
										createdBy : null,
										modifiedDate : null,
										modifiedBy : null,
										id : null
									};
								}
							},
							previousState : [
									"$state",
									function($state) {
										var currentStateData = {
											isCopy : 'false',
											params : $state.params,
											url : $state.href(
													$state.current.name,
													$state.params)
										};
										return currentStateData;
									} ]
						})
	}

})();
