/*eslint-env jquery*/
(function () {
	// 'use strict';

	angular
		.module('mobilityexchangeApp')
		.controller('MemberContactsDialogController', MemberContactsDialogController)
		.directive('ngThumb', ['$window', function ($window) {
			var helper = {
				support: !!($window.FileReader && $window.CanvasRenderingContext2D),
				isFile: function (item) {
					return angular.isObject(item) && item instanceof $window.File;
				},
				isImage: function (file) {
					var type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
					return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
				}
			};

			return {
				restrict: 'A',
				template: '<canvas/>',
				link: function (scope, element, attributes) {
					if (!helper.support) return;

					var params = scope.$eval(attributes.ngThumb);

					if (!helper.isFile(params.file)) return;
					if (!helper.isImage(params.file)) return;

					var canvas = element.find('canvas');
					var reader = new FileReader();

					reader.onload = onLoadFile;
					reader.readAsDataURL(params.file);

					function onLoadFile(event) {
						var img = new Image();
						img.onload = onLoadImage;
						img.src = event.target.result;
					}

					function onLoadImage() {
						var width = params.width || this.width / this.height * params.height;
						var height = params.height || this.height / this.width * params.width;
						canvas.attr({ width: width, height: height });
						canvas[0].getContext('2d').drawImage(this, 0, 0, width, height);
					}
				}
			};
		}]);

	MemberContactsDialogController.$inject = ['MemberContacts', '$scope', '$state', '$stateParams', 'entity', 'Lookups',
	    '$http', 'Principal', 'Account', 'MemberProfile', '$filter', 'ContactCommunicationpreference',
	    'ContactQualifications', 'AlertService', 'VolunteerGroups', 'VolunteerEngagement', 'FileUploader', '$cookies',
	    'DateUtils', '$q', '$timeout', 'Disclaimer', '$sce', '$uibModal', '$location', '$window'];

	function MemberContactsDialogController(MemberContacts, $scope, $state, $stateParams, entity, Lookups,
	    $http, Principal, Account, MemberProfile, $filter, ContactCommunicationpreference,
	    ContactQualifications, AlertService, VolunteerGroups, VolunteerEngagement, FileUploader, $cookies,
	    DateUtils, $q, $timeout, Disclaimer, $sce, $uibModal, $location, $window) {

		$scope.controllerName = "MemberContactsDialogController";
		var vm = this;
		vm.datePickerOpenStatus = {};
		vm.emailSubscriptions = Lookups.getByLookupType({
			id: 65
		});
		vm.commPreSwitchdata = [];
		vm.commPreRadioBGroupdata = [];
		vm.CommPrePopulated = false;

		vm.functionalRoles = Lookups.getByLookupType({
			id: 12
		});
		vm.contactQualifications = Lookups.getByLookupType({ id: 33 });
		vm.volunteerGroups = Lookups.getByLookupType({ id: 34 });
		vm.timeZones = Lookups.getByLookupType({ id: 66 });
		vm.disclaimers = Disclaimer.getMemberDisclaimers();
		vm.memberContacts = entity;
		vm.disableCommPrefAccordion = vm.memberContacts.unsubscribeAll;
		vm.unsubscribeCommPre = vm.memberContacts.unsubscribeAll;
		vm.openCommunicationAccordion = false;
		vm.openPrivacy = false;
		vm.save = save;
		vm.saveProfile = saveProfile;
		vm.openCalendar = openCalendar;
		vm.deleteContactTmpl = 'deleteContactTmpl.html';
		$scope.activateLabel = "";
		$scope.switchAdminLabel = "";
		$scope.activateButtons = true;
		vm.memberContactDisplayId;
		vm.hours = DateUtils.getHoursList();
		$scope.isAssignedMemberAdminUser = false;
		vm.volunteerEngagements = [];
        vm.editVolunteerEngagement = editVolunteerEngagement;
        vm.saveVolunteerEngagement = saveVolunteerEngagement;
        vm.clearVolunteerEngagement = clearVolunteerEngagement;
        vm.volunteerEngagement = {};

		$scope.trustAsHtml = function (html) {
			return $sce.trustAsHtml(html);
		}

        var memberProfileUrl = '/api/member-profile';
        $http.get(memberProfileUrl).then(function(res){
            var memberProfile = res.data;
            if (memberProfile && memberProfile.contactControls){
                console.log("Processing member profile: " + memberProfile);
                var assignedMemberAdmin = function (contactControl) {
                    return contactControl.spId === vm.memberContacts.serviceProviderId && contactControl.adminFlag == true;
                };
                $scope.isAssignedMemberAdminUser = _.some(memberProfile.contactControls, assignedMemberAdmin);
                console.log('Assigned member admin (contact): ' + $scope.isAssignedMemberAdminUser);
            }
        });

		$scope.removeGDPR = function (memberContact) {
			var modalInstance = $uibModal.open({
				templateUrl: "app/entities/member-contacts/member-contacts-gdpr-delete-dialog.html",
				controller: "MemberContactsGdprDeleteController",
				controllerAs: 'vm',
				size: 'md',
				resolve: {
					entity: memberContact
				}
			});

			modalInstance.result.then(function (result) {
				if (result) {
					onSaveSuccess(result);
					$timeout(function () {
						$location.path('/#');
					}, 5000);
				}

			}, function () {
				vm.memberContacts.DisclaimerTrackingNo = false;
			});
		};

		$scope.linkdInButton = function (credential, id) {
			var certYear = credential.certDate.getFullYear();
			var certMonth = credential.certDate.getMonth() + 1;
			var expYear = credential.expDate.getFullYear();
			var expMonth = credential.expDate.getMonth() + 1;
			var certId = credential.id;
			var name = credential.qualification.lookupvalue;

			window.open("https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=" + name +
				"&organizationId=35514264&issueYear=" + certYear +
				"&issueMonth=" + certMonth + "&expirationYear=" + expYear + "&expirationMonth=" + expMonth +
				"&certId=" + certId + "&certUrl=" + encodeURIComponent("https://mobilityex.com/#/member-credentials/" + id));
		}
		$scope.viewCredentials = function (hasCredential, id) {
			if (vm.memberContacts.mxstatus === 'Validated' || vm.memberContacts.mxstatus === 'IAMTrusted') {
				if (hasCredential) {
					window.open(
						"#/member-credentials?ref=" + id, "_blank");
				} else {
					var modalInstance = $uibModal.open({
						templateUrl: 'app/entities/member-contacts/credentialsPopUp.html',
						controller: 'MembershipModalContentCtrl'
					});
				}
			} else {
				var modalInstance = $uibModal.open({
					templateUrl: 'app/entities/member-contacts/profilePictureNotValidated.html',
					controller: 'MembershipModalContentCtrl'
				});
			}
		}
		$scope.uploadProfilePic = function (memberContact) {
		    console.log("Upload profile picture, memberContact: %o", memberContact);
			var hasId = memberContact && memberContact.id && memberContact.id > 0;
			if (!hasId) {
				var modalInstance = $uibModal.open({
					templateUrl: 'app/entities/member-contacts/profilePictureNoContact.html',
					controller: 'MembershipModalContentCtrl'
				});
			} else if ((memberContact.mxstatus === 'Validation Underway' || memberContact.mxstatus === 'Validated' || memberContact.mxstatus === 'IAMTrusted')
			        && memberContact.publicDisplay) {
				var modalInstance = $uibModal.open({
					templateUrl: "app/entities/member-contacts/member-contacts-profile-image-crop.html",
					controller: "MemberContactsProfileImageCropController",
					controllerAs: 'vm',
					size: 'md',
					resolve: {
						entity: memberContact
					}
				});

				modalInstance.result.then(function (selectedItem) {
					if (selectedItem) {
                        // Append a cache busting parameter to force image reload so that a new image is loaded when a user
                        // goes back to the member's page after a new image is uploaded
						$scope.uploadCert[1] = _.replace(selectedItem.imagePath, '/apps', '') + "?cb=" + Date.now();
						console.log('Uploaded item, certificate image: ' + $scope.uploadCert[1]);
					}
				}, function () {
				});
			} else if (memberContact.mxstatus === 'Validated' || memberContact.mxstatus === 'IAMTrusted') {
				var modalInstance = $uibModal.open({
					templateUrl: 'app/entities/member-contacts/profilePictureNoPublicDisplay.html',
					controller: 'MembershipModalContentCtrl'
				});
			} else {
				var modalInstance = $uibModal.open({
					templateUrl: 'app/entities/member-contacts/profilePictureNotValidated.html',
					controller: 'MembershipModalContentCtrl'
				});
			}
		};

		$scope.ifUserNotLoggedIn = function () {
			return !Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_ANALYST', 'ROLE_ASSN_FINANCE', 'ROLE_USER']);
		};

		$scope.ifUserNotAdmin = function () {
			return !Principal.hasAnyAuthority(['ROLE_ADMIN']);
		};

        $scope.ifUserAdmin = function () {
			return Principal.hasAnyAuthority(['ROLE_ADMIN']);
		};

		$scope.ifUserNotSPAdmin = function () {
			return !Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_SP_ADMIN']);
		};

		$scope.ifUserAdminAnalyst = function () {
			return Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_ANALYST']);
		};

		$scope.ifUserFinanceAdmin = function () {
			return Principal.hasAnyAuthority(['ROLE_ASSN_FINANCE']);
		};

		$scope.ifUserHasSPAdmin = function () {
			return $scope.isProviderContactInRole('ROLE_SP_ADMIN')
		};

		$scope.authorities = [];

		/** ************* Security & Roles ***************** */
		$http.get('/api/authorities').then(function (res) {
			$scope.authorities = res.data;
		});

		$scope.providerContactRoles = [];

		$scope.providerContactRoles = [{}];
		$scope.roleUpdateMessage = null;
		// providerContactRoles[providerContacts.id].role[authority]

		$scope.isProviderContactInRole = function (authName) {
			var roles = vm.memberContacts.providerContactRoles && vm.memberContacts.providerContactRoles.authorities;
			if (roles) {
				for (var i = 0; i < roles.length; i++) {
					if (roles[i] && (roles[i].name === authName)) {
						return true;
					}
				}
			}
			return false;
		};

		$scope.updateRole = function (providerContacts, authority) {
		    vm.setPendingOperation(true);
			$http.put('/api/update-provider-contact-roles/' + providerContacts.id + '/' + authority, function (data) {
			}).then(function success(response) {
			    // Reload member contacts and provider roles, update the grant/revoke label, prevent save while this is in progress
                vm.reloadContactControlsAndRoles();
                console.log('Successfully updated role: ' + authority);
                vm.setPendingOperation(false);
              }, function error(response) {
                console.log('Failed to update role: ' + authority);
                vm.setPendingOperation(false);
              });
		};

		$scope.sendPrivacyEmail = function (id) {
			$http.put('/api/send-privacy-email/' + id, function (data) {

			}).success(function (response) {
				// $scope.providerContactRoles = response.data;
			});
		};

		vm.updateParentIdMessage = null;
		vm.updateParentId = function (contact, id) {
			$http.put('/api/update-sp-id/email/' + contact.email + '/'+ id +'/' + contact.id).then(
				function (response) {

					if (response.data && response.data.resp && response.data.resp !== 'Ok') {
						vm.updateParentIdMessage = response.data.resp;
					} else {
						//console.log("267. Updated : "+JSON.stringify(response));
						vm.roleUpdateMessage = "Successfully Updated!";
					}
				}
			);
		};

		vm.updateHomeMembership = function (spId) {
			vm.memberContacts.serviceProviderId = spId;
			vm.memberContacts.grantedServiceProviderId = spId;
			vm.updateParentId(vm.memberContacts, spId);
		}

		vm.applyAllAdmin = function () {
			for (i = 0; i < vm.memberContacts.contactControls.length; i++) {
				vm.memberContacts.contactControls[i].adminFlag = true;
			}
		}

		vm.applyAllDisplay = function () {
			for (i = 0; i < vm.memberContacts.contactControls.length; i++) {
				vm.memberContacts.contactControls[i].displayFlag = true;
			}
		}
		// date format
		$scope.displayDateFormat = DateUtils.displayDateFormat;
		$scope.navtype = _.isUndefined($stateParams.navtype) ? '' : _.isUndefined($stateParams.navtype);

		$scope.getFormattedDate = function (date) {
			// console.log(' date ' + date);
			return DateUtils.convertDateTimeFromServer(date);
		};

		/** *********** Qualifications ************* */
		vm.contactQualificationsMd = [];
		vm.uploadCert = [];
		$scope.uploadCertNow = [];
		$scope.ctQualError = [];
		$scope.uploadCert = [];
		$scope.uploadCert[1] = '/content/images/img_avatar.png';
		console.log('Processing member contact files');
		if (vm.memberContacts && vm.memberContacts.providerContactFiles) {
			for (var j = 0; j < vm.memberContacts.providerContactFiles.length; j++) {
				var fileItem = vm.memberContacts.providerContactFiles[j];
				console.log('Processing member contact files, index [' + j + '], fileItem [' + fileItem + ']');
				if (fileItem) {
				    // Append a cache busting parameter to force image reload so that a new image is loaded when a user
				    // goes back to the member's page after a new image is uploaded
					$scope.uploadCert[fileItem.itemId] = _.replace(fileItem.imagePath, '/apps', '') + "?cb=" + Date.now();
					console.log('Upload cert [' + j + '], fileItem [' + fileItem + '], itemId [' + fileItem.itemId + '], upload cert: ' + $scope.uploadCert[fileItem.itemId]);
				}
			}
		}

		vm.setContactQualifications = function () {

			if (vm.memberContacts && vm.memberContacts.contactQualifications) {

				for (var j = 0; j < vm.memberContacts.contactQualifications.length; j++) {

					for (var k = 0; k < vm.contactQualifications.length; k++) {

						if (vm.memberContacts.contactQualifications[j]) {

							if (vm.contactQualifications[k].id === vm.memberContacts.contactQualifications[j].qualification.id) {

								vm.contactQualificationsMd[vm.contactQualifications[k].id]
									= vm.memberContacts.contactQualifications[j];
							}
						}
					}
				}
			}

            console.log('Processing member contact files 2');
			if (vm.memberContacts && vm.memberContacts.providerContactFiles) {

				for (var j = 0; j < vm.memberContacts.providerContactFiles.length; j++) {
                    console.log('Processing member contact file, index: ' + j);
					var fileItem = vm.memberContacts.providerContactFiles[j];
					if (fileItem) {
					    console.log('Processing member contact file, file item: ' + fileItem + ', index: ' + j);
                        // Append a cache busting parameter to force image reload so that a new image is loaded when a user
                        // goes back to the member's page after a new image is uploaded
						$scope.uploadCert[fileItem.itemId] = _.replace(fileItem.imagePath, '/apps', '') + "?cb=" + Date.now();
                        console.log('Processing member contact file, file item: ' + fileItem + ', index: ' + j + ', upload cert: ' + $scope.uploadCert[fileItem.itemId]);
					}
				}
			}
		};

		vm.saveContactQualifications = function () {

			if (vm.memberContacts && vm.memberContacts.contactQualifications) {

				for (var j = 0; j < vm.memberContacts.contactQualifications.length; j++) {

					for (var k = 0; k < vm.contactQualifications.length; k++) {

						if (vm.memberContacts.contactQualifications[j]) {

							if (
								vm.contactQualifications[k].id === vm.memberContacts.contactQualifications[j].qualification
								&&
								vm.contactQualifications[k].id === vm.memberContacts.contactQualifications[j].qualification.id) {

								vm.memberContacts.contactQualifications[j]
									= vm.contactQualificationsMd[vm.contactQualifications[k].id];
							}
						}
					}
				}
			}

			_.filter(vm.contactQualifications, function (o) { return !_.isNull(o); });

		};

		$scope.setContactQualification = function (providerContacts, contactQualification) {

			if (providerContacts && providerContacts.contactQualifications) {

				var qualIndex = _.findIndex(providerContacts.contactQualifications, { qualification: { id: contactQualification.id } });

				if (qualIndex > -1) {

					ContactQualifications.delete({ id: providerContacts.contactQualifications[qualIndex].id });

					delete providerContacts.contactQualifications[qualIndex];
					delete vm.contactQualificationsMd[contactQualification.id];

				} else {
					vm.contactQualificationsMd[contactQualification.id] = {};
					vm.contactQualificationsMd[contactQualification.id].qualification = contactQualification;
					// cQual.providerConact = providerContacts;
					providerContacts.contactQualifications.push(vm.contactQualificationsMd[contactQualification.id]);
					// ContactQualifications.save(cQual);
				}

			} else {
				var cQual = {};
				cQual.qualification = contactQualification;
				// providerContacts.contactQualifications.push(cQual);
				providerContacts.contactQualifications.push(vm.contactQualificationsMd[contactQualification.id]);
			}
		};

		$scope.isContactQualificationChecked = function (contactQualification, providerContacts) {

			var qualIndex = _.findIndex(providerContacts.contactQualifications, { qualification: { id: contactQualification.id } });

			if (qualIndex > -1) {
				return true;
			} else {
				return false;
			}
		};

        refreshVolunteerEngagements();

        function editVolunteerEngagement(id) {
            console.log("Edit volunteer engagement, id: " + id);

            var volunteerEngagement = vm.volunteerEngagements.filter(function(item){
              return item.id === id;
            });

            if (volunteerEngagement && volunteerEngagement.length > 0) {
                vm.volunteerEngagement.id = volunteerEngagement[0].id;
                if (volunteerEngagement[0].startDate) {
                    vm.volunteerEngagement.startDate = new Date(volunteerEngagement[0].startDate);
                }
                if (volunteerEngagement[0].expDate) {
                    vm.volunteerEngagement.expDate = new Date(volunteerEngagement[0].expDate);
                }
                vm.volunteerEngagement.position = volunteerEngagement[0].position;
                vm.volunteerEngagement.comments = volunteerEngagement[0].comments;
                vm.volunteerEngagement.category = volunteerEngagement[0].category;
            }
        }

        function onSaveVolunteerEngagementSuccess (result) {
            $scope.saveVolunteerEngagementResult = "The volunteer engagement was successfully saved.";
            $scope.saveVolunteerEngagementSuccess = true;
            $scope.showSaveVolunteerEngagementAlert = true;

            refreshVolunteerEngagements();
            $scope.$emit('mobilityexchangeApp:volunteerEngagementUpdate', result);
            vm.isSaving = false;
            $scope.editForm.$dirty=false;
        }

        $scope.closeSaveVolunteerEngagementAlert = function() {
            console.log('Closing save volunteer engagement alert');
            $scope.showSaveVolunteerEngagementAlert = false;
        }

        function onSaveVolunteerEngagementError () {
            vm.isSaving = false;

            $scope.saveVolunteerEngagementResult = "Failed to save the volunteer engagement.";
            $scope.saveVolunteerEngagementSuccess = false;
            $scope.showSaveVolunteerEngagementAlert = true;
        }

        function saveVolunteerEngagement () {
            vm.isSaving = true;

            vm.volunteerEngagement.providerContact = {};
            vm.volunteerEngagement.providerContact.id = vm.memberContacts.id;

            if (vm.volunteerEngagement.id) {
                VolunteerEngagement.update(vm.volunteerEngagement, onSaveVolunteerEngagementSuccess, onSaveVolunteerEngagementError);
            } else {
                VolunteerEngagement.save(vm.volunteerEngagement, onSaveVolunteerEngagementSuccess, onSaveVolunteerEngagementError);
            }
        }

        function clearVolunteerEngagement () {
            // Clear form fields
            vm.volunteerEngagement.id = null;
            vm.volunteerEngagement.startDate = null;
            vm.volunteerEngagement.expDate = null;
            vm.volunteerEngagement.position = null;
            vm.volunteerEngagement.comments = '';
            vm.volunteerEngagement.category = '';
        }

        function refreshVolunteerEngagements() {
            console.log("Refreshing volunteer engagements");

            VolunteerEngagement.getAll({
                providerContactId: vm.memberContacts.id
            }).$promise.then(function success(response) {
                vm.volunteerEngagements = response;
                // Flatten provider contact in alerts to be able to save the provider contact with latest engagement
                var volunteerEngagements = response;
                _.forEach(volunteerEngagements, function (volunteerEngagement) {
                    volunteerEngagement.providerContact = volunteerEngagement.providerContact.id;
                });
                // vm.providerContact.volunteerEngagaments = volunteerEngagements;
            });
            clearVolunteerEngagement();
        }

		/** *********** Volunteer groups ************* */
		vm.volunteerGroupsMd = [];

		vm.setVolunteerGroups = function () {
			if (vm.memberContacts && vm.memberContacts.volunteerGroups) {
				for (var j = 0; j < vm.memberContacts.volunteerGroups.length; j++) {
					for (var k = 0; k < vm.volunteerGroups.length; k++) {
						if (vm.memberContacts.volunteerGroups[j]) {
							if (vm.volunteerGroups[k].id === vm.memberContacts.volunteerGroups[j].volunteerGroup.id) {
								vm.volunteerGroupsMd[vm.volunteerGroups[k].id] = vm.memberContacts.volunteerGroups[j];
							}
						}
					}
				}
			}
		};

		$scope.setVolunteerGroup = function (providerContacts, volunteerGroup) {
            console.log('Set volunteer group: ' + volunteerGroup + ', id: ' + (volunteerGroup && volunteerGroup.id));

			if (providerContacts && providerContacts.volunteerGroups) {
				var volunteerGroupIndex = _.findIndex(providerContacts.volunteerGroups, { volunteerGroup: { id: volunteerGroup.id } });
                console.log('Volunteer group index: ' + volunteerGroupIndex);

				if (volunteerGroupIndex > -1) {
                    console.log('Found volunteer group, index: ' + volunteerGroupIndex);
					// ContactQualifications.delete({ id: providerContacts.volunteerGroups[qualIndex].id });
					VolunteerGroups.delete({ id: providerContacts.volunteerGroups[volunteerGroupIndex].id });

                    console.log('Volunteer group count before delete: ' + providerContacts.volunteerGroups.length);
					delete providerContacts.volunteerGroups[volunteerGroupIndex];
					console.log('Deleted volunteer group from provider contacts ' + volunteerGroupIndex + ', volunteer group count: ' + providerContacts.volunteerGroups.length);
					var removedVolunteerGroup = providerContacts.volunteerGroups.splice(volunteerGroupIndex, 1);
					console.log('After splice volunteer group count: ' + providerContacts.volunteerGroups.length + ', removed volunteer group id: ' + (removedVolunteerGroup && removedVolunteerGroup.id));
					console.log('After splice VM member contacts volunteer group count: ' + vm.memberContacts.volunteerGroups.length);
					delete vm.volunteerGroupsMd[volunteerGroup.id];
				} else {
				    console.log('Volunteer group not found, adding id:: ' + volunteerGroup.id);
					vm.volunteerGroupsMd[volunteerGroup.id] = {};
					vm.volunteerGroupsMd[volunteerGroup.id].volunteerGroup = volunteerGroup;
					providerContacts.volunteerGroups.push(vm.volunteerGroupsMd[volunteerGroup.id]);
				}
			} else {
			    console.log('Volunteer group not found, adding id: ' + volunteerGroup.id);
				providerContacts.volunteerGroups.push(vm.volunteerGroupsMd[volunteerGroup.id]);
				console.log('Provider contact volunteer groups: ' + providerContacts.volunteerGroups);
			}
		};

		$scope.isVolunteerGroupChecked = function (volunteerGroup, providerContacts) {
			var qualIndex = _.findIndex(providerContacts.volunteerGroups, { volunteerGroup: { id: volunteerGroup.id } });
			return (qualIndex > -1);
		};

        $scope.hasVolunteerEngagementAccess = function () {
            return Principal.hasAnyAuthority(['ROLE_ADMIN']);
        };

		if ($stateParams.iammembertype) {
			$scope.iammembertype = $stateParams.iammembertype;
		} else {
			$scope.iammembertype = "";
		}

		function getIamMemberType() {
			var memberNumber = '';
			if (vm.memberContacts.memberAssociations) {
				for (var i = 0; i < vm.memberContacts.memberAssociations.length; i++) {
					if (vm.memberContacts.memberAssociations[i]
						&& vm.memberContacts.memberAssociations[i].memberType
						&& vm.memberContacts.memberAssociations[i].memberStatus
						&& vm.memberContacts.memberAssociations[i].memberStatus.id === 1201) {
						$scope.iammembertype = vm.memberContacts.memberAssociations[i].memberType.lookupvalue
							+ ' | ' + $scope.iammembertype;
						if (vm.memberContacts.memberAssociations[i].memberNumber)
							memberNumber = ' (ID #: '
								+ vm.memberContacts.memberAssociations[i].memberNumber
								+ ')';

					}
				}
			}

			$scope.iammembertype = $scope.iammembertype + memberNumber;
		}
		;

		getIamMemberType();

		function save() {
			vm.isSaving = true;
			$scope.error = null;
			if ($scope.editForm.$invalid) {
				$scope.submitted = true;
                vm.isSaving = false;
			} else {
			    vm.setPendingOperation(true);
			    console.log('Save member contact, volunteer groups size: ' + vm.memberContacts && vm.memberContacts.volunteerGroups && vm.memberContacts.volunteerGroups.length);
				MemberContacts.update(vm.memberContacts, onSaveSuccess, onSaveError);
			}
		}

		function saveProfile() {
			// vm.isSaving = true;
			if ($scope.editForm.$invalid) {
				$scope.submitted = true;
			} else if (vm.memberContacts.id !== null) {
			    console.log('Save member profile, volunteer groups size: ' + + vm.memberContacts && vm.memberContacts.volunteerGroups && vm.memberContacts.volunteerGroups.length);
				MemberProfile.update(vm.memberContacts, onSaveSuccess, onSaveError);
			}
		}

		function onSaveSuccess(result) {

			$scope.editForm.$valid = true;
			vm.memberContacts = result;
			vm.setContactQualifications();
			vm.setVolunteerGroups();
			$scope.editForm.$dirty = false;
			$scope.getActivateLabels();
			$scope.getSwitchAdminLabel();
			vm.memberContactDisplayId = vm.memberContacts.id;
            vm.isSaving = false;
            vm.setPendingOperation(false);
            // Reload member contacts and provider roles and update the grant/revoke label
            vm.reloadContactControlsAndRoles();
		}

		function onSaveError(error) {
			vm.isSaving = false;
			vm.setPendingOperation(false);
			// AlertService.error(error.data.message);
			$scope.error = error.data.description ? error.data.description : error.data.message;
		}

		var uploader = $scope.uploader = new FileUploader({
			url: '/api/contactfileupload?spid=' + entity.serviceProviderId + '&type=qual',
			headers: {
				'X-CSRF-TOKEN': $cookies.get('CSRF-TOKEN')
			}
		});

		$scope.$on('$stateChangeStart', function (event) {
			// console.log('changing location ....' + event);
			if ($scope.editForm.$dirty) {
				var answer = confirm('Have you saved changes? ' +
					'Please click Ok to leave this page, any unsaved changes will be lost. ' +
					'Click Cancel to stay on the page.');
				if (!answer) {
					event.preventDefault();
				}
			}
		}

		);


		$scope.uploadCertError = [];
		$scope.uploadMemberShipError = [];

		$scope.saveChanges = false;

		// CALLBACKS

		uploader.onWhenAddingFileFailed = function (item /* {File|FileLikeObject} */, filter, options) {
			console.info('onWhenAddingFileFailed', item, filter, options);
		};

		uploader.onAfterAddingFile = function (fileItem) {

			console.info('onAfterAddingFile', fileItem);

			$scope.showSpinner = true;
			if (fileItem.type == 'qual')
				$scope.uploadCertNow[fileItem.id] = fileItem.file.name;
			/*
			 * if($scope.saveChanges) save();
			 */
			if (uploader.queue) {
				uploader.uploadAll();
			}
		};

		uploader.onAfterAddingAll = function (addedFileItems) {
			console.info('onAfterAddingAll', addedFileItems);
		};

		uploader.onBeforeUploadItem = function (item) {
			console.info('onBeforeUploadItem', item);
		};

		uploader.onProgressItem = function (fileItem, progress) {
			console.info('onProgressItem', fileItem, progress);
		};

		uploader.onProgressAll = function (progress) {
			console.info('onProgressAll', progress);
		};

		uploader.onSuccessItem = function (fileItem, response, status, headers) {
			console.info('onSuccessItem', fileItem, response, status, headers);
            // Append a cache busting parameter to force image reload so that a new image is loaded when a user
            // goes back to the member's page after a new image is uploaded
			$scope.uploadCert[response.itemId] = _.replace(response.imagePath, '/apps', '') + "?cb=" + Date.now();
			console.log('Upload successful, itemId: ' + response.itemId + ', upload cert: ' + $scope.uploadCert[response.itemId]);
			$scope.uploadCertNow[fileItem.id] = "";

			if (fileItem.type == 'qual') {
				$scope.ctQualError[fileItem.id] = "";

			}
		};

		uploader.onErrorItem = function (fileItem, response, status, headers) {
			$scope.ctQualError[fileItem.id] = "Error uploading file, please check that the file's size is 3 MB or lesser. Please contact support@mobilityexchange.com " +
				" if the problem persists.";
			$scope.uploadCertNow[fileItem.id] = "";

		};

		uploader.onCancelItem = function (fileItem, response, status, headers) {
			console.info('onCancelItem', fileItem, response, status, headers);
		};

		uploader.onCompleteItem = function (fileItem, response, status, headers) {
			console.info('onCompleteItem', fileItem, response, status, headers);
		};

		uploader.onCompleteAll = function () {
			console.info('onCompleteAll');
			$scope.showSpinner = false;
			// $window.location.reload();
			$scope.saveChanges = false;

		};

		vm.datePickerOpenStatus.createddate = false;
		vm.datePickerOpenStatus.modifieddate = false;
		$scope.contactQualExpMinDate = new Date();
		$scope.volunteerGroupExpMinDate = new Date();


		vm.openCalendarCtQuals = function (field, id, certDate) {
			if (certDate) $scope.contactQualExpMinDate = certDate;
			openCalendar(field, id);
		}

		function openCalendar(field, id) {

			if (id) {
				if (!vm.datePickerOpenStatus[field]) {
					vm.datePickerOpenStatus[field] = [];
				}
				vm.datePickerOpenStatus[field][id] = true;
			} else {
				vm.datePickerOpenStatus[field] = true;
			}
		}

		vm.openCalendarVolunteerGroup = function (field, id, startDate) {
			if (startDate) $scope.volunteerGroupExpMinDate = startDate;
			openCalendar(field, id);
		}

		vm.expDateError = false;
		vm.validateExpiryDate = function (obj) {
			if (obj.certDate !== null && obj.certDate > obj.expDate) {
				obj.expDateError = true;
				obj.expDate = '';
			} else {
				obj.expDateError = false;
			}
		};

		vm.certDateError = false;
		vm.validateCertDate = function (obj) {
			if (obj.expDate !== null && obj.expDate < obj.certDate) {
				obj.certDateError = true;
				obj.certDate = '';
			} else {
				obj.certDateError = false;
			}
		};

		vm.startDateError = false;
		vm.validateStartDate = function (obj) {
			if (obj.expDate !== null && obj.expDate < obj.startDate) {
				obj.startDateError = true;
				obj.startDate = '';
			} else {
				obj.startDateError = false;
			}
		};

		$scope.getActivateLabels = function () {
			if (vm.memberContacts) {
				vm.existingContacts = vm.memberContacts;
				$http.get('/api/isactive?login=' + vm.memberContacts.email).then(function (response) {
					if (response.data.activated === 'true' || response.data.activated === true) {
						$scope.activateLabel = 'Deactivate ';
						if (!$scope.activeProviderContactExists)
							$scope.activeProviderContactExists = true;
					} else {
						$scope.activateLabel = 'Activate ';
					}
				});
			}
		};

		$scope.checkHistory = function (history) {
			if (typeof history !== 'undefined')
				return Object.keys(history).length === 0;
			else
				return true;
		}

		$scope.getActivateLabels();

		$scope.getSwitchAdminLabel = function () {

            var hasServiceProviderAdminRole = function (authority) {
                return authority && authority.name === 'ROLE_SP_ADMIN';
            };

            var serviceProviderAdminFound = false;
            if (vm.memberContacts) {
                // Calculate admin label
                if (vm.memberContacts.providerContactRoles && vm.memberContacts.providerContactRoles.authorities) {
                    serviceProviderAdminFound = _.some(vm.memberContacts.providerContactRoles.authorities, hasServiceProviderAdminRole);
                    console.log('Service provider role found: ' + serviceProviderAdminFound);
                    if (serviceProviderAdminFound) {
                        $scope.switchAdminLabel = 'Revoke';
                    } else {
                        $scope.switchAdminLabel = 'Grant';
                    }
                } else {
                    var email = vm.memberContacts.email;
                    $http.get('/api/hasadminrole?email=' + email).then(function (response) {
                        if (response.data.hasAdmin === 'true') {
                            $scope.switchAdminLabel = 'Revoke';
                        } else {
                            $scope.switchAdminLabel = 'Grant';
                        }
                    });
                }
            }
		};

		$scope.getSwitchAdminLabel();

		$scope.userActivation = function (login, label) {
			vm.isSaving = true;

			if (label)
				label = _.trim(label);

			if (label === 'Deactivate') {
				login.publicDisplay = false;
				$scope.activateLabel = 'Activate';
			} else {
				$scope.activateLabel = 'Deactivate';
			}
			Account.activateuser(login.id);
			vm.isSaving = false;
		};

		$scope.switchAdminRole = function (login) {
		    // Block the UI while the operation is in progress. This prevents the user from triggering another admin
		    // action or from wiping out changes by clicking on the Save button while the action is pending.
		    vm.setPendingOperation(true);

			if ($scope.switchAdminLabel)
				$scope.switchAdminLabel = _.trim($scope.switchAdminLabel);

			if ($scope.switchAdminLabel === 'Revoke') {
				$scope.switchAdminLabel = 'Grant';
			} else {
				$scope.switchAdminLabel = 'Revoke';
			}

			$http.put('api/account/grant_admin_role', login)
                .then(function (response) {
                    // Reload member contacts and provider roles and update the grant/revoke label
                    vm.reloadContactControlsAndRoles();

                    // Update authorities to get the UI updated. Do it inside a timeout to avoid potentially interfering
                    // with a pending digest.
                    $timeout(function() {
                      $scope.$apply();
                    });
                })
                .catch(function (data) {
                    console.log('Failed to grant admin role');
                    vm.setPendingOperation(false);
                });
		};

        vm.reloadContactControlsAndRoles = function() {
            vm.setPendingOperation(true);
            MemberContacts.get({id: vm.memberContacts.id}).$promise.
                then(function (result) {
                    if (result != null) {
                        console.log('Retrieved contact controls');
                        vm.memberContacts.contactControls = result.contactControls;
                        vm.memberContacts.providerContactRoles = result.providerContactRoles;

                        console.log('Recalculate admin labels');
                        // Recalculate admin label
                        $scope.getSwitchAdminLabel();
                        vm.setPendingOperation(false);
                    }
                })
                .catch(function (data) {
                    console.log('Failed to reload contact controls and roles');
                    vm.setPendingOperation(false);
                })
                ;
        }

        vm.setPendingOperation = function(pending) {
            $scope.showSpinner = pending;
            vm.pendingOperation = pending;
        }

		vm.deleteDoc = function (index, itemType, cpid) {
			if (index == 1) {
			    console.log('Delete document, index: 1, set certificate to avatar');
				$scope.uploadCert[1] = '/content/images/img_avatar.png';
			} else {
				delete $scope.uploadCert[index];
			}
			$http.delete('/api/deleteContactFile/' + cpid + '/' + index).then(function (response) {
				vm.memberContacts = response.data;
			});
		}
		/** ***********************UiServiceProviderBacktoListChanges******************* */


		vm.backToListStateSPState = "service-providers.editcopymc({id: " + vm.memberContacts.serviceProviderId + "})";
		vm.backToListmemState = "member-contacts"

		vm.getBackToListState = function () {

			if ($scope.$parent.fromState.parent == 'service-providers')
				return vm.backToListStateSPState;

			return vm.backToListmemState;
		}

		vm.backToListState = vm.getBackToListState();

		vm.setDisplayId = function () {
			if (vm.memberContacts.id != -1)
				vm.memberContactDisplayId = vm.memberContacts.id;
		}

		vm.setDisplayId();


		/** ***********************UiServiceProviderBacktoListChangesEnd******************* */


		/** *************Commmunication Preference Changes ************ */

		vm.setupCommPrefRadioButtonGroup = function () {

			vm.emailSubscriptions.forEach(function (emailSubscription) {
				vm.commPreRadioBGroupdata.push({
					"lookupId": emailSubscription.id,
					"OptIn": "OptIn",
					"OptOut": "OptOut",
					"commPreObj": "",
					"providerCt": "",
					"noRecord": true,
					"recordDelete": false,
					"recordInsert": false,
					"recordUpdate": false,
					"selValue": ""
				});
			});
		}

		vm.setContactCommunicationPreferences = function () {

			if (vm.CommPrePopulated)

				return true;
			if (vm.commPreRadioBGroupdata.length == 0)
				vm.setupCommPrefRadioButtonGroup();

			if (vm.memberContacts)

				if (vm.memberContacts
					&& vm.memberContacts.communicationPreferences) {

					for (var j = 0; j < vm.memberContacts.communicationPreferences.length; j++) {

						for (var k = 0; k < vm.emailSubscriptions.length; k++) {

							if (vm.memberContacts.communicationPreferences[j]) {

								if (vm.emailSubscriptions[k].id === vm.memberContacts.communicationPreferences[j].capabilityType.id) {

									var lookupId = vm.memberContacts.communicationPreferences[j].capabilityType.id;
									var optIn = vm.memberContacts.communicationPreferences[j].include;
									var optOut = vm.memberContacts.communicationPreferences[j].exclude;

									var selectedRB = $filter('filter')(vm.commPreRadioBGroupdata, { 'lookupId': lookupId })[0];

									if (optIn) {
										// selectedRB.OptIn ="OptIn"
										selectedRB.selValue = "OptIn";
									}

									if (optOut) {
										// selectedRB.OptOut ="OptOut"
										selectedRB.selValue = "OptOut";
									}

									selectedRB.commPreObj = vm.memberContacts.communicationPreferences[j];

								}

							}
						}
					}
				}

			vm.CommPrePopulated = true;
		};

		vm.updateCommPreferenceRecord = function (emailSubId) {
			// alert(emailSubId);
			// return;

			var selected = $filter('filter')(vm.commPreRadioBGroupdata, { 'lookupId': emailSubId })[0];

			if (selected.commPreObj == "") {
				selected.recordUpdate = false;
				selected.recordInsert = true;
				vm.updateContactPreferencesSelected(selected);
				selected.noRecord = false;
				onPreferenceSave();
				return;
			}

			// include =true condition
			if (selected.selValue == 'OptIn') {
				if (!selected.commPreObj == "") {
					selected.recordUpdate = true;
					selected.recordInsert = false;
					selected.commPreObj.include = true;
					selected.commPreObj.exclude = false;
					vm.updateContactPreferencesSelected(selected);
				}
			}

			// Exclude=true condition
			else if (selected.selValue == 'OptOut') {

				if (!selected.commPreObj == "") {
					selected.recordUpdate = true;
					selected.recordInsert = false;
					selected.commPreObj.include = false;
					selected.commPreObj.exclude = true;
					vm.updateContactPreferencesSelected(selected);

				}
			}

			//
			onPreferenceSave();
			//
		}

		function onPreferenceSave() {
			$scope.editForm.$valid = true;
			vm.isSaving = false;
			vm.setPendingOperation(false);
			$scope.editForm.$dirty = false;
			$scope.getActivateLabels();
			$scope.getSwitchAdminLabel();
			vm.memberContactDisplayId = vm.memberContacts.id;
		}

		vm.updateContactPreferencesSelected = function (data) {

			var providerContact = {};
			providerContact.id = vm.memberContacts.id;

			if (data.recordInsert) {

				var selectedLookup = $filter('filter')(vm.emailSubscriptions, { 'id': data.lookupId })[0];

				var capabilityType = {};
				capabilityType.id = selectedLookup.id;

				var optInBVal, optOutBVal;
				// include =true condition
				if (data.selValue == 'OptIn') {
					optInBVal = true
				}

				if (data.selValue == 'OptOut') {
					optOutBVal = true
				}

				var commPreObj = {
					"providerContactId": vm.memberContacts.id,
					"capabilityType": capabilityType,
					"include": optInBVal,
					"exclude": optOutBVal,

				}
				data.commPreObj = commPreObj;

			}
			else if (data.recordUpdate) {
				// data.commPreObj.include= data.optInOrOut;
				// data.commPreObj.exclude= !data.optInOrOut;
				data.commPreObj.providerContact = providerContact;

			}

			ContactCommunicationpreference.save(data.commPreObj, function (response) {
				if (angular.isUndefined(data.commPreObj.id))
					data.commPreObj.id = response.id;
				vm.updatedCommunicationPreferenceMessage = vm.updatedCommunicationPreferenceMessage + " " + response.capabilityType.lookupvalue;
			}
			)
		}

		vm.unsubscribe = function () {
			if (vm.unsubscribeCommPre) {
				vm.disableCommPrefAccordion = true;
				// vm.openCommunicationAccordion = false;
				// alert('Checked');

			}

			else {
				vm.disableCommPrefAccordion = false;
				// alert('UnChecked');

			}

			vm.memberContacts.unSubscribeAll = vm.unsubscribeCommPre;

			$http.put(
				'/api/update-communicationpreferences-unsubscribe/unsubscribeAll/'
				+ vm.memberContacts.unSubscribeAll + '/spid/'
				+ vm.memberContacts.id).then(
					function (response) {
						// console.log("Updated : "+response.data);
						if (response.data && response.data.resp
							&& response.data.resp !== 'Ok') {
							// alert('DataUpdated');
						} else {
							// alert('DataNoUpdated');
						}
					});

		}

		// for unlogged user
		if (!Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_ANALYST', 'ROLE_ASSN_FINANCE', 'ROLE_USER'])) {
			if (vm.memberContacts.disclaimerTracking.length === 0) {
				vm.openPrivacy = true
			} else {
				vm.openCommunicationAccordion = true;
				angular.element(document).ready(function () {
					$timeout(function () {
						vm.setContactCommunicationPreferences();
					}, 1010);

				});
			}
		}
	}
})();
