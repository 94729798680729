(function () {
    //'use strict';
    angular
        .module('mobilityexchangeApp')
        .factory('MemberContacts', MemberContacts);

    MemberContacts.$inject = ['$resource', 'DateUtils'];

    function MemberContacts($resource, DateUtils) {
        var resourceUrl = 'api/member-contacts/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                        data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                        data.dob = DateUtils.convertLocalDateFromServer(data.dob);
                        $.each(
                                data.contactQualifications,
                                function (index, contactQualifications) {
                                    contactQualifications.certDate = DateUtils.convertLocalDateFromServer(contactQualifications.certDate);
                                    contactQualifications.expDate= DateUtils.convertLocalDateFromServer(contactQualifications.expDate);
                                }
                        );

                        $.each(
                            data.volunteerGroups,
                            function (index, volunteerGroup) {
                                volunteerGroup.startDate = DateUtils.convertLocalDateFromServer(volunteerGroup.startDate);
                                volunteerGroup.expDate= DateUtils.convertLocalDateFromServer(volunteerGroup.expDate);
                            }
                        );
                    }
                    return data;
                }
            },
            'getById': {
                method: 'GET',
                url: 'api/member-contacts/:id',
                params: {id: '@id'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                        data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                    }
                    return data;
                }
            },
            'getSupportById': {
                method: 'GET',
                url: 'api/skype-support',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                        data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    data.dob = DateUtils.convertLocalDateToServer(data.dob);

                    $.each
                    (
                        data.contactQualifications,
                        function (index, contactQualifications) {
                        	 if(contactQualifications && contactQualifications.expDate)
                        		 contactQualifications.expDate = DateUtils.convertLocalDateToServerDateTime(contactQualifications.expDate);
                        	 if(contactQualifications && contactQualifications.certDate)
                        		 contactQualifications.certDate = DateUtils.convertLocalDateToServerDateTime(contactQualifications.certDate);

                        }
                    )
                    ;

                    $.each(
                        data.volunteerGroups,
                        function (index, volunteerGroup) {
                            if (volunteerGroup && volunteerGroup.expDate) {
                                volunteerGroup.expDate = DateUtils.convertLocalDateToServerDateTime(volunteerGroup.expDate);
                            }
                            if (volunteerGroup && volunteerGroup.startDate) {
                                volunteerGroup.startDate = DateUtils.convertLocalDateToServerDateTime(volunteerGroup.startDate);
                            }
                        }
                    );

                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                	if (data) {
                        data = angular.fromJson(data);
                        data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                        data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                        data.dob = DateUtils.convertLocalDateFromServer(data.dob);
                        $.each(
                                data.contactQualifications,
                                function (index, contactQualifications) {
                                    contactQualifications.certDate = DateUtils.convertLocalDateFromServer(contactQualifications.certDate);
                                    contactQualifications.expDate= DateUtils.convertLocalDateFromServer(contactQualifications.expDate);
                                }
                        );

                        $.each(
                            data.volunteerGroups,
                            function (index, volunteerGroup) {
                                volunteerGroup.startDate = DateUtils.convertLocalDateFromServer(volunteerGroup.startDate);
                                volunteerGroup.expDate= DateUtils.convertLocalDateFromServer(volunteerGroup.expDate);
                            }
                        );
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.createddate = DateUtils.convertLocalDateToServer(data.createddate);
                    data.modifieddate = DateUtils.convertLocalDateToServer(data.modifieddate);
                    data.dob = DateUtils.convertLocalDateToServer(data.dob);
                    $.each
                    (
                        data.contactQualifications,
                        function (index, contactQualifications) {
                        	 if(contactQualifications && contactQualifications.expDate)
                        		 contactQualifications.expDate = DateUtils.convertLocalDateToServerDateTime(contactQualifications.expDate);
                        	 if(contactQualifications && contactQualifications.certDate)
                        		 contactQualifications.certDate = DateUtils.convertLocalDateToServerDateTime(contactQualifications.certDate);

                        }
                    )
                    ;

                    $.each(
                        data.volunteerGroups,
                        function (index, volunteerGroup) {
                            if (volunteerGroup && volunteerGroup.expDate) {
                                volunteerGroup.expDate = DateUtils.convertLocalDateToServerDateTime(volunteerGroup.expDate);
                            }
                            if (volunteerGroup && volunteerGroup.startDate) {
                                volunteerGroup.startDate = DateUtils.convertLocalDateToServerDateTime(volunteerGroup.startDate);
                            }
                        }
                    );

                    return angular.toJson(data);
                }
            },
            'getByRef': {
                method: 'GET',
                url: 'api/member-gateway/ref/:ref',
                transformResponse: function (data, headers, status) {
                    if(status !== 200)
                        return status;

                    if (data || !_.isEmpty(data)) {
                    	 data = angular.fromJson(data);
                         data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                         data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                         data.dob = DateUtils.convertLocalDateFromServer(data.dob);
                         $.each(
                                 data.contactQualifications,
                                 function (index, contactQualifications) {
                                     contactQualifications.certDate = DateUtils.convertLocalDateFromServer(contactQualifications.certDate);
                                     contactQualifications.expDate= DateUtils.convertLocalDateFromServer(contactQualifications.expDate);
                                 }
                         );

                         $.each(
                             data.volunteerGroups,
                             function (index, volunteerGroup) {
                                 volunteerGroup.startDate = DateUtils.convertLocalDateFromServer(volunteerGroup.startDate);
                                 volunteerGroup.expDate= DateUtils.convertLocalDateFromServer(volunteerGroup.expDate);
                             }
                         );
                    }
                    return data;
                }
            },
            'getMemberCredential': {
                method: 'GET',
                url: 'api/member-credentials/ref/:ref',
                transformResponse: function (data, headers, status) {
                    if(status !== 200)
                        return status;

                    if (data || !_.isEmpty(data)) {
                    	 data = angular.fromJson(data);
                         data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                         data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                         data.dob = DateUtils.convertLocalDateFromServer(data.dob);
                         $.each(
                                 data.contactQualifications,
                                 function (index, contactQualifications) {
                                     contactQualifications.certDate = DateUtils.convertLocalDateFromServer(contactQualifications.certDate);
                                     contactQualifications.expDate= DateUtils.convertLocalDateFromServer(contactQualifications.expDate);
                                 }
                         );

                        $.each(
                            data.volunteerGroups,
                            function (index, volunteerGroup) {
                                volunteerGroup.startDate = DateUtils.convertLocalDateFromServer(volunteerGroup.startDate);
                                volunteerGroup.expDate= DateUtils.convertLocalDateFromServer(volunteerGroup.expDate);
                            }
                        );
                    }
                    return data;
                }
            }
        });
    }
})();
