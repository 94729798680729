(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('IssueTrackerSearch', IssueTrackerSearch);

    IssueTrackerSearch.$inject = ['$resource'];

    function IssueTrackerSearch($resource) {
        // TODO: Parameter name
        var resourceUrl =  'api/_search/issue-tracker/:id?&issueId=:issueId&filedBy=:filedBy&filedByMxStatus=:filedByMxStatus&filedAgainst=:filedAgainst&filedAgainstMxStatus=:filedAgainstMxStatus&submittedOn=:submittedOn&acknowledgedTarget=:acknowledgedTarget&acknowledgedActual=:acknowledgedActual&resolutionTarget=:resolutionTarget&resolutionActual=:resolutionActual&status=:status&type=:type';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false }
        });
    }
})();
