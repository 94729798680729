(function() {
	// 'use strict';

	angular.module('mobilityexchangeApp').factory('ClaimInvoiceDetailSearch',
			ClaimInvoiceDetailSearch);

	ClaimInvoiceDetailSearch.$inject = [ '$resource' ];

	function ClaimInvoiceDetailSearch($resource) {
		var resourceUrl = 'api/_search/claim-invoice-details/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			}
		});
	}
})();
