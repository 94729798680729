(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('SupplierCapabilitiesDialogController', SupplierCapabilitiesDialogController);

    SupplierCapabilitiesDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SupplierCapabilities', 'ServiceProviders', 'Lookups'];

    function SupplierCapabilitiesDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, SupplierCapabilities, ServiceProviders, Lookups) {
        var vm = this;

        // TODO: Need equivalent on industry partner side
        vm.supplierCapabilities = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.serviceproviders = ServiceProviders.query();
        vm.lookups = Lookups.query();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            // TODO: Need equivalent on the industry partner side
            if (vm.supplierCapabilities.id !== null) {
                SupplierCapabilities.update(vm.supplierCapabilities, onSaveSuccess, onSaveError);
            } else {
                SupplierCapabilities.save(vm.supplierCapabilities, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            // TODO: Need equivalent on the industry partner side
            $scope.$emit('mobilityexchangeApp:supplierCapabilitiesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdby = false;
        vm.datePickerOpenStatus.modifieddate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
