(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('MemberCapabilitiesDialogController', MemberCapabilitiesDialogController);

    MemberCapabilitiesDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'MemberCapabilities', 'Capabilities', 'Lookups'];

    function MemberCapabilitiesDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, MemberCapabilities, Capabilities, Lookups) {
        var vm = this;

        vm.memberCapabilities = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.capabilities = Capabilities.query();
        vm.lookups = Lookups.query();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.memberCapabilities.id !== null) {
                MemberCapabilities.update(vm.memberCapabilities, onSaveSuccess, onSaveError);
            } else {
                MemberCapabilities.save(vm.memberCapabilities, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('mobilityexchangeApp:memberCapabilitiesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdby = false;
        vm.datePickerOpenStatus.modifieddate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
