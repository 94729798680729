(function () {
    // 'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('IAMXDashboardController', IAMXDashboardController)
        .controller('PcgGuidelinesController', PcgGuidelinesController)
        .controller('HomeController', HomeController)
        .controller('ContactPrefsController', ContactPrefsController)
        .controller('SupplierPortalController', SupplierPortalController)
        .directive('googlePlace', function () {

            var componentForm = {
                street_number: 'short_name',
                route: 'long_name',
                locality: 'long_name',
                administrative_area_level_1: 'short_name',
                country: 'long_name',
                postal_code: 'short_name'
            };

            var mapping = {
                street_number: 'number',
                route: 'street',
                locality: 'city',
                administrative_area_level_1: 'state',
                country: 'country',
                postal_code: 'zip'
            };

            return {
                require: 'ngModel',
                controller: 'HomeController',

                scope: {
                    ngModel: '=',
                    details: '=?',
                    setLatLng: '&'
                },

                link: function (scope, element, attrs, model) {
//
                    var options = {
                        fields: ['name', 'geometry.location', 'address_components', 'formatted_address'],
                        types: attrs.googlePlace !== "" ? attrs.googlePlace.split(',') : []
                    };

                    scope.gPlace = new google.maps.places.Autocomplete(element[0], options);

                    google.maps.event.addListener(scope.gPlace, 'place_changed', function () {

                        var place = scope.gPlace.getPlace();

                        var details = place.geometry && place.geometry.location ? {
                            latitude: place.geometry.location.lat(),
                            longitude: place.geometry.location.lng()
                        } : {};

                        if (!_.isUndefined(place) && !_.isUndefined(place.address_components)) {
                            for (var i = 0; i < place.address_components.length; i++) {
                                var addressType = place.address_components[i].types[0];
                                if (componentForm[addressType]) {
                                    var val = place.address_components[i][componentForm[addressType]];
                                    details[mapping[addressType]] = val;
                                }
                            }
                        }

                        details.formatted = (place.formatted_address) ? place.formatted_address : "";

                        scope.$apply(function () {

                            scope.details = details; // array containing each location component
                            scope.latitude = details.latitude;
                            scope.longitude = details.longitude;
                            scope.ngModel = details.formatted;

                            model.$setViewValue(element.val());
                            // .$setViewValue(element.val());
                            scope.setLatLng({'details': details});
                            // scope.setLoc({'loc':element[0].val()});
                        });
                    });
                }
            };
        });


    ContactPrefsController.$inject = ['$http', '$scope', '$state', '$stateParams', 'Principal'];

    function ContactPrefsController($http, $scope, $state, $stateParams, Principal) {

        var vm = this;

        vm.key = $stateParams.key;
        if (!vm.key) vm.key = '';
        vm.commprefs;
        vm.usercommprefs;
        vm.deletedMessage;
        vm.consentMessage;
        vm.noConsentMessage;

        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
        });

        vm.getCommPrefs = function () {
            $http.get('/api/comm-prefs').then(function (response) {
                vm.commprefs = response.data;
            });
        };

        vm.getUserCommPrefs = function () {
            $http.get('/api/user-comm-prefs').then(function (response) {
                vm.usercommprefs = response.data;
                if (vm.usercommprefs) {
                    for (var i = 0; i < vm.usercommprefs.length; i++) {
                        if (vm.usercommprefs[i] && vm.usercommprefs[i].disclaimer)
                            vm.checkedVal[vm.usercommprefs[i].disclaimer.id] = true;
                    }
                }
            });
        };

        vm.noconsent = function (e) {
            e.preventDefault();
            e.stopPropagation();
            $http.post('/api/contact-dissent?key=' + vm.key)
                .then(function (response) {
                    vm.noConsentMessage = 'Thanks! Your contact details are not visible on IAMX.';
                    vm.deletedMessage = null;
                    vm.consentMessage = null;
                });
        };

        vm.consent = function (e) {
            e.preventDefault();
            e.stopPropagation();
            $http.post('/api/contact-consent?key=' + vm.key)
                .then(function (response) {
                    vm.consentMessage = 'Thanks for choosing to display your contact details on IAMX!';
                    vm.deletedMessage = null;
                    vm.noConsentMessage = null;
                });
        };

        vm.delete = function (e) {
            e.preventDefault();
            e.stopPropagation();
            $http.post('/api/contact-delete?key=' + vm.key)
                .then(function (response) {
                    vm.deletedMessage = 'Thanks! Your contact details have been deleted.';
                    vm.consentMessage = null;
                    vm.noConsentMessage = null;
                });
        };

        vm.checkedVal = [];

        vm.setPref = function (id) {
            $http.post('/api/user-comm-prefs?id=' + id + '&value=' + vm.checkedVal[id] + '&key=' + vm.key)
                .then(function (response) {
                });
        };

        vm.getCommPrefs();
        vm.getUserCommPrefs();

    }


    IAMXDashboardController.$inject = ['$http', '$scope', '$state'];

    function IAMXDashboardController($http, $scope, $state) {

        $scope.userValidated = false;

        $scope.isUserValidated = function () {

            // force setting to true for initial few weeks

            $scope.userValidated = false;

            $http.get('/api/is-user-validated').then(function (response) {

                if (response && response === true) {
                    $scope.userValidated = true;
                }
            }).catch(function (data) {

                if (data.status === 401) {
                    $state.go('iamx-dashboard-simple');
                }
            });
        }

        $scope.isUserValidated();
    }

    PcgGuidelinesController.$inject = ['$http', '$scope', '$uibModal'];

    function PcgGuidelinesController($http, $scope, $uibModal) {

        $scope.nonMember = false;

        $scope.isNonMember = function () {
            $scope.nonMember = false;
            $http.get('/api/is-non-member').then(function (response) {
                if (response && response.data === true) {
                    $scope.nonMember = true;
                }
            });
        }

        $scope.isNonMember();

        $scope.openGuidelines = function () {
            console.log("Clicked open guidelines, non-member: " + $scope.nonMember);
            if ($scope.nonMember) {
                $scope.showNonMemberAlert();
            } else {
                window.open('/content/pdf/PCG.pdf', '_blank');
            }
        }

        $scope.openRedlinedVersion = function () {
            console.log("Clicked open redlined version, non-member: " + $scope.nonMember);
            if ($scope.nonMember) {
                $scope.showNonMemberAlert();
            } else {
                window.open('/content/pdf/redlinedPCG.pdf', '_blank');
            }
        }

        $scope.nonMemberAlertModalInstance = null;
        $scope.showNonMemberAlert = function () {

            $scope.nonMemberAlertModalInstance = $uibModal.open({
                templateUrl: 'app/entities/support/pcg/pcg-access-error-non-member.html',
                scope: $scope
            });
        }

        $scope.ok = function () {
            $scope.nonMemberAlertModalInstance.close("Ok");
        }

        $scope.cancel = function () {
            $scope.nonMemberAlertModalInstance.dismiss();
        }
    }

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$state', '$http',
        '$rootScope', '$stateParams', '$location', '$timeout', '$interval', '$sce', '$window', '$analytics', 'Lookups',
        'MemberContacts', 'ContentControl', 'ServiceProviders'];

    function HomeController($scope, Principal, LoginService, $state, $http,
                            $rootScope, $stateParams, $location, $timeout, $interval, $sce,
                            $window, $analytics, Lookups, MemberContacts, ContentControl, ServiceProviders) {

        var vm = this;

        vm.memberCapabilities = Lookups.getByLookupType({id: 2});
        vm.movingCapabilities = Lookups.getByLookupType({id: 5});
        vm.relocationCapabilities = Lookups.getByLookupType({id: 6});
        vm.industryPartnerCapabilities = Lookups.getByLookupType({id: 7});
        vm.industrySupplierCapabilities = Lookups.getByLookupType({id: 8});

        vm.associations = Lookups.getByLookupType({id: 9});
        vm.quality = Lookups.getByLookupType({id: 11});
        vm.usGovt = Lookups.getByLookupType({id: 19});
        vm.languageCapabilities = Lookups.getByLookupType({id: 53});
        vm.compliance = Lookups.getByLookupType({id: 23});
        vm.contactQualifications = Lookups.getByLookupType({id: 33});
        vm.destinationServices = Lookups.getByLookupType({id: 95});

        vm.invoiceStatus = Lookups.getByLookupType({id: 36});
        vm.paymentTerms = Lookups.getByLookupType({id: 38});
        vm.paymentStatus = Lookups.getByLookupType({id: 39});
        vm.paymentMethods = Lookups.getByLookupType({id: 40});
        vm.currencies = Lookups.getByLookupType({id: 52});

        vm.currentPath = $location.path();
        vm.id = 'HomeController';
        $rootScope.showSearchBox = true;
        $rootScope.mainLogoLink = "#";
        $rootScope.mainLogoLink = "http://www.iamovers.org";
        $rootScope.mcsfilters = undefined;
        $rootScope.mocsfilters = undefined;
        $rootScope.recsfilters = undefined;
        $rootScope.sucsfilters = undefined;
        $rootScope.frcsfilters = undefined;
        $rootScope.cscsfilters = undefined;
        $rootScope.uscsfilters = undefined;
        $rootScope.lngsfilters = undefined;
        $rootScope.lngsfilters = undefined;
        $rootScope.countryGuideLink = undefined;
        $rootScope.assocsfilters = "800";
        $rootScope.certsfilters = undefined;
        $rootScope.compliancefilters = undefined;
        $rootScope.contactQualificationfilters = undefined;
        $rootScope.searchMode = 'IAM';
        $rootScope.searchModeLabel = 'Display all Service Providers';
        $rootScope.searchModeText = 'Displaying IAM members Only';

        vm.homeOn = function () {
            return !$location.absUrl().endsWith("supplier-portal");
            // return !$rootScope.supplierPortal;
        }

        vm.generateEventGa4 = function (ga4EventName, ga4EventDetails) {
            if ($rootScope.supplierPortal === true) {
                // ga4EventDetails = _.mapKeys(ga4EventDetails, (value,key) =>  key+'_SUP' );
                ga4EventDetails = _.mapKeys(ga4EventDetails, function (value, key) {
                    return key + '_SUP';
                });
                ga4EventName = ga4EventName + '_SUP';
            }
            $window.gtag('event', ga4EventName, ga4EventDetails);
        };


        vm.features = ContentControl.getFeatures();
        if (vm.homeOn()) {
            $rootScope.supplierPortal = false;

            vm.iamxHelp = ContentControl.getIAMXhelp({supplier: false});
            vm.iamxNews = ContentControl.getIAMXNews({supplier: false});
            vm.resource = ContentControl.getResource({supplier: false});
            vm.resource1 = ContentControl.getResource1({supplier: false});
            vm.resource2 = ContentControl.getResource2({supplier: false});
        }

        $scope.endoIndex = -1;
        vm.endorsment;
        vm.endorsments = [];
        $scope.totalEndorsment = 0;
        vm.ray = MemberContacts.getSupportById();
        vm.controlId = true;
        $scope.skypeUrl = function () {
            $window.open('skype:raydasilva1');
        }

        $scope.getSubscriptionControl = function () {
            $http
                .get('/api/subscription-control-home?supplier=false')
                .then(
                    function (response) {
                        vm.endorsments = response.data;
                        if (vm.endorsments && vm.endorsments.length > 0) {
                            vm.endorsment = vm.endorsments[0];
                            //$scope.logAdvertiser(vm.endorsment.id);
                            vm.generateEventGa4('IAMX Home Page Ad Impression', {'sp_home_ad_impression': vm.endorsment.spName});
                        }
                    });
        };

        // Kick off the interval
        $scope.$on('$viewContentLoaded', function () {
            $scope.getSubscriptionControl();
        });

        $scope.isSupportAvailable = function () {
            if (vm.ray.timezone && !vm.ray.skypeDisable) {
                var today = new Date();
                var utc = today.getTime() + (today.getTimezoneOffset() * 60000);

                var nd = new Date(utc + (3600000 * vm.ray.timezone.flex1));

                var hour = nd.getHours();
                var date = nd.getFullYear() + '/' + (nd.getMonth() + 1) + '/' + nd.getDate() + ' ' + nd.getHours() + ':' + nd.getMinutes() + ':' + nd.getSeconds();
                var dateTo = nd.getFullYear() + '/' + (nd.getMonth() + 1) + '/' + nd.getDate() + ' ' + vm.ray.skypeTimeTo + ':' + nd.getSeconds();
                var dateFrom = nd.getFullYear() + '/' + (nd.getMonth() + 1) + '/' + nd.getDate() + ' ' + vm.ray.skypeTimeFrom + ':' + nd.getSeconds();

                if (Date.parse(date) >= Date.parse(dateFrom) && Date.parse(date) <= Date.parse(dateTo)) {
                    var day = nd.getDay();

                    switch (day) {
                        case 1:
                            if (vm.ray.skypeMon) return true;
                            break;
                        case 2:
                            if (vm.ray.skypeTue) return true;
                            break;
                        case 3:
                            if (vm.ray.skypeWed) return true;
                            break;
                        case 4:
                            if (vm.ray.skypeThr) return true;
                            break;
                        case 5:
                            if (vm.ray.skypeFri) return true;
                            break;
                        case 6:
                            if (vm.ray.skypeSat) return true;
                            break;
                        case 0:
                            if (vm.ray.skypeSun) return true;
                            break;
                    }
                }
            }
            return false;
        }


        // $rootScope.fullMapMarkers = null;

        vm.splasMessage = null;
        vm.splasLink = null;

        $http.get('/api/splashmessage').then(
            function (response) {
                vm.splasMessage = response.data.splash_message;
                vm.splasLink = response.data.splash_link;
            }
        );

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;

        vm.iamUrl = 'http://www.iamovers.org/';

        $rootScope.image_path = "";

        var locInfoEntered = false;

        vm.splashMessageHeader = null;
        vm.splashlink = null;
        vm.splashMessageLine = null

        vm.getSplashMessage = function () {
            $http.get('api/splashmessage').then(
                function (response) {
                    vm.splashMessageHeader = $sce.trustAsHtml(response.data.splash_message_header);
                    vm.splashMessageLine = $sce.trustAsHtml(response.data.splash_message_line);
                    vm.splashlink = response.data.splash_link;
                }
            );
        };

        vm.getSplashMessage();

        $scope.setLatLng = function (details) {
            $rootScope.lat = details.details.latitude + '';
            $rootScope.lng = details.details.longitude + '';
            $rootScope.loc = details.details.formatted + '';
            $rootScope.ctry = details.details.country + '';
            $rootScope.locInfoEntered = true;
        };

        if (!$rootScope.locInfoEntered) {
            $rootScope.lat = null;
            $rootScope.lng = null;
            $rootScope.loc = null;
            $rootScope.ctry = null;
        }

        $scope.$on('authenticationSuccess', function () {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        });

        function register() {
            $state.go('register');
        }

        vm.asyncLocationSelected = '';
        vm.asyncServiceSelected = '';

        $scope.iamxstatusmap = function () {
            $state.go('iamxstatusmap', null, {reload: true}).then(function () {

            });

            $timeout(function () {
                $window.location.reload();
            }, 1000);
        };

        vm.memberCapability = null;
        vm.memberCapabilitiesChosen = [];

        vm.setMemberCapability = function (mCap) {
            _.concat(vm.memberCapabilitiesChosen, mCap);
        };

        vm.search = function () {

            $timeout(
                function () {

                    var lat_lng;
                    var lat;
                    var lng;
                    var loc;
                    var ctry;
                    var svc;
                    var searchTerm;
                    var params = {};
                    params.assocs = 800;
                    var searchLabel = '';
                    var searchLocation = '';
                    var searchGeoCode = '';
                    var searchCountry = '';

                    if ($rootScope.lat && $rootScope.lng) {
                        // lat_lng =
                        // vm.asyncLocationSelected.geometry.location;
                        params.lat = $rootScope.lat;// locationDetails.latitude;
                        params.lng = $rootScope.lng;// locationDetails.longitude;
                        params.loc = $rootScope.loc;// locationDetails.formatted+'';
                        params.ctry = $rootScope.ctry;
                        searchLabel = params.lat + ',' + params.lng + ' , ' + params.loc + ' , ' + params.ctry;
                        searchLocation = params.loc;
                        searchGeoCode = params.lat + ',' + params.lng;
                        searchCountry = params.ctry;
                    }

                    if (vm.asyncServiceSelected) {
                        if (!_.isEmpty(vm.asyncServiceSelected.companylegalname)
                            && !_.isEmpty(vm.asyncServiceSelected.city)
                            && !_.isEmpty(vm.asyncServiceSelected.country)
                        ) {
                            params.svc = vm.asyncServiceSelected.companylegalname;

                        } else {
                            params.svc = vm.asyncServiceSelected.searchResult;
                            // TODO: This needs to be revisited
                            if (params.svc === "Industry Partners Services Company ") {
                                vm.memberCapabilitiesChosen[0] = 13;
                                // TODO: This needs to be revisited
                            } else if (params.svc === "Freight Services Company ") {
                                vm.memberCapabilitiesChosen[0] = 12;
                            } else if (vm.asyncServiceSelected === "Relocation Services Company ") {
                                vm.memberCapabilitiesChosen[0] = 11;
                            } else if (vm.asyncServiceSelected === "Moving Services Company ") {
                                vm.memberCapabilitiesChosen[0] = 10;
                            }
                            // MX-528
                            if (params.svc === "Validated Member Company") {
                                $rootScope.assocsfilters = "920";
                                params.assocs = "920";
                                $rootScope.searchMode = "Validated";
                                $rootScope.searchModeLabel = 'Display all Service Providers';
                                $rootScope.searchModeText = 'Displaying Validated members Only';
                            } else if (params.svc === "ILN Member Company") {
                                params.assocs = "881";
                                $rootScope.assocsfilters = "881";
                                $rootScope.searchMode = "ILN";
                                $rootScope.searchModeLabel = 'Display all Service Providers';
                                $rootScope.searchModeText = 'Displaying ILN members Only';
                            } else if (params.svc === "ISA Member Company") {
                                params.assocs = "866";
                                $rootScope.assocsfilters = "866";
                                $rootScope.searchMode = "ISA";
                                $rootScope.searchModeLabel = 'Display all Service Providers';
                                $rootScope.searchModeText = 'Displaying ISA members Only';
                            }
                        }
                        //}
                        searchLabel = searchLabel + '  ' + params.svc;
                    }

                    // control to close all filters from home page
                    params.fvw = 'c';
                    params.spc = vm.memberCapabilitiesChosen.length > 0 ? _.join(_.without(vm.memberCapabilitiesChosen, false), ",") : "";
                    $rootScope.locInfoEntered = false;
                    $analytics.eventTrack('Search Term', {category: 'search', label: searchLabel});

                    if (!_.isEmpty(params.svc) && _.isEmpty(searchLocation)) {
                        vm.generateEventGa4('IAMX Search', {'iamx_search': 'Term Search'});
                        vm.generateEventGa4('IAMX Search Term', {'iamx_search_term': vm.asyncServiceSelected});
                    }

                    if (!_.isEmpty(searchLocation) && _.isEmpty(params.svc)) {
                        vm.generateEventGa4('IAMX Search', {'iamx_search': 'Location Search'});
                        vm.generateEventGa4('IAMX Search Location', {
                            'iamx_search_location': searchLocation,
                            'iamx_search_geo_code': searchGeoCode
                        });
                        vm.generateEventGa4('IAMX Search Country',
                            {'iamx_search_geo_code': searchGeoCode, 'iamx_search_country': searchCountry});
                    }

                    if (!_.isEmpty(params.svc) && !_.isEmpty(searchLocation)) {
                        vm.generateEventGa4('IAMX Search',
                            {'iamx_search': 'Combined Search'});
                        vm.generateEventGa4('IAMX Search Term',
                            {'iamx_search_term': params.svc});
                        vm.generateEventGa4('IAMX Search Location',
                            {
                                'iamx_search_location': searchLocation,
                                'iamx_search_geo_code': searchGeoCode
                            });
                        vm.generateEventGa4('IAMX Search Country',
                            {'iamx_search_geo_code': searchGeoCode, 'iamx_search_country': searchCountry});
                    }

                    $state.go('search', params);
                }
                , 500);
        };

        vm.supplierPortal = function () {
            var params = {};
            $rootScope.supplierPortal = true;
            $state.go('supplier-portal', params);
        };

        $scope.supplierPortal = function () {
            var params = {};
            $rootScope.supplierPortal = true;
            $state.go('supplier-portal', params);
        };

        vm.about = function () {
            $state.go('about');
        };


        // ///////////////

        var _selected;
        vm.selected = undefined;


        vm.getServices = function (svc_val) {
            if (svc_val.length < 3)
                return;
            var spcVal = vm.memberCapabilitiesChosen.length > 0 ? _.join(_.without(vm.memberCapabilitiesChosen, false), ",") : "";

            return $http.get('api/_search/services/service', {
                params: {
                    query: '' + svc_val + '',
                    sensor: false,
                    spc: '' + spcVal + ''
                }
            }).then(function (response) {
                return response.data.map(function (searchresults) {
                    return searchresults;
                });
            });
        };


        vm.display = 'style="height: 35px;width: 100%; display: block !important;';
    }


    SupplierPortalController.$inject = ['$scope', 'Principal', 'LoginService', '$state', '$http',
        '$rootScope', '$stateParams', '$location', '$timeout', '$interval', '$sce', '$window', '$analytics',
        'Lookups', 'MemberContacts', 'ContentControl', 'ServiceProviders'];

    function SupplierPortalController($scope, Principal, LoginService, $state, $http,
                                      $rootScope, $stateParams, $location, $timeout, $interval, $sce,
                                      $window, $analytics, Lookups, MemberContacts, ContentControl, ServiceProviders) {


        var vm = this;

        vm.memberCapabilities = Lookups.getByLookupType({id: 2});
        vm.movingCapabilities = Lookups.getByLookupType({id: 5});
        vm.relocationCapabilities = Lookups.getByLookupType({id: 6});
        vm.industryPartnerCapabilities = Lookups.getByLookupType({id: 7});
        vm.industrySupplierCapabilities = Lookups.getByLookupType({id: 8});

        vm.associations = Lookups.getByLookupType({id: 9});
        vm.quality = Lookups.getByLookupType({id: 11});
        vm.usGovt = Lookups.getByLookupType({id: 19});
        vm.languageCapabilities = Lookups.getByLookupType({id: 53});
        vm.compliance = Lookups.getByLookupType({id: 23});
        vm.contactQualifications = Lookups.getByLookupType({id: 33});
        vm.destinationServices = Lookups.getByLookupType({id: 95});

        vm.invoiceStatus = Lookups.getByLookupType({id: 36});
        vm.paymentTerms = Lookups.getByLookupType({id: 38});
        vm.paymentStatus = Lookups.getByLookupType({id: 39});
        vm.paymentMethods = Lookups.getByLookupType({id: 40});
        vm.currencies = Lookups.getByLookupType({id: 52});

        vm.currentPath = $location.path();
        vm.id = 'SupplierPortalController';
        $rootScope.showSearchBox = true;
        $rootScope.mainLogoLink = "#";
        $rootScope.mainLogoLink = "http://www.iamovers.org";
        $rootScope.mcsfilters = undefined;
        $rootScope.mocsfilters = undefined;
        $rootScope.recsfilters = undefined;
        $rootScope.sucsfilters = undefined;
        $rootScope.frcsfilters = undefined;
        $rootScope.cscsfilters = undefined;
        $rootScope.uscsfilters = undefined;
        $rootScope.lngsfilters = undefined;
        $rootScope.lngsfilters = undefined;
        $rootScope.countryGuideLink = undefined;
        $rootScope.assocsfilters = "800";
        $rootScope.certsfilters = undefined;
        $rootScope.compliancefilters = undefined;
        $rootScope.contactQualificationfilters = undefined;
        $rootScope.searchMode = 'IAM';
        $rootScope.searchModeLabel = 'Display all Service Providers';
        $rootScope.searchModeText = 'Displaying IAM members Only';

        vm.supplierPortalOn = function () {
            return $location.absUrl().endsWith("supplier-portal");
            // return $rootScope.supplierPortal;
        }

        vm.features = ContentControl.getFeatures();
        if (vm.supplierPortalOn()) {
            $rootScope.supplierPortal = true;
            vm.supplierPortalIamxHelp = ContentControl.getIAMXhelp({supplier: true});
            vm.supplierPortalIamxNews = ContentControl.getIAMXNews({supplier: true});
            vm.supplierPortalResource = ContentControl.getResource({supplier: true});
            vm.supplierPortalResource1 = ContentControl.getResource1({supplier: true});
            vm.supplierPortalResource2 = ContentControl.getResource2({supplier: true});
        }

        $scope.endoIndex = -1;
        vm.endorsment;
        vm.endorsments = [];
        $scope.totalEndorsment = 0;
        vm.ray = MemberContacts.getSupportById();
        vm.controlId = true;
        $scope.skypeUrl = function () {
            $window.open('skype:raydasilva1');
        }

        $scope.getSubscriptionControl = function () {

            $http
                .get('/api/subscription-control-home?supplier=true')
                .then(
                    function (response) {
                        vm.endorsments = response.data;
                        if (vm.endorsments && vm.endorsments.length > 0) {
                            vm.endorsment = vm.endorsments[0];
                            //$scope.logAdvertiser(vm.endorsment.id);
                            vm.generateEventGa4('IAMX Home Page Ad Impression', {'sp_home_ad_impression': vm.endorsment.spName});
                        }
                    });
        };

        // Kick off the interval
        $scope.$on('$viewContentLoaded', function () {
            $scope.getSubscriptionControl();
        });

        $scope.isSupportAvailable = function () {
            if (vm.ray.timezone && !vm.ray.skypeDisable) {
                var today = new Date();
                var utc = today.getTime() + (today.getTimezoneOffset() * 60000);

                var nd = new Date(utc + (3600000 * vm.ray.timezone.flex1));

                var hour = nd.getHours();
                var date = nd.getFullYear() + '/' + (nd.getMonth() + 1) + '/' + nd.getDate() + ' ' + nd.getHours() + ':' + nd.getMinutes() + ':' + nd.getSeconds();
                var dateTo = nd.getFullYear() + '/' + (nd.getMonth() + 1) + '/' + nd.getDate() + ' ' + vm.ray.skypeTimeTo + ':' + nd.getSeconds();
                var dateFrom = nd.getFullYear() + '/' + (nd.getMonth() + 1) + '/' + nd.getDate() + ' ' + vm.ray.skypeTimeFrom + ':' + nd.getSeconds();

                if (Date.parse(date) >= Date.parse(dateFrom) && Date.parse(date) <= Date.parse(dateTo)) {
                    var day = nd.getDay();

                    switch (day) {
                        case 1:
                            if (vm.ray.skypeMon) return true;
                            break;
                        case 2:
                            if (vm.ray.skypeTue) return true;
                            break;
                        case 3:
                            if (vm.ray.skypeWed) return true;
                            break;
                        case 4:
                            if (vm.ray.skypeThr) return true;
                            break;
                        case 5:
                            if (vm.ray.skypeFri) return true;
                            break;
                        case 6:
                            if (vm.ray.skypeSat) return true;
                            break;
                        case 0:
                            if (vm.ray.skypeSun) return true;
                            break;
                    }
                }
            }

            return false;
        }

        // $rootScope.fullMapMarkers = null;

        vm.splasMessage = null;
        vm.splasLink = null;

        $http.get('/api/splashmessage').then(
            function (response) {
                vm.splasMessage = response.data.splash_message;
                vm.splasLink = response.data.splash_link;
            }
        );

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;

        vm.iamUrl = 'http://www.iamovers.org/';

        $rootScope.image_path = "";

        var locInfoEntered = false;

        vm.splashMessageHeader = null;
        vm.splashlink = null;
        vm.splashMessageLine = null

        vm.getSplashMessage = function () {
            $http.get('api/splashmessage').then(
                function (response) {
                    vm.splashMessageHeader = $sce.trustAsHtml(response.data.splash_message_header);
                    vm.splashMessageLine = $sce.trustAsHtml(response.data.splash_message_line);
                    vm.splashlink = response.data.splash_link;
                }
            );
        };

        vm.getSplashMessage();

        $scope.setLatLng = function (details) {
            //console.log("385. map details = " + JSON.stringify(details.details));
            $rootScope.lat = details.details.latitude + '';
            $rootScope.lng = details.details.longitude + '';
            $rootScope.loc = details.details.formatted + '';
            $rootScope.ctry = details.details.country + '';
            $rootScope.locInfoEntered = true;
        };

        if (!$rootScope.locInfoEntered) {
            $rootScope.lat = null;
            $rootScope.lng = null;
            $rootScope.loc = null;
            $rootScope.ctry = null;
        }

        $scope.$on('authenticationSuccess', function () {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        });

        function register() {
            $state.go('register');
        }

        vm.home = function () {
            var params = {};
            $rootScope.supplierPortal = false;
            $state.go('home', params);
        };

        $scope.home = function () {
            var params = {};
            $rootScope.supplierPortal = false;
            $state.go('home', params);
        };

        vm.asyncLocationSelected = '';
        vm.asyncServiceSelected = '';

        $scope.iamxstatusmap = function () {
            $state.go('iamxstatusmap', null, {reload: true}).then(function () {
            });

            $timeout(function () {
                $window.location.reload();
            }, 1000);
        };

        vm.memberCapability = null;
        vm.memberCapabilitiesChosen = [];

        vm.setMemberCapability = function (mCap) {
            _.concat(vm.memberCapabilitiesChosen, mCap);
        };

        vm.search = function () {

            $timeout(
                function () {

                    var lat_lng;
                    var lat;
                    var lng;
                    var loc;
                    var ctry;
                    var svc;
                    var searchTerm;
                    var params = {};
                    params.assocs = 800;
                    var searchLabel = '';
                    var searchLocation = '';
                    var searchGeoCode = '';
                    var searchCountry = '';

                    if ($rootScope.lat && $rootScope.lng) {
                        // lat_lng =
                        // vm.asyncLocationSelected.geometry.location;
                        params.lat = $rootScope.lat;// locationDetails.latitude;
                        params.lng = $rootScope.lng;// locationDetails.longitude;
                        params.loc = $rootScope.loc;// locationDetails.formatted+'';
                        params.ctry = $rootScope.ctry;
                        searchLabel = params.lat + ',' + params.lng + ' , ' + params.loc + ' , ' + params.ctry;
                        searchLocation = params.loc;
                        searchGeoCode = params.lat + ',' + params.lng;
                        searchCountry = params.ctry;
                    }

                    if (vm.asyncServiceSelected) {
                        if (!_.isEmpty(vm.asyncServiceSelected.companylegalname)
                            && !_.isEmpty(vm.asyncServiceSelected.city)
                            && !_.isEmpty(vm.asyncServiceSelected.country)
                        ) {
                            params.svc = vm.asyncServiceSelected.companylegalname;
                        } else {
                            params.svc = vm.asyncServiceSelected.searchResult;
                            // TODO: This needs to be revisited
                            if (params.svc === "Industry Partners Services Company ") {
                                vm.memberCapabilitiesChosen[0] = 13;
                                // TODO: This needs to be revisited
                            } else if (params.svc === "Freight Services Company ") {
                                vm.memberCapabilitiesChosen[0] = 12;
                            } else if (params.svc === "Relocation Services Company ") {
                                vm.memberCapabilitiesChosen[0] = 11;
                            } else if (params.svc === "Moving Services Company ") {
                                vm.memberCapabilitiesChosen[0] = 10;
                            }
                            // MX-528
                            if (params.svc === "Validated Member Company") {
                                $rootScope.assocsfilters = "920";
                                params.assocs = "920";
                                $rootScope.searchMode = "Validated";
                                $rootScope.searchModeLabel = 'Display all Service Providers';
                                $rootScope.searchModeText = 'Displaying Validated members Only';
                            } else if (params.svc === "ILN Member Company") {
                                params.assocs = "881";
                                $rootScope.assocsfilters = "881";
                                $rootScope.searchMode = "ILN";
                                $rootScope.searchModeLabel = 'Display all Service Providers';
                                $rootScope.searchModeText = 'Displaying ILN members Only';
                            } else if (params.svc === "ISA Member Company") {
                                params.assocs = "866";
                                $rootScope.assocsfilters = "866";
                                $rootScope.searchMode = "ISA";
                                $rootScope.searchModeLabel = 'Display all Service Providers';
                                $rootScope.searchModeText = 'Displaying ISA members Only';
                            }
                        }
                    }
                    searchLabel = searchLabel + '  ' + params.svc;

                    // control to close all filters from home page
                    params.fvw = 'c';
                    params.spc = vm.memberCapabilitiesChosen.length > 0 ? _.join(_.without(vm.memberCapabilitiesChosen, false), ",") : "";
                    params.supplier = 'true';
                    $rootScope.locInfoEntered = false;
                    $analytics.eventTrack('Search Term', {category: 'search', label: searchLabel});

                    if (!_.isEmpty(params.svc) && _.isEmpty(searchLocation)) {
                        vm.generateEventGa4('IAMX Search', {'iamx_search': 'Term Search'});
                        vm.generateEventGa4('IAMX Search Term', {'iamx_search_term': vm.asyncServiceSelected});
                    }

                    if (!_.isEmpty(searchLocation) && _.isEmpty(params.svc)) {
                        vm.generateEventGa4('IAMX Search', {'iamx_search': 'Location Search'});
                        vm.generateEventGa4('IAMX Search Location', {
                            'iamx_search_location': searchLocation,
                            'iamx_search_geo_code': searchGeoCode
                        });
                        vm.generateEventGa4('IAMX Search Country',
                            {'iamx_search_geo_code': searchGeoCode, 'iamx_search_country': searchCountry});
                    }

                    if (!_.isEmpty(params.svc) && !_.isEmpty(searchLocation)) {
                        vm.generateEventGa4('IAMX Search',
                            {'iamx_search': 'Combined Search'});
                        vm.generateEventGa4('IAMX Search Term',
                            {'iamx_search_term': params.svc});
                        vm.generateEventGa4('IAMX Search Location',
                            {
                                'iamx_search_location': searchLocation,
                                'iamx_search_geo_code': searchGeoCode
                            });
                        vm.generateEventGa4('IAMX Search Country',
                            {'iamx_search_geo_code': searchGeoCode, 'iamx_search_country': searchCountry});
                    }

                    $state.go('supplier-search', params);
                }
                , 500);
        };

        vm.about = function () {
            $state.go('about');
        };

        vm.generateEventGa4 = function (ga4EventName, ga4EventDetails) {
            if ($rootScope.supplierPortal === true) {
                // ga4EventDetails = _.mapKeys(ga4EventDetails, (value,key) =>  key+'_SUP' );
                ga4EventDetails = _.mapKeys(ga4EventDetails, function (value, key) {
                    return key + '_SUP';
                });
                ga4EventName = ga4EventName + '_SUP';
            }
            $window.gtag('event', ga4EventName, ga4EventDetails);
        };

        // ///////////////

        var _selected;
        vm.selected = undefined;


        vm.getServices = function (svc_val) {
            if (svc_val.length < 3)
                return;
            var spcVal = vm.memberCapabilitiesChosen.length > 0 ? _.join(_.without(vm.memberCapabilitiesChosen, false), ",") : "";

            return $http.get('api/_search/services/service', {
                params: {
                    query: '' + svc_val + '',
                    sensor: false,
                    spc: '' + spcVal + '',
                    supplier: true
                }
            }).then(function (response) {
                return response.data.map(function (searchresults) {
                    return searchresults;
                });
            });
        };

        vm.display = 'style="height: 35px;width: 100%; display: block !important;';
    }

})();
