(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('CRMNotesSearch', CRMNotesSearch);

    CRMNotesSearch.$inject = ['$resource'];

    function CRMNotesSearch($resource) {
        // var resourceUrl = 'api/_search/c-rm-notes/:id?&companyLegalName=:companyLegalName';
        var resourceUrl = 'api/_search/c-rm-notes?companyLegalName=:companyLegalName&providerContactName=:providerContactName&actor=:actor&note=:note&action=:action&targetDate=:targetDate&createddate=:createddate';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }
})();
