(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .factory('InvoiceHeaderSearch', InvoiceHeaderSearch);

    InvoiceHeaderSearch.$inject = ['$resource'];

    function InvoiceHeaderSearch($resource) {
        var resourceUrl =  'api/_search/invoice-headers/:id?&spn=:spn&invdate=:invdate&pdate=:pdate&invstat=:invstat&paymeth=:paymeth&invamt=:invamt&invnum=:invnum&payref=:payref&spname=:spname&invfromdate=:invfromdate&invtodate=:invtodate&pstat=:pstat';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();
