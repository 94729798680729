(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('ReportScheduleController', ReportScheduleController);

    ReportScheduleController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ReportSchedule', 'Principal'];

    function ReportScheduleController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ReportSchedule, Principal) {
        var vm = this;

        vm.reportSchedule = entity;
        parseMailFrequency();

        Principal.identity().then(function (account) {
            if (!vm.reportSchedule.user) {
                vm.reportSchedule.user = {};
                vm.reportSchedule.user.login = account.login;
                // If this is create populate the email address with the account login
                if (!vm.reportSchedule.id) {
                    vm.reportSchedule.emailAddresses = account.email;
                }
            }
        });

        vm.clear = clear;
        vm.save = save;
        vm.schedule = schedule;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            schedule();
        }

        function onSaveSuccess (result) {
            $scope.$emit('mobilityexchangeApp:reportScheduleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function schedule () {
            vm.isSaving = true;
            vm.reportSchedule.mailFrequency = buildMailFrequency();

            if (vm.reportSchedule.id) {
                ReportSchedule.update(vm.reportSchedule, onSaveSuccess, onSaveError);
            } else {
                ReportSchedule.save(vm.reportSchedule, onSaveSuccess, onSaveError);
            }
        }

        function parseMailFrequency() {
            if (vm.reportSchedule.mailFrequency) {
                var mailFrequencies = vm.reportSchedule.mailFrequency.split(",");

                vm.reportSchedule.monday = mailFrequencies.includes('1');
                vm.reportSchedule.tuesday = mailFrequencies.includes('2');
                vm.reportSchedule.wednesday = mailFrequencies.includes('3');
                vm.reportSchedule.thursday = mailFrequencies.includes('4');
                vm.reportSchedule.friday = mailFrequencies.includes('5');
            }
        }

        function buildMailFrequency() {
            var mailFrequency = [];

            if (vm.reportSchedule.monday) {
                mailFrequency.push(1);
            }

            if (vm.reportSchedule.tuesday) {
                mailFrequency.push(2);
            }

            if (vm.reportSchedule.wednesday) {
                mailFrequency.push(3);
            }

            if (vm.reportSchedule.thursday) {
                mailFrequency.push(4);
            }

            if (vm.reportSchedule.friday) {
                mailFrequency.push(5);
            }

            return mailFrequency.join(",");
        }
    }
})();
