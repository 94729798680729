/*eslint-env jquery*/
(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('ProviderContactsDialogController', ProviderContactsDialogController);

    ProviderContactsDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$uibModalInstance', 'entity', 'ProviderContacts', 'Lookups', 'CustomerNotes', '$http'];

    function ProviderContactsDialogController($timeout, $scope, $state, $stateParams,
                                              $uibModalInstance, entity, ProviderContacts, Lookups, CustomerNotes, $http) {

        $scope.controllerName = "ProviderContactsDialogController";
        var vm = this;

        vm.providerContacts = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.saveCRM = saveCRM;
        //vm.serviceproviders = ServiceProviders.query();
        vm.lookups = Lookups.query();
        vm.closeCustomerNotesDialog = closeCustomerNotesDialog;

        vm.customerNotesList = vm.providerContacts.customerNotes;

        vm.customerNotes = {};

        $http.get
        (
            'api/userslist',
            {}
        )
            .then
            (
                function (response) {
                    $.each
                    (
                        vm.providerContacts.customerNotes,
                        function (index, customerNote) {
                            $.each
                            (
                                response.data,
                                function (index, user) {
                                    if (user.login == customerNote.modifiedby) {
                                        customerNote.actor = user.firstName + " " + user.lastName;
                                        return false;

                                    }

                                }
                            )
                            ;
                        }
                    )
                    ;

                }
            )
        ;

        $scope.actor;
        $scope.actors;
        $scope.actions;

        $http.get
        (
            'api/userslist',
            {}
        )
            .then
            (
                function (response) {

                    $scope.actors =
                        response.data
                            .filter
                            (
                                function (user) {
                                    return user.activated && (user.authorities.indexOf("ROLE_ADMIN") != -1 || user.authorities.indexOf("ROLE_ANALYST") != -1);

                                }
                            )
                            .map
                            (
                                function (user) {
                                    user.name = user.firstName + " " + user.lastName;

                                    return user;

                                }
                            )
                    ;

                    // set actor


                    for (var i = 0; i < $scope.actors.length; i++) {

                        if (vm.customerNotes && $scope.actors[i].firstName + ' ' + $scope.actors[i].lastName == vm.customerNotes.actor) {
                            $scope.actor = $scope.actors[i];
                            break;

                        }

                    }

                }
            )
        ;

        // get actions

        $http.get
        (
            'api/lookups/lookuptypes/20',
            {}
        )
            .then
            (
                function (response) {
                    $scope.actions = response.data;

                    for (var i = 0; i < $scope.actions.length; i++) {

                        if (vm.customerNotes && vm.customerNotes.action != null && $scope.actions[i].id == vm.customerNotes.action.id) {
                            vm.customerNotes.action = $scope.actions[i];
                            break;

                        }

                    }

                }
            )
        ;



        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            
            vm.providerContacts.communicationPreferences=[];

            if (vm.providerContacts.id !== null) {
                ProviderContacts.update(vm.providerContacts, onSaveSuccess, onSaveError);
            } else {
                ProviderContacts.save(vm.providerContacts, onSaveSuccess, onSaveError);
            }
        }
        
        function saveCRM() {

            vm.isSaving = true;

            //console.log('vm.customerNotes.id'+vm.customerNotes.id);

            if(vm.customerNotes && vm.customerNotes.id !== null
                    && !_.isUndefined( vm.customerNotes.id)) {
                var pContacts = vm.providerContacts;
                delete pContacts.customerNotes;
                vm.customerNotes.providerContacts = pContacts; 
                //vm.customerNotes.serviceProviders = vm.serviceproviders;
                vm.customerNotes.actor = $scope.actor.firstName+' '+$scope.actor.lastName;
                CustomerNotes.update(vm.customerNotes, onUpdatesNotesSuccess, onUpdatesNotesFailure);
            }else{
                vm.customerNotes.providerContacts = vm.providerContacts;
                //vm.customerNotes.serviceProviders = vm.serviceproviders;
                vm.customerNotes.actor = $scope.actor.firstName+' '+$scope.actor.lastName;
                CustomerNotes.save(vm.customerNotes, onSaveNotesSuccess, onSaveNotesFailure);
            }
        }


        function onUpdatesNotesSuccess(result) {
            vm.customerNotes = {};
            $scope.actor = null;
            var index = _.findIndex(vm.customerNotesList, {id: result.id});
            vm.customerNotesList.splice(index, 1, result);
            vm.isSaving = false;
        }

        function onUpdatesNotesFailure() {
            vm.isSaving = false;
        }


        function onSaveNotesSuccess(result) {
            vm.customerNotes = {};
            $scope.actor = null;
            vm.customerNotesList.push(result);
            vm.isSaving = false;
        }

        function onSaveNotesFailure() {
            vm.isSaving = false;
        }


        function onSaveSuccess(result) {
            $scope.$emit('mobilityexchangeApp:providerContactsUpdate', result);
            //$uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createddate = false;
        vm.datePickerOpenStatus.modifieddate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function closeCustomerNotesDialog() {
            $uibModalInstance.dismiss("cancel");

        }

        vm.newNote = function(){
            vm.customerNotes = {};
            $scope.actor = null;
           // vm.customerNotes.note = null;
        }

        vm.editNote = function(note){
            vm.customerNotes = note;
            for (var i = 0; i < $scope.actors.length; i++) {
                if (vm.customerNotes && $scope.actors[i].firstName
                    + ' ' + $scope.actors[i].lastName == vm.customerNotes.actor) {
                    $scope.actor = $scope.actors[i];
                    break;
                }
            }
            for (var i = 0; i < $scope.actions.length; i++) {
                if (vm.customerNotes && vm.customerNotes.action != null
                    && $scope.actions[i].id == vm.customerNotes.action.id) {
                    vm.customerNotes.action = $scope.actions[i];
                    break;
                }
            }
            vm.customerNotes.targetDate = new Date(vm.customerNotes.targetDate);
        }

    }
})();
