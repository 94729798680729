(function() {
    //'use strict';
    angular
        .module('mobilityexchangeApp')
        .factory('ClaimHeader', ClaimHeader);

    ClaimHeader.$inject = ['$resource', 'DateUtils'];

    function ClaimHeader ($resource, DateUtils) {
        var resourceUrl =  'api/claim-headers/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                url: 'api/claim-headers/:id?'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data, headers, status) {

                    if(status !== 200)
                        return status;

                    if (data || !_.isEmpty(data)) {
                        data = angular.fromJson(data);
                        data.submissionDate = DateUtils.convertDateTimeFromServer(data.submissionDate);
                        data.staffReviewDate = DateUtils.convertDateTimeFromServer(data.staffReviewDate);
                        data.firstInterventionDate = DateUtils.convertDateTimeFromServer(data.firstInterventionDate);
                        data.secondInterventionDate = DateUtils.convertDateTimeFromServer(data.secondInterventionDate);
                        data.thirdInterventionDate = DateUtils.convertDateTimeFromServer(data.thirdInterventionDate);
                        data.adlPublicationDate = DateUtils.convertDateTimeFromServer(data.adlPublicationDate);
                        data.expulsionNoticeDate = DateUtils.convertDateTimeFromServer(data.expulsionNoticeDate);
                        data.explusionRequestDate = DateUtils.convertDateTimeFromServer(data.explusionRequestDate);
                        data.expulsionConfirmDate = DateUtils.convertDateTimeFromServer(data.expulsionConfirmDate);

                        data.informalInterventionDate = DateUtils.convertDateTimeFromServer(data.informalInterventionDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'getServiceProvider': {
                method: 'GET',
                url:'api/claim-headers/against/:id',
                transformResponse: function (data) {
                    ////console.log('GOT DATA, TRANSFORMING!!');
                    if (data) {
                        data = angular.fromJson(data);
                        if( data.businessRegExpiry){
                        	data.businessRegExpiry = DateUtils.convertLocalDateFromServer(data.businessRegExpiry);
                        }
                        if(data.createddate)
                            data.createdDateForDisplay = data.createddate;
                        if(data.modifieddate)
                            data.modifiedDateForDisplay = data.modifieddate;

                        //data.createddate = DateUtils.convertLocalDateFromServer(data.createddate);
                        //data.modifieddate = DateUtils.convertLocalDateFromServer(data.modifieddate);
                        if(data.subscriptionControl)
                        	data.subscriptionControl.startDate = DateUtils.convertLocalDateFromServer(data.subscriptionControl.startDate);
                        if(data.subscriptionControl)
                        data.subscriptionControl.endDate = DateUtils.convertLocalDateFromServer(data.subscriptionControl.endDate);
                        // convert quality expiration date

                        $.each
                        (
                            data.quality,
                            function (index, quality) {
                                quality.expirydate = DateUtils.convertLocalDateFromServer(quality.expirydate);
                                quality.reviewdate = DateUtils.convertLocalDateFromServer(quality.reviewdate);

                            }
                        )
                        ;

                        $.each
                        (
                            data.compliance,
                            function (index, compliance) {
                                compliance.expirydate = DateUtils.convertLocalDateFromServer(compliance.expirydate);
                                compliance.reviewdate = DateUtils.convertLocalDateFromServer(compliance.reviewdate);

                            }
                        )
                        ;




                        $.each
                        (
                            data.memberAssociations,
                            function (index, memberAssociations) {


                                //console.log(' memberAssociations.valExpiryDate before :: '+memberAssociations.valExpiryDate);

                                //memberAssociations.valExpiryDate = DateUtils.convertDateTimeFromServer(memberAssociations.valExpiryDate);
                                //memberAssociations.valReviewDate = DateUtils.convertDateTimeFromServer(memberAssociations.valReviewDate);

                                memberAssociations.valExpiryDate = DateUtils.convertLocalDateFromServer(memberAssociations.valExpiryDate);
                                memberAssociations.valReviewDate = DateUtils.convertLocalDateFromServer(memberAssociations.valReviewDate);


                                //console.log(' memberAssociations.valExpiryDate after :: '+memberAssociations.valExpiryDate);

                                memberAssociations.statusDate = DateUtils.convertLocalDateFromServer(memberAssociations.statusDate);
                                memberAssociations.originalJoinDate = DateUtils.convertLocalDateFromServer(memberAssociations.originalJoinDate);
                                memberAssociations.currentJoinDate = DateUtils.convertLocalDateFromServer(memberAssociations.currentJoinDate);
                                memberAssociations.expiryDate = DateUtils.convertLocalDateFromServer(memberAssociations.expiryDate);
                                memberAssociations.terminationDate = DateUtils.convertLocalDateFromServer(memberAssociations.terminationDate);

                            }
                        )
                        ;

                        $.each
                        (
                            data.serviceproviderContacts,
                            function (index, serviceproviderContacts) {
                                serviceproviderContacts.dob = DateUtils.convertLocalDateFromServer(serviceproviderContacts.dob);
                                $.each(
                                    serviceproviderContacts.contactQualifications,
                                    function (index, contactQualifications) {
                                        contactQualifications.certDate = DateUtils.convertLocalDateFromServer(contactQualifications.certDate);
                                        contactQualifications.expDate= DateUtils.convertLocalDateFromServer(contactQualifications.expDate);
                                    }
                                )
                            }
                        )
                        ;

                    }
                    return data;
                }
            },
            'getAll': {
                method: 'GET',
                url: 'api/claim-headers/sp/:spId',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'getClaimant': {
                method: 'GET',
                url: 'api/claim-headers-claimant/',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            }
            ,
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.submissionDate = DateUtils.convertDateTimeFromServer(data.submissionDate);
                        data.staffReviewDate = DateUtils.convertDateTimeFromServer(data.staffReviewDate);
                        data.firstInterventionDate = DateUtils.convertDateTimeFromServer(data.firstInterventionDate);
                        data.secondInterventionDate = DateUtils.convertDateTimeFromServer(data.secondInterventionDate);
                        data.thirdInterventionDate = DateUtils.convertDateTimeFromServer(data.thirdInterventionDate);
                        data.adlPublicationDate = DateUtils.convertDateTimeFromServer(data.adlPublicationDate);
                        data.expulsionNoticeDate = DateUtils.convertDateTimeFromServer(data.expulsionNoticeDate);
                        data.explusionRequestDate = DateUtils.convertDateTimeFromServer(data.explusionRequestDate);
                        data.expulsionConfirmDate = DateUtils.convertDateTimeFromServer(data.expulsionConfirmDate);
                        data.informalInterventionDate = DateUtils.convertDateTimeFromServer(data.informalInterventionDate);

                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.submissionDate = DateUtils.convertDateTimeFromServer(data.submissionDate);
                        data.staffReviewDate = DateUtils.convertDateTimeFromServer(data.staffReviewDate);
                        data.firstInterventionDate = DateUtils.convertDateTimeFromServer(data.firstInterventionDate);
                        data.secondInterventionDate = DateUtils.convertDateTimeFromServer(data.secondInterventionDate);
                        data.thirdInterventionDate = DateUtils.convertDateTimeFromServer(data.thirdInterventionDate);
                        data.adlPublicationDate = DateUtils.convertDateTimeFromServer(data.adlPublicationDate);
                        data.expulsionNoticeDate = DateUtils.convertDateTimeFromServer(data.expulsionNoticeDate);
                        data.explusionRequestDate = DateUtils.convertDateTimeFromServer(data.explusionRequestDate);
                        data.expulsionConfirmDate = DateUtils.convertDateTimeFromServer(data.expulsionConfirmDate);
                        data.informalInterventionDate = DateUtils.convertDateTimeFromServer(data.informalInterventionDate);

                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            }
        });
    }
})();
