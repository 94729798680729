(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('ComplianceController', ComplianceController);

    ComplianceController.$inject = ['Compliance', 'ComplianceSearch'];

    function ComplianceController(Compliance, ComplianceSearch) {

        var vm = this;

        vm.compliances = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            Compliance.query(function(result) {
                vm.compliances = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            ComplianceSearch.query({query: vm.searchQuery}, function(result) {
                vm.compliances = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
