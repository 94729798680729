(function() {
	// 'use strict';

	angular.module('mobilityexchangeApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'issue-tracker',
						{
							parent : 'entity',
							url : '/issue-tracker?page&sort&search&issueId&filedBy&filedByMxStatus&filedAgainst&filedAgainstMxStatus&submittedOn&acknowledgedTarget&acknowledgedActual&resolutionTarget&resolutionActual&type&status&navtype',
							data : {
								authorities : [ 'IIRS_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ],
								pageTitle : 'mobilityexchangeApp.claimHeader.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/issue-tracker/issue-trackers.html',
									controller : 'IssueTrackerController',
									controllerAs : 'vm'
								}
							},
							params : {
								page : {
									value : '1',
									squash : true
								},
								sort : {
									value : 'id,desc',
									squash : true
								},
								search : null,
								issueId: null,
								filedBy : null,
								filedByMxStatus : null,
								filedAgainst : null,
								filedAgainstMxStatus : null,
                                submittedOn: null,
                                acknowledgedTarget: null,
                                acknowledgedActual: null,
                                resolutionTarget: null,
                                resolutionActual: null,
                                type: null,
								status : null,
								navtype : null
							},
							resolve : {
								pagingParams : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												page : PaginationUtil
														.parsePage($stateParams.page),
												sort : $stateParams.sort,
												predicate : PaginationUtil
														.parsePredicate($stateParams.sort),
												ascending : PaginationUtil
														.parseAscending($stateParams.sort),
												search : $stateParams.search
											};
										} ],
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('issueTracker');
											$translatePartialLoader
													.addPart('claimHeader');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})
				.state(
						'issue-tracker-lookup',
						{
							parent : 'entity',
							url : '/issue-tracker?page&sort&search&navtype',
							data : {
								authorities : [ 'IIRS_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ],
								pageTitle : 'mobilityexchangeApp.claimHeader.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/issue-tracker/issue-trackers.html',
									controller : 'IssueTrackerController',
									controllerAs : 'vm'
								}
							},
							params : {
								page : {
									value : '1',
									squash : true
								},
								sort : {
									value : 'id,desc',
									squash : true
								},
								search : null,
								issueId: null,
								filedBy : null,
								filedByMxStatus : null,
								filedAgainst : null,
								filedAgainstMxStatus : null,
                                submittedOn: null,
                                acknowledgedTarget: null,
                                acknowledgedActual: null,
                                resolutionTarget: null,
                                resolutionActual: null,
                                type: null,
								status : null,
							},
							resolve : {
								pagingParams : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												page : PaginationUtil
														.parsePage($stateParams.page),
												sort : $stateParams.sort,
												predicate : PaginationUtil
														.parsePredicate($stateParams.sort),
												ascending : PaginationUtil
														.parseAscending($stateParams.sort),
												search : $stateParams.search
											};
										} ],
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('claimHeader');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})
				.state(
						'issue-tracker-detail',
						{
							parent : 'issue-tracker',
							url : '/issue-tracker/{id}',
							data : {
								authorities : [ 'IIRS_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ],
								pageTitle : 'mobilityexchangeApp.claimHeader.detail.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/issue-tracker/issue-tracker-detail.html',
									controller : 'IssueTrackerDetailController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('claimHeader');
											return $translate.refresh();
										} ],
								entity : [ '$stateParams', 'IssueTracker',
										function($stateParams, claimHeader) {
											return IssueTracker.get({
												id : $stateParams.id
											}).$promise;
										} ],
								previousState : [
										"$state",
										function($state) {
											var currentStateData = {
												name : $state.current.name
														|| 'issue-tracker',
												params : $state.params,
												url : $state.href(
														$state.current.name,
														$state.params)
											};
											return currentStateData;
										} ]
							}
						})
				.state(
						'issue-tracker-detail.edit',
						{
							parent : 'issue-tracker-detail',
							url : '/detail/edit',
							data : {
								authorities : [ 'IIRS_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ]
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/issue-tracker/issue-tracker-dialog.html',
									controller : 'IssueTrackerDialogController',
									controllerAs : 'vm',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('claimHeader');
											return $translate.refresh();
										} ],
								entity : [ 'IssueTracker',
										function(IssueTracker) {
											return IssueTracker.get({
												id : $stateParams.id
											}).$promise;
										} ],
								previousState : [
										"$state",
										function($state) {
											var currentStateData = {
												name : $state.current.name,
												params : $state.params,
												url : $state.href(
														$state.current.name,
														$state.params)
											};
											return currentStateData;
										} ]
							}
						})
				.state(
						'issue-tracker.new',
						{
							parent : 'issue-tracker',
							// url : '/new/{sp}',
							url : '/new/{sp}?{filingServiceProvider}',
							data : {
								authorities : [ 'IIRS_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ]
							},
							views : {
								'content@' : {
									templateProvider: ['Principal', '$templateRequest', function (Principal, $templateRequest) {
									    var iirsAdmin = Principal.hasAnyAuthority(['IIRS_ADMIN']);
                                        if (iirsAdmin) {
                                            return $templateRequest('app/entities/issue-tracker/issue-tracker-dialog-admin.html');
                                        } else {
                                            return $templateRequest('app/entities/issue-tracker/issue-tracker-dialog-user.html');
                                        }
									}],
									controller : 'IssueTrackerDialogController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								entity : function() {
									return {
										comments : null,
										createdDate : null,
										createdBy : null,
										modifiedDate : null,
										modifiedBy : null,
										id : null
									};
								}
							},
							previousState : [
									"$state",
									function($state) {
										var currentStateData = {
											isCopy : 'false',
											params : $state.params,
											url : $state.href(
													$state.current.name,
													$state.params)
										};
										return currentStateData;
									} ]
						})
				.state(
						'issue-tracker.file',
						{
							parent : 'issue-tracker',
							url : '/file',
							data : {
								authorities : [ 'ROLE_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ]
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/issue-tracker/step1.html',
									controller : 'IssueTrackerFileController',
									controllerAs : 'vm',
								}
							},
							resolve : {
								entity : [
										'$stateParams',
										'IssueTracker',
										function($stateParams, IssueTracker) {
											return IssueTracker.getClaimant().$promise;
										} ]
							},
							previousState : [
									"$state",
									function($state) {
										var currentStateData = {
											isCopy : 'false',
											params : $state.params,
											url : $state.href(
													$state.current.name,
													$state.params)
										};
										return currentStateData;
									} ]
						})


				.state(
						'issue-tracker.non-member-company-selected',
						{
							parent : 'issue-tracker',
							url : '/non-member-company-selected/{sp}?{filingServiceProvider}',
							data : {
								authorities : [ 'ROLE_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ]
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/issue-tracker/non-member-company-selected.html',
									controller : 'IssueTrackerNonMemberSelectedController',
									controllerAs : 'vm',
								}
							},
							resolve : {
								entity : [
										'$stateParams',
										'IssueTracker',
										function($stateParams, IssueTracker) {
											return IssueTracker.getClaimant().$promise;
										} ]
							},
							previousState : [
									"$state",
									function($state) {
										var currentStateData = {
											isCopy : 'false',
											params : $state.params,
											url : $state.href(
													$state.current.name,
													$state.params)
										};
										return currentStateData;
									} ]
						})



				.state(
						'issue-tracker.edit',
						{
							parent : 'issue-tracker',
							url : '/{id}',
							data : {
								authorities : [ 'IIRS_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ],
								pageTitle : 'mobilityexchangeApp.claimHeader.detail.title'
							},
							views : {
								'content@' : {
									// templateUrl : 'app/entities/issue-tracker/issue-tracker-dialog.html',
									templateProvider: ['Principal', '$templateRequest', function (Principal, $templateRequest) {

									    // var rppAdmin = Principal.hasAnyAuthority(['RPP_ADMIN']);
									    var iirsAdmin = Principal.hasAnyAuthority(['IIRS_ADMIN']);
                                        if (iirsAdmin) {
                                            return $templateRequest('app/entities/issue-tracker/issue-tracker-dialog-admin.html');
                                        } else {
                                            return $templateRequest('app/entities/issue-tracker/issue-tracker-dialog-user.html');
                                        }
									}],
									controller : 'IssueTrackerDialogController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								entity : [ '$stateParams', 'IssueTracker',
										function($stateParams, IssueTracker) {
											return IssueTracker.get({
												id : $stateParams.id
											}).$promise;
										} ],
								previousState : [
										"$state",
										function($state) {
											var currentStateData = {
												name : $state.current.name
														|| 'issue-tracker',
												params : $state.params,
												url : $state.href(
														$state.current.name,
														$state.params)
											};
											return currentStateData;
										} ]
							}
						})
				.state(
						'issue-tracker.delete',
						{
							parent : 'issue-tracker',
							url : '/{id}/delete',
							data : {
								authorities : [ 'IIRS_ADMIN', 'ROLE_SP_ADMIN', 'ROLE_SP_FINANCE', 'ROLE_USER' ]
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/issue-tracker/issue-tracker-delete-dialog.html',
													controller : 'claimHeaderDeleteController',
													controllerAs : 'vm',
													size : 'md',
													resolve : {
														entity : [
																'IssueTracker',
																function(
																		IssueTracker) {
																	return IssueTracker
																			.get({
																				id : $stateParams.id
																			}).$promise;
																} ]
													}
												}).result.then(function() {
											$state.go('issue-tracker', null, {
												reload : 'issue-tracker'
											});
										}, function() {
											$state.go('^');
										});
									} ]
						});
	}

})();
