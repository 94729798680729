(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['Auth', 'Principal','$rootScope','Account'];

    function PasswordController(Auth, Principal,$rootScope, Account) {
        //$scope.controllerName = "PasswordController";

        var vm = this;
        $rootScope.showSearchBox = true;
        $rootScope.mainLogoLink = "#";
        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;

        Principal.identity().then(function (account) {
            vm.account = account;
        });

        function changePassword() {
            if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                vm.doNotMatch = null;
                Auth.changePassword(vm.password).then(function () {
                    vm.error = null;
                    vm.success = 'OK';
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }

        vm.userActivation = function (login) {

            console.log('Activating user :: '+login);

            vm.isSaving = true;
            Account.activateuser(login.id);
            vm.isSaving = false;
        };

    }
})();
