(function () {
    angular
        .module('mobilityexchangeApp');
    stateConfig.$inject = ['$stateProvider'];
    function stateConfig($stateProvider) {

        $stateProvider

            .state('expandRange', {
                parent: 'home',
                url: "search?svc&loc&lat&lng&page&sort&search&range&mocs&recs&sucs&frcs&cscs&uscs&lngs&assocs&certs&cmp&spId&ctry&inq&supplier&spc",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/search/search.html',
                        controller: 'SearchController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('sp.edit', {
                parent: 'searchdetails',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/service-providers/service-providers-dialog.html',
                        controller: 'ServiceProvidersDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'ServiceProviders', function ($stateParams, ServiceProviders) {
                        return ServiceProviders.get({id: $stateParams.id}).$promise;
                    }]
                }

            })

            .state('search', {
                parent: 'home',
                url: "search?svc&loc&lat&lng&page&sort&search&range&mocs&recs&sucs&frcs&cscs&uscs&lngs&assocs&certs&cmp&query&ctry&inq&supplier&spc",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/search/search.html',
                        controller: 'SearchController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    lat: null,
                    lng: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
