(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('MovingCapabilitiesDialogController', MovingCapabilitiesDialogController);

    MovingCapabilitiesDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'MovingCapabilities', 'ServiceProviders', 'Lookups'];

    function MovingCapabilitiesDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, MovingCapabilities, ServiceProviders, Lookups) {
        var vm = this;

        vm.movingCapabilities = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.serviceproviders = ServiceProviders.query();
        vm.lookups = Lookups.query();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.movingCapabilities.id !== null) {
                MovingCapabilities.update(vm.movingCapabilities, onSaveSuccess, onSaveError);
            } else {
                MovingCapabilities.save(vm.movingCapabilities, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('mobilityexchangeApp:movingCapabilitiesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdby = false;
        vm.datePickerOpenStatus.modifieddate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
