(function() {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('MemberPaymentControlDetailController', MemberPaymentControlDetailController);

    MemberPaymentControlDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MemberPaymentControl'];

    function MemberPaymentControlDetailController($scope, $rootScope, $stateParams, previousState, entity, MemberPaymentControl) {
        var vm = this;

        vm.memberPaymentControl = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:memberPaymentControlUpdate', function(event, result) {
            vm.memberPaymentControl = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
