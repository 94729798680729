(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('CustomerNotesController', CustomerNotesController);

    CustomerNotesController.$inject = ['CustomerNotes', 'CustomerNotesSearch', '$scope',
        'ParseLinks', 'AlertService', 'pagingParams',
        'paginationConstants', '$state', '$http'];

    function CustomerNotesController(CustomerNotes, CustomerNotesSearch, $scope,
                                     ParseLinks, AlertService, pagingParams,
                                     paginationConstants, $state, $http) {

        var vm = this;

        vm.customerNotes = [];
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        $rootScope.showSearchBox = true;
        $rootScope.mainLogoLink = "#";
        loadAll();


        $scope.actor;
        $scope.actors;
        $scope.actions;

        // get actors
        $http.get
        (
            'api/userslist',
            {}
        )
            .then
            (
                function (response) {

                    $scope.actors =
                        response.data
                            .filter
                            (
                                function (user) {
                                    return user.activated && (user.authorities.indexOf("ROLE_ADMIN") != -1 || user.authorities.indexOf("ROLE_ANALYST") != -1);
                                }
                            )
                            .map
                            (
                                function (user) {
                                    user.name = user.firstName + " " + user.lastName;
                                    return user;
                                }
                            )
                    ;

                    // set actor
                    for (var i = 0; i < $scope.actors.length; i++) {
                        if ($scope.actors[i].firstName + ' ' + $scope.actors[i].lastName == vm.customerNotes.actor) {
                            $scope.actor = $scope.actors[i];
                            break;
                        }
                    }
                }
            )
        ;

        // get actions

        $http.get
        (
            'api/lookups/lookuptypes/20',
            {}
        )
            .then
            (
                function (response) {
                    $scope.actions = response.data;

                    for (var i = 0; i < $scope.actions.length; i++) {
                        if (vm.customerNotes.action != null && $scope.actions[i].id == vm.customerNotes.action.id) {
                            vm.customerNotes.action = $scope.actions[i];
                            break;
                        }
                    }
                }
            )
        ;


        function loadAll() {
            if (pagingParams.search) {
                CustomerNotes.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                CustomerNotes.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.customerNotes = data;
                vm.page = pagingParams.page;
                _.forEach(vm.customerNotes, setDisplayNote);
            }

            function setDisplayNote(customerNote) {
                // Augment with display note. To avoid issues with long notes limit the number of
                // characters displayed in the table. Edit a note shows the complete note.
                var displayNoteLength = 100;
                customerNote.displayNote = (customerNote.note && customerNote.note.length < displayNoteLength) ?
                    customerNote.note : (customerNote.note.substring(0, displayNoteLength) + " ...");
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
