(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('MovingCapabilitiesDetailController', MovingCapabilitiesDetailController);

    MovingCapabilitiesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MovingCapabilities', 'ServiceProviders', 'Lookups'];

    function MovingCapabilitiesDetailController($scope, $rootScope, $stateParams, previousState, entity, MovingCapabilities, ServiceProviders, Lookups) {
        var vm = this;

        vm.movingCapabilities = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mobilityexchangeApp:movingCapabilitiesUpdate', function (event, result) {
            vm.movingCapabilities = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
