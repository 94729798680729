(function() {
	//'use strict';

	angular.module('mobilityexchangeApp').controller('InvoiceHeaderController',
			InvoiceHeaderController).controller('BatchBillingController',
			BatchBillingController).controller('PaymentGateWayController',
			PaymentGateWayController);

	BatchBillingController.$inject = [ '$state', 'InvoiceHeader',
			'InvoiceHeaderSearch', 'ParseLinks', 'AlertService',
			'paginationConstants', 'pagingParams', '$stateParams', '$http',
			'$scope', 'DateUtils', '$q', '$cookies' ];

	PaymentGateWayController.$inject = [ '$state', 'InvoiceHeader',
			'InvoiceHeaderSearch', 'AlertService', '$stateParams', '$http',
			'$scope', 'DateUtils', '$q', 'BillingPlan', 'Lookups', '$timeout',
			'$window' ];

	function PaymentGateWayController($state, InvoiceHeader,
			InvoiceHeaderSearch, AlertService, $stateParams, $http, $scope,
			DateUtils, $q, BillingPlan, Lookups, $timeout, $window) {

		var vm = this;
		vm.processing = false;
		vm.ref = $stateParams.ref;
		vm.invoiceId = $stateParams.invId;
		vm.donationsList = Lookups.getByLookupType({id: 45});
		console.log('in controller :: ' + vm.ref);

		if (vm.ref)
			vm.invoice = InvoiceHeader.getByRef({
				ref : vm.ref
			});

		if (vm.invoiceId)
			vm.invoice = InvoiceHeader.get({
				id : vm.invoiceId
			});

		vm.spId = $stateParams.id;
		vm.isDuesSplittable = false;
		vm.splitdues = false;

		/** ************************************************* */

		vm.sendMail = function(emailId, subject, message) {
			$window.open("mailto:" + emailId + "?subject=" + subject + "&body="
					+ message, "_self");
		};

		vm.splitPaymentDues = function() {
			if (!vm.invoiceId)
				vm.invoiceId = vm.invoice.id;

			if (vm.splitdues)
				$http.put(
						'/api/create-split-gov-invoice/spId/' + vm.spId
								+ '/invId/' + vm.invoiceId).then(function(res) {
					vm.invoice = res.data;
				});

		};

		$scope.showMessage = false;
		$scope.processing = false;
		$scope.progressValue = '100%';
		$scope.progressText = ' Processing Credit Card Payment completed.';

		$scope.handleStripe = function(status, response) {

			// console.log(JSON.stringify($scope.myForm.$error));
			$scope.showMessage = true;
			$scope.progressValue = '80%';
			$scope.progressText = ' Processing Credit Card Payment...';
			$scope.processing = true;

			if (!vm.invoiceId)
				vm.invoiceId = vm.invoice.id;

			if (response.error) {
				if (!$scope.myForm.exp.$valid)
					$scope.message = "INVALID EXPIRATION DATE";
				else if (!$scope.myForm.cvc.$valid)
					$scope.message = "INVALID CVS";
				else if (!$scope.myForm.cardn.$valid)
					$scope.message = "INVALID CARD NUMBER";
				else
					$scope.message = response.error.message ? response.error.message
							: response.error;

				$scope.status = "NOK";
				$scope.paid = false;
				$scope.number = '';
				$scope.expiry = '';
				$scope.cvc = '';
				$scope.progressValue = '100%';
				$scope.progressText = ' Processing Credit Card Payment completed.';
				// vm.processing = false;

			} else {

				vm.amount = vm.invoice.invoiceAmount - vm.invoice.paymentAmount;

				var $payInfo = {
					'token' : response.id,
					'name' : $scope.name,
					'phone' : $scope.phone,
					'email' : $scope.email,
					'number' : $scope.number,
					'expiry' : $scope.expiry,
					'cvc' : $scope.cvc,
					'amount' : vm.amount,
					'invId' : vm.invoiceId,
					'spId' : vm.spId
				};

				$http.post(
						'/api/chargefees?invId=' + vm.invoiceId + '&spId='
								+ vm.spId + '&split=' + vm.splitdues, $payInfo)
						.success(function(data) {
							$scope.processing = true;

							if (data.status == "OK") {
								$scope.paid = true;
								$scope.status = "OK";
								$scope.responsemessage = data.message;
								$scope.number = '';
								$scope.expiry = '';
								$scope.cvc = '';
								//$scope.showMessage = false;
								$scope.processing = false;
							} else {
								$scope.paid = false;
								$scope.status = "NOK";
								$scope.message = data.message;
								$scope.number = '';
								$scope.expiry = '';
								$scope.cvc = '';
								//$scope.showMessage = false;
								$scope.processing = false;
							}
							$scope.progressValue = '100%';
							$scope.progressText = ' Processing Credit Card Payment completed.';
						});
			}
		};

		/** **************************************************************** */

		vm.invoiceId = $stateParams.invId;
		vm.spId = $stateParams.id;

		vm.showHideCreditProcessing = false;
		vm.showCreditProcessing = function() {
			if (!vm.invoiceId)
				vm.invoiceId = vm.invoice.id;

			vm.showHideCreditProcessing = !vm.showHideCreditProcessing;

			$http.put(
					'api/invoice-headers/ccpayment/sp/' + vm.spId
							+ '/invoiceid/' + vm.invoiceId + '/change/'
							+ vm.ccredit).success(
					function(data, status, headers, config) {
						vm.invoice = data;
					});

			if (vm.wfees > 0) {
				vm.wfees = 0;
			}
			if (vm.check > 0) {
				vm.check = 0;
			}
		}

		vm.showHideCheckMailInstructions = false;
		vm.check = 0;
		vm.showCheckMailInstructions = function() {
			if (!vm.invoiceId)
				vm.invoiceId = vm.invoice.id;
			vm.showHideCheckMailInstructions = !vm.showHideCheckMailInstructions;
			$http.put(
					'api/invoice-headers/checkpayment/sp/' + vm.spId
							+ '/invoiceid/' + vm.invoiceId + '/change/'
							+ vm.check).success(
					function(data, status, headers, config) {
						vm.invoice = data;
					});
			if (vm.wfees > 0) {
				vm.wfees = 0;
			}
			if (vm.ccredit > 0) {
				vm.ccredit = 0;
			}
		}

		vm.showHideWireInstructions = false;
		vm.showWireInstructions = function() {
			vm.showHideWireInstructions = !vm.showHideWireInstructions;
			if (vm.ccredit > 0) {
				vm.ccredit = 0;
			}
			if (vm.check > 0) {
				vm.check = 0;
			}
		}

		if (vm.ref)
			vm.invoice = InvoiceHeader.getByRef({
				ref : vm.ref
			});

		if (vm.invoiceId)
			vm.invoice = InvoiceHeader.get({
				id : vm.invoiceId
			});

		console.log('vm.invoice :: ' + JSON.stringify(vm.invoice));

		vm.charityBillingPlans = BillingPlan.getAllByCategory({
			type : 1803
		});
		vm.charityBillingPlanMetaData = Lookups.getByLookupType({
			id : 63
		});
		vm.charityWebsiteAddress = [];

		vm.getCharityBillingPlanMetaData = function() {
			for (var i = 0; i < vm.charityBillingPlanMetaData.length; i++) {
				vm.charityWebsiteAddress[vm.charityBillingPlanMetaData[i].lookupcode] = vm.charityBillingPlanMetaData[i].flex1;
			}
		};
		$timeout(function() {
			vm.getCharityBillingPlanMetaData()
		}, 2000);

		vm.donation = [];
		vm.extraAmount = 0;
		vm.showDonationMessage = false;
		vm.otherAmtChecked = [];

		vm.setDonation = function(planId) {
			if (!vm.invoiceId)
				vm.invoiceId = vm.invoice.id;
			vm.showOtherAmt[planId] = false;
			vm.showDonationMessage = true;
			$http.put(
					'api/invoice-headers/donations/sp/' + vm.spId
							+ '/invoiceid/' + vm.invoiceId + '/donation/'
							+ vm.donation[planId] + '/billingPlan/' + planId)
					.success(function(data, status, headers, config) {
						vm.invoice = data;
					});
		};
		vm.addMemoryHonor = function(planId, donationForOption, donationForName) {
			if (!vm.invoiceId)
				vm.invoiceId = vm.invoice.id;
			vm.showOtherAmt[planId] = false;
			vm.showDonationMessage = true;
			$http.put(
					'api/invoice-headers/donations/sp/' + vm.spId
							+ '/invoiceid/' + vm.invoiceId + '/billingPlan/' + planId
							+ '/donationForOption/' + donationForOption
							+ '/donationForName/' + donationForName)
					.success(function(data, status, headers, config) {
						vm.invoice = data;
					});
		};

		vm.otherAmt = [];
		vm.setOtherDonation = function(planId) {
			if (!vm.invoiceId)
				vm.invoiceId = vm.invoice.id;
			vm.showDonationMessage = true;

			$http.put(
					'api/invoice-headers/donations/sp/' + vm.spId
							+ '/invoiceid/' + vm.invoiceId + '/donation/'
							+ vm.otherAmt[planId] + '/billingPlan/' + planId)
					.success(function(data, status, headers, config) {
						vm.invoice = data;
					});

		};

		vm.wfees = 0;

		vm.setWireTransferFees = function() {
			if (!vm.invoiceId)
				vm.invoiceId = vm.invoice.id;
			$http.put(
					'api/invoice-headers/sp/' + vm.spId + '/invoiceid/'
							+ vm.invoiceId + '/change/' + vm.wfees).success(
					function(data, status, headers, config) {
						vm.invoice = data;
					});
		};

		vm.setDonationValues = function() {

			if (!vm.invoiceId)
				vm.invoiceId = vm.invoice.id;

			console.log('1...' + 'api/invoice-headers/' + vm.invoiceId);

			$http
					.get('api/invoice-headers/' + vm.invoiceId)
					.success(
							function(data, status, headers, config) {

								vm.invoice = data;
								if (vm.invoice) {

									if (vm.invoice.paymentMethod) {
										if (vm.invoice.paymentMethod.lookupcode === 'WIRE') {
											vm.wfees = 10;
										}
										if (vm.invoice.paymentMethod.lookupcode === 'CHK') {
											vm.check = 10;
										}
										if (vm.invoice.paymentMethod.lookupcode === 'CCREDIT') {
											vm.ccredit = 10;
										}
									}

									var invoiceLines = vm.invoice.invoiceLines;
									if (invoiceLines) {
										for (var i = 0; i < invoiceLines.length; i++) {

											var invoiceLine = invoiceLines[i];
											if (invoiceLine.billingPlan
													&& invoiceLine.billingPlan.category
													&& invoiceLine.billingPlan.category.id === 1803) {

												vm.donation[invoiceLine.billingPlan.id] = invoiceLine.amount;

												if (invoiceLine.amount !== 50
														|| invoiceLine.amount !== 100) {
													console
															.log('invoiceLine.amount :: '
																	+ invoiceLine.amount);
													vm.otherAmt[invoiceLine.billingPlan.id] = invoiceLine.amount;
													vm.showOtherAmt[invoiceLine.billingPlan.id] = true;
													vm.otherAmtChecked[invoiceLine.billingPlan.id] = true;
												}
											}

										}

										// determine if dues can be split
										for (var i = 0; i < invoiceLines.length; i++) {
											var invLine = invoiceLines[i];
											if (invLine.billingPlan
													&& invLine.billingPlan.memberType
													&& invLine.billingPlan.memberType.id === 1661) {
												console
														.log(invLine.amount
																+ '    ======   '
																+ invLine.billingPlan.rates);
												if (invLine.billingPlan
														&& invLine.billingPlan.rates
														&& invLine.amount === invLine.billingPlan.rates) {
													vm.isDuesSplittable = true;
												}
											}
										}

									}

								}
							});

		};

		$timeout(function() {
			vm.setDonationValues()
		}, 2000);

		vm.showOtherAmt = [];

		vm.showHideOtherAmt = function(charityBillingPlanId) {
			if (!vm.showOtherAmt[charityBillingPlanId]) {
				vm.showOtherAmt[charityBillingPlanId] = true;
				vm.otherAmt[charityBillingPlanId] = 0;
				vm.setOtherDonation(charityBillingPlanId);
			} else {
				vm.showOtherAmt[charityBillingPlanId] = false;
				vm.otherAmt[charityBillingPlanId] = 0;
				vm.setOtherDonation(charityBillingPlanId);
			}
			// vm.showOtherAmt[charityBillingPlanId] =
			// !vm.showOtherAmt[charityBillingPlanId];
		};

		vm.previewInvoice = function(event, invoiceId) {

			event.preventDefault();
			event.stopPropagation();
			if (_.isEmpty(vm.taxId))
				vm.taxId = " ";
			$http.get('api/generate-invoice/' + invoiceId, {
				responseType : 'arraybuffer'
			}).then(function successCallback(data) {
				var file = new Blob([ data.data ], {
					type : 'application/pdf'
				});
				var fileURL = URL.createObjectURL(file);
				window.open(fileURL);
			}, function errorCallback(response) {
			});

		};

		vm.isIAMInvoice = function() {
			var invoiceLines = vm.invoice.invoiceLines;
			var iam = false;
			if (invoiceLines)
				for (var i = 0; i < invoiceLines.length; i++) {
					var invoiceLine = invoiceLines[i];
					if (invoiceLine.billingPlan != null
							&& invoiceLine.billingPlan.association != null) {
						var assoc = invoiceLine.billingPlan.association.lookupvalue;
						if (assoc === "IAM" || assoc === "ILN"
								|| assoc === "MXVAL") {
							return true;
						}
					} else {
						iam = false;
					}
				}
			return iam;
		};

		vm.isISAInvoice = function() {
			var invoiceLines = vm.invoice.invoiceLines;
			var isa = true;
			if (invoiceLines)
				for (var i = 0; i < invoiceLines.length; i++) {
					var invoiceLine = invoiceLines[i];
					if (invoiceLine.billingPlan != null
							&& invoiceLine.billingPlan.association != null) {
						var assoc = invoiceLine.billingPlan.association.lookupvalue;
						if (assoc === "IAM" || assoc === "ILN") {
							return false;
						}
					} else {
						isa = false;
					}
				}
			return isa;
		};

		vm.isAFWSFChairityInvoiceLine = function() {
			var invoiceLines = vm.invoice.invoiceLines;
			var isCharity = false;
			if (invoiceLines)
				for (var i = 0; i < invoiceLines.length; i++) {
					var invoiceLine = invoiceLines[i];
					if (invoiceLine.billingPlan != null
							&& invoiceLine.billingPlan.id != null) {

						var planName = invoiceLine.billingPlan.planName;

						var assoc;
						if (invoiceLine.billingPlan.association)
							assoc = invoiceLine.billingPlan.association.lookupvalue;

						if (planName === "AFWSF Donation") {
							isCharity = true;
						} else if (assoc === "IAM" || assoc === "ILN"
								|| assoc === "ISA") {
							return false;
						}
					}
				}
			return isCharity;
		};

		vm.isH2HFChairityInvoiceLine = function() {
			var invoiceLines = vm.invoice.invoiceLines;
			var isCharity = false;
			if (invoiceLines)
				for (var i = 0; i < invoiceLines.length; i++) {
					var invoiceLine = invoiceLines[i];
					if (invoiceLine.billingPlan != null
							&& invoiceLine.billingPlan.id != null) {
						var planName = invoiceLine.billingPlan.planName;
						var assoc;
						if (invoiceLine.billingPlan.association)
							assoc = invoiceLine.billingPlan.association.lookupvalue;
						if (planName === "H2H Donation") {
							isCharity = true;
						} else if (assoc === "IAM" || assoc === "ILN"
								|| assoc === "ISA") {
							return false;
						}
					}
				}
			return isCharity;
		};

	}

	function BatchBillingController($state, InvoiceHeader, InvoiceHeaderSearch,
			ParseLinks, AlertService, paginationConstants, pagingParams,
			$stateParams, $http, $scope, DateUtils, $q, $cookies) {

		var vm = this;

		vm.batchPeriod = null;
		vm.invoiceDate = null;
		vm.datePickerOpenStatus = [];
		vm.datePickerOpenStatus.invoiceDate = false;
		vm.datePickerOpenStatus.invoiceAdvDate = false;
		vm.datePickerOpenStatus.invoiceFromDate = false;
		vm.datePickerOpenStatus.invoiceToDate = false;
		$scope.displayDateFormat = DateUtils.displayDateFormat;
		vm.openCalendar = openCalendar;

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}

		vm.showProgress = false;
		vm.showAdProgress = false;
		vm.invoiceHeaders = null;

		vm.searchInvoices = function() {
			vm.invoiceHeaders = InvoiceHeader.get();
		};

		vm.batchAlreadyRunning = false;
		vm.batchRunning = false;
		vm.advRunningCompleted = false;

		vm.isBatchRunning = function() {
			$http
					.get('/api/isbatchrunning')
					.then(
							function(res) {
								vm.batchAlreadyRunning = false;
							},
							function(res) {
								console.log(JSON.stringify(res));
								if (res
										&& res.data
										&& (res.data.batch_status === 'BATCH_ALREADY_RUNNING')) {
									vm.batchAlreadyRunning = true;
								}
							});
		};

		vm.isBatchRunning();

		vm.executeBatchBilling = function() {

			vm.batchRunning = true;
			var invDate = DateUtils.convertLocalDateToServer(vm.invoiceDate);
			vm.showProgress = true;
			var promises = [];

			$http({
				method : 'POST',
				url : '/api/batch-billing',
				headers : {
					'X-CSRF-TOKEN' : $cookies.get('CSRF-TOKEN'),
					'cookie' : $cookies.cookiename
				},
				data : {
					invoiceYear : vm.batchPeriod,
					invoiceDate : invDate
				}
			}).then(function(response) {
				console.log(response);
				vm.showProgress = false;
			});

			/*
			 * $http({ method: 'POST', url:
			 * '/api/batch-billing?invoiceYear='+vm.batchPeriod+"&invoiceDate="+invDate,
			 * headers: { 'X-CSRF-TOKEN': $cookies.get('CSRF-TOKEN') }, data:
			 * null) .then(function(response){ vm.showProgress = false; });
			 */

		};

		vm.billableCount = null;

		vm.getBillableCount = function() {
			$http.get('/api/billable-count').then(function(response) {
				vm.billableCount = response.data;
			});
		}

		vm.getAdvInvoiceLineCount = function() {

			var invDate = DateUtils.convertLocalDateToServer(vm.invoiceAdvDate);
			$http({
				method : 'GET',
				url : '/api/invoice-line-count',
				headers : {
					'X-CSRF-TOKEN' : $cookies.get('CSRF-TOKEN'),
					'cookie' : $cookies.cookiename
				},
				params : {
					invoiceDate : invDate
				}
			}).then(function(response) {
				vm.advInvoiceCount = response.data;
			});
		}

		vm.advInvoiceCount = null;

		vm.executeAdvertiseInvoiceLine = function() {

			var invDate = DateUtils.convertLocalDateToServer(vm.invoiceAdvDate);
			vm.showAdProgress = true;
			var promises = [];

			$http({
				method : 'POST',
				url : '/api/adv-invoice-line',
				headers : {
					'X-CSRF-TOKEN' : $cookies.get('CSRF-TOKEN'),
					'cookie' : $cookies.cookiename
				},
				data : {
					invoiceDate : invDate
				}
			}).then(function(response) {
				vm.showAdProgress = false;
				vm.advRunningCompleted = true;
			});

		};

	}
	;

	InvoiceHeaderController.$inject = [ '$state', 'InvoiceHeader',
			'InvoiceHeaderSearch', 'ParseLinks', 'AlertService',
			'paginationConstants', 'pagingParams', '$stateParams', '$http',
			'$scope', 'DateUtils', 'Lookups', 'ServiceProviders', '$q',
			'$cookies', 'Principal' ];

	function InvoiceHeaderController($state, InvoiceHeader,
			InvoiceHeaderSearch, ParseLinks, AlertService, paginationConstants,
			pagingParams, $stateParams, $http, $scope, DateUtils, Lookups,
			ServiceProviders, $q, $cookies, Principal) {

		var vm = this;

		vm.loadPage = loadPage;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.clear = clear;
		vm.search = search;
		vm.loadAll = loadAll;
		vm.searchQuery = pagingParams.search;
		vm.currentSearch = pagingParams.search;
		vm.datePickerOpenStatus = {};
		$scope.displayDateFormat = DateUtils.displayDateFormat;
		vm.datePickerOpenStatus.invoiceDate = false;
		vm.datePickerOpenStatus.invoiceFromDate = false;
		vm.datePickerOpenStatus.invoiceToDate = false;
		vm.datePickerOpenStatus.paymenteDate = false;
		vm.datePickerOpenStatus.emailInvoiceDate = false;
		vm.openCalendar = openCalendar;
		vm.emailsent = false;
		vm.reminderSendPending = false;

		vm.spName = $stateParams.spn;
		vm.sp = $stateParams.sp;
		vm.spn = $stateParams.spn;

		vm.invoiceStatus = Lookups.getByLookupType({
			id : 36
		});
		vm.paymentStatus = Lookups.getByLookupType({
			id : 39
		});
		vm.paymentMethods = Lookups.getByLookupType({
			id : 40
		});

		vm.emailPopupUrl = "email_invoice_templ.html";
		vm.emailOnePopupUrl = "email_one_invoice_templ.html";
		vm.emailOneReminderPopupUrl = "email_one_reminder_templ.html";
		vm.emailInvoiceDate;

		vm.searchinvoiceDate = DateUtils
				.convertDateTimeFromServer($stateParams.invdate);
		//
		vm.searchpaymenteDate = DateUtils
				.convertDateTimeFromServer($stateParams.pdate);
		// vm.searchinvoiceStatus = $stateParams.invstat;
		vm.searchpaymentMethod = $stateParams.paymeth;
		vm.invoiceAmount = $stateParams.invamt;
		vm.spcName = $stateParams.spname;
		vm.invFromDate = DateUtils
				.convertDateTimeFromServer($stateParams.invfromdate);
		vm.invtodate = DateUtils
				.convertDateTimeFromServer($stateParams.invtodate);
		vm.invnum = $stateParams.invnum;
		vm.payref = $stateParams.payref;
		// vm.searchpaymentStatus = $stateParams.pstat;
		//

        vm.mxFinanceAdmin = Principal.hasAnyAuthority(['ROLE_MX_FINANCE']);

		if (!vm.sp)
			vm.sp = $stateParams.spId;

		vm.typeOfDocToBeEmailed;
		vm.idOdDocToBeEmailed;

		vm.setValuesForDocToBeEmailed = function(type, docId) {
			console.log(type + '    ' + docId);
			vm.typeOfDocToBeEmailed = type;
			vm.idOdDocToBeEmailed = docId;
			vm.emailsent = false;
			if (type === 'Reminder') {
			    vm.initializeEmailInvoiceReminderState();
			}
		};

		vm.emailInvoices = function(e) {
			e.stopPropagation();
			e.preventDefault();
			vm.emailsent = true;
			vm.reminderSendPending = false;
			var invDate = DateUtils
					.convertLocalDateToServer(vm.emailInvoiceDate);

			// $http.post('/api/email-invoices/'+invDate);

			$http({
				method : 'POST',
				url : '/api/email-invoices',
				headers : {
					'X-CSRF-TOKEN' : $cookies.get('CSRF-TOKEN'),
					'cookie' : $cookies.cookiename
				},
				data : {
					invDate : invDate
				}
			}).then(function(response) {
				console.log(response);
				// vm.showProgress = false;
			});
		};

		vm.emailOne = function(e) {
			e.stopPropagation();
			e.preventDefault();

			// For email reminders we display message that the email was sent once we get a response from the server
			// that
			if (vm.typeOfDocToBeEmailed === 'Reminder') {
			    vm.reminderSendPending = true;
			} else {
			    vm.emailsent = true;
			}

			console.log("Sending emails ====>> " + vm.typeOfDocToBeEmailed);

			if (vm.typeOfDocToBeEmailed === 'Reciept') {

				$http({
					method : 'POST',
					url : '/api/email-one-reciept',
					headers : {
						'X-CSRF-TOKEN' : $cookies.get('CSRF-TOKEN'),
						'cookie' : $cookies.cookiename
					},
					data : {
						idOfDocToBeEmailed : vm.idOdDocToBeEmailed
					}
				}).then(function(response) {
					console.log(response);
					vm.showProgress = false;
				});

				/*
				 * $http.post('/api/email-one-reciept/' +
				 * vm.idOdDocToBeEmailed);
				 */
			}

			if (vm.typeOfDocToBeEmailed === 'Invoice') {

				$http({
					method : 'POST',
					url : '/api/email-one-invoice',
					headers : {
						'X-CSRF-TOKEN' : $cookies.get('CSRF-TOKEN'),
						'cookie' : $cookies.cookiename
					},
					data : {
						idOfDocToBeEmailed : vm.idOdDocToBeEmailed
					}

				}).then(function(response) {
					console.log(response);
					vm.showProgress = false;
				});

				/*
				 * $http.post('/api/email-one-invoice/' +
				 * vm.idOdDocToBeEmailed);
				 */

			}

			if (vm.typeOfDocToBeEmailed === 'Reminder') {
                console.log('Reminder email');
                vm.processEmailInvoiceReminder();
			}
		};

        vm.processEmailInvoiceReminder = function() {
            console.log('Reminder email');
            vm.reminderSendPending = true;
            $http({
                method : 'POST',
                url : '/api/email-one-invoice-reminder',
                headers : {
                    'X-CSRF-TOKEN' : $cookies.get('CSRF-TOKEN'),
                    'cookie' : $cookies.cookiename
                },
                data : {
                    idOfDocToBeEmailed : vm.idOdDocToBeEmailed
                }
            }).then(function(response) {
                console.log(response);
                vm.showProgress = false;
                vm.reminderSent = true;
                vm.reminderSendPending = false;
            }).catch(function(err) {
                console.log("Failed to send invoice email reminder, details: " + err);
                vm.showProgress = false;
                if (err.status === 400) {
                    console.log("Failed to send invoice email reminder - no recipients");
                    vm.reminderNoRecipients = true;
                } else {
                    console.log("Failed to send invoice email reminder - server error");
                    vm.reminderSendError = true;
                }
                vm.reminderSendPending = false;
            });
        }

        vm.initializeEmailInvoiceReminderState = function() {
            vm.reminderSendPending = false;
            vm.reminderSent = false;
            vm.reminderNoRecipients = false;
            vm.reminderSendError = false;
        }

		vm.previewReceipt = function(event, invoiceId) {

			event.preventDefault();
			event.stopPropagation();

			if (_.isEmpty(vm.taxId))
				vm.taxId = " ";

			$http.get('api/generate-receipt/' + invoiceId, {
				responseType : 'arraybuffer'
			}).then(function successCallback(data) {

				var file = new Blob([ data.data ], {
					type : 'application/pdf'
				});
				var fileURL = URL.createObjectURL(file);
				window.open(fileURL);

			}, function errorCallback(response) {
			});

		};

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}

		loadAll();

		vm.setReviewed = function(sp) {
			if (!sp)
				sp = 0;
			$http
					.put(
							'/api/update-invoices-status/spId/0/toStatus/1761/fromStatus/1760')
					.then(function(data) {
						vm.invoiceHeaders = data.data;
					});
		};

		vm.setPublished = function(sp) {
			if (!sp)
				sp = 0;
			$http
					.put(
							'/api/update-invoices-status/spId/0/toStatus/1762/fromStatus/1761')
					.then(function(data) {
						vm.invoiceHeaders = data.data;
					});
		};

		function loadAll() {

			console.log('loadAll...');

			if (pagingParams.search) {
				InvoiceHeaderSearch
						.query(
								{

									query : pagingParams.search,
									page : pagingParams.page - 1,
									size : vm.itemsPerPage,
									sort : sort(),
									spId : vm.sp,
									spn : vm.spn,
									invdate : vm.searchinvoiceDate ? DateUtils
											.convertLocalDateToServer(vm.searchinvoiceDate)
											: DateUtils
													.convertLocalDateToServer($stateParams.invdate),
									pdate : vm.searchpaymenteDate ? DateUtils
											.convertLocalDateToServer(vm.searchpaymenteDate)
											: $stateParams.pdate,
									invstat : vm.searchinvoiceStatus ? vm.searchinvoiceStatus.lookupcode
											: $stateParams.invstat,
									paymeth : vm.searchpaymentMethod ? vm.searchpaymentMethod.lookupcode
											: $stateParams.paymeth,
									invamt : vm.invoiceAmount ? vm.invoiceAmount
											: $stateParams.invamt,
									spname : vm.spcName ? vm.spcName
											: $stateParams.spname,
									invfromdate : vm.invFromDate ? DateUtils
											.convertLocalDateToServer(vm.invFromDate)
											: DateUtils
													.convertLocalDateToServer($stateParams.invfromdate),
									invtodate : vm.invoiceToDate ? DateUtils
											.convertLocalDateToServer(vm.invoiceToDate)
											: DateUtils
													.convertLocalDateToServer($stateParams.invoiceToDate),
									invnum : vm.invnum ? vm.invnum
											: $stateParams.invnum,
									payref : vm.payref ? vm.payref
													: $stateParams.payref,
									pstat : vm.searchpaymentStatus ? vm.searchpaymentStatus.lookupcode
											: $stateParams.pstat

								}, onSuccess, onError);
			} else {
				InvoiceHeader
						.query(
								{

									page : pagingParams.page - 1,
									size : vm.itemsPerPage,
									sort : sort(),
									spId : $stateParams.spId,
									spn : vm.spn,
									invdate : vm.searchinvoiceDate ? DateUtils
											.convertLocalDateToServer(vm.searchinvoiceDate)
											: DateUtils
													.convertLocalDateToServer($stateParams.invdate),
									pdate : vm.searchpaymenteDate ? DateUtils
											.convertLocalDateToServer(vm.searchpaymenteDate)
											: $stateParams.pdate,
									invstat : vm.searchinvoiceStatus ? vm.searchinvoiceStatus.lookupcode
											: $stateParams.invstat,
									paymeth : vm.searchpaymentMethod ? vm.searchpaymentMethod.lookupcode
											: $stateParams.paymeth,
									invamt : vm.invoiceAmount ? vm.invoiceAmount
											: $stateParams.invamt,
									spname : vm.spcName ? vm.spcName
											: $stateParams.spname,
									invfromdate : vm.invFromDate ? DateUtils
											.convertLocalDateToServer(vm.invFromDate)
											: DateUtils
													.convertLocalDateToServer($stateParams.invfromdate),
									invtodate : vm.invoiceToDate ? DateUtils
											.convertLocalDateToServer(vm.invoiceToDate)
											: DateUtils
													.convertLocalDateToServer($stateParams.invoiceToDate),
									invnum : vm.invnum ? vm.invnum
											: $stateParams.invnum,
									payref : vm.payref ? vm.payref
													: $stateParams.payref,
									pstat : vm.searchpaymentStatus ? vm.searchpaymentStatus.lookupcode
											: $stateParams.pstat

								}, onSuccess, onError);
			}

			function sort() {

				var result = [ vm.predicate + ','
						+ (vm.reverse ? 'asc' : 'desc') ];

				if (vm.predicate !== 'invoiceDate') {
					result.push('invoiceDate');
				}

				console.log(result);

				return result;
			}

			vm.companyLegalNames = [];

		}

		function onSuccess(data, headers) {
			vm.links = ParseLinks.parse(headers('link'));
			vm.totalItems = headers('X-Total-Count');
			vm.queryCount = vm.totalItems;
			vm.invoiceHeaders = data;
			vm.companyLegalNames = [];

			var promises = [];
			var invIds = [];

			// console.log(vm.companyLegalNames);
			vm.page = pagingParams.page;

			for (var i = 0; i < vm.invoiceHeaders.length; i++) {
				var req = vm.invoiceHeaders[i].serviceprovider;
				if (req && !req.id) {
					var refId = req;
					var refObj = _.find(vm.invoiceHeaders, function(o) {
						return o.serviceprovider.id == refId;
					});
					vm.invoiceHeaders[i].serviceprovider = refObj.serviceprovider;
				}
			}

		}

		function onError(error) {
			AlertService.error(error.data.message);
		}

		/*
		 * vm.spnames = []; vm.getCompanyLegalName = function(id){ var cname ;
		 * return $timeout(function(){ cname = vm.companyLegalNames[id];
		 * vm.spnames[id] = vm.companyLegalNames[id]; return cname; },2000); };
		 */

		function loadPage(page) {

			console.log('loadPage...');

			vm.page = page;
			vm.transition();
		}

		function transition() {

			$state
					.transitionTo(
							$state.$current,
							{
								page : vm.page,
								sort : vm.predicate + ','
										+ (vm.reverse ? 'asc' : 'desc'),
								search : vm.currentSearch,
								spId : $stateParams.spId,
								spn : $stateParams.spn,
								invdate : vm.searchinvoiceDate ? DateUtils
										.convertLocalDateToServer(vm.searchinvoiceDate)
										: DateUtils
												.convertLocalDateToServer($stateParams.invdate),
								pdate : vm.searchpaymenteDate ? DateUtils
										.convertLocalDateToServer(vm.searchpaymenteDate)
										: $stateParams.pdate,
								invstat : vm.searchinvoiceStatus ? vm.searchinvoiceStatus.lookupcode
										: $stateParams.invstat,
								paymeth : vm.searchpaymentMethod ? vm.searchpaymentMethod.lookupcode
										: $stateParams.paymeth,
								invamt : vm.invoiceAmount ? vm.invoiceAmount
										: $stateParams.invamt,
								spname : vm.spcName ? vm.spcName
										: $stateParams.spname,
								invnum : vm.invnum ? vm.invnum
										: $stateParams.invnum,
								payref : vm.payref ? vm.payref
												: $stateParams.payref,
								invfromdate : vm.invFromDate ? DateUtils
										.convertLocalDateToServer(vm.invFromDate)
										: DateUtils
												.convertLocalDateToServer($stateParams.invfromdate),
								invtodate : vm.invoiceToDate ? DateUtils
										.convertLocalDateToServer(vm.invoiceToDate)
										: DateUtils
												.convertLocalDateToServer($stateParams.invoiceToDate),
								pstat : vm.searchpaymentStatus ? vm.searchpaymentStatus.lookupcode
										: $stateParams.pstat
							});

			vm.currentSearch = null;

		}

		function appendQuery(q, con) {

			if (q && con)
				return " " + q + " AND " + con;
			else if (!q && con)
				return con;
			else if (q && !con)
				return q;
		}

		function search(searchQuery) {

			console.log('search....');

			/*
			 * if(vm.searchinvoiceDate) searchQuery = appendQuery(searchQuery, "
			 * invoiceDate=\""+DateUtils.convertLocalDateToServer(vm.searchinvoiceDate)+"\"");
			 * if(vm.searchpaymenteDate) searchQuery = appendQuery(searchQuery, "
			 * paymentDate=\""+DateUtils.convertLocalDateToServer(vm.searchpaymenteDate)+"\"");
			 * if(vm.searchinvoiceStatus) searchQuery = appendQuery(searchQuery, "
			 * invoiceStatus.lookupcode="+vm.searchinvoiceStatus.lookupcode+"");
			 * if(vm.searchpaymentStatus) searchQuery = appendQuery(searchQuery, "
			 * paymentStatus.lookupcode="+vm.searchpaymentStatus.lookupcode+"");
			 * if(vm.searchpaymentMethod) searchQuery = appendQuery(searchQuery, "
			 * paymentMethod.lookupcode="+vm.searchpaymentMethod.lookupcode+"");
			 * if(vm.invoiceAmount) searchQuery = appendQuery(searchQuery, "
			 * invoiceAmount="+vm.invoiceAmount+".0"); if(vm.spcName)
			 * searchQuery = appendQuery(searchQuery,
			 * "(serviceprovider.companylegalname='"+encodeURIComponent(vm.spcName)+"' " +
			 * "OR
			 * serviceprovider.tradenames='"+encodeURIComponent(vm.spcName)+"')");
			 */

			// console.log(searchQuery);
			searchQuery = '-';

			if (!searchQuery) {
				return vm.clear();
			}

			vm.links = null;
			vm.page = 1;
			vm.predicate = 'invoiceDate';
			vm.reverse = false;
			vm.currentSearch = searchQuery;
			vm.transition();
		}

		function clear() {
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			vm.currentSearch = null;
			vm.transition();
		}

		vm.previewInvoice = function(event, invoiceId) {

			event.preventDefault();
			event.stopPropagation();

			if (_.isEmpty(vm.taxId))
				vm.taxId = " ";

			$http.get('api/generate-invoice/' + invoiceId, {
				responseType : 'arraybuffer'
			}).then(function successCallback(data) {

				var file = new Blob([ data.data ], {
					type : 'application/pdf'
				});
				var fileURL = URL.createObjectURL(file);
				window.open(fileURL);

			}, function errorCallback(response) {
			});

		};

	}
})();
